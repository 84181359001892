import React, { useState, useContext, useEffect } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasicTextViewWithImg,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
} from "../../Components/Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";

import EndPoints from "../../Constants/EndPoints";
import { POST } from "../../Services/api_services";
import { SettingsRadioButton } from "../../Components/Edit Profile/SettingsComponents/SettingsUtills";
import config from "../../config";
import LoaderGif from "../../Gif/loader.gif"
import Constants, {
  EventKeys,
  EventName,
} from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import FoodHabitImage from '../../Images/Group 509.svg'
import SmookingImage from '../../Images/Group 508.svg'
import DrinkingImage from '../../Images/Group 507.svg'
import { NavBarContext } from "../../Contexts/NavBarContext";
import axios from "axios";
import { getDeviceInfo, getGroupAuthId } from "../../Components/Kalyanlite/utils";
import { useDispatch, useSelector } from "react-redux";
import { getConversationList } from "../../Store/Chats/Action";
import { useTranslation } from "react-i18next";
import OverflowToolTip from "../utils/OverflowTooltip";

const HabitsAddComponents = (props) => {
  const [isShowEdit, setEditView] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [editMyProfileData, setEditMyProfielData] = useState();
const [enablesavebtn,setEnableSavebtn] = useState(true)
const { completeNow,setCompleteNow ,showModel,setShowModel ,clickAddHabits, setClickAddHabits ,submitStatus, setSubmitStatus} = useContext(NavBarContext);
const [commonError, setCommonError] = useState("");

const { t:trans } = useTranslation();

console.log(clickAddHabits,"clickAddHabits");
  let foodHabits = [
    {
      foodHabits: trans('trial_pack_popup__habits_detai.vegetarian'),
      selected: "N",
      id: "1",
    },
    {
      foodHabits:trans('trial_pack_popup__habits_detai.non_vegetarian'),
      selected: "N",
      id: "2",
    },
    {
      foodHabits:trans('trial_pack_popup__habits_detai.eggetarian'),
      selected: "N",
      id: "3",
    },
  ];
  let smokingDrinkingHabits = [
    {
      name: trans('trial_pack_popup__habits_detai.no'),
      selected: "N",
      id: "1",
    },
    {
      name: trans('trial_pack_popup__habits_detai.occasionally'),
      selected: "N",
      id: "2",
    },
    {
      name: trans('trial_pack_popup__habits_detai.yes'),
      selected: "N",
      id: "3",
    },
  ];
  const [foodHabitsData, setFoodHabits] = useState(foodHabits);
  const [smokingHabits, setSmokingHabits] = useState(smokingDrinkingHabits);
  const [drinkingHabits, setDrinkingHabits] = useState(smokingDrinkingHabits);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [packStatusdata, setPackStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch()
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
    );
  const loggedInProfileStatus = userData?.status
  const receiverId = useSelector((state)=> state?.Chats?.currentChat?.receiverId)
  
  useEffect(() => {
    // apicallDashboard()
    setEditMyProfielData(props.data || props.editMyProfileData);
  }, [props.dashboard1Data, props.data]);

console.log(submitStatus,"submitStatus");
  useEffect(() => {
    console.log("editMyProfileData", editMyProfileData);
    console.log(isDataChanged,'isDataChanged');
  }, [
    editMyProfileData,
    isDataChanged
   
  ]);
  useEffect(()=>{
    if(props.isFrom == 'TrailPack'){
      props.apiCallEditProfile()
    }
  },[])
  useEffect(() => {}, [foodHabitsData, smokingHabits, drinkingHabits]);

  const initialSet = ()=>{
    setFoodValue(editMyProfileData?.food ? editMyProfileData?.food[0]?.name : "" );
    setSmokeValue(editMyProfileData?.smoking ? editMyProfileData?.smoking[0]?.id : "" );
    setDrinkingValue(
      editMyProfileData?.drinking ? editMyProfileData?.drinking[0]?.name : ""
    );
  }

  useEffect(initialSet, [editMyProfileData]);
  const setFoodValue = (item) => {
    var newVal = foodHabitsData.map((ele, i) => {
      if (item === ele.foodHabits) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    console.log(newVal,'newVal');
    setFoodHabits(newVal);
    setIsDataChanged(true);
    checkEnableSaveButton(newVal, smokingHabits, drinkingHabits);

  };
  const setSmokeValue = (item) => {
    var newVal = smokingHabits.map((ele, i) => {
      if (item == ele.id) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    setSmokingHabits(newVal);
    setIsDataChanged(true);
    checkEnableSaveButton(foodHabitsData, newVal, drinkingHabits);

  };
  const setDrinkingValue = (item) => {
    var newVal = drinkingHabits.map((ele, i) => {
      if (item === ele.name) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    setDrinkingHabits(newVal);
    setIsDataChanged(true);
    checkEnableSaveButton(foodHabitsData, smokingHabits, newVal);

  };

  const requestType = props.isFrom == 24 || props.isFrom == 25 || props.isFrom == 26

  const checkEnableSaveButton = (foodData, smokingData, drinkingData) => {
    const isFoodSelected = foodData.some((item) => item.selected === "Y");
    const isSmokingSelected = smokingData.some((item) => item.selected === "Y");
    const isDrinkingSelected = drinkingData.some((item) => item.selected === "Y");

    if (isFoodSelected && isSmokingSelected && isDrinkingSelected) {
      setEnableSavebtn(false);
      setCommonError("");
    }else if(requestType && (isFoodSelected || isSmokingSelected || isDrinkingSelected)){
      setEnableSavebtn(false);
      setCommonError("");
    } else {
      setEnableSavebtn(true);
      setSubmitStatus(false)
      if(props.isFrom == 'TrailPack'){
        setCommonError("")      
      }else if(!requestType){
        setCommonError(trans('trial_pack_popup__habits_detai.all_fields_are_mandatory'));
      }
    }
  };
  // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? 
  // sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId

  const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
  const Notificationsid = userData?.popup[0]?.notificationId;
  
  const isValid = (value) => value && (!Array.isArray(value) || value.length > 0);
  
  const NotificationId =
  localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
      ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
        props?.showdatas ||
        props?.message ||
        props?.ShowpopupDataNotification
      : userData?.popup[0]?.notificationId ||
        props?.message ||
        props?.ShowpopupDataNotification;


  console.log(NotificationId,"NotificationIdjj");
  

  const apicallHabits = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      foodId: foodHabitsData.filter((e) => e.selected === "Y").length > 0 ? foodHabitsData.filter((e) => e.selected === "Y")[0].id : "",
      smokingId: smokingHabits.filter((e) => e.selected === "Y").length > 0 ? smokingHabits.filter((e) => e.selected === "Y")[0].id : "",
      drinkingId: drinkingHabits.filter((e) => e.selected === "Y").length > 0 ? drinkingHabits.filter((e) => e.selected === "Y")[0].id : "",
      // ...(props.isFrom == 24 && {  foodhabitrequesttypeid:24 }),
      // ...(props.isFrom == 25 && {  smokingrequesttypeid:25 }),
      // ...(props.isFrom == 26 && {   drinkingrequesttypeid:26 }),
      ...(foodHabitsData.filter((e) => e.selected === "Y").length > 0 && {  foodhabitrequesttypeid:24 }),
      ...(smokingHabits.filter((e) => e.selected === "Y").length > 0 && {  smokingrequesttypeid:25 }),
      ...(drinkingHabits.filter((e) => e.selected === "Y").length > 0 && {  drinkingrequesttypeid:26 }),

      requestfulfilledfrom:getDeviceInfo() ,
      requestfulfilledvia:props?.isFromSms =="yes" ? "sms" : "chat" ,
      recieverprofileid:getGroupAuthId() ,
      ...(props.isFrom === 'TrailPack' && { editviatrialpack: props.editviatrialpack }),
      ...(props.isFrom === 'TrailPack' && { notificationId: NotificationId ? NotificationId : props.KMNotificationId })
    };
    
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.edithabitsinfosaveUrl()}`,
      request
    );
   
    if (statusCode === 200) {
      setSubmitStatus(true)
      
      setTimeout(() => {
        
        requestType ? props.close() : props?.postData();
      }, 2000);
      if (data.data.status === "SUCCESS") {
        setShowModel(false)
        if(props.isFrom == 'TrailPack'){
          props.apiCallEditProfile()
          if(countFilledFields() == 3 ){
            props.setCompleteNow(true)
            setClickAddHabits(false)
          }
        }else {
          setClickAddHabits(false)
        }
        setSubmitStatus(false) 
        !requestType && props.callBackReload();
        setClickAddHabits(false)
        setEditView(false);        
        onClickTrack(EventName.profileHabitsSaveButton)

       
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const { onClickTrack } = useAnalytics()

  const countFilledFields = () => {
    let count = 0;
  
    // Count selected food habit
    if (foodHabitsData.some((item) => item.selected === "Y")) {
      count++;
    }
  
    // Count selected smoking habit
    if (smokingHabits.some((item) => item.selected === "Y")) {
      count++;
    }
  
    // Count selected drinking habit
    if (drinkingHabits.some((item) => item.selected === "Y")) {
      count++;
    }
  
    return count; 
  };


  return (
    <div>
        <div className={`${props?.isFrom ? "p-0" : "p-5"} md:p-0`}>
          <div className="space-y-3">
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[13px] lg:text-[14px]   ">
             {trans('trial_pack_popup__habits_detai.food_habits')}
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[100%]">
              <div className="grid grid-cols-3">
                {foodHabitsData.map((e, i) => (
                  <div className="col-span-1">
                  <SettingsRadioButton
                    id={e.id}
                    key={i}
                    name={e.foodHabits}
                    selected={e.selected}
                    radiotext={e.foodHabits}
                    onChange={(item) => {
                      //console.log("radio", item.target.value);
                      setFoodValue(item.target.value);
                      setEnableSavebtn(false)
                      // var newVal = foodHabitsData.map((ele, i) => {
                      //   if (item.target.value === ele.foodHabits) {
                      //     return { ...ele, selected: "Y" };
                      //   } else {
                      //     return { ...ele, selected: "N" };
                      //   }
                      // });
                      // setFoodHabits(newVal);
                    }}
                    isFrom={"food"}
                    menu="foodadd"
                  />
                  </div>
                ))}
                </div>
            </div>
          </div>
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[13px] lg:text-[14px]   ">
              {trans('trial_pack_popup__habits_detai.smoking')}
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[100%]">
              <div className="grid grid-cols-3">
                {smokingHabits.map((e, i) => (
                 <div className="col-span-1">
                  <SettingsRadioButton
                    id={e.id}
                    key={i}
                    name={e.id}
                    selected={e.selected}
                    radiotext={e.name}
                    onChange={(item) => {
                      //console.log("radio", item.target.value);
                      setSmokeValue(item.target.value);
                      // var newVal = smokingHabits.map((ele, i) => {
                      //   if (item.target.value == ele.id) {
                      //     return { ...ele, selected: "Y" };
                      //   } else {
                      //     return { ...ele, selected: "N" };
                      //   }
                      // });
                      // setSmokingHabits(newVal);
                    }}
                    isFrom={"smoke"}
                  />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[13px] lg:text-[14px]   ">
              {trans('trial_pack_popup__habits_detai.drinking')}
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[100%]">
            <div className="grid grid-cols-3">
              {drinkingHabits.map((e, i) => (
                <div className="col-span-1">
                <SettingsRadioButton
                  id={e.id}
                  key={i}
                  name={e.name}
                  selected={e.selected}
                  radiotext={e.name}
                  onChange={(item) => {
                    //console.log("radio", item.target.value);
                    setDrinkingValue(item.target.value);
                    // var newVal = drinkingHabits.map((ele, i) => {
                    //   if (item.target.value === ele.name) {
                    //     return { ...ele, selected: "Y" };
                    //   } else {
                    //     return { ...ele, selected: "N" };
                    //   }
                    // });
                    // setDrinkingHabits(newVal);
                  }}
                  isFrom={"drink"}
                />
                </div>
              ))}
            </div>
            </div>
          </div>
          </div>
          {commonError && (
          <div className="text-red-500 text-xs mt-2">{commonError}</div>
        )}
          <div className=" flex justify-center space-x-3  items-center mt-[2rem] ">
          
            {props.isFrom == 'TrailPack' ?
            <>
             <button
             onClick={()=>{
              props.OnClose();
              props.setCompleteNow(true);
             }}
             className={`flex items-center justify-center whitespace-nowrap border border-[#707070]
               rounded-[5px] font-bold w-[9rem] lg:w-[8rem] xl:w-[10rem] py-1 md:text-[12px] lg:text-[14px] `}
            >
              <span className="truncate">
                  {trans('trial_pack_popup__habits_detai.cancel')}
              </span>
            </button>
            <button
              onClick={() => {
                if(props.isFrom === 'TrailPack' && countFilledFields() !== 3){
                  if(foodHabitsData.some((item) => item.selected === "N")|| smokingHabits.some((item) => item.selected === "N") ||drinkingHabits.some((item) => item.selected === "N")){
                    setCommonError(trans('trial_pack_popup__habits_detai.all_fields_are_mandatory'))
                  }
                }
                if(props.isFrom == 'TrailPack' && countFilledFields() !== 0){
                  apicallHabits();
                  onClickTrack(EventName.Trial_Pack_popup_Add_Habit_Details_Submit_Button)
                }
               
              }}
              className={`flex items-center justify-center text-white border whitespace-nowrap rounded-[5px] font-bold w-[9rem] lg:w-[8rem] xl:w-[10rem] py-1 md:text-[12px] lg:text-[14px] 
                ${(props.isFrom === 'TrailPack' && countFilledFields() === 0) 
                  ? 'bg-[#8E8E8E] cursor-not-allowed border-[#8E8E8E]' 
                  : 'bg-[#D10A11] cursor-pointer border-[#D10A11]'}`}
            >
               {console.log(props.isFrom == 'TrailPack' && countFilledFields() == 0,'props.isFrom ==  && countFilledFields() == 0')}
            {submitStatus ? <><img src={LoaderGif} 
            className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></> : <span className="truncate">{props.isFrom == 'TrailPack' ? (countFilledFields() == 0 ? trans('trial_pack_popup__habits_detai.save') 
            : `${trans('trial_pack_popup__habits_detai.save')} (${countFilledFields()})`) : trans('trial_pack_popup__habits_detai.save') }</span>}
            </button>
            </>
            :
            <>
            <button
              onClick={props.handelCloseClick}
              className={`flex items-center justify-center whitespace-nowrap border border-[#707070]
                rounded-[5px] font-bold lg:w-[6rem] w-[5rem] py-1 md:text-[12px] lg:text-[14px] `}
            >
              
              <OverflowToolTip as='p' className='truncate px-2'>{trans('my_profile.cancel')}</OverflowToolTip>
            </button>
            <button
              onClick={() => {
                if (enablesavebtn && !requestType) {
                  setCommonError(trans('trial_pack_popup__habits_detai.all_fields_are_mandatory'));
                }else{
                  apicallHabits();
                }
              }}
              className={`flex items-center justify-center text-white bg-[#D10A11] border  whitespace-nowrap 
                rounded-[5px] font-bold lg:w-[6rem] w-[5rem] py-1 md:text-[12px] lg:text-[14px ${enablesavebtn ? 'bg-[#E78387] cursor-not-allowed border-[#D10A11]' : 'bg-[#D10A11] border-[#D10A11]'}`}
            >
            {submitStatus ? <><img src={LoaderGif} 
            className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></> : <OverflowToolTip as='p' className='truncate px-2'>{trans('trial_pack_popup__habits_detai.save')}</OverflowToolTip>}
            </button>
            </>
          }
          </div>
        </div>
    
    </div>
  );
};

export default HabitsAddComponents;