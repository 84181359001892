import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import {
  ProfileCard,
  ProfileLookingForCard,
  ResponseProfileCard
} from "./ProfileCard";
import Slider from "react-slick";
import { NavListContext } from "../../Contexts/navContext";
import { NavBarContext } from "../../Contexts/NavBarContext";
import { RouteHelper, RoutePaths } from "../../Routes/RouteSegment";
import slider1 from '../../Images/slider1.svg';
import slider2 from '../../Images/slider2.svg';
import { EventName } from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";

var sliderResponsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 765,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 740,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    },
  },
];

const DashboardSection = (props) => {
  const { setMenuSelect } = useContext(NavBarContext);

  const { navList, setNavList } = useContext(NavListContext);
  const sliderRef = useRef();
  const parentRef = useRef(null); 

  const ref = useRef(null); 
  const navigate = useNavigate();

  const shiftPrev = () => {
    ref.current.scrollLeft -= 50;
  };

  const shiftNext = (num) => {
    if (num === props.data.length - 2) {
      // console.log("next", num, props.data.length);
      props.callLoadMore();
    }
  };

  useEffect(() => {
    // //console.log("lenght", props?.data?.length);
    //console.log(props?.phonenumberstatus,"phonen02");
  }, [props?.phonenumberstatus, props?.photoRequest]);
  
  
  function CustomNextArrow(props) {
    const { className, style, onClick ,currentSlide, slideCount} = props;
    const hasNextCard = currentSlide < slideCount - props?.count;
    const handleNextClick = () => {
      if (hasNextCard) {
        onClick();
      }
    };
    return hasNextCard ? ( 
      <div
        className={className}
        // style={{ ...style, display: "block"}}
        onClick={handleNextClick}
      ><img alt="" src={slider2} className="w-6 custom-next-arrow" /></div>) :null
  }

  var settings = {
    dots: false,
    infinite: props.title === "Similar Matches Profiles" ? true : false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 1,
    prevArrow: props?.data.length >2 ? <img alt="" src={slider1} className="w-6" /> : false,
    nextArrow: props?.data.length >2 ? <CustomNextArrow count={2}/> : false,

    afterChange: shiftNext,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: props?.data.length >1 ? <img alt="" src={slider1} className="w-6" /> : false,
          nextArrow: props?.data.length >1 ? <CustomNextArrow count={1}/> : false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          
        },
      },
    ],
    
  };

  
  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e) => handleScroll(e));

    return (() => {
      parentRef?.current?.removeEventListener('wheel', (e) => handleScroll(e));
    });
  }, [parentRef, props?.data]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };

  return (
    <div className="md:border-[1.5px] md:border-gray-300 mb-4 rounded-[10px]  md:p-3 lg:p-3 ">
      <div className="flex justify-between mx-2 py-1 md:py-0">
        <p className={`text-[13pt] md:text-[17px] lg:pl-[1.1rem] md:pl-0 pl-4 lg:text-[19px] text-[#575556] font-segeo font-semibold ${props.title === "Profiles Matching the Partner Expectation" ? "flex items-center" : ""}`}>
          {props.title} 
          {props.count ===0 ?"":
         <span className="inline-flex  font-bold justify-center items-center ml-2 w-[3rem] h-[1.8rem] md:w-[3rem] md:h-[2rem] lg:w-[3.5rem] lg:h-[2rem] text-[14px] md:text-[14px] lg:text-[15px] text-[#000000] bg-[#FDC300] rounded-[10px]">
         {props.count}
       </span> } 

        </p>
        <span className=" hidden md:block lg:hidden mx-4">
          {props?.data.length > 1 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
        <span className=" hidden lg:block mx-4">
          {props?.data.length > 2 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
      </div>

      <div>
        <>
          {/* <div  className=" flex overflow-x-auto  gap-4 pt-3 mx-0 md:mx-3 lg:mx-16" > */}
          {props.title === "Similar Matches Profiles" ?
              <div ref={parentRef} className="rightSideSlider">
                  <Slider className=" " {...settings} ref={sliderRef} >
                     {props?.data?.map((element, i) => {
            
              return (
              <ProfileCard
              // phonenumberstatus={props?.phonenumberstatus}
              key={element.profileid}
              cardData={element} 
              // callBackBlockProfile={() => props?.callBackBlockProfile(element)}
              callBackNav={() => {
                setNavList([...navList, "Similar Matches"])
                navigate(RouteHelper.getProfileUrl, { state: { id: element.profileid } })
                setMenuSelect("")
              }}
              callbackRequestPhoto={() => props.callbackRequestPhoto(element )
              }
              callbackParentNumberRequest={() => props.callbackParentNumberRequest(element)
              }
              callBackPhone={() => props.callBackViewPhone(element.profileid,element)} 
               callbackSelectReject={(type, typeRequest) => {
                    props.callbackSelectReject(type, element.profileid, typeRequest, element, i);
                  }}
            />
             ); 
            
              })}
            </Slider>
            </div>
        :
          <div ref={parentRef}>
            {props?.data && (
              <Slider className="md:pt-3 mx-0 md:mx-4 lg:mx-6" {...settings} ref={sliderRef}>
                {props?.data?.map((element, i) => (
                  // <Link to='/matches' state={{id:element.profileid}} >

                  <ProfileCard 
                    phonenumberstatus={props?.phonenumberstatus}
                    key={element.profileid}
                    
                    title={props?.title}
                    cardData={element}
                    dashboard1Data={props?.dashboard1Data} 
                    callBackNav={() => {
                      navigate(RoutePaths.PROFILE, { state: { id: element.profileid, myPhoto: props?.myPhoto, isfrom: "matches" } })
                      setNavList([...navList, "Profile Page"])
                      setMenuSelect("")
                    }
      
                    }
                    viewProfileStatusPopup={(msg) => props.viewProfileStatusPopup(msg)}
                    callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
                      callbackParentNumberRequest={() => props.callbackParentNumberRequest(element)
                       }
                    callBackPhone={() => props.callBackViewPhone(element.profileid,element)}
                    callBackBlockProfile={() => props.callBackBlockProfile(element)}
                    callbackSelectReject={(type, typeRequest) => {
                      props.callbackSelectReject(type, element.profileid, typeRequest,element, i);
                    }}
                  />
                  // </Link>
                ))}
              </Slider>
            )}
          </div>
        } 
        </>
        <span className="md:hidden block mx-4 mt-2 text-end mb-4 md:mb-0 ">          
          <ViewAllButton
            btnCallBack={props.ViewAllCallBack}
          />
        </span>
      </div>
    </div>
  );
};
const ViewAllButton = (props) => {

  return (
    <button
      onClick={() =>
        props.btnCallBack()
      }
      className="text-[12px] text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] whitespace-nowrap"
    > 
      View All
    </button>
  );
};
const DashboardSection2 = (props) => {
  const { navList, setNavList } = useContext(NavListContext);
  const { setMenuSelect } = useContext(NavBarContext);

  const { onClickTrack } = useAnalytics()

  const sliderRef = useRef();
  const parentRef = useRef(null);
  const navigate = useNavigate();
  const shiftNext = (num) => {
    if (num === props.data.length - 2) {
      // //console.log("next", num, props.data.length);
      props.callLoadMore();
    }
  };
  const reinit = () => { };
  useEffect(() => { }, []);

  function CustomNextArrow(props) {
    const { className, style, onClick ,currentSlide, slideCount} = props;
    const hasNextCard = currentSlide < slideCount - props?.count;
    const handleNextClick = () => {
      if (hasNextCard) {
        onClick();
      }
    };
    return hasNextCard ? ( 
      <div
        className={className}
        // style={{ ...style, display: "block"}}
        onClick={handleNextClick}
      ><img alt="" src={slider2} className="w-6 custom-next-arrow" /></div>) :null
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 1,
    prevArrow: props?.data.length >2 ? <img alt="" src={slider1} className="w-6" /> :false,
    nextArrow: props?.data.length >2 ? <CustomNextArrow count={2}/>:false,

    afterChange: shiftNext,
    //onReInit: reinit,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: props?.data.length >1 ? <img alt="" src={slider1} className="w-6" /> :false,
          nextArrow: props?.data.length >1 ? <CustomNextArrow count={1}/>:false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          
        },
      },
    ],
    
  };
  useEffect(() => {
  },[props?.photoRequest])
  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e) => handleScroll(e));

    return (() => {
      parentRef?.current?.removeEventListener('wheel', (e) => handleScroll(e));
    });
  }, [parentRef, props?.data]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };
  return (
    <div className="section md:border-[1.5px] md:border-gray-300 mb-4 rounded-[10px] p-3 ">
      <div className="flex justify-between mx-2 pt-3">
        <p className="text-[13pt] md:text-[17px] lg:pl-[1.1rem] pl-4  md;pl-0 lg:text-[19px] text-[#575556] font-segeo font-semibold">
          {props.title}
          {props.count===0 ?"":
          <span className="inline-flex  font-bold justify-center items-center ml-2 w-[3rem] h-[1.8rem] md:w-[3rem] md:h-[2rem] lg:w-[3.5rem] lg:h-[2rem] text-[14px] md:text-[14px] lg:text-[15px] text-[#000000] bg-[#FDC300] rounded-[10px]">
            {props.count}
          </span>}
        </p>       
        <span className=" hidden md:block lg:hidden mx-4">
          {props?.data.length > 1 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
        <span className=" hidden lg:block mx-4">
          {props?.data.length > 2 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
        
      </div>
      <div ref={parentRef}>
        {props?.data && (
          <Slider className="py-3 mx-12 md:mx-4 lg:mx-6 " {...settings} ref={sliderRef}>
            {props?.data?.map((element, i) => (
              <ProfileLookingForCard
                key={element.profileid}
                // callBackBlockProfile={() => props?.callBackBlockProfile(element)}
                cardData={element}
                callBackNav={() =>{
                  setNavList([...navList, "Profile Page"])
                  navigate(RoutePaths.PROFILE, { state: { id: element.profileid } })
                  setMenuSelect("")
                  onClickTrack(EventName.profileCard, { matchId: element.profileid })
                }
                }
                viewProfileStatusPopup={(msg) => props.viewProfileStatusPopup(msg)}
                callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
                callbackParentNumberRequest={() => props.callbackParentNumberRequest(element)
              }
                callBackPhone={() => props.callBackViewPhone(element.profileid,element)}
                callbackSelectReject={(type, typeRequest) => {
                  props.callbackSelectReject(
                    type,
                    element.profileid,
                    typeRequest, element, i
                  );
                }}
              />
            ))}
          </Slider>
        )}
      </div>
      <div>
      </div>
    </div>
  );
};

const DashboardSection3 = (props) => {
  const { navList, setNavList } = useContext(NavListContext);
  const { setMenuSelect } = useContext(NavBarContext);

  const navigate = useNavigate();
  const sliderRef = useRef();
  const parentRef = useRef(null);
  const shiftNext = (num) => {
    if (num === props.data.length - 3) {
      // //console.log("next", num, props.data.length);
      props.callLoadMore();
    }
  }; 
  useEffect(() => {
    // //console.log("lenght section 3",props?.data?.length)
  }, [props?.data]);
  function CustomNextArrow(props) {
    const { className, style, onClick ,currentSlide, slideCount} = props;
    const hasNextCard = currentSlide < slideCount - props?.count;
    const handleNextClick = () => {
      if (hasNextCard) {
        onClick();
      }
    };
    return hasNextCard ? ( 
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={handleNextClick}
      ><img alt="" src={slider2} className="w-6 custom-next-arrow" /></div>) :null
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: true,
    prevArrow: props?.data?.length >3 ? <img alt="" src={slider1} className="w-6" />:false,
    nextArrow: props?.data?.length >3 ? <CustomNextArrow count={3}/> :false,
    afterChange: shiftNext,
    //onReInit: reinit,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: props?.data?.length >1 ? <img alt="" src={slider1} className="w-6" />:false,
          nextArrow: props?.data?.length >1 ? <CustomNextArrow count={1}/> :false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          
        },
      },
    ],
    
  };
  useEffect(() => {    

    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e)=>handleScroll(e));
  
    return (() => {
      parentRef?.current?.removeEventListener('wheel',(e)=>handleScroll(e));
    });
  }, [parentRef]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };
  //console.log(props?.data.length,"lencheck");
  return (
    <div className=" section md:border-[1.5px] md:border-gray-300 mb-4 rounded-[10px]  md:p-1 lg:p-3  " >
      <div className="flex justify-between mx-2 py-1 md:py-0">
        <p className="text-[13pt] md:text-[17px] lg:pl-[1.1rem] pl-4 md:pl-0 lg:text-[19px] text-[#575556] font-segeo font-semibold">
          {" "}
          {props.title}
         {props.count ===0 ?"":
          <span className="inline-flex  font-bold justify-center items-center ml-2 h-[1.8rem] w-[3rem] md:w-[3rem] md:h-[2rem] lg:w-[2.5rem] lg:h-[2rem] text-[14px] md:text-[14px] lg:text-[15px] text-[#000000] bg-[#FDC300] rounded-[10px]">
            {props.count}
          </span>}
        </p>

        <span className=" hidden md:block lg:hidden mx-4">
          {props?.data.length > 1 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
        <span className=" hidden lg:block mx-4">
          {props?.data.length > 3 &&
            <ViewAllButton
              btnCallBack={props.ViewAllCallBack}
            />}          
        </span>
      </div>
      <div ref={parentRef}>
      {props?.data && (
        <Slider className="py-3 mx-0 md:mx-5 lg:mx-6" {...settings} ref={sliderRef}>
          {props?.data?.map((element, i) => (
            <ResponseProfileCard
              key={element.profileid}
              cardData={element}
              callbackSelectReject={(type, typeRequest) => {
                props.callbackSelectReject(type, element.profileid, typeRequest, element, i);
              }}
              isViewmore={props.isViewmore}
               type="Unread"
              // callBackNav={() =>
              //   navigate(`/matches?id=${element.profileid}`, { state: { id: element.profileid }, replace: true })
              // }
              // callBackBlockProfile={() => props?.callBackBlockProfile(element)}
              viewProfileStatusPopup={(msg) => props.viewProfileStatusPopup(msg)}
              callBackNav={() => {
                setNavList([...navList, "Profile Page"])
                navigate(RoutePaths.PROFILE, { state: { id: element.profileid, isfrom: "matches" } })
                setMenuSelect("")
              }
              }
              callBackChatnow={() => {
                props.callBackChatnow()
              }}
              callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
              callBackViewMore={() => {
                props.callBackViewMore(props.isViewmore)

              }}

              callbackUploadRequestPhoto={()=> props.callbackUploadRequestPhoto()}
            />
          ))}
        </Slider>
      )}
     </div>
      <span className="md:hidden block mx-4  text-end">
        <ViewAllButton btnCallBack={props.ViewAllCallBack} />
      </span>

      {/* </div> */}
      <div>
      </div>
    </div>
  );
};

const DashboardSection4 = (props) => {
  const { navList, setNavList } = useContext(NavListContext);
  const { setMenuSelect } = useContext(NavBarContext);

  useEffect(() => {
    // console.log("lenght", props?.data?.length);
    console.log(props?.phonenumberstatus,"phonen02");
  }, [props?.phonenumberstatus]);
  // console.log(props?.photoRequest,"photoRequest2");

  const sliderRef = useRef();
  const parentRef = useRef(null);
  const navigate = useNavigate();
  const shiftNext = (num) => {
    if (num === props.data.length - 2) {
      // //console.log("next", num, props.data.length);
      props.callLoadMore();
    }
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: true,
    prevArrow: <img alt="" src={slider1} className="w-6" />,
    nextArrow: <img alt="" src={slider2} className="w-6" />,
    afterChange: shiftNext,
    //onReInit: reinit,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          
        },
      },
    ],
    
  };
  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef?.current?.addEventListener('wheel', (e) => handleScroll(e));

    return (() => {
      parentRef?.current?.removeEventListener('wheel', (e) => handleScroll(e));
    });
  }, [parentRef, props?.data]);

  const handleScroll = e => {
    if (e.deltaX > 0) {
      sliderRef && sliderRef?.current?.slickNext();
    } else if (e.deltaX < 0) {
      sliderRef && sliderRef?.current?.slickPrev();
    }
  };



  return (
    <>
      <div className="flex justify-between items-center  py-3">
        <div className="flex">
        <p className="text-[13pt] pt-1 md:text-[15px] lg:text-[19px] text-[#575556] md:px-3 lg:px-3 font-segeo font-semibold">
          {props.title}
        </p>
         {/* {props?.menu==="matchview" ? */}
        {props.count ===0 ?"":
         <span className="inline-flex mt-1 font-bold justify-center items-center ml-2 w-[3rem] h-[1.8rem] md:w-[3rem] md:h-[2rem] lg:w-[2.5rem] lg:h-[2rem] text-[14px] md:text-[14px] lg:text-[15px] text-[#000000] bg-[#FDC300] rounded-[10px]">
         {props.count}
       </span>}
          </div>
        <div className="mx-3 md:mx-5">
          <ViewAllButton btnCallBack={props.ViewAllCallBack} />
        </div>
      </div>
      <div> 
        {/* <div className=" flex overflow-x-auto  gap-4 pt-3 mx-0 "> */}
        <div ref={parentRef} className="rightSideSlider">
          <Slider className="gap-4 pt-1 mx-0 " {...settings} ref={sliderRef}>
            {props?.data?.map((element, i) => (
              <ResponseProfileCard
              // console={  //console.log(element,"jkjklmjllj")}
                key={element.profileid}
                cardData={element}
                // type="Unread" 
                callbackSelectReject={(type, typeRequest) => {
                  props.callbackSelectReject(type, element.profileid, typeRequest, element, i);
                }}
                isViewmore={props.isViewmore}
                type="recentlyviewed"
                viewProfileStatusPopup={(msg) => props.viewProfileStatusPopup(msg)}
                callBackNav={() =>{
                  setNavList([...navList, "Profile Page"])
                  navigate(RouteHelper.getProfileUrl, { state: { id: element.profileid, isfrom:"matches" } })
                  setMenuSelect("")
                } } 
                callBackChatnow={() => { 
                  props.callBackChatnow()
                }} 
                callbackRequestPhoto={() => props.callbackRequestPhoto(element)}
                // callBackBlockProfile={() => props?.callBackBlockProfile(element)}
                callBackViewMore={() => {
                  props.callBackViewMore(props.isViewmore)
                  navigate(RoutePaths.PROFILE, { state: { id: element.profileid, isfrom:"matches" }, replace: true })

                }}
              />
            ))}
          </Slider>
        </div>
        <center className="text-center relative top-[0.7rem] tracking-[0.3rem]">....</center>
        <br/>
        <span className="md:hidden block mx-4 mt-2 text-end mb-4 md:mb-0">
          <ViewAllButton btnCallBack={props.ViewAllCallBack} />
        </span>
      </div>
    </>
  );
};

export {
  ViewAllButton,
  DashboardSection,
  DashboardSection2,
  DashboardSection3,
  DashboardSection4,
};
