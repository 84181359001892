import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import ImageCroperComponent from "./Edit Profile/ImageCroperComponent";
import EndPoints from "../Constants/EndPoints";
import { IP_Address } from "../Constants/IpAddress";
import { GET, POST } from "../Services/api_services";
import Constants, {
  EditProfileMenu,
  loginFrom,
  EventName,
  EventKeys,
} from "../Constants/constants";
import { LoginContext } from "../Contexts/LoginContext";
import axios from "axios";
import { Field, FieldArray, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import { useNavigate } from "react-router";
import { error } from "jquery";
import { validateImage } from "../Utils";
import ImageCropper from "./Edit Profile/ImageCropper";
import Loader from "./Loader";
import config from "../config";
import LoaderGif from "../Gif/loader.gif";
import CloseIcon from "../Images/Group 761@2x.png";
import MessageSentIcon from "../Images/message-sent.svg";
import SuccessIcon from "../Images/Group 809.svg";
import EyeIcon from "../Images/passwordeye.svg";
import EyeOpenIcon from "../Images/passwordeyeopen.png";
import UploadPhotoIcon from "../Images/Group 865-red1.svg";
import { CommonCheckButton } from "./DashboardComponents/DashboardUtills";
import { call, put, retry, takeEvery } from "redux-saga/effects";
import DangerIcon from "../Components/Kalyanlite/assets/Images/Group 2666.svg";
import { useLocation,useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
  phonenoicon,
  kalyanremovebg,
  chaticon,
  validityicon,
  gifticon,
  avatarimage,
  DefaultAvatar,
  redtickicon,
  resplusicon,
  profilematch1,
  profileimages,
} from "../Components/Kalyanlite/assets/images";
import Inviteicon from '../Components/Kalyanlite/assets/Images/inviteicon.svg';
import messageicon from '../Components/Kalyanlite/assets/Images/googlemessagesicon.svg'
import emailIcon from '../Components/Kalyanlite/assets/Images/GmailLogo.svg'
import * as Images from "../Components/Kalyanlite/assets/images"
import KalyanLeafLogo from "../Components/Kalyanlite/assets/Images/kalyan-logo.png"
import * as MatchSelector from "../Store/KalyanLite/selectors"

import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
// import SocialMediaUpload from "./BasicInfo/SocialMediaUpload";
// import Img from 'react-optimized-image';
import Compressor from "compressorjs";
import {
  imageSizeReducer,
  imageSizeCompression,
  handleImageSizeCompress,
} from "./ImageSizeReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getDenominationList,
  registerCasteList,
  registerDetail,
  registerSubCasteList,
} from "../Store/Home/Action";
import {
  BasicEditTextView,
  RawSelectInput,
  SelectCurrencyInput,
  SelectInput,
} from "./Edit Profile/EditProfileUtills";
import SocialMediaUpload from "./BasicInfo/SocialMediaUpload";

import { useMediaQuery } from "react-responsive";

import addphotoIcon from "../Components/Kalyanlite/assets/Images/addphotoIcon2.svg";
import facebookIcon from "../Components/Kalyanlite/assets/Images/facebookIcon.svg";
import instragramIcon from "../Components/Kalyanlite/assets/Images/instragramIcon.svg";
import whatsappIcon from "../Components/Kalyanlite/assets/Images/whatsappIcon.svg";
import googleicon from "../Images/google.png";
import playstoreicon from "../Images/appstore.png";
import AddGroupIcon from "../Components/Kalyanlite/assets/Images/addgroupicon.svg";
import DefaultIcon from "../Components/Kalyanlite/assets/Images/default_avatar.svg";
import Success from "../Components/Kalyanlite/assets/Images/Group 3560.svg"


// import welcomeimage from '../Components/Kalyanlite/assets/Images/11111@3x.png'
import welcomeimage from "../Components/Kalyanlite/assets/Images/video.png";
import DPimage from "../Components/Kalyanlite/assets/Images/Rectangle 10@2x.png";
import HelpPage from "../Pages/Footer/HelpPage";
import QueryForm from "../Components/HelpAndFAQ/QueryForm";
import HelpSection from "../Components/HelpAndFAQ/HelpSection";
import { sendCustomerServiceMessage, setCurrentChat, toggleMuteConversations, RankingPopup, getCustomerServiceConversation, getConversationList } from "../Store/Chats/Action";
import {
  applyFilter,
  closeCurrentMatch,
  toggleProfileBlock,
} from "../Store/KalyanLite/Action";
import ForgetPasswordPopup from "./LoginForm/ForgetPasswordPopup";
import * as KalyanLiteSelector from "../Store/KalyanLite/selectors";
import { CMS_Login, Family_Chats_Detail, familycode_login, Update_Notification_Status } from '../Helpers/api_helpers'
import { NavBarContext } from "../Contexts/NavBarContext";
import { useAnalytics } from "../Hooks/usePageChange";

import { validateNumber } from "../Utils";
import { getDeviceInfo, getGroupAuthId } from "./Kalyanlite/utils";
import { getBasicData } from "../Store/Login/Action";
import { Divider, Flex, Popover, Progress, Tooltip } from "antd";
import { SettingsRadioButton } from "./Edit Profile/SettingsComponents/SettingsUtills";
import ProfileHoroscopeComponent from "./Edit Profile/ProfileHoroscopeComponent";
import HoroscopeComponent from "./Edit Profile/MyProfileComponents/HoroscopeComponent";
import ProfileBasicComponent from "./Edit Profile/ProfileBasicComponent";
import AstroComponent from "./TrailPack/AstroComponent";
import AstroBasic from "./TrailPack/AstroBasic";
import AstrologicalComponent from "./TrailPack/AstrologicalComponent";
import FamilyBasic from "./TrailPack/FamilyBasic";
import HabitsBasic from "./TrailPack/HabitsBasic";
import HoroBasic from "./TrailPack/HoroBasic";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import PaperBlast from "./PaperBlast";
import { rewardPopupShown } from "../Store/KalyanRewards/Action";
import { toast } from "react-toastify";
import ActionRestrict from "./Kalyanlite/Common/ActionRestrict";
import SadImage from "../Components/Kalyanlite/assets/Images/sad-face.svg"
import HappyImage from "../Components/Kalyanlite/assets/Images/smile.svg"
import { FaStar } from "react-icons/fa"
import AstroPopupDetails from "./Kalyanlite/Popup/AstroPopupDetails";
import RedClose from '../Images/CloseIconRed.svg'
import ParentsPhonePopupLite from "./DashboardComponents/ParentsPhonePopupLite";
import HobbyEdit from './Kalyanlite/HobbyEdit'
import i18n from "../i18n";
import OverflowToolTip from "./utils/OverflowTooltip";
import WhatsAppSuccesssIcon from '../Images/whatsAppSuccess.svg'
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";
import CameraPopup from "./Kalyanlite/Common/CameraPopup";

const CommonPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div
        className={`${props.menu === "policy"
          ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
          : props?.menu === "View Horoscope Match"
            ? "relative w-[32rem] lg:w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
              ? "relative w-[25rem] text-center my-6 mx-6 "
              : props.menu === "report"
                ? "relative  my-6 mx-6  w-[30rem]"
                : props?.menu == "profileRejection"
                  ? "relative text-center my-6 mx-6"
                  : props?.menu == "liteprofile"
                    ? "relative  my-6 mx-6 w-[90%] md:w-[60%]  lg:w-[30%]"
                    : "relative  my-6 mx-6 w-[90%] md:w-[50%]  lg:w-[38%]"
          } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div
            className={
              props.menu == "liteprofile"
                ? "flex items-center py-1 justify-center mt-2 mx-2  rounded-t "
                : "flex items-center py-1 justify-center border-b border-dashed mt-2 border-[#D10A11] mx-2  rounded-t "
            }
          >
            <h3
              className={`text-[20px] text-[#D10A11] ml-2 font-semibold align-middle ${props?.menu == "profileRejection" ? "lg:whitespace-nowrap" : ""
                }`}
            >
              {props.title}
            </h3>
          </div>
          {props?.isLoading ? (
            <Loader />
          ) : (
            <div
              className={
                props?.menu === "policypopup"
                  ? "relative p-10 pr-[0.1rem]  flex justify-center items-center"
                  : props?.menu == "liteprofile"
                    ? "relative px-10 py-5 flex justify-center items-center"
                    : "relative p-10  flex justify-center items-center"
              }
            >
              {props.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CommonPopupWithoutHeader = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div
        className={`${props.menu === "policy"
          ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
          : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
              ? "relative w-[25rem] text-center my-6 mx-6 "
              : props.menu === "report"
                ? "relative  my-6 mx-6  w-[30rem]"
                : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
          } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="Noscroll max-h-[80vh] overflow-y-auto border-0  md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-center py-1 mx-2 mt-2 rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div
            className={
              props?.menu === "policypopup"
                ? "relative px-6 py-6 pr-[0.1rem] w-[100%] flex-auto"
                : "relative p-6  flex-auto w-[100%]"
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageSuccessPopup = (props) => {

  const navigate = useNavigate();
  const { t:trans } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative flex-auto p-6">
            {props?.dashboard1Data?.myphoto === "Y" ? (
              <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
                <span className="text-[16px] font-semibold">
                  Message sent successfully!
                </span>
                <img className="w-16 h-16 mt-2" alt="" src={MessageSentIcon} />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <span className="text-[16px] font-normal">
                  Message sent successfully
                </span>
                <span className="text-[16px] font-normal">
                  Increase your chance of getting a response by adding your
                  photo
                </span>
                <a
                  onClick={() => {
                    navigate(
                      RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath),
                      { state: { tab: "3" } }
                    );
                  }}
                  className="text-[#D10A11] font-bold underline cursor-pointer"
                >
                  {trans('matches.add_now')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonSuccessPopup = (props) => {

  const isCLC = props?.from == "AddPhotoFromCLC";

  const { t:trans } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className={`flex items-center justify-center mx-2  rounded-t ${isCLC ? "pb-4 pt-8" : "py-1"} `}>
            <h3 className="text-[20px] text-[#D10A11]  font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className={`relative flex-auto ${isCLC ? "p-6 md:p-3 lg:p-6" : "p-6"}`}>
            <div className={`flex flex-col justify-center items-center ${isCLC ? "w-[60vw] md:w-auto lg:w-[30vw]" : "w-[60vw] md:w-[30vw]"}`}>
              <span className='text-[13px] md:text-[16px] lg:text-[16px] font-semibold  lg:whitespace-nowrap text-center'>
                {props.content}
              </span>
              <img className="w-16 h-16 mt-2" alt="" src={isCLC ? Success : SuccessIcon} />
            </div>
          </div>
          {isCLC &&
            <>
              <button
                onClick={() => props.buttonClick()}
                className="mx-auto flex items-center cursor-pointer justify-center mt-4 mb-4 md:mb-4 lg:mb-8 h-[1.5rem] text-[#D10A11] border border-black px-[1rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                View Profiles who Viewed Me
              </button>
            </>
          }
          {props?.buttonName &&
            <div className="flex items-center justify-center mb-6">
              <button onClick={props?.callBackPopupFun} className={`bg-[#D10A11] md:bg-transparent text-white md:text-[#000000] px-5 py-2 border border-[#D10A11] text-[12px] lg:text-[10px] xl:text-[12px] rounded ${props?.buttonName == trans('chat.view_horoscope') ? "font-bold" : ""}`}>
                {props?.buttonName}
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

// const CommonPayNowPopup = (props) => {
//   const { onClickTrack } = useAnalytics()
//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button)
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close(); // Close the popup after navigating
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
//       <div className="relative w-[80%]  md:w-[50%] lg:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="w-5 h-5 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative flex-auto p-6">
//             <div className="space-y-2">
//               <p className="text-[#1A1A1A] font-bold text-[16px] xl:text-[18px] pb-2">{props?.title}</p>
//               <p className="text-[#7A7A7A] text-[12px] xl:text-[14px] pb-4">
//                 {props?.content}
//               </p>
//             </div>

//             <div className="flex justify-center gap-5 py-3">
//               <button
//                 onClick={handlePayNowClick}
//                 className="text-[13px] py-2 px-[2.5rem] text-white font-bold rounded-md bg-red-600" >Pay Now</button>
//             </div>
//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }
// const CommonPayBackPopup = (props) => {

//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close();
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
//       <div className="relative w-[42%] lg:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="w-5 h-5 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative flex-auto p-6">
//             <div className="space-y-2">
//               <p className="text-[#575556] font-bold text-[16px]">Buy a Pack to Contact Profile</p>
//               <p className="text-[#7A7A7A] text-[12px] pb-4">
//                 You have already contacted this profile and awaiting response.Please Upgrade to send personalized messages
//               </p>
//             </div>

//             <div className="flex justify-center gap-5 py-3">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button
//                 onClick={handlePayNowClick}
//                 className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600" >Pay Now</button>
//             </div>

//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }

const CommonConfrimPopup = (props) => {

  const navigate = useNavigate();

  const { t:trans } = useTranslation();

  const handlePayNowClick = () => {
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#575556] font-bold text-[16px]">{trans('my_profile_icon.logout')}</p>
              <p className="text-[#7A7A7A] text-[12px] pb-4">
                Are You Sure You Want To Logout?
              </p>
            </div>

            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonDownloadmediaPopup = (props) => {

  const [selectedOption, setSelectedOption] = useState("option1");

  const { t:trans } = useTranslation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="py-4 space-y-2">
              <p className="text-[#7A7A7A] text-[12px]">
                show newly downloaded media from this chat in your device's
                gallery?{" "}
              </p>
            </div>

            <div className="flex items-center mb-4 space-x-6">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2   text-[13px] text-[#575556]"
              >
                Default (Yes)
              </label>
            </div>
            <div className="flex items-center mb-4 space-x-6">
              <input
                type="radio"
                id="option2"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option2"
                className="ml-2   text-[13px] text-[#575556]"
              >
                {trans('send_bulk_request.yes')}
              </label>
            </div>
            <div className="flex items-center mb-4 space-x-6">
              <input
                type="radio"
                id="option3"
                value="option3"
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option3"
                className="ml-2   text-[13px] text-[#575556]"
              >
                No
              </label>
            </div>

            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button className="text-[13px] py-2 px-[4rem] text-white rounded-md bg-red-600">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonCameraPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-[94%] md:w-[91%] lg:w-[60%]  my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" p-6 flex md:h-[27rem] lg:h-[30rem]">
            <div className="flex items-end justify-start">
              <div className="relative">
                <div className="w-[12rem] md:w-[24rem] h-[30rem]">
                  <img
                    className="h-[92%] w-[170px] md:h-full md:w-full object-cover"
                    src={welcomeimage}
                    alt="WelcomeImage"
                  />
                </div>
              </div>
            </div>
            <div className="self-end right-[1rem] md:right-0 relative bottom-[11rem] md:bottom-[9rem]">
              <div className="flex flex-col justify-center">
                <p className="text-[14px] md:text-[22px] text-[#000000DB] whitespace-nowrap">
                  <span className="text-[#D63838] font-bold text-[14px] md:text-[22px]">
                    Download
                  </span>{" "}
                  Our App <br /> to connect{" "}
                </p>

                <p className="text-[#000000] text-[14px] lg:text-[22px] text-start ">
                  using{" "}
                  <span className="text-[#000000] text-[14px] md:text-[22px] text-start font-bold">
                    video call
                  </span>{" "}
                </p>
              </div>
              <div className="flex gap-3 md:gap-10 mt-[1.5rem]">
                <a
                  target="_blank"
                  href={RouteSegments.staticLinkForAppDownload.android}
                >
                  {" "}
                  <img
                    className="w-[8rem] md:h-[2.6rem] h-[2rem]"
                    src={playstoreicon}
                    alt="ApptoreIcon"
                  />
                </a>
                <a
                  href={RouteSegments.staticLinkForAppDownload.ios}
                  target="_blank"
                >
                  <img
                    className="w-[8rem] h-[2rem] md:h-[2.6rem]"
                    src={googleicon}
                    alt="PlayStoreIcon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonMutePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("all time");
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const { onClickTrack } = useAnalytics();

  const { t:trans } = useTranslation();

  const cancelClick = () => {
    onClickTrack(EventName.chatThreeDotMuteCancelClick);
    props.close();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onMute = () => {
    dispatch(
      toggleMuteConversations({
        conversation_id: props.conversationId,
        receiver_id: props.receiverId,
        mute_duration: selectedOption,
        operation: "mute",
      })
    );
    onClickTrack(EventName.chatThreeDotMuteOKClick, {
      [EventKeys.reciverId]: props.receiverId,
      [EventKeys.conversationId]: props.conversationId,
      [EventKeys.muteDuration]: props.selectedOption,
    });
    props.close();
    props.onMute(true);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-x-0 space-y-2 md:space-x-4 lg:space-x-8">
              <p className="text-[#1A1A1A] font-bold text-[18px] pl-0 md:pl-[1rem] lg:pl-[2rem]">
                {trans('mute_notifications_popup.mute_notifications')}
              </p>
              <p className="text-[#7A7A7A] text-[12px] pb-2">
                {/* The other participant will not see that you <br /> have muted
                this chat. */}
                {trans('mute_notifications_popup.the_other_participant_will_not')}
              </p>
            </div>
            <div className="pl-0 md:pl-[1rem] lg:pl-[2rem]">
              <div className="flex items-center mb-4 space-x-6">
                <input
                  type="radio"
                  id="option1"
                  value="1 day"
                  checked={selectedOption === "1 day"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option1"
                  className="ml-2  text-[12px] text-[#575556]"
                >
                  {trans('mute_notifications_popup.1day')}
                </label>
              </div>

              <div className="flex items-center mb-4 space-x-6">
                <input
                  type="radio"
                  id="option2"
                  value="7 days"
                  checked={selectedOption === "7 days"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4  accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option2"
                  className="ml-2 text-[12px] text-[#575556]"
                >
                  {trans('mute_notifications_popup.1week')}
                </label>
              </div>
              <div className="flex items-center mb-4 space-x-6">
                <input
                  type="radio"
                  id="option3"
                  value="all time"
                  checked={selectedOption === "all time"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4  accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option2"
                  className="ml-2 text-[12px] text-[#575556]"
                >
                  {trans('mute_notifications_popup.always')}
                </label>
              </div>
            </div>

            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={() => {
                  cancelClick();
                }}
                className="text-[13px] py-1.5 px-[3rem] whitespace-nowrap ext-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                {trans('mute_notifications_popup.cancel')}
              </button>
              <button
                onClick={onMute}
                className="text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]"
              >
                {trans('mute_notifications_popup.ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// Block
const CommonBlockPopup = ({ userData, props, receiverId, close, name }) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const { onClickTrack } = useAnalytics();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closePopupRef = useRef();


  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleOptionChange = (event) => {
    setSelectedOption((prev) => !prev);
    onClickTrack(EventName.chatThreeDotBlockReportContactRadioClick);
  };

  const cancelClick = () => {
    onClickTrack(EventName.chatThreeDotBlockCancelClick);
    close();
  };

  const blockProfile = () => {
    dispatch(
      toggleProfileBlock({
        profileId: receiverId,
        isBlocked: false,
      })
    );
    if (selectedOption) {
      navigate(RouteSegments.HELP_AND_FAQ(), {
        state: { isfrom: "report", senderId: userData?.profileId, receiverId },
      });
      onClickTrack(EventName.chatThreeDotBlockReport_BlockButtonClick, {
        [EventKeys.reciverId]: receiverId,
        [EventKeys.senderId]: userData?.profileId,
      });
    }
    if (!selectedOption) {
      onClickTrack(EventName.chatThreeDotBlockButtonClick, {
        [EventKeys.reciverId]: receiverId,
        [EventKeys.senderId]: userData?.profileId,
      });
    }
    close();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[18px]">
                {/* {`Block ${name}?`} */}
                 {trans('block_popup.block_name', { name })}
                </p>
              <p className="text-[#7A7A7A] text-[13px] pb-2">
                {trans('block_popup.blocked_contacts_cannot_call_o')}
              </p>
            </div>

            <div className="flex items-center mb-4 space-x-6">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption}
                onClick={handleOptionChange}
                className="form-radio h-4 w-4 mb-[2rem] lg:mb-3 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2 font-Poppins-Regular  font-bold text-[12px] text-[#575556]"
              >
                {trans('block_popup.report_contact')}
                <p className="text-[#7A7A7A] font-normal text-[12px]">
                {/* {trans('block_popup.the_last_number_messages_will', {number : 5})} */}
                 {trans('block_popup.the_last_5_messages_will_be_fo')}

                </p>
              </label>
            </div>
            <div className="flex justify-center gap-5 py-4">
              <button
                onClick={() => {
                  cancelClick();
                }}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                {trans('block_popup.cancel')}
              </button>
              <button
                onClick={() => blockProfile(selectedOption)}
                className="text-[13px] font-bold text-[#FFFFFF] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem]  rounded-[4px] border border-[#D10A11] bg-[#D10A11]"
              >
                {selectedOption ? trans('block_popup.report__block') : trans('block_popup.block')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonUnBlockPopup = (props) => {
  const dispatch = useDispatch();
  const closePopupRef = useRef();

  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const unBlockProfile = () => {
    dispatch(
      toggleProfileBlock({
        profileId: props.receiverId,
        isBlocked: true,
      })
    );
    props.close();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] modal-container md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[18px]">
              {trans('my_profile.profile_status')}
              </p>
              <p className="text-[#7A7A7A] pl-1 text-[13px] pt-2 pb-2">
                {/* {`${props?.name} was blocked by you. Do you wish to unblock now?`} */}
                {trans('unblock_popup.name_was_blocked_by_you_do_you',{name:props?.name})}
              </p>
            </div>
            <div className="flex justify-center gap-5 pt-3 pb-4">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] rounded-[4px] border text-[#7A7A7A] border-[#707070]"
              >
                {trans('unblock_popup.cancel')}
              </button>
              <button
                className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
                onClick={unBlockProfile}
              >
                {trans('unblock_popup.unblock')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ApplyEditPreferencePopup = (props) => {

  const { onClickTrack } = useAnalytics();
  const { t:trans } = useTranslation();
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack lg:items-center focus:outline-none">
      <div className="relative w-[100%] md:w-[55%] lg:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-8">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] text-[15px] pb-2 text-center">
                {trans('filters.set_this_as_your_partner_expec')}
              </p>
            </div>

            <div className="flex justify-center gap-5 pt-4">
              <button
                onClick={() => {
                  props.close();
                  onClickTrack(
                    EventName.filterBasicSetAsPartnerExpectationNoButton
                  );
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070] text-[#575556]"
              >
                {trans('filters.no')}
              </button>
              <button
                className="text-[13px] py-2 px-[3rem] text-white rounded-md bg-red-600"
                onClick={() => {
                  props.onClick();
                  onClickTrack(
                    EventName.filterBasicSetAsPartnerExpectationYesButton
                  );
                }}
              >
                {trans('filters.yes')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonReportPopup = ({ close, title, onReportClick }) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  const { t: trans } = useTranslation();
  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleOptionChange = (event) => {
    setSelectedOption((prev) => !prev);
    onClickTrack(EventName.chatThreeDotReportBlockContactRadioClick);
  };

  const handleReportClick = (selectedOption) => {
    if (selectedOption) {
      onClickTrack(EventName.chatThreeDotReport_ReportAndBlockButtonClick);
      onReportClick();
    } else {
      onClickTrack(EventName.chatThreeDotReportButtonClick);
      onReportClick();
    }
  };

  const onReportCancel = () => {
    onClickTrack(EventName.chatThreeDotReportCancelClick);
    close();
  };

  console.log({ selectedOption });

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%] md:w-[55%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className=" font-Poppins-Bold font-bold text-[#1A1A1A] text-[18px]">
                {title}
              </p>
              <p className=" font-Poppins-Regular text-[#7A7A7A] text-[12px] pb-4">
               
                {trans('report_popup.the_last_5_messages_from_this')}

              </p>
            </div>

            <div className="flex items-center mb-4 space-x-6">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption}
                onClick={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2 font-Poppins-Regular  text-[12px] text-[#7A7A7A]"
              >
               {trans('report_popup.block_contact_and_delete_chat')}
              </label>
            </div>

            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={onReportCancel}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
               {trans('block_popup.cancel')}
              </button>
              <button
                onClick={() => handleReportClick(selectedOption)}
                className=" whitespace-nowrap text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]"
              >
                {selectedOption ? trans('block_popup.report__block') : trans('report_popup.report')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonMessagePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("option1");
  const { t:trans } = useTranslation();


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-8 h-8 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full h-[42rem] bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              <div className="flex-shrink-0">
                <img src={DPimage} className="w-16 h-16" alt="" />
              </div>
              <div>
                <p className="text-[#4C5B71] text-[18px]">Jasmine Thompson</p>
                <p className="text-[#8292A8] text-[14px]">
                  25 yrs, 4'7", Pillal, B.Sc IT,
                </p>
                <p className="text-[#8292A8] text-[14px]">
                  Software Developer, Bengaluru
                </p>
              </div>
            </div>
            <div className="mt-[1rem] space-y-4">
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option1"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option1"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We are interested in your profile and would like to proceed
                  further
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option2"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option2"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We think you will be a good match for our Son/Daughter. Let us
                  know how we can take this ahead
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option3"
                  value="option3"
                  checked={selectedOption === "option3"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option3"
                  className="text-[12px] text-[#575556] px-2"
                >
                  Very happy to know you. I would like to take this forward and
                  connect with you.
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option4"
                  value="option4"
                  checked={selectedOption === "option4"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option4"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We seem to have a lot of shared interests. would like to know
                  you better.
                </label>
              </div>
            </div>
            <div className="flex justify-center py-5">
              <p className="text-[#D10A11] text-[18px] underline">
                Buy a pack to Send Your Own Message
              </p>
            </div>

            <div className="flex-col mt-2">
              <div className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <label className="mx-2 font-semibold text-[20px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                  {trans('view_profile.request_parents_number')}
                </label>
              </div>

              <div className="flex items-center mt-4">
                <input type="checkbox" className="mr-2" />
                <label className="mx-2 font-semibold text-[20px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                  {trans('chat.request_photo')}
                </label>
              </div>
            </div>

            <div className="flex py-5 justify-left">
              <p className="text-[#D10A11] text-[14px] underline">
                {trans('view_profile.report_an_abuse')}
              </p>
            </div>

            <div className="flex justify-center ">
              <button className="w-[22rem] h-[3rem] bg-[#D10A11] rounded-lg">
                <p className="text-[#ffffff] text-[20px] font-bold">Send</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonDangerPopup = ({ close, content, icon }) => {
  const { t:trans } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div className="relative w-[100%] lg:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="py-4 space-y-2 text-center">
              <div className="flex flex-col items-center justify-center">
                <img
                  src={icon ? icon : DangerIcon}
                  className="w-12 h-12"
                  alt="Danger Icon"
                />
              </div>
              <div className="py-3">
                <p className="text-[#000000] text-[18px]">{content}</p>
              </div>
            </div>

            <div className="flex justify-center gap-5">
              <button
                onClick={close}
                className="text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600"
              >
                {trans('km_care_mic_permission_popup.ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FileSizeWarningPopup = (props) => {

  const closePopupRef = useRef();
  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="py-4 space-y-2 text-center">
              <div className="flex flex-col items-center justify-center">
                <img src={DangerIcon} className="w-12 h-12" alt="Danger Icon" />
              </div>
              <div className="py-3 w-[80%] mx-auto xl:px-5">
                <p
                  className="text-[#000000] text-[13px] lg:text-[12px] xl:text-[14px]"
                  dangerouslySetInnerHTML={{ __html: props.content }}
                />
              </div>
            </div>

            <div className="flex justify-center gap-5">
              {/* <button onClick={props.close} className='text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600'>
                Close
              </button> */}
              <button
                onClick={props.onAdd}
                className="text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600"
              >
                {trans('matches.add_now')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonParentPopup = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setSearchFilter, setShowClearFilterPopup } =
    useContext(NavBarContext);
  const dispath = useDispatch();

  const onEditClick = () => {
    // setShowClearFilterPopup(false)
    if (location.pathname === RouteSegments.FILTER_LITE) {
      props.close();
      return;
    }
    if (location?.state?.isFrom == "basicAdvanceSearch") {
      navigate(RouteSegments.FILTER_LITE, {
        state: {
          tab: location.state?.isBasicSearch ? 1 : 2,
          isFrom: "editSearch",
          formData: location.state?.formData,
          searchState: location.state,
        },
      });
      setSearchFilter(false);
    } else {
      setSearchFilter(true);
      navigate(RouteSegments.FILTER_LITE, { state: { isFrom: "editfilter" } });
    }
    props.close();
  };

  const onClearClick = () => {
    setShowClearFilterPopup(false);
    if (location?.state?.isFrom == "basicAdvanceSearch") {
    } else {
      dispath(applyFilter([]));
    }
    setSearchFilter(true);
    props.close();
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.filterState);
    dispath(closeCurrentMatch());
    navigate(props.navPath ?? RouteSegments.MATCH_LITE);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      {/* <div className='font-Poppins-Regular flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none'> */}

      <div className="relative w-[100%] md:w-[55%] lg:w-[35%] my-6 mx-6">
        <div className="lg:block md:block">
          <button
            className="bg-transparent z-40 border-0 text-black  absolute top-[-8px] md:top-[-8px] lg:top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative lg:mt-[0.5rem] py-6 lg:py-0 mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2 text-center lg:py-2">
              <div className="lg:py-3">
                <p className="lg:text-[#1A1A1A] text-[#787878] text-[14px] font-segoe-ui lg:font-poppins lg:text-[16px]">
                  Do You Want To Edit Or Clear All Filters?
                </p>
              </div>
            </div>

            <div className="flex justify-center gap-5 pt-9 lg:py-4">
              <button
                onClick={onEditClick}
                className="text-[13px] py-2 px-[3rem] text-[#575556] rounded-md border lg:border-[#707070] border-[#707070]"
              >
                Edit
              </button>
              <button
                onClick={() => {
                  onClearClick();
                }}
                className="text-[13px] py-2 px-[2rem] text-white font-bold rounded-md bg-red-600"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonValidPopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const afterOneSpaceOnDot = (str) => {
    if (str && typeof str === "string") {
      return str
        .split(".")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(". ");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center lg:items-center focus:outline-none">
      <div
        className="relative w-[100%] md:w-[50%] lg:w-[35%] md:my-6 md:mx-6 modal-container"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-poppins font-bold text-[20px] text-start">
                {props.title}
              </p>
              <p className="text-[#7A7A7A] font-poppins text-[16px] pt-3 pb-1 text-start">
                {afterOneSpaceOnDot(props.content)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonProfilePopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const afterOneSpaceOnDot = (str) => {
    if (str && typeof str === "string") {
      return str
        .split(".")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(". ");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center lg:items-center focus:outline-none">
      <div
        className="relative w-[100%] md:w-[45%] lg:w-[31%] md:my-6 md:mx-6 modal-container"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[20px] text-center text-[#D10A11] ml-2 font-semibold align-middle ">
                {props.title}
              </p>
              <div className="relative flex-auto p-4">
                <p className="text-[#7A7A7A] text-center font-poppins text-[16px] pt-3 pb-1 ">
                  {afterOneSpaceOnDot(props.content)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const YesOrNoComponent = ({ value = true, onChange }) => {

  const { t:trans } = useTranslation();

  return (
    <>
      <CommonCheckButton
        id={"bulkRequestYes"}
        name={"bulkRequestYes"}
        selected={value}
        checktext={trans('send_bulk_request.yes')}
        value={"yes"}
        onChange={onChange}
      />
      <CommonCheckButton
        id={"bulkRequestNo"}
        name={"bulkRequestNo"}
        selected={!value}
        checktext={trans('send_bulk_request.no')}
        value={"no"}
        onChange={onChange}
      />
    </>
  );
};

export const BulkRequestPopup = ({
  close,
  title,
  content,
  onSend,
  message,
  imageSrc,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 678 });

  const { t:trans } = useTranslation();

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".modal-container")) {
      // Close the modal if clicked outside the modal content
      // onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [choiceStatus, setChoiceStatus] = useState(true);
  return (
    <div
      onClick={(e) => {
        if (e.target.id == "model-wrapper" && isMobile) {
          close();
        }
      }}
      id="model-wrapper"
      className="fixed inset-0 z-50 flex items-end justify-end overflow-x-hidden overflow-y-auto outline-none md:justify-center bg-opacityblack md:items-center focus:outline-none"
    >
      <div
        id="model-content"
        className="relative md:w-auto md:my-6 md:mx-6 modal-container"
      >
        <div className="hidden md:block">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0  rounded-tl-[40px] rounded-tr-[40px] md:rounded-lg shadow-lg relative md:mt-[0.5rem] md:mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div
            className={
              imageSrc
                ? "flex items-center pb-1 pt-5 justify-center  border-[#D10A11] mx-2   "
                : "flex items-center pb-1 pt-[3rem] justify-center  border-[#D10A11] mx-2   "
            }
          >
            <h3
              className={
                imageSrc
                  ? "text-[20px] text-[#D10A11] ml-2 font-semibold align-middle"
                  : "text-[#D10A11] align-middle font-bold font-Poppins text-[18px]"
              }
            >
              {title}
            </h3>
          </div>
          <div
            className={
              imageSrc
                ? "relative p-6 flex-auto"
                : "relative px-6 mb-[3rem] flex-auto"
            }
          >
            <div className="flex flex-col justify-center items-center w-[88vw] md:w-[35vw]">
              <p
                className={
                  imageSrc
                    ? "text-[16px] font-semibold whitespace-nowrap text-[#575556]"
                    : "text-[#777777] font-regular pt-2 font-Poppins text-[14px] whitespace-nowrap"
                }
              >
                {content}
              </p>
              {imageSrc ? (
                <img className="w-16 h-16 mt-2" alt="" src={imageSrc} />
              ) : (
                <img
                  className="w-10 h-10 mt-[1.2rem]"
                  src={SuccessIcon}
                  alt=""
                />
              )}
              {message && (
                <>
                  <p className="text-[14px] text-[#575556] text-center pb-2 pt-4">
                    {message}
                  </p>
                  <div className="flex justify-center w-full space-x-4">
                    <YesOrNoComponent
                      value={choiceStatus}
                      onChange={() => setChoiceStatus((prev) => !prev)}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => onSend(choiceStatus)}
                    className={`mt-4 flex items-center justify-center  lg:h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[4rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[14px] ${trans('send_bulk_request.send_request') == 'Send Request' ? 'lg:w-[2.5rem]' : ''}`}
                  >
                    {trans('send_bulk_request.send_request')}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentBannerPopup = ({ close }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              Hi
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadPhotoPopuplite = (props) => {

  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const { setMenuSelect } = useContext(NavBarContext);
  const location = useLocation();
  const { onClickTrack } = useAnalytics()
  const { t:trans } = useTranslation();



  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const handleCrop = (file, type) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.height_and_width_must_be_more'),
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.image_size_should_not_be_great'),
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");


      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {
    // let imageData = new FormData();
    // imageData.append("image", file);
    // let compressedImageData = await axios.post(
    //   `${config.cms_api}/compressImage`,
    //   imageData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    // if (compressedImageData.data.code === 200) {

    // await fetch(compressedImageData?.data?.base64,{
    //     mode: 'no-cors'
    //   })
    // .then((res) => res.blob())
    // .then((myBlob) => {
    //   console.log(myBlob,"myBlob");
    //    const compressedImageUrl = new File([myBlob], compressedImageData?.data?.compressed_image, {type: myBlob.type});
    //    originalImageUpload(compressedImageUrl)
    //   });

    // }
    //  else {
    //   alert("Please upload file less than 10 mb");
    //   props.close();
    // }
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);

      },
    });
  };
  // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? 
  // sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId

console.log(props?.microtrackidData, props?.isFrom,'props.microtrackidData');

  const NotificationId =
    localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
        ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
          props?.showdatas ||
          props?.message ||
          props?.ShowpopupDataNotification
        : userData?.popup[0]?.notificationId ||
        props?.message ||
        props?.ShowpopupDataNotification;


          console.log(NotificationId,'NotificationIdNotificationIdhap');

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    let notificationId;
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
    fileData.append("photorequesttypeid", 85);
    fileData.append("requestfulfilledfrom", getDeviceInfo());
    fileData.append("recieverprofileid", getGroupAuthId());

    if (props?.isFrom === "Addphoto" && props?.microtrackidData) {
      fileData.append("microtrackId", props.microtrackidData);
    }
    if(props.messageTypeId && props.kmcareId){
      fileData.append("messageTypeId", props.messageTypeId);
      fileData.append("kmcareId", props.kmcareId);
    }
    if(props.messageTypeId == 78 || props.messageTypeId == 75 || props.messageTypeId == 80){
      fileData.append("notificationId", props.KMNotificationId);
    }
    if (props.isFrom === 'TrailPack') {
      fileData.append("editviatrialpack", props.editviatrialpack);
      fileData.append("notificationId", NotificationId ? NotificationId : props.KMNotificationId);
    }
    notificationId = sessionStorage.getItem(
      Constants.loginLocalStorageKeys.notificationId
    )
    if (notificationId) {
      fileData.append("notificationId", notificationId);
    }
    if(props.isFrom=="wizardphoto"){
      fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
    }
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        if (props?.isFrom == 'TrailPack') {
          props.postData();
        }
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const ShowPopup =()=>{
    alert('njm')
    setTimeout(()=>{
      setshowSuccessPopup(true);

    },1000)
    // props?.close();
  }

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup || props?.from || props.PrivacyOptionTitle) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      
      if(props?.isFrom === "Addphoto" && props?.microtrackidData){
        fileData.append("microtrackId", props?.microtrackidData);
        
      }
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      if (props.isFrom === 'TrailPack') {
        fileData.append("editviatrialpack", props.editviatrialpack);
        fileData.append("notificationId", NotificationId ? NotificationId : props.KMNotificationId);
      }

      if(props.isFrom=="wizardphoto"){
        fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
      }
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());
      if(props.messageTypeId && props.kmcareId){
        fileData.append("messageTypeId", props.messageTypeId);
        fileData.append("kmcareId", props.kmcareId);
      }
      if(props.messageTypeId == 78 || props.messageTypeId == 75 || props.messageTypeId == 80){
        fileData.append("notificationId", props.KMNotificationId);
      }

      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          if (props?.isFrom == "contentcard") {
            // props?.callBackReload();
            props?.setUploadPhotoSuccessPopup(true);
            return props?.close();
          }
         console.log(props?.isFrom,"props?.isFrommm");
         
          if(props?.microtrackidData){
            props?.setUploadPhotoSuccessPopup(true);
            return props?.close();
          }
          if (props?.isFrom == 'TrailPack') {
            props.postData();
            props.setCompleteNow(true)
          }
          if (props?.from == "AddPhotoFromCLC") {
            props?.clcClick();
          } else if (props.messageTypeId !== 78){
            props.setSuccessPopup(true)
          }else if (props.PrivacyOptionTitle == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
            props.setshowUploadSuccessPopup(true);
          }
         
         
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  // const logoutUsignAPI = async () => {
  //   let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });

  //   if (data.status === 200) {
  //     setShowLoader(false);
  //     logout();
  //   }
  // };

  // const signout = () => {
  //   setShowLoader(true)
  //   logoutUsignAPI()
  // };

  useEffect(() => { }, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    if (props.isFrom == 'TrailPack') {
      return;
    } else {
      props.close();
    }
  };

  const closePopup = () => {
    // Add any additional logic to close the popup
    props.close();
  };

  console.log(props?.count, "props?.countprops?.count");

  // function openInstagramPopup() {
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const popupWidth = 500;
  //   const popupHeight = 800;

  //   const left = (screenWidth - popupWidth) / 2;
  //   const top = (screenHeight - popupHeight) / 2;

  //   window.open(
  //     "https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0",
  //     "InstagramPopup",
  //     `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
  //   );
  // }

  // function openFacebookPopup() {
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const popupWidth = 500;
  //   const popupHeight = 800;

  //   const left = (screenWidth - popupWidth) / 2;
  //   const top = (screenHeight - popupHeight) / 2;

  //   window.open(
  //     "https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct",
  //     "FacebookPopup",
  //     `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
  //   );
  // }
  // const userData = useSelector(
  //   (state) => state?.DashBoardAPI?.ProfileData?.data
  // );

  const helplineClickTrack = () => {
    onClickTrack(EventName.web_addphotopopup_callexecutive)
  }

  const renderhelpline = () => {
    return <div className="border rounded-lg flex space-x-2 px-2.5 py-2 mx-auto w-fit items-center">
      <img alt="call" src={Images.PhoneIcon} className="w-6 h-6 mx-2" />
      <div className=" space-y-1.5">
        <p className="text-center text-black-500 font-Poppins-SemiBold text-[12px] md:text-[14px]">
          {props.title == "Add Photo" ? trans('photo_popup.call_us') :
            trans('logout_popup.call_us')}</p>
        <p className="text-center text-[#D10A11] font-Poppins-Bold text-[12px] md:text-[14px]">{Constants.helpLinePhNo}</p>
      </div>
    </div>
  }

  return (
    <>
      <>
        {notification.show && (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        )}
        {showCrop && (
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props.close();
              }

              if (props?.callBackReload) {
                props?.callBackReload();
              }
            }}
          />
        )}

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={trans('photos.success')}
            content={"Image upload success"}
          />
        )}
        {
          !showCrop && showLoader ? (
            <Loader
              className="loaderTransperancy"
              loaderClassName="loaderTransperancySpin"
            />
          ) : props?.menu === "profileview" ? (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
              <div className="relative w-auto mx-6 my-6">
                <div className="flex items-center py-1 justify-between border-b border-solid bg-[#D10A11] border-gray-300 rounded-t ">
                  <h3 className="text-[16px] text-white ml-4 font-semibold align-middle">
                    {props.title}
                  </h3>
                  <button
                    className="float-right pr-4 text-black bg-transparent border-0 "
                    onClick={() => {
                      props.close();
                    }}
                  >
                    <img className="w-5 h-5 " src={CloseIcon} alt="" />
                  </button>
                </div>
                {compressing ? (
                  <div className="p-6 bg-white flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] lg:w-auto justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                       {trans('upload_photos.uploading')}
                    </div>
                  </div>
                ) : isUploading ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] lg:w-auto justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                       {trans('upload_photos.uploading')}
                    </div>
                  </div>
                ) : (
                  <div className="relative flex-auto p-6 bg-white ">
                    <div className="flex flex-col justify-center items-center w-[60vw] md:w-[60vw] ">
                      {props.title === trans('who_viewed_my_profile.who_viewed_my_profile') ? (
                        <p className="text-[20px] font-semibold mb-4 text-center">
                          {props.count}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="text-[18px] ">
                      {trans('who_viewed_my_profile.people_who_viewed_you_might_lo')}{" "}
                      </p>
                      <p className="text-[18px] ">
                      {trans('who_viewed_my_profile.please_add_now_to_increase_the')}
                      </p>

                      <div
                        className="mt-5"
                        onClick={() => {
                          if (props.isFrom == "sign out") {
                            setMenuSelect("2");
                            navigate(
                              RouteHelper.getEditProfileUrl(
                                EditProfileMenu[2].urlPath
                              ),
                              { state: { tab: "3" } }
                            );
                            closePopup();
                          }
                        }}
                      >
                        <label>
                          <a className="bg-[#D10A11] font-semibold text-white py-2 px-3 text-[13px] cursor-pointer whitespace-nowrap">
                          {trans('who_viewed_my_profile.add_your_photo_now')}
                          </a>

                          <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={(event) => {
                              handleCrop(event.target.files[0]);
                            }}
                            accept="image/png, image/jpg, image/jpeg"
                            data-max-size="10485760"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={`font-Poppins-Regular flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none ${props.isFrom == "sign out" ? 'z-[1000]' : 'z-[99]'}`}>
              <div className="relative w-auto md:my-6  mt-[10rem] mb-[2rem] mx-6">
                <div className="">
                  <button
                    className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-2px]"
                    onClick={() => {
                      props.close();
                      if (props.isFrom == 'TrailPack') {
                        props.setCompleteNow(true)
                      }
                    }}
                  >
                    <img
                      className="w-5 h-5 "
                      src={CloseIcon}
                      alt=""
                      loading="lazy"
                    />
                  </button>
                </div>

                <div className="lg:max-w-[70vw] border-0 rounded-lg shadow-lg relative lg:px-[4rem] xl:px-2 mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none xl:max-w-[60vw]">
                  <div className="flex items-center justify-center py-1 mx-2 mt-5 ">
                    <h3 className="text-[23px] text-[#1A1A1A] ml-2 font-Poppins font-bold align-middle text-center">
                      {props.title == trans('photo_popup.add_photo') ? trans('photo_popup.add_photo') :trans('logout_popup.logout')}
                    </h3>
                  </div>
                  {isUploading ? (
                    <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                      <img
                        alt="loader"
                        src={LoaderGif}
                        className={`w-16 h-16`}
                      />
                      <div className="text-[16px] font-semibold my-2">
                         {trans('upload_photos.uploading')}
                      </div>
                    </div>
                  ) : (
                    <div className="relative flex-auto p-4 md:p-6 ">
                      <div className="flex flex-col items-center justify-center ">
                        {props?.menu === "profileview" ? (
                          ""
                        ) : (
                          <span className="xl:text-[16px] md:text-[16px] text-[11px] text-[#1A1A1A] font-regular font-Poppins font-bold text-center">

                            {props.title == "Add Photo" ?
                              trans('photo_popup.profiles_with_photo_get_more_r') :
                              trans('logout_popup.profiles_with_photo_get_more_r')}
                          </span>
                        )}
                      </div>

                      <div className="lg:grid md:grid-cols-7 md:gap-4">
                        <div className='lg:col-span-3'>
                          <div className="grid grid-cols-2 gap-4 px-3 py-4 md:py-9">
                            {/* <lable
                        className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl"
                        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}
                        onClick={() => {
                          if (props.isFrom == "sign out") {
                            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                            closePopup();
                          }

                        }}
                      >
                          <img src={addphotoIcon} className="w-[3rem] h-[3rem]" />
                          <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#D10A11] shadow-md rounded-xl cursor-pointer">
                            <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a className="cursor-pointer">
                              Add Photo Now!
                            </a>
                            {props.isFrom === "sign out"
                            ? <></> :
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) => {
                                handleCrop(event.target.files[0]);
                              }}
                              accept="image/png, image/jpg, image/jpeg"
                              data-max-size="10485760"
                            />
                          }
                            </p>
                          </div>
                      </lable> */}

                            <label>
                              <div
                                className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() => {
                                  if (props.isFrom == "sign out") {
                                    setMenuSelect("2");
                                    navigate(
                                      RouteHelper.getEditProfileUrl(
                                        EditProfileMenu[2].urlPath
                                      ),
                                      { state: { tab: "3" } }
                                    );
                                    closePopup();
                                  }
                                }}
                              >
                                <img
                                  src={addphotoIcon}
                                  className="w-[3rem] h-[3rem]"
                                  loading="lazy"
                                  alt=""
                                />
                                <div className="flex items-center justify-center lg:w-[7rem] xl:w-[7rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#D10A11] shadow-md rounded-xl cursor-pointer">
                                <p className="text-[#000000] font-medium font-Poppins text-[8px] text-center">
                                    <a className="text-[#D10A11] font-bold  cursor-pointer" onClick={() => { props.isFrom == 'TrailPack' && onClickTrack(EventName.Trial_Pack_popup_Add_Your_Photo_Upload_Button) }}>
                                    <OverflowToolTip as='p' className='truncate lg:w-[6rem] xl:w-[6rem] md:w-[7rem] w-[6rem]'>{props.title == "Add Photo" ?
                                        trans('photo_popup.add_photo_now') :
                                        trans('logout_popup.add_photo_now')}</OverflowToolTip>
                                    </a>

                                    {props.isFrom === "sign out" ? (
                                      <></>
                                    ) : (
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) => {
                                          handleCrop(event.target.files[0]);
                                        }}
                                        accept="image/png, image/jpg, image/jpeg"
                                        data-max-size="10485760"
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                            </label>

                            <div
                              className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer"
                              style={{
                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                              }}
                              onClick={() => {
                                window.open(
                                  Constants.kalyanSupportWhatsappUrl(
                                    userData?.smProfileId
                                  )
                                );
                                onClickTrack(EventName.Trial_Pack_popup_Add_Your_Photo_Whatsapp_Button)
                              }}
                            >
                              <img
                                src={whatsappIcon}
                                className="w-[3rem] h-[3rem]"
                                loading="lazy"
                                alt=""
                              />
                              <div className="flex items-center justify-center lg:w-[7rem] xl:w-[7rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#49C858] shadow-md rounded-xl cursor-pointer">
                                {props.title == "Add Photo" ?
                                <p className="text-[#000000] font-bold font-Poppins text-[8px] cursor-pointer text-center">
                                  {props.title == "Add Photo" ?
                                    trans('photo_popup.add_photo_via_whatsapp')
                                    :trans('logout_popup.add_photo_via_whatsapp')
                                  }
                                </p>:
                                <OverflowToolTip as="p" className="text-[#000000] font-bold font-Poppins text-[8px] cursor-pointer text-center truncate lg:w-[6rem] xl:w-[6rem] md:w-[7rem] w-[6rem]">
                                  {props.title == "Add Photo" ?
                                    trans('photo_popup.add_photo_via_whatsapp')
                                    :trans('logout_popup.add_photo_via_whatsapp')
                                  }
                                </OverflowToolTip>}

                              </div>
                            </div>

                            {/* <div className="flex flex-col  space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} onClick={() => openFacebookPopup()}>
                        <img src={facebookIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt=""/>
                        <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#1976D2] shadow-md rounded-xl cursor-pointer">
                          <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Upload From facebook</a>
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}  onClick={() => openInstagramPopup()}>
                        <img src={instragramIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt=""/>
                        <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#7F4BAD] shadow-md rounded-xl cursor-pointer">
                          <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a href="https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                              Upload From Instagram
                            </a>
                          </p>
                        </div>
                      </div> */}
                          </div>
                          <SocialMediaUpload
                            closeModal={() => props.close()}
                            callBackImage={(e) => props?.callBackReload(e)}
                            isFrom={'trailpack'}
                          />
                        </div>
                        <div className="lg:col-span-1 flex justify-center items-center">
                          {/* <div className="relative hidden flex justify-center items-center  h-full md:hidden lg:block">
                            <div className="flex justify-center items-center  h-full">
                              <p className={`text-[#D10A11] font-bold bg-white py-2 z-[1] text-center ${(trans('photo_popup.or') || trans('logout_popup.or')) == 'OR'? 'lg:pl-8 ' : 'lg:pl-2 '}`}>
                                {props.title == trans('photo_popup.add_photo') ?
                                  trans('photo_popup.or') :trans('logout_popup.or')}</p>
                            </div>
                            <div className='flex justify-center items-center  h-full '>
                              <Divider type="vertical" style={{ height: "100%" }} plain={false} />
                            </div>
                          </div> */}
                        
                            <div className="relative hidden h-full md:hidden lg:flex lg:items-center">
                              <div className="flex items-center justify-center h-full">
                                <p
                                  className={`text-[#D10A11] font-bold bg-white py-2 z-[1] text-center ${ (trans("photo_popup.or")
                                  || trans("logout_popup.or")) == 'OR' ? 'lg:pl-5' : ''}`}
                                >
                                  {props.title === trans("photo_popup.add_photo")
                                    ? trans("photo_popup.or")
                                    : trans("logout_popup.or")}
                                </p>
                              </div>
                              <div className="flex justify-center items-center h-full absolute px-5">
                                <Divider type="vertical" style={{ height: "100%" }} plain={false} />
                              </div>
                            </div>

                          <div className="block md:block lg:hidden">
                            <Divider style={{ width: "100%" }}>
                              <div className="flex justify-center items-center text-[#D10A11] font-bold">
                                <p className='text-center'>{props.title == trans('photo_popup.add_photo') ?
                                  trans('photo_popup.or') :trans('logout_popup.or')
                                }</p>
                              </div>
                            </Divider>
                          </div>
                        </div>
                        <div className="md:flex lg:col-span-3">
                          {/* <div className="relative hidden pt-3 mr-2 md:block">
                            <div className="flex justify-center items-center absolute  h-full">
                              <p className="text-[#D10A11] font-bold bg-white py-2 z-[1] text-center">
                                {props.title == "Add Photo" ?
                                  trans('photo_popup.or') :trans('logout_popup.or')}</p>
                            </div>
                            <Divider type="vertical" style={{ height: "100%" }} plain={false} />
                          </div>
                          <div className="block mr-2 md:hidden">
                            <Divider style={{ width: "100%" }}>
                              <div className="flex justify-center items-center text-[#D10A11] font-bold">
                                {props.title == "Add Photo" ?
                                  trans('photo_popup.or') :trans('logout_popup.or')

                                }
                              </div>
                            </Divider>
                          </div> */}
                          <div className="flex flex-col items-center justify-center mb-4 space-y-4">
                            <div className="flex items-center justify-center">
                              <img alt="call" src={Images.PhoneIcon} className="w-8 h-8 mx-2" />
                              <p className="text-center font-Poppins font-bold text-[13px] md:text-[16px]">
                                {props.title == "Add Photo" ?
                                  trans('photo_popup.call_our_expert_to_know_what_i')
                                  :trans('logout_popup.call_our_expert_to_know_what_i')}
                              </p>
                            </div>

                            <div className="hidden lg:block">{renderhelpline()}</div>
                            <a onClick={helplineClickTrack} className="block lg:hidden" href={`tel:${Constants.helpLinePhNo}`}>
                              {renderhelpline()}
                            </a>
                          </div>
                        </div>
                      </div>

                      {props.isFrom == "sign out" && (
                        <div className="flex justify-center mb-2">
                          <button
                            onClick={props?.signout}
                            className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                          >
                            {trans('logout_popup.sign_out')}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
          //   <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
          //   <div className="relative w-auto mx-6 my-6">
          //     <div className="">
          //       <button
          //         className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
          //         onClick={() => {
          //           props.close();
          //         }}
          //       >
          //         <img
          //           className="w-5 h-5 "
          //           src={CloseIcon}
          //         />
          //       </button>
          //     </div>

          //     <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          //       <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
          //         <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
          //           {props.title}
          //         </h3>
          //       </div>
          //       {compressing ?
          //         <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
          //           <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
          //           <div className="text-[16px] font-semibold my-2">
          //             Uploading...
          //           </div>
          //         </div>
          //         :
          //         isUploading
          //           ? <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
          //             <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
          //             <div className="text-[16px] font-semibold my-2">
          //               Uploading...
          //             </div>
          //           </div>
          //           : <div className="relative flex-auto p-6 ">

          //             <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
          //               {props?.menu === "profileview" ? ""
          //                 : <span className="text-[16px] font-semibold">
          //                   Profiles with photo, gets more response
          //                 </span>}
          //               {props?.menu === "profileview" ? "" : <img
          //                 className="w-16 h-16 mt-2"
          //                 alt=""
          //                 src={UploadPhotoIcon}
          //               />}
          //               <label>

          //                 <a className="text-[#D10A11] font-bold underline cursor-pointer"
          //                   onClick={() => {
          //                     if (props.isFrom == "sign out") {
          //                       navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
          //                       closePopup();
          //                     }

          //                   }}
          //                 >
          //                   Add Photo Now
          //                 </a>

          //                 {props.isFrom === "sign out"
          //                   ? <></> :
          //                   <input
          //                     style={{ display: "none" }}
          //                     type="file"
          //                     onChange={(event) => {
          //                       handleCrop(event.target.files[0]);
          //                     }}
          //                     accept="image/png, image/jpg, image/jpeg"
          //                     data-max-size="10485760"
          //                   />
          //                 }
          //               </label>
          //             </div>
          //           </div>}

          //       {/* Social Login */}
          //       {props?.menu === "profileview" ? "" :

          //         <SocialMediaUpload closeModal={() => props.close()} callBackImage={(e) => props?.callBackImage(e)} />

          //       }
          //       {props.isFrom == "sign out" &&
          //         <div className="flex justify-center mb-2">
          //           <button
          //             onClick={signout}
          //             className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
          //           >
          //             Sign out
          //           </button>
          //         </div>
          //       }
          //     </div>
          //   </div>
          // </div>
        }
      </>
    </>
  );
};

const UploadPhotoPopup = (props) => {
  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();

  const { t:trans } = useTranslation();

  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const handleCrop = (file) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.height_and_width_must_be_more'),
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.image_size_should_not_be_great'),
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {
    // let imageData = new FormData();
    // imageData.append("image", file);
    // let compressedImageData = await axios.post(
    //   `${config.cms_api}/compressImage`,
    //   imageData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    // if (compressedImageData.data.code === 200) {

    // await fetch(compressedImageData?.data?.base64,{
    //     mode: 'no-cors'
    //   })
    // .then((res) => res.blob())
    // .then((myBlob) => {
    //   console.log(myBlob,"myBlob");
    //    const compressedImageUrl = new File([myBlob], compressedImageData?.data?.compressed_image, {type: myBlob.type});
    //    originalImageUpload(compressedImageUrl)
    //   });

    // }
    //  else {
    //   alert("Please upload file less than 10 mb");
    //   props.close();
    // }
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);
      },
    });
  };

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
    fileData.append("photorequesttypeid", 85);
    fileData.append("requestfulfilledfrom", getDeviceInfo());
    fileData.append("recieverprofileid", getGroupAuthId());
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());
      
      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setshowSuccessPopup(true);
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }
          props?.close();
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  const logoutUsignAPI = async () => {
    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        loginFrom: localStorage.getItem(
          Constants.loginLocalStorageKeys.loginDevice
        ),
        familyloginid:
          localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId) ??
          undefined,
      },
    });

    if (data.status === 200) {
      setShowLoader(false);
      logout();
      localStorage.setItem("isLoggedout", true)
    }
  };

  const signout = () => {
    setShowLoader(true);
    logoutUsignAPI();
  };

  useEffect(() => { }, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    props.close();
  };

  const closePopup = () => {
    // Add any additional logic to close the popup
    props.close();
  };

  console.log(props?.count, "props?.countprops?.count");

  return (
    <>
      <>
        {notification.show ? (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        ) : null}
        {showCrop && (
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props.close();
              }
            }}
          />
        )}

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={trans('photos.success')}
            content={"Image upload success"}
          />
        )}
        {!showCrop && showLoader ? (
          <Loader
            className="loaderTransperancy"
            loaderClassName="loaderTransperancySpin"
          />
        ) : props?.menu === "profileview" ? (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
            <div className="relative w-auto mx-6 my-6">
              <div className="flex items-center py-1 justify-between border-b border-solid bg-[#D10A11] border-gray-300 rounded-t ">
                <h3 className="text-[16px] text-white ml-4 font-semibold align-middle">
                  {props.title}
                </h3>
                <button
                  className="float-right pr-4 text-black bg-transparent border-0 "
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="w-5 h-5 " src={CloseIcon} alt="" />
                </button>
              </div>
              {compressing ? (
                <div className="p-6 bg-white flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                  <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                  <div className="text-[16px] font-semibold my-2">
                     {trans('upload_photos.uploading')}
                  </div>
                </div>
              ) : isUploading ? (
                <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                  <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                  <div className="text-[16px] font-semibold my-2">
                     {trans('upload_photos.uploading')}
                  </div>
                </div>
              ) : (
                <div className="relative flex-auto p-6 bg-white ">
                  <div className="flex flex-col justify-center items-center  md:w-[40vw]">
                    <div className='flex justify-center'>
                    {props.title === trans('who_viewed_my_profile.who_viewed_my_profile') ? (
                      <p className="text-[20px] font-semibold mb-4 text-center">
                        {props.count}
                      </p>
                    ) : (
                      ""
                    )}</div>
                    <p className="text-[18px] ">
                      {trans('who_viewed_my_profile.people_who_viewed_you_might_lo')}{" "}
                    </p>
                    <p className="text-[18px] ">
                    {trans('who_viewed_my_profile.please_add_now_to_increase_the')}
                    </p>
                    </div>
                    <div className='mt-2 flex justify-center'>
                    <button
                      className="bg-[#D10A11] font-semibold text-white py-2 px-3 text-[13px] cursor-pointer"
                      onClick={() => {
                        if (props.isFrom === "sign out") {
                          navigate(
                            RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath),
                            { state: { tab: "3" } }
                          );
                          closePopup();
                        }
                      }}
                    >
                      <label
                        className="font-semibold text-white text-center text-[13px] cursor-pointer whitespace-nowrap"
                        htmlFor="file-upload"
                      >
                        {trans('who_viewed_my_profile.add_your_photo_now')}
                      </label>
                      <input
                        id="file-upload"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(event) => handleCrop(event.target.files[0])}
                        accept="image/png, image/jpg, image/jpeg"
                        data-max-size="10485760"
                      />
                    </button>

                    </div>
                  
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
            <div className="relative w-auto mx-6 my-6">
              <div className="">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="w-5 h-5 " src={CloseIcon} alt="" />
                </button>
              </div>

              <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full md:w-[25rem] lg:w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center py-1 justify-center border-[#D10A11] mx-2  rounded-t ">
                  <h3 className="text-[20px] text-[#D10A11] ml-2 mt-2 font-semibold align-middle">
                    {props.title}
                  </h3>
                </div>
                {compressing ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                       {trans('upload_photos.uploading')}
                    </div>
                  </div>
                ) : isUploading ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                       {trans('upload_photos.uploading')}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="relative flex justify-center flex-auto p-6">
                      <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
                        {props?.menu === "profileview" ||
                          props?.menu == "newphoto" ? (
                          ""
                        ) : props?.menu == "changephoto" ? (
                          <span className="text-[16px] font-semibold">
                            {/* Add a new photo to change your profile picture */}
                            {trans('photos.add_a_new_photo_to_change_your')}
                          </span>
                        ) : (
                          <span className="text-[16px] font-semibold">
                            {trans('photo_popup.profiles_with_photo_get_more_r')}
                          </span>
                        )}
                        {props?.menu === "profileview" || props?.isV3User ? (
                          ""
                        ) : (
                          <img
                            className="w-16 h-16 mt-2"
                            alt=""
                            src={UploadPhotoIcon}
                          />
                        )}
                        <label>
                          {/* {props.menu=="mobileverification"?
                  <a className="text-[#D10A11] font-bold underline cursor-pointer" 
                      onClick={() => {
                      if (props.isFrom == "sign out") {
                        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                        }
                      }}
                      >
                    Browse
                  </a>
                  : */}
                          {props?.isV3User ? (
                            <></>
                          ) : (
                            <a
                              className="text-[#D10A11] font-bold underline cursor-pointer"
                              onClick={() => {
                                if (props.isFrom == "sign out") {
                                  navigate(
                                    RouteHelper.getEditProfileUrl(
                                      EditProfileMenu[2].urlPath
                                    ),
                                    { state: { tab: "3" } }
                                  );
                                  closePopup();
                                }
                              }}
                            >
                              {trans('photo_popup.add_photo_now')}
                            </a>
                          )}
                          {/* // <button type="submit" className="w-full flex items-center justify-center  h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1rem]  whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] ">
                  //   Browse 
                  // </button>
                    } */}
                          {props.isFrom === "sign out" ? (
                            <></>
                          ) : (
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) => {
                                handleCrop(event.target.files[0]);
                              }}
                              accept="image/png, image/jpg, image/jpeg"
                              data-max-size="10485760"
                            />
                          )}
                        </label>
                      </div>
                    </div>
                    {/* Social Login */}
                    {props?.menu === "profileview" ? (
                      ""
                    ) : (
                      <SocialMediaUpload
                        closeModal={() => props.close()}
                        callBackImage={(e) => props?.callBackImage(e)}
                        handleCrop={handleCrop}
                        menu={props?.menu}
                      />
                    )}
                  </>
                )}

                {props.isFrom == "sign out" && (
                  <div className="flex justify-center mb-2">
                    <button
                      onClick={signout}
                      className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                    >
                      Log out
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

const ViewPhotoPasswordPopup = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);

  const { t:trans } = useTranslation();

  const formik = useFormik({
    initialValues: {
      password: "",
      errorMsg: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(trans('photo_view_popup__password_pro.please_enter_photo_password'))
        .min(8, trans('photo_view_popup__password_pro.password_must_contain_at_least'))
        .max(16, "Password must less than 16 characters"),
    }),

    onSubmit: (values) => {
      props.callBackApply(values.password);
    },
  });

  useEffect(() => {
    //console.log(props?.errorMsg,"props?.errorMsg");

    if (props?.errorMsg) {
      // props?.errorMsg
    } else {
      return;
    }
  }, [props?.errorMsg, formik.values]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto max-w-3xl mx-6 my-6 ">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-1px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="w-[90vw] md:w-[57vw] lg:w-[35vw] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-center py-1 mx-2 rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {trans('photo_view_popup__password_pro.view_photo')}
            </h3>
          </div>
          <p className="mx-5 mt-5 text-[13px] text-center text-[#575556]">
            {trans('photo_view_popup__password_pro.this_photo_is_password_protect')}
          </p>
          <div className="relative flex items-center justify-center flex-auto p-6 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center justify-center"
            >
              <label className="my-2">
                <input
                  name={"password"}
                  id={"password"}
                  type={showHidePassword ? "text" : "password"}
                  placeholder={trans('photo_view_popup__password_pro.enter_photo_password')}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="truncate w-[72vw] md:w-[41vw] lg:w-[30vw] p-2.5 my-1  text-sm text-gray-900  rounded-[3px] border border-[#F18181]"
                />
                <div className="absolute top-[3rem] md:top-[3rem] lg:top-[2.8rem] right-[3rem] md:right-[4.5rem] lg:right-[3rem] 2xl:right-[3.7rem]">
                  <img
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    src={showHidePassword ? EyeOpenIcon : EyeIcon}
                    className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                    alt=""
                  />
                </div>
                <div className="h-2">
                  {props?.errorMsg && formik.touched.password ? (
                    <>
                      <div className='lg:block md:block hidden'>
                        <p className="text-[red] text-[12px]">{props?.errorMsg}</p>
                      </div>
                      <div className='lg:hidden md:hidden w-[72vw] md:w-[41vw] lg:w-[30vw]'>
                        <OverflowToolTip as='p' className="text-[red] text-[12px] truncate ">{props?.errorMsg}</OverflowToolTip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='lg:block md:block hidden'>
                        <p className="text-[red] text-[12px]">
                          {formik.errors.password}
                        </p>
                      </div>
                      <div className='lg:hidden md:hidden w-[72vw] md:w-[41vw] lg:w-[30vw]'>
                        <OverflowToolTip as='p' className="text-[red] text-[12px] truncate ">{formik.errors.password}</OverflowToolTip>
                      </div>
                    </>
                  )}
                </div>
              </label>

              <button
                type="submit"
                className="truncate flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
              >
                {trans('photo_view_popup__password_pro.submit')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationPopup = (props) => {
  return (
    <div
      onClick={() => {
        props.close();
      }}
      className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none"
    >
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img alt="" className="w-5 h-5 " src={CloseIcon} />
          </button>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative flex-auto p-6">
            <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
              <span
                className="text-black text-[16px] font-semibold text-center"
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuspendedPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} />
          </button>
        </div>
        <div className="w-[35rem] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              {props.title}
            </h3>
          </div>
          <div className="relative flex-auto p-6">
            <div className="flex flex-col items-center justify-center ">
              <span className="text-[16px] font-semibold font-segeo">
                {props.content}
              </span>
              <p className="mt-4 text-center">
                Please contact our{" "}
                <a
                  href={RouteSegments.HELP_AND_FAQ()}
                  className=" text-[#D10A11] font-semibold font-segeo cursor-pointer underline"
                  target="_blank"
                >
                  help desk
                </a>{" "}
                For more details
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginVia = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="w-[35rem] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              Status
            </h3>
          </div>
          <div className="relative flex-auto p-6">
            <div className="flex flex-col items-center justify-center ">
              <span className="text-[16px] font-semibold font-segeo">
                {props.content}
              </span>
              <p className="mt-4 text-center">{props?.status}</p>
            </div>
            <button
              onClick={() => {
                props.close();
              }}
              type="button"
              className=" items-center flex float-right my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.3rem] md:px-[1.3rem] lg:px-[2rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden outline-none bg-opacityblack focus:outline-none lg:hidden">
      <div className="relative  my-6 mx-6  w-[90%] md:w-[65%]">
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full outline-none focus:outline-none">
          <div className="relative flex-auto">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

const RegSuccessPopup = (props) => {
  return (
    <div className="absolute inset-x-0 top-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div
        className={`${props.menu === "policy"
          ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
          : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
              ? "relative w-[25rem] text-center my-6 mx-6 "
              : props.menu === "report"
                ? "relative  my-6 mx-6  w-[30rem]"
                : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
          } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            {/* <img className="w-5 h-5 " src={CloseIcon} alt=""/> */}
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-center py-1 mx-2 mt-2 border-none rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div
            className={
              props?.menu === "policypopup"
                ? "relative px-6 py-6 pr-[0.1rem]  flex-auto"
                : "relative p-[4.5rem]  flex-auto"
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageSuccessPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative flex-auto p-6">
            <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
              <span className="text-[16px] font-semibold whitespace-nowrap">
                {props.content}
              </span>
              <img className="w-16 h-16 mt-2" alt="" src={props?.image} />
            </div>
            <div className="flex justify-end pt-3">
              <button
                onClick={() => props.close()}
                className="mr-4 text-[#7A7A7A] border border-[#9F9F9F] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] cursor-pointer font-segeo"
              >
                Close
              </button>
              <button
                onClick={() => props.goto()}
                className="text-[#FFFFFF] bg-[#D10A11] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px]  md:w-8 lg:w-24 cursor-pointer font-segeo"
              >
                Go to Photos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReligionMigrationPopup = ({ close, profileDate }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { loginDetail } = useContext(LoginContext);
  console.log(profileDate, "profileDate");

  const dispatch = useDispatch();
  const { casteList, domainList, subcasteList } = useSelector((state) => ({
    casteList: state?.Home?.cList?.data?.data?.castes ?? [],
    subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
    domainList: state?.Home?.denominationList?.data?.domain ?? [],
  }));
  const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);

  const { t:trans } = useTranslation();

  useEffect(() => {
    console.log(casteList, "casteList");
  }, [casteList]);
  const [inputCasteValue, setInputCasteValue] = useState("");
  const [matchedCastes, setMatchedCastes] = useState([]);
  const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);

  const [inputSubCasteValue, setInputSubCasteValue] = useState("");
  const [matchedSubCastes, setMatchedSubCastes] = useState([]);

  console.log(inputSubCasteValue, "inputSubCasteValue");
  const isLoading = casteList.length == 0 && domainList.length == 0;

  useEffect(() => {
    dispatch(getDenominationList());
  }, []);

  const rearrangeOthersToLast = (list) => {
    const othersOption = list.find(
      (option) => option.name.toLowerCase() === "others"
    );
    const filteredList = list.filter(
      (option) => option.name.toLowerCase() !== "others"
    );
    return othersOption ? [...filteredList, othersOption] : filteredList;
  };

  useEffect(() => {
    if (profileDate) {
      dispatch(
        registerCasteList(
          profileDate?.mothertongueid,
          profileDate?.religion ? profileDate?.religion[0]?.newid : ""
        )
      );
      dispatch(
        registerSubCasteList(
          profileDate?.caste ? profileDate?.caste[0]?.newid : ""
        )
      );
    }
  }, [dispatch, profileDate]);

  // getsubcaste
  // const apiCallSubCast = async (castId, initial=false) => {
  //   let { statusCode, data } = await GET(
  //     `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
  //   );
  //   //console.log("subcaste", data);
  //   if (statusCode === 200) {
  //     // setDropDownData(data.data, type);
  //     if (data.status == "Success") {
  //       setSubCastDropDown(data.data.subcastes);

  //       if(initial === false){
  //         const temp = [];
  //         data?.data?.subcastes?.forEach(caste => {
  //           if(formik?.values?.subcaste?.includes(caste.id)){
  //             temp.push(caste.id)
  //           }
  //         });
  //         formik.setFieldValue("subcaste", temp);
  //       }
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };

  const handleCasteSelection = (selectedCaste) => {
    setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("casteId", selectedCaste.id);
    dispatch(registerSubCasteList(selectedCaste.id));
  };

  const handleSubCasteSelection = (selectedSubCaste) => {
    setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
  };

  const handleSubCasteInputChange = (e) => {
    const SubCasteValue = e.target.value;
    const SubCasteInput = SubCasteValue.replace(/^\s+/, '')                // Remove leading spaces
      .replace(/[^\w\s]/gi, '')            // Remove special characters
      .replace(/  +/g, ' ')                // Replace multiple spaces with a single space
      .replace(/[^a-zA-Z0-9 .]/g, '');
    setInputSubCasteValue(SubCasteInput);
    formik.setFieldValue("otherSubcaste", SubCasteInput); // Ensure correct field name here

    console.log("SubCasteValue", SubCasteInput);
    if (SubCasteValue.trim() === "") {
      formik.setFieldValue("othersubcaste", "");

      setMatchedSubCastes([]);
    } else {
      const filteredSubCastes = subcasteList?.filter((subcaste) =>
        subcaste.name.toLowerCase().includes(SubCasteValue.toLowerCase())
      );
      setMatchedSubCastes(filteredSubCastes);
    }
  };
  console.log({ profileDate });

  const handleCasteInputChange = (e) => {
    const CasteValue = e.target.value;
    const CasteInput = CasteValue.replace(/^\s+/, '')                // Remove leading spaces
      .replace(/[^\w\s]/gi, '')            // Remove special characters
      .replace(/  +/g, ' ')                // Replace multiple spaces with a single space
      .replace(/[^a-zA-Z0-9 .]/g, '');
    setInputCasteValue(CasteInput);
    formik.setFieldValue("otherCaste", CasteValue);

    console.log(CasteValue, "CasteValue");

    if (CasteValue.trim() === "") {
      // Clear matched castes when input value is empty
      setMatchedCastes([]);
    } else {
      // Filter caste names starting with the input value
      const filteredCastes = casteList?.filter((caste) =>
        caste.name.toLowerCase().includes(CasteValue.toLowerCase())
      );

      // Check if "Others" is present in the filtered castes
      const othersCaste = filteredCastes.find(
        (caste) => caste.name.toLowerCase() === "others"
      );

      // Remove "Others" from the filtered castes if present
      const filteredCastesWithoutOthers = othersCaste
        ? filteredCastes.filter(
          (caste) => caste.name.toLowerCase() !== "others"
        )
        : filteredCastes;

      // Sort the filtered castes alphabetically
      const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Add "Others" as the last option if present
      const finalFilteredCastes = othersCaste
        ? [...sortedFilteredCastes, othersCaste]
        : sortedFilteredCastes;

      setMatchedCastes(finalFilteredCastes);
    }
  };

  const casteOptions = useMemo(
    () => rearrangeOthersToLast(casteList),
    [casteList]
  );

  const subcasteOptions = useMemo(
    () => rearrangeOthersToLast(subcasteList),
    [subcasteList]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      religionId: profileDate?.religion ? profileDate?.religion[0]?.newid : "",
      denomination: profileDate?.domainId,
      casteId: profileDate?.caste ? profileDate?.caste[0]?.newid : "",
      subcaste: profileDate?.subcaste ? profileDate?.subcaste[0]?.newid : "",
      otherSubcaste: profileDate?.othersubcaste ?? "",
      otherCaste: profileDate?.otherCaste ?? "",
    },
    validationSchema: Yup.object().shape({
      denomination: Yup.string().when("religionId", (religionId, schema) => {
        console.log("value business : ", religionId);
        if (religionId.includes(Constants.christialId)) {
          return schema.required("Please Select Denomination");
        } else {
          return schema;
        }
      }),
      casteId: Yup.string()
        .notOneOf([Constants.casteNotKnowId], "Please select caste")
        .required("Please select caste"),
      otherCaste: Yup.string().when("casteId",
        (cast, schema) => {
          if (selectedCaste?.name.trim() == "Others") {
            return schema
              .required('Please select othercaste')
              .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
              .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))
          } else {
            return schema;
          }
        }
      ),
      otherSubcaste: Yup.string().when("subcaste",
        (subcast, schema) => {
          if (selectedSubCaste?.name.trim() == "Others") {
            return schema
              .required('Please select subcaste')
              .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
              .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))
          } else {
            return schema;
          }
        }
      ),
    }),
    onSubmit: (values) => {
      console.log("ReligionMigrationPopup", values);
      apiCallSubmit(values);
    },
  });

  console.log(profileDate?.subcaste, "profileDate?.subcaste");
  const apiCallSubmit = async (values) => {
    console.log((values, "values1"));
    const request = {
      newsubcasteId: values?.subcaste,
      newcasteId: values?.casteId,
      newdomainId: values?.denomination,
      newreligionId: values?.religionId,
      newothercaste: values.otherCaste,
      newothersubcaste: values.otherSubcaste,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.savecastmigrationUrl()}`,
      request
    );

    if (statusCode == 200) {
      window.location.reload();
      close();
    }
  };

  console.log(profileDate, "profileDate?.otherSubcaste");
  const isChristian = profileDate?.religion
    ? profileDate?.religion[0]?.newid == Constants.christialId
    : "";
  const subcasteAvailable = subcasteList?.length !== 0;

  const subcasteAvailablename = subcasteList[0]?.name === "Others";

  console.log(subcasteAvailablename, "subcasteAvailable");
  const selectedDenomination = useMemo(() => {
    return domainList
      ?.filter((option) => option.id === formik.values.denomination)
      ?.at(0);
  }, [domainList, formik.values.denomination]);

  const selectedCaste = useMemo(() => {
    return casteList
      ?.filter((option) => option.id === formik.values.casteId)
      ?.at(0);
  }, [casteList, formik.values.casteId]);

  const selectedSubCaste = useMemo(() => {
    return subcasteList
      ?.filter((option) => option.id === formik.values.subcaste)
      ?.at(0);
  }, [subcasteList, formik.values.subcaste]);

  const apiCallConfirm = async () => {
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.confirmcastmigrationUrl()}`,
      {
        isconfirmed: "Yes",
      }
    );

    if (statusCode == 200) {
      close();
    }
  };

  const onConfirm = () => {
    if (formik.values.casteId == Constants.casteNotKnowId) {
      setIsEdit(true);
    } else {
      formik.submitForm();
    }
    close();
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  console.log(formik.values.subcaste, "formik.values.subcaste[0]?.name");
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className="">
          {/* <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt=""/>
          </button> */}
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {trans('caste_restrurcture.please_confirm_religious_detai')}
            </h3>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32">
              <Loader />
            </div>
          ) : (
            <div className="relative flex-auto p-6">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col items-center ">
                  <div className="flex flex-col w-full space-y-2">
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        {trans('caste_restrurcture.religious')}
                      </label>
                      {isEdit ? (
                        <select
                          id="religionId"
                          name="religionId"
                          disabled={true}
                          value={formik.values.religionId}
                          onChange={formik.handleChange}
                          className="  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-2 px-1 md:py-2 md:px-2  w-full block cursor-not-allowed "
                        >
                          <option value={formik.values.religionId}>
                            {profileDate?.religion
                              ? profileDate?.religion[0]?.newName
                              : ""}
                          </option>
                        </select>
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.religion
                            ? profileDate?.religion[0]?.newName
                            : trans('my_profile.not_specified')}
                        </label>
                      )}
                    </div>
                    {isChristian && (
                      <div className="flex flex-col">
                        <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                          {trans('caste_restrurcture.divisiondenomination')}
                        </label>
                        {isEdit ? (
                          <RawSelectInput
                            name="denomination"
                            title={trans('view_profile.denomination')}
                            value={selectedDenomination}
                            onChange={(selected, ev) => {
                              formik.setFieldValue("denomination", selected.id);
                            }}
                            error={formik.errors.denomination}
                            options={[
                              {
                                id: null,
                                name: "Select Denomination",
                                isDisabled: true,
                              },
                              ...domainList,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                          />
                        ) : (
                          <label className="font-segeo text-[14px] font-semibold">
                            {profileDate?.domainName}
                          </label>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        {trans('caste_restrurcture.caste')}
                      </label>
                      {isEdit ? (
                        <RawSelectInput
                          name="casteId"
                          title={trans('view_profile.caste')}
                          value={selectedCaste}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("casteId", selected.id);
                            dispatch(registerSubCasteList(selected.id));
                            setShowOtherCasteInput(
                              selected.id === Constants.casteOtherId
                            );
                          }}
                          error={formik.errors.casteId}
                          options={[
                            {
                              id: null,
                              name: "Select Caste",
                              isDisabled: true,
                            },
                            ...casteOptions,
                          ]}
                          required={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          getOptionClassName={(option) =>
                            option.name.toLowerCase() === "others"
                              ? "dropdown-option-others"
                              : ""
                          }
                        />
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.caste
                            ? profileDate?.caste[0]?.newName
                            : trans('my_profile.not_specified')}
                        </label>
                      )}
                    </div>
                    {formik.values.casteId == "433" && isEdit && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other Caste
                          </p>
                        </div>
                        <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="othercaste"
                              autoComplete="off"
                              placeholder="Enter Your Caste"
                              className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
                              value={inputCasteValue}
                              // value={formik.values.otherCaste}
                              // onChange={formik.handleChange}
                              // onChange={handleCasteInputChange}
                              onChange={(e) => {
                                formik.setFieldValue("otherCaste", e.id);
                                handleCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              error={formik.errors.otherCaste}
                            />
                            {inputCasteValue.trim() !== "" &&
                              matchedCastes?.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedCastes.map((caste) => (
                                      <li
                                        key={caste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleCasteSelection(caste)
                                        }
                                      >
                                        {caste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            {formik.errors?.otherCaste &&
                              formik?.touched?.otherCaste && (
                                <p className="text-[red] text-[12px]">
                                  {formik.errors?.otherCaste}
                                </p>
                              )}{" "}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        {trans('caste_restrurcture.sub_caste')}
                      </label>
                      {isEdit ? (
                        subcasteAvailable ? (
                          <RawSelectInput
                            name="subcaste"
                            title="SubCaste"
                            value={selectedSubCaste}
                            onChange={(selected, ev) => {
                              formik.setFieldValue("subcaste", selected.id);
                              setShowOtherSubCasteInput(
                                selected.name === "Others"
                              );
                            }}
                            error={formik.errors.subcaste}
                            options={[
                              {
                                id: null,
                                name: "Select SubCaste",
                                isDisabled: true,
                              },
                              ...subcasteOptions,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            getOptionClassName={(option) =>
                              option.name.toLowerCase() === "others"
                                ? "dropdown-option-others"
                                : ""
                            }
                          />
                        ) : (
                          <>
                            <input
                              type="text"
                              value={formik.values.otherSubcaste}
                              onChange={formik.handleChange}
                              name="otherSubcaste"
                              className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
                            />
                          </>
                        )
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.subcaste
                            ? profileDate?.subcaste[0]?.newName
                            : trans('my_profile.not_specified')}
                        </label>
                      )}
                    </div>
                    {showOtherSubCasteInput && (
                      // ||formik.values?.subcaste?.name == "Others"
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other SubCaste
                          </p>
                        </div>
                        <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="otherSubcaste"
                              autoComplete="off"
                              placeholder="Enter Your Sub-Caste"
                              className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                              value={inputSubCasteValue}
                              // onChange={handleSubCasteInputChange}
                              onChange={(e) => {
                                formik.setFieldValue("otherSubcaste", e.id);
                                handleSubCasteInputChange(e);
                                // formik.handleChange();
                              }}
                            />

                            {inputSubCasteValue.trim() !== "" &&
                              matchedSubCastes.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedSubCastes.map((subcaste) => (
                                      <li
                                        key={subcaste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F]cursor-pointer"
                                        onClick={() =>
                                          handleSubCasteSelection(subcaste)
                                        }
                                      >
                                        {subcaste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isEdit ? (
                    <div className="flex w-full mt-8 space-x-8">
                      <button
                        disabled={formik.isSubmitting}
                        type="submit"
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
                      >
                        {formik.isSubmitting ? trans('my_profile.saving') :trans('caste_restrurcture.save')}
                      </button>
                    </div>
                  ) : (
                    <div className="flex w-full px-4 mt-8 space-x-8">
                      <button
                        disabled={formik.isSubmitting}
                        onClick={onConfirm}
                        type="button"
                        className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Confirm {trans('caste_restrurcture.confirm')}
                      </button>
                      <button
                        onClick={() => setIsEdit(true)}
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Edit {trans('caste_restrurcture.edit')}
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
// const ReligionMigrationLitePopup = ({ close, profileDate }) => {
//   const [isEdit, setIsEdit] = useState(false);
//   const { loginDetail } = useContext(LoginContext);
//   console.log(profileDate,'profileDate');

//   const dispatch = useDispatch();
//   const { casteList, domainList, subcasteList } = useSelector((state) => ({
//     casteList: state.Home?.cList?.data?.data?.castes ?? [] ,
//     subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
//     domainList: state?.Home?.denominationList?.data?.domain ?? [],
//   }));

//   const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);

//   const [inputCasteValue, setInputCasteValue] = useState("");
//   const [matchedCastes, setMatchedCastes] = useState([]);
//   const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);

//   const [inputSubCasteValue, setInputSubCasteValue] = useState("");
//   const [matchedSubCastes, setMatchedSubCastes] = useState([]);

//   const isLoading = casteList.length == 0 && domainList.length == 0;
//  const [showProfiledata,setShowProfileData] =useState([])

//  console.log(domainList,"showProfiledata");
//   useEffect(() => {
//     dispatch(getDenominationList());
//   }, []);

//   useEffect(() => {
//     const fetchRecommendedMatches = async () => {
//         try {
//             const request = {
//                 "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == "" ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
//                 "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
//                 "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
//                 "recomendationpageno": "1",
//                 "status": "new",
//             };

//             const response = await axios.post(
//                 `${config.api.API_URL}${EndPoints.matchesliteapp()}`,
//                 request
//             );

//             if (response.data.status === "Success") {

//               setShowProfileData(response.data.data);
//             } else {
//                 // setError(response);
//             }
//         } catch (error) {
//             // setError(error);
//         }
//     };

//     fetchRecommendedMatches();
// }, []); // Add dependencies as needed

//   const rearrangeOthersToLast = (list) => {
//     const othersOption = list.find(
//       (option) => option.name.toLowerCase() === "others"
//     );
//     const filteredList = list.filter(
//       (option) => option.name.toLowerCase() !== "others"
//     );
//     return othersOption ? [...filteredList, othersOption] : filteredList;
//   };

//   useEffect(() => {
//     if (profileDate) {
//       dispatch(
//         registerCasteList(
//           profileDate?.mothertongueid,
//           profileDate?.religionId ? profileDate?.religionId : ""
//         )
//       );
//       dispatch(
//         registerSubCasteList(profileDate?.caste ? profileDate?.caste[0] : "")
//       );
//     }
//   }, [dispatch, profileDate]);

//   // getsubcaste
//   // const apiCallSubCast = async (castId, initial=false) => {
//   //   let { statusCode, data } = await GET(
//   //     `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
//   //   );
//   //   //console.log("subcaste", data);
//   //   if (statusCode === 200) {
//   //     // setDropDownData(data.data, type);
//   //     if (data.status == "Success") {
//   //       setSubCastDropDown(data.data.subcastes);

//   //       if(initial === false){
//   //         const temp = [];
//   //         data?.data?.subcastes?.forEach(caste => {
//   //           if(formik?.values?.subcaste?.includes(caste.id)){
//   //             temp.push(caste.id)
//   //           }
//   //         });
//   //         formik.setFieldValue("subcaste", temp);
//   //       }
//   //     }
//   //   } else if (statusCode === 401) {
//   //     logout();
//   //   }
//   // };

//   const handleCasteSelection = (selectedCaste) => {
//     setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
//     formik.setFieldValue("casteId", selectedCaste.id); // Update formik values with the selected caste's ID
//   };

//   const handleSubCasteSelection = (selectedSubCaste) => {
//     setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
//     formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
//   };

//   const handleSubCasteInputChange = (e) => {
//     const SubCasteValue = e.target.value;
//     const SubCasteInput = SubCasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
//       .trim()
//       .replace(/\s+/g, " ");
//     setInputSubCasteValue(SubCasteInput);
//     formik.setFieldValue("otherSubcaste", SubCasteInput); // Ensure correct field name here

//     console.log("SubCasteValue", SubCasteInput);
//     if (SubCasteValue.trim() === "") {
//       setMatchedSubCastes([]);
//     } else {
//       const filteredSubCastes = subcasteList?.filter((subcaste) =>
//         subcaste.name.toLowerCase().startsWith(SubCasteValue.toLowerCase())
//       );
//       setMatchedSubCastes(filteredSubCastes);
//     }
//   };
//   console.log({ showProfiledata });

//   const handleCasteInputChange = (e) => {
//     const CasteValue = e.target.value;
//     const CasteInput = CasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
//       .trim()
//       .replace(/\s+/g, " ");
//     setInputCasteValue(CasteInput);
//     formik.setFieldValue("otherCaste", CasteValue);

//     console.log(CasteValue, "CasteValue");

//     if (CasteValue.trim() === "") {
//       // Clear matched castes when input value is empty
//       setMatchedCastes([]);
//     } else {
//       // Filter caste names starting with the input value
//       const filteredCastes = casteList?.filter((caste) =>
//         caste.name.toLowerCase().startsWith(CasteValue.toLowerCase())
//       );

//       // Check if "Others" is present in the filtered castes
//       const othersCaste = filteredCastes.find(
//         (caste) => caste.name.toLowerCase() === "others"
//       );

//       // Remove "Others" from the filtered castes if present
//       const filteredCastesWithoutOthers = othersCaste
//         ? filteredCastes.filter(
//             (caste) => caste.name.toLowerCase() !== "others"
//           )
//         : filteredCastes;

//       // Sort the filtered castes alphabetically
//       const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
//         a.name.localeCompare(b.name)
//       );

//       // Add "Others" as the last option if present
//       const finalFilteredCastes = othersCaste
//         ? [...sortedFilteredCastes, othersCaste]
//         : sortedFilteredCastes;

//       setMatchedCastes(finalFilteredCastes);
//     }
//   };

//   const casteOptions = useMemo(
//     () => rearrangeOthersToLast(casteList),
//     [casteList]
//   );

//   const subcasteOptions = useMemo(
//     () => rearrangeOthersToLast(subcasteList),
//     [subcasteList]
//   );

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       religionId: showProfiledata?.religiondata ? showProfiledata?.religiondata[0]?.newid : "",
//       denomination: showProfiledata?.domainid,
//       casteId: showProfiledata?.castedata ? showProfiledata?.castedata[0]?.newid : "",
//       subcaste: showProfiledata?.subcastedata ? showProfiledata?.subcastedata[0]?.newid : "",
//       otherSubcaste: showProfiledata?.othersubcaste ?? "",
//       otherCaste: showProfiledata?.othercaste ?? "",
//     },
//     validationSchema: Yup.object().shape({
//       denomination: Yup.string().when("religionId", (religionId, schema) => {
//         console.log("value business : ", religionId);
//         if (religionId.includes(Constants.christialId)) {
//           return schema.required("Please Select Denomination");
//         } else {
//           return schema;
//         }
//       }),
//       casteId: Yup.string()
//         .notOneOf([Constants.casteNotKnowId], "Please select caste")
//         .required("Please select caste"),
//     }),
//     onSubmit: (values) => {
//       console.log("ReligionMigrationPopup", values);
//       apiCallSubmit(values);
//     },
//   });

//   const apiCallSubmit = async (values) => {
//     console.log(values,"values11");
//     const request = {
//       newsubcasteId: values?.subcaste ? values?.subcaste : undefined,
//       newcasteId: values?.casteId,
//       newdomainId: values?.denomination,
//       newreligionId: values?.religionId,
//       newothercaste: values.otherCaste,
//       newothersubcaste: values.otherSubcaste ,
//     };

//     let { statusCode, data } = await POST(
//       `${config.api.API_URL}${EndPoints.savecastmigrationUrl()}`,
//       request
//     );

//     if (statusCode == 200) {
//       // window.location.reload();
//       close();
//     }
//   };

//   console.log(showProfiledata,"showProfiledata?.otherSubcaste");
//   const isChristian = showProfiledata?.religiondata
//     ? showProfiledata?.religiondata[0]?.newid == Constants.christialId
//     : "";
//   const subcasteAvailable = subcasteList?.length !== 0;

//   const subcasteAvailablename = subcasteList[0]?.name === "Others"

//   console.log(subcasteAvailablename,"subcasteAvailable");
//   const selectedDenomination = useMemo(() => {
//     return domainList
//       ?.filter((option) => option.id === formik.values.denomination)
//       ?.at(0);
//   }, [domainList, formik.values.denomination]);

//   const selectedCaste = useMemo(() => {
//     return casteList
//       ?.filter((option) => option.id === formik.values.casteId)
//       ?.at(0);
//   }, [casteList, formik.values.casteId]);

//   const selectedSubCaste = useMemo(() => {
//     return subcasteList
//       ?.filter((option) => option.id === formik.values.subcaste)
//       ?.at(0);
//   }, [subcasteList, formik.values.subcaste]);

//   const apiCallConfirm = async () => {
//     let { statusCode, data } = await POST(
//       `${config.api.API_URL}${EndPoints.confirmcastmigrationUrl()}`,
//       {
//         isconfirmed: "Yes",
//       }
//     );

//     if (statusCode == 200) {
//       close();
//     }
//   };

//   const onConfirm = () => {
//     if (formik.values.casteId == Constants.casteNotKnowId) {
//       setIsEdit(true);
//     } else {
//       formik.submitForm();
//     }
//     close();
//   };

//   const onCancel = () => {
//     setIsEdit(false);
//   };

//   console.log(formik.values.subcaste,"formik.values.subcaste[0]?.name");
//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
//       <div className="relative w-auto mx-6 my-6">
//         <div className="">

//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
//           <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
//             <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
//               Please Confirm Religious Details
//             </h3>
//           </div>
//           {isLoading ? (
//             <div className="flex items-center justify-center w-full h-32">
//               <Loader />
//             </div>
//           ) : (
//             <div className="relative flex-auto p-6">
//               <form onSubmit={formik.handleSubmit}>
//                 <div className="flex flex-col items-center ">
//                   <div className="flex flex-col w-full space-y-2">
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Religious
//                       </label>
//                       {isEdit ? (
//                         <select
//                           id="religionId"
//                           name="religionId"
//                           disabled={true}
//                           value={formik.values.religionId}
//                           onChange={formik.handleChange}
//                           className="  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-2 px-1 md:py-2 md:px-2  w-full block cursor-not-allowed "
//                         >
//                           <option value={formik.values.religionId}>
//                             {showProfiledata?.religiondata
//                               ? showProfiledata?.religiondata[0]?.newName
//                               : ""}
//                           </option>
//                         </select>
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.religiondata
//                             ? showProfiledata?.religiondata[0]?.newName
//                             : trans('my_profile.not_specified')}
//                         </label>
//                       )}
//                     </div>
//                     {isChristian && (
//                       <div className="flex flex-col">
//                         <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                           Denomination
//                         </label>
//                         {isEdit ? (
//                           <RawSelectInput
//                             name="denomination"
//                             title="Denomination"
//                             value={selectedDenomination}
//                             onChange={(selected, ev) => {
//                               formik.setFieldValue("denomination", selected.id);
//                             }}
//                             error={formik.errors.denomination}
//                             options={[
//                               {
//                                 id: null,
//                                 name: "Select Denomination",
//                                 isDisabled: true,
//                               },
//                               ...domainList,
//                             ]}
//                             required={true}
//                             getOptionLabel={(option) => option.name}
//                             getOptionValue={(option) => option.id}
//                           />
//                         ) : (
//                           <label className="font-segeo text-[14px] font-semibold">
//                             {showProfiledata?.domainname}
//                           </label>
//                         )}
//                       </div>
//                     )}
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Caste
//                       </label>
//                       {isEdit ? (
//                         <RawSelectInput
//                           name="casteId"
//                           title="Caste"
//                           value={selectedCaste}
//                           onChange={(selected, ev) => {
//                             formik.setFieldValue("casteId", selected.id);
//                             dispatch(registerSubCasteList(selected.id));
//                             setShowOtherCasteInput(
//                               selected.id === Constants.casteOtherId
//                             );
//                           }}
//                           error={formik.errors.casteId}
//                           options={[
//                             {
//                               id: null,
//                               name: "Select Caste",
//                               isDisabled: true,
//                             },
//                             ...casteOptions,
//                           ]}
//                           required={true}
//                           getOptionLabel={(option) => option.name}
//                           getOptionValue={(option) => option.id}
//                           getOptionClassName={(option) =>
//                             option.name.toLowerCase() === "others"
//                               ? "dropdown-option-others"
//                               : ""
//                           }
//                         />
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.castedata
//                             ? showProfiledata?.castedata[0]?.newName
//                             : trans('my_profile.not_specified')}
//                         </label>
//                       )}
//                     </div>
//                     {formik.values.casteId === "433" && showOtherCasteInput && (
//                       <>
//                         <div className="col-span-2 mt-5">
//                           <p className="font-segeo text-[14px] font-semibold text-[#575556]">
//                             Other Caste
//                           </p>
//                         </div>
//                         <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
//                           <div className="space-y-1">
//                             <input
//                               type="text"
//                               name="othercaste"
//                               autoComplete="off"
//                               placeholder="Enter Your Caste"
//                               className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
//                               value={inputCasteValue}
//                               // value={formik.values.otherCaste}
//                               // onChange={formik.handleChange}
//                               onChange={handleCasteInputChange}
//                               error={formik.errors.otherCaste}
//                             />
//                             {inputCasteValue.trim() !== "" &&
//                               matchedCastes?.length > 0 && (
//                                 <div
//                                   className="absolute bg-[#ffffff] z-10 overflow-y-auto"
//                                   style={{
//                                     boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                                     minWidth: "90%",
//                                     maxHeight: "254px",
//                                     height: "auto",
//                                     maxHeight: "254px",
//                                   }}
//                                 >
//                                   <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
//                                     Do you Mean
//                                   </p>
//                                   <ul
//                                     ref={(ulElement) => {
//                                       // Access the ul element and calculate its height
//                                       if (ulElement) {
//                                         const ulHeight =
//                                           ulElement.getBoundingClientRect()
//                                             .height;
//                                         if (ulHeight > 254) {
//                                           ulElement.style.maxHeight = "254px";
//                                         }
//                                       }
//                                     }}
//                                   >
//                                     {matchedCastes.map((caste) => (
//                                       <li
//                                         key={caste.id}
//                                         className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
//                                         onClick={() =>
//                                           handleCasteSelection(caste)
//                                         }
//                                       >
//                                         {caste.name}
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 </div>
//                               )}
//                             {formik.errors?.otherCaste &&
//                               formik?.touched?.otherCaste && (
//                                 <p className="text-[red] text-[12px]">
//                                   {formik.errors?.otherCaste}
//                                 </p>
//                               )}{" "}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Subcaste
//                       </label>
//                       {isEdit ? (
//                         subcasteAvailable ? (
//                           <RawSelectInput
//                             name="subcaste"
//                             title="SubCaste"
//                             value={selectedSubCaste}
//                             onChange={(selected, ev) => {
//                               formik.setFieldValue("subcaste", selected.id);
//                               setShowOtherSubCasteInput(
//                                 selected.name === "Others"
//                               );
//                             }}
//                             error={formik.errors.subcaste}
//                             options={[
//                               {
//                                 id: null,
//                                 name: "Select SubCaste",
//                                 isDisabled: true,
//                               },
//                               ...subcasteOptions,
//                             ]}
//                             required={true}
//                             getOptionLabel={(option) => option.name}
//                             getOptionValue={(option) => option.id}
//                             getOptionClassName={(option) =>
//                               option.name.toLowerCase() === "others"
//                                 ? "dropdown-option-others"
//                                 : ""
//                             }
//                           />
//                         ) : (
//                           <>
//                             <input
//                               type="text"
//                               value={formik.values.otherSubcaste}
//                               onChange={formik.handleChange}
//                               name="otherSubcaste"
//                               className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
//                             />

//                           </>
//                         )
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.subcastedata
//                             ? showProfiledata?.subcastedata[0]?.newName
//                             : trans('my_profile.not_specified')}
//                         </label>
//                       )}
//                     </div>
//                     {formik.values.subcaste==="170" && showOtherSubCasteInput && (
//                       // ||formik.values?.subcaste?.name == "Others"
//                       <>
//                         <div className="col-span-2 mt-5">
//                           <p className="font-segeo text-[14px] font-semibold text-[#575556]">
//                             Other SubCaste
//                           </p>
//                         </div>
//                         <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
//                           <div className="space-y-1">
//                             <input
//                               type="text"
//                               name="otherSubcaste"
//                               autoComplete="off"
//                               placeholder="Enter Your Sub-Caste"
//                               className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
//                               value={inputSubCasteValue}
//                               onChange={handleSubCasteInputChange}
//                             />

//                             {inputSubCasteValue.trim() !== "" &&
//                               matchedSubCastes.length > 0 && (
//                                 <div
//                                   className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
//                                   style={{
//                                     boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                                     minWidth: "90%",
//                                     maxHeight: "254px",
//                                     height: "auto",
//                                     maxHeight: "254px",
//                                   }}
//                                 >
//                                   <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
//                                     Do you Mean
//                                   </p>
//                                   <ul
//                                     ref={(ulElement) => {
//                                       // Access the ul element and calculate its height
//                                       if (ulElement) {
//                                         const ulHeight =
//                                           ulElement.getBoundingClientRect()
//                                             .height;
//                                         if (ulHeight > 254) {
//                                           ulElement.style.maxHeight = "254px";
//                                         }
//                                       }
//                                     }}
//                                   >
//                                     {matchedSubCastes.map((subcaste) => (
//                                       <li
//                                         key={subcaste.id}
//                                         className="px-5 py-2 text-[15px] text-[#8F8F8F]cursor-pointer"
//                                         onClick={() =>
//                                           handleSubCasteSelection(subcaste)
//                                         }
//                                       >
//                                         {subcaste.name}
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 </div>
//                               )}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                   </div>
//                   {isEdit ? (
//                     <div className="flex w-full mt-8 space-x-8">
//                       <button
//                         disabled={formik.isSubmitting}
//                         type="submit"
//                         className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
//                       >
//                         {formik.isSubmitting ? "Saving..." : "Save"}
//                       </button>
//                     </div>
//                   ) : (
//                     <div className="flex w-full px-4 mt-8 space-x-8">
//                       <button
//                         disabled={formik.isSubmitting}
//                         onClick={onConfirm}
//                         type="button"
//                         className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
//                       >
//                         Confirm
//                       </button>
//                       <button
//                         onClick={() => setIsEdit(true)}
//                         className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
//                       >
//                         Edit
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </form>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
const CommonPayNowPopup = (props) => {

  const navigate = useNavigate();
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handlePayNowClick = () => {
    if (props.track) {
      props.track();
      navigate(RouteSegments.MEMBERSHIP_REGISTER());
      props.close(); // Close the popup after navigating
    }
    onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button);
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close(); // Close the popup after navigating
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-full md:w-[50%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[16px] xl:text-[18px] pb-2">
                {props?.title}
              </p>
              <p className="text-[#7A7A7A] text-[12px] xl:text-[14px] pb-4">
                {props?.content}
              </p>
            </div>
 
            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={handlePayNowClick}
                className="text-[13px] py-2 px-[2.5rem] text-white font-bold rounded-md bg-red-600"
              >
                {trans('match_horo_popup.pay_now')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonPayBackPopup = (props) => {

  const navigate = useNavigate();
  const closePopupRef = useRef();
  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handlePayNowClick = () => {
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[42%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className="text-[#575556] font-bold text-[16px]">
                Buy a Pack to Contact Profile
              </p>
              <p className="text-[#7A7A7A] text-[12px] pb-4">
                You have already contacted this profile and awaiting
                response.Please Upgrade to send personalized messages
              </p>
            </div>

            <div className="flex justify-center gap-5 py-3">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button
                onClick={handlePayNowClick}
                className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600"
              >
                {trans('matches.pay_now')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const CommonConfrimPopup = (props) => {

//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close();
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
//       <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="w-6 h-6 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative flex-auto p-6">
//             <div className="space-y-2">
//               <p className="text-[#575556] font-bold text-[16px]">Logout</p>
//               <p className="text-[#7A7A7A] text-[12px] pb-4">
//                 Are You Sure You Want To Logout?
//               </p>
//             </div>

//             <div className="flex justify-center gap-5 py-3">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button
//                 className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600" >Confirm</button>
//             </div>

//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }
// const CommonDownloadmediaPopup = (props) => {

//   const [selectedOption, setSelectedOption] = useState('option1');

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   return (
//     <div className='fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none'>
//       <div className='relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6'>
//         <div className=''>
//           <button
//             className='hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]'
//             onClick={() => {
//               props.close();
//             }}>
//             <img className='w-6 h-6 ' src={CloseIcon} alt='' />
//           </button>
//         </div>
//         <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative flex-auto p-6">
//             <div className="py-4 space-y-2">
//               <p className="text-[#7A7A7A] text-[12px]">
//                 show newly downloaded media from this chat in your device's gallery?              </p>
//             </div>

//             <div className="flex items-center mb-4 space-x-6">
//               <input
//                 type="radio"
//                 id="option1"
//                 value="option1"
//                 checked={selectedOption === 'option1'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option1" className="ml-2   text-[13px] text-[#575556]">Default (Yes)</label>

//             </div>
//             <div className="flex items-center mb-4 space-x-6">
//               <input
//                 type="radio"
//                 id="option2"
//                 value="option2"
//                 checked={selectedOption === 'option2'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option2" className="ml-2   text-[13px] text-[#575556]">Yes</label>

//             </div>
//             <div className="flex items-center mb-4 space-x-6">
//               <input
//                 type="radio"
//                 id="option3"
//                 value="option3"
//                 checked={selectedOption === 'option3'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option3" className="ml-2   text-[13px] text-[#575556]">No</label>

//             </div>

//             <div className="flex justify-center gap-5 py-3">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button className="text-[13px] py-2 px-[4rem] text-white rounded-md bg-red-600" >Ok</button>
//             </div>
//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }

const CommonHelpPopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-[75%] h-[80%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" md:grid grid-cols-9  px-2 mb-[3rem]  md:px-[1rem] lg:px-[3rem] md:space-x-2 lg:space-x-3 ">
            <div className="md:col-span-3 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
              <HelpPage />
            </div>
            {/* {props?.menu === "help"?
          <div className="md:col-span-3 mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
           
            <QueryForm data={helpData} />
          </div>
          : */}
            <div className="md:col-span-3 mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
              <QueryForm menu="help" />
            </div>
            {/* } */}
            {/* {props?.menu ==="help"?
  <div className=" md:col-span-3 mt-[2rem] md:mt-0 pb-[1rem] pd:mb-0 space-y-3  ">
           
            <HelpSection data={helpData} />
          </div>
        : */}
            <div className=" md:col-span-3 mt-[2rem] md:mt-0 pb-[1rem] pd:mb-0 space-y-3  ">
              <HelpSection menu="help" />
            </div>
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailAddPopup = (props) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div
        className={`${props.menu === "policy"
          ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
          : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
              ? "relative w-[25rem] text-center my-6 mx-6 "
              : props.menu === "report"
                ? "relative  my-6 mx-6  w-[30rem]"
                : props?.menu == "profileRejection"
                  ? "relative text-center my-6 mx-6"
                  : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
          } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed mt-2 border-[#D10A11] mx-2  rounded-t ">
            <h3
              className={`text-[20px] text-[#D10A11] ml-2 font-semibold align-middle ${props?.menu == "profileRejection" ? "lg:whitespace-nowrap" : ""
                }`}
            >
              {props.title}
            </h3>
          </div>
          {props?.isLoading ? (
            <Loader />
          ) : (
            <div
              className={
                props?.menu === "policypopup"
                  ? "relative px-6 py-6 pr-[0.1rem]  flex-auto"
                  : "relative px-[3.5rem] py-8  flex-auto"
              }
            >
              {props.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CommonNoMatchesPopup = (props) => {

  const closePopupRef = useRef();

  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-end overflow-x-hidden overflow-y-auto outline-none md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center focus:outline-none">
        <div
          className="lg:mt-[10rem] lg:bottom-[4rem] relative xl:w-[40%] lg:w-[45%] lg:my-6 lg:mx-auto md:w-[80%] w-[100%] md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg bg-white lg:rounded-[1rem] py-10 flex flex-col items-center justify-center">
            <div className="w-full lg:px-5 md:w-[27rem] lg:w-[100%]  rounded-2xl">
              <div className="hidden md:block lg:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-0.3rem] right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="w-6 h-6 " src={CloseIcon} alt="" />
                </button>
              </div>
              {props?.selectedFilter == "ProfilesWhoSelectedMe" ? 
                <>
                <div className="items-start text-center">
                  <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                    {trans('filters.no_profiles_have_selected_your')}
                  </p>
                </div>
                </> 
                : 
                <>
                  <div className="hidden lg:block md:hidden items-start text-center">
                    <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                    {trans('filters.there_are_no_matches_based_on')}
                    </p>
                  </div>
                  <div className="hidden md:block lg:hidden items-start text-center">
                    <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                    {trans('filters.there_are_no_matches_based_on')}
                    </p>
                  </div>
                  <div className="lg:hidden md:hidden items-start text-center space-y-2">
                    <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                    {trans('filters.there_are_no_matches_based_on')}
                    </p>
                  </div>
                </>
              }
            </div>
            <div className="flex items-center justify-center pt-[2rem]">
              <button
                className="bg-[#D10A11] text-[#FFFFFF] w-[120px] py-2 rounded"
                onClick={() => {
                  props?.onYesClick();
                  props.close();
                }}
              >
                {trans('no_matches_found_popup.yes')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonSuccessfullUpload = (props) => {

  const { t:trans } = useTranslation();

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-end overflow-x-hidden overflow-y-auto outline-none md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center focus:outline-none">
        <div className="mt-[10rem] relative w-auto mx-auto ">
          <div className="bg-white h-[120px] rounded-[1rem] ">
            <div className="w-full py-8 px-10 sm:p-6 sm:pb-4 md:w-[27rem] lg:w-[35rem]  rounded-2xl">
              <div className="">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="w-5 h-5 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="items-start ">
                <p className="text-[#1A1A1A] flex justify-center font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  {trans('my_profile.upload_photo')}
                </p>
              </div>
              <div className="mt-3">
                <p className="text-[#7A7A7A] text-14px text-center">
                  Photo Uploaded Successfully,{" "}
                  <span className="text-[#0095EF] underline">
                    {trans('view_profile.click_here_to_view')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LogoutPopup = (props) => {

  const { t:trans } = useTranslation();

  return (
    <div>
      <div className="flex justify-center bg-opacityblack75 items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none">
        <div className="relative w-[22rem] md:w-auto my-6 mx-auto ">
          <div className="bg-white rounded-[1rem]">
            <div className="w-full py-8 lg:py-12 px-10 sm:p-6 sm:pb-4 md:w-[27rem] md:py-8 lg:w-[30rem]  rounded-2xl">
              <div className="md:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-6px] right-[-3px] lg:right-[-4px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="w-6 h-6 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="items-start ">
                <p className="text-[#1A1A1A] flex justify-center font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  {trans('my_profile_icon.logout')}
                </p>
                <p className="text-[#777777] text-[13px] font-Poppins-Regular py-3 text-center">
                  {/* Are You Sure You Want To Logout? */}
                  {trans('matches.are_you_sure_you_want_to_logou')}
                </p>
              </div>
              <div className="flex items-center justify-center gap-5 px-3 pt-2 footer">
                <button
                  className="w-[8rem] border border-[#707070]  py-2 rounded "
                  onClick={() => {
                    props.close();
                  }}
                >
                  <OverflowToolTip as='p' className='text-[#7A7A7A] text-[13px] font-segeo font-bold'>{trans('logout_popup.cancel')}</OverflowToolTip>
                </button>

                <button
                  className="bg-[#D10A11] py-2 w-[8rem] rounded "
                  onClick={() => {
                    props.logout();
                  }}
                >
                  <OverflowToolTip as='p' className='truncate text-white  text-[13px] font-segeo font-bold'>{trans('logout_popup.confirm')}</OverflowToolTip>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonFamilyFriendPopup = ({ setShowFamilyPopup, props }) => {

  const [showChoosFriendPopup, setShowChoosFriendPopup] = useState(false);
  console.log(showChoosFriendPopup, "showChoosFriendPopup");

  const { t:trans } = useTranslation();

  const formik = useFormik({
    initialValues: {
      number: "",
      isdCode: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      number: Yup.string()
        .min(7, trans('family.please_enter_a_valid_mobile_nu'))
        .max(15, trans('family.please_enter_a_valid_mobile_nu'))
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode === "+91") {
            return !value || value.length === 10
              ? true
              : this.createError({
                message:trans('family.please_enter_a_valid_mobile_nu'),
                path: "number",
              });
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode !== "+91") {
            return !value || (value.length >= 7 && value.length <= 15)
              ? true
              : this.createError({
                message:trans('family.please_enter_a_valid_mobile_nu'),
                path: "number",
              });
          }
          return true;
        })
        .required("Please enter a Mobile Number"),
      isdCode: Yup.string().required("Please Select Isd code"),
    }),

    onSubmit: () => {
      setShowChoosFriendPopup(true);
      setShowFamilyPopup(false);
      setTimeout(() => {
        setShowChoosFriendPopup(false);
        setShowFamilyPopup(false);
      }, 2000);
    },
  });
  const OncloseClick = () => {
    setShowChoosFriendPopup(true);
    setShowFamilyPopup(false);
  };
  return (
    <>
      {showChoosFriendPopup && (
        <CommonChooseFamilyPopup close={() => setShowChoosFriendPopup(false)} />
      )}

      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
        <div className="relative w-[100%] md:w-[60%] lg:w-[35%] md:my-6 md:mx-6">
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0  rounded-tr-[40px] rounded-tl-[40px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative items-center justify-center flex-auto p-6 text-center">
              <p className="text-[18px] text-[#D10A11] mt-2 ml-2 font-semibold font-poppins text-center">
                {/* {props.title} */}FAMILY/FRIEND LOGIN
              </p>
            </div>
            <div className="relative flex items-center justify-center flex-auto px-6 py-3 bottom-6 ">
              {/* {onSubmitPopup ?
       <Loader/>
       :showpopup ?
       <div className='border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none'>
       <div className='flex items-center justify-center py-1 mx-2 rounded-t '>
         <h3 className='text-[20px] text-[#D10A11] ml-2 font-semibold align-middle'>
           Success
         </h3>
       </div>
       <div className='relative flex-auto p-6'>
         <div className='flex flex-col justify-center items-center w-[60vw] md:w-[30vw] '>
           <span className='text-[16px] font-semibold whitespace-nowrap'>
           Request Send Successfully!
           </span>
           <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
         </div>
       </div>
     </div>
         : */}
              <form onSubmit={formik.handleSubmit} className="flex flex-col ">
                <div className="flex my-2 space-x-2 ">
                  <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[4rem]">
                    <select
                      name="isdCode"
                      id="isdCode"
                      // value={formik.values.isdCode}
                      // onChange={formik.handleChange}
                      className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins md:text-[12px] lg:text-[16px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  "
                    >
                      <option disabled>select</option>
                      <option>+91</option>
                      <option>+93</option>
                      <option>+234</option>
                      {/* {isdCodeList?.map((e, i) => (
                    <option key={i} value={e.isdcode}>
                      {e.isdcode}
                    </option>
                  ))} */}
                    </select>
                    {/* {formik.errors.isdCode && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.isdCode}
                    </p>
                  )} */}
                  </label>

                  <label className="">
                    <input
                      name={"number"}
                      id={"number"}
                      type={"tel"}
                      placeholder="Enter Your Mobile Number"
                      // value={formik.values.number}

                      // onChange={(e)=>{
                      //   if(validateNumber(e.target.value)){
                      //     formik.handleChange(e)
                      //   }
                      // }}
                      className="no-spinner  p-2.5 my-1 w-[12.3rem]  text-[13px] font-Poppins text-[#575556]  rounded-[3px] border border-[#C4C4C4]"
                    />
                    {/* <div className="h-2 mb-2">
                  {formik.errors.number && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.number}
                    </p>
                  )}
                  
                </div> */}
                  </label>
                </div>

                {/* {onSubmitPopup ?
          <button
          type="submit"
         
          className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
        >
          Submitig...
        </button>
        : */}
                <div className="flex items-center justify-center space-x-8">
                  <button
                    onClick={() => {
                      props.close();
                    }}
                    type="submit"
                    className=" flex items-center justify-center my-1 h-[1.5rem]  text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={OncloseClick}
                    className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    Submit
                  </button>
                </div>

                {/* }   */}
              </form>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CommonChooseFamilyPopup = ({
  close,
  familynumber,
  parentsMobileNumber,
}) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  // console.log(familynumber, "familydata123");
  const navigate = useNavigate();
  const userProfileData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);

  // console.log(userProfileData, profileData, "userProfileData");
  const { onClickTrack } = useAnalytics();

  const formik = useFormik({
    initialValues: {
      member: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      member: Yup.string().required("Please select member "),
      password: Yup.string().required(
        "Enter the password associated with the profile holder!"
      ),
    }),
    onSubmit: (values) => {
      console.log(values, "values123");
      setShowLoader(true);
      apiLogin(
        values,
        familynumber.find((member) => member.id === values.member)?.id
      );
      onClickTrack(EventName.Home_page_Login_as_FamilyFriend_Login_Button, {
        [EventKeys.FamilyFriendLoginMemberId]: values.member,
      });
    },
  });

  const apiLogin = async (values, selectedMemberId) => {
    try {
      if (!selectedMemberId) {
        console.error("Selected member ID not available");
        return;
      }

      let loginRequest = {
        email: parentsMobileNumber,
        password: values.password,
        loginFrom: localStorage.getItem(
          Constants.loginLocalStorageKeys.loginDevice
        ),
        ioskey: "",
        androidkey: "",
        profileid: selectedMemberId,
      };

      let response = await POST(
        `${config.api.API_URL}${EndPoints.groupLoginUrl()}`,
        loginRequest
      );

      if (response.data.status === "Success") {
        if (response.data.data.status === "SUCCESS") {
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginFrom,
            new URL(window.location.href).pathname +
            new URL(window.location.href).search
          );
          localStorage.setItem(
            Constants.loginLocalStorageKeys.showLoader,
            true
          );

          localStorage.setItem(
            Constants.loginLocalStorageKeys.isLoggedIn,
            true
          ); //is loggedin
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginName,
            response.data.data.userName ?? ""
          ); //username
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginEmail,
            response.data.data.userName ?? ""
          );
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginId,
            response.data.data.userId
          ); //userid
          localStorage.setItem(
            Constants.loginLocalStorageKeys.familyLoginId,
            response.data.data.familyLoginId
          ); //family login id
          localStorage.setItem(
            Constants.loginLocalStorageKeys.familyLoginMobileNumber,
            parentsMobileNumber
          );

          //for vernacular
       i18n.changeLanguage(response.data.data?.vernacularCode ?? "en")
       localStorage.setItem(Constants.loginLocalStorageKeys.vernacularCode, response.data.data.vernacularCode ?? "en")

          if (response.data.data?.selectedversion == "V3") {
            localStorage.setItem("isV3User", true)
            // const cms_response = await CMS_Login({
            //   email: response.data?.data?.userName,
            //   password: loginRequest.password,
            // })
            const cms_response = await familycode_login({
              mobile_number: parentsMobileNumber,
              profile_id: loginRequest.profileid,
              password: loginRequest.password,
            })

            setShowLoader(false)

            console.log({ cms_response });
            if (cms_response.data) {
              console.log(cms_response, "cms_response");
              localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLogin,
                String(cms_response?.data?.access_token) ?? "OLD"
              );
              localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLoginId,
                String(cms_response?.data?.id) ?? ""
              );
            }
          }

          if (response.data.data.nextPage == "Redirect") {
            // history(`${response.data.data.redirect}`)
            window.location.href = "https://" + response.data.data.redirect;
          } else if (response.data.data.nextPage === "phoneNumberEdit") {
            localStorage.setItem("phoneNumberEdit", true);
            navigate(
              `${RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath)}`
            );
          } else {
            localStorage.setItem(
              Constants.loginLocalStorageKeys.loginCurrentPage,
              `/${response.data.data.nextPage}`
            );
            // history(`/match-lite`)
            navigate(`/${response.data.data.nextPage}`);
          }
        } else {
          // localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
          formik.setFieldError("password", response?.data?.data?.message);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.error("Error during login API call:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      {showLoader && (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      )}
      <div className="relative w-[100%] md:w-[60%] lg:w-[35%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[40px] rounded-tl-[40px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative items-center justify-center flex-auto p-6 text-center">
            <p className="text-[18px] text-[#D10A11] mt-2 ml-2 font-semibold font-poppins text-center">
              FAMILY/FRIEND LOGIN
            </p>
            <p className="text-[#8292A8] font-bold text-[15px] pt-2">
              Choose Member and Enter Password
            </p>
          </div>
          <div className="relative flex items-center justify-center flex-auto px-6 bottom-6 ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col space-y-4"
            >
              <div className="flex my-2 space-x-2 ">
                <label className="select text-[#575556] w-[18rem]">
                  <select
                    name="member"
                    id="member"
                    placeholder="Select Member"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.member}
                    className="md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] md:text-[12px] lg:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                  >
                    <option>Select Member</option>
                    {familynumber.map((member) => (
                      member?.familynumber === "Y" && (
                        <option key={member.id} value={member.id}>
                          {member.name} / {member.id}
                        </option>
                      )
                    ))}
                  </select>
                  {formik.touched.member && !formik.errors.member && (
                    <p className="text-[green] text-[12px]"></p>
                  )}
                  {formik.touched.member && formik.errors.member && (
                    <p className="text-[red] pb-1 text-[12px]">
                      {formik.errors.member}
                    </p>
                  )}
                </label>
              </div>
              <div>
                <label className="relative flex justify-end ">
                  <input
                    id="password"
                    name="password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    type={showHidePassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="w-[14rem] form-input shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full block"
                  />

                  <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                    <img
                      onClick={() => changeShowHidePassword(!showHidePassword)}
                      src={
                        showHidePassword
                          ? "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png"
                          : "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png"
                      }
                      className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                      alt=""
                    />
                  </div>
                </label>
                {formik.touched.password && !formik.errors.password && (
                  <p className="text-[green] text-[12px]"></p>
                )}
                {formik.touched.password && formik.errors.password && (
                  <p className="text-[red] text-[12px]">
                    {formik.errors.password}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-center space-x-8">
                <button
                  onClick={() => {
                    close();
                    onClickTrack(
                      EventName.Home_page_Login_as_FamilyFriend_Cancel_Button
                    );
                  }}
                  className="flex items-center justify-center my-1 h-[1.5rem] text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                >
                  Login
                </button>
              </div>
              <div>
                {/* You can include additional components or UI elements here */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonErrorPopup = ({ closePopup, message, buttonClick, buttonName }) => {
  const closePopupRef = useRef();

  const { t:trans } = useTranslation();

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!closePopupRef.current.contains(e.target)) {
  //       closePopup();
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 768px)"); // Adjust the width as needed for mobile views
    const handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        closePopup();
      }
    };
    const addListeners = () => {
      if (mobileQuery.matches) {
        document.addEventListener("mousedown", handler);
      }
    };
    const removeListeners = () => {
      document.removeEventListener("mousedown", handler);
    };

    // Check and add listeners on mount
    addListeners();

    // Remove listeners on unmount
    return () => {
      removeListeners();
    };
  }, []);


  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[38%] modal-container md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] right-[-6px]"
            onClick={() => {
              closePopup();
            }}
          >
            <img className="w-5 h-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto px-6 py-8">
            <div className="flex flex-col items-center justify-center mb-5">
              <span className="text-[16px] text-center">
                {message
                  ? message
                  :trans('family_alert_popup.group_member_limit_reached_you')}
              </span>
            </div>
            {buttonName && (
              <button
                onClick={buttonClick}
                className=" mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                {buttonName}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonNewPopup = ({
  closePopup,
  message,
  buttonClick,
  buttonName,
  title,
  image,
  notificationId
}) => {
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  

  const { t:trans } = useTranslation();

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!closePopupRef.current.contains(e.target)) {
  //       closePopup()
  //     }
  //   }
  //   document.addEventListener('mousedown', handler)

  //   return () => {
  //     document.removeEventListener('mousedown', handler)
  //   }
  // })
  const isViewProfile = buttonName == "WhoViewedMyprofile";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let textArray = buttonName == "WhoViewedMyprofile" && message ? message?.split(".") : "";

  const highlightext = textArray != "" ? textArray[0] + "." : "";

  const remaintext = textArray != "" ? textArray[1] + "." + textArray[2] : "";


  return (
    <div className={`flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? 'overflow-y-hidden ' : 'overflow-y-auto bg-opacityblack '}`}>
      <div
        // className={`relative modal-container md:my-6 md:mx-6 ${isViewProfile ? "lg:w-[23vw] md:w-[42vw] w-[71vw]" :"lg:w-[40vw] md:w-[70vw] w-[90vw]"}`}
        className={`relative  modal-container md:my-6 md:mx-6 ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? 'w-full h-full lg:w-[40vw] md:w-[70vw] pt-[5rem]' : isViewProfile ? 'xl:w-[23vw] 2xl:w-[23vw] lg:w-[30vw] md:w-[42vw] w-[71vw]' : 'w-[90vw] lg:w-[40vw] md:w-[70vw] '}`}
        ref={closePopupRef}
      >
        <div className="">
          {title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? '' :
            <button
              className=" bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] left-0"
              onClick={() => {
                if (isViewProfile) {
                  onClickTrack(EventName.CLC_Close_Button_Add_Photo_View_Matches_Popup)
                }
                if (title == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
                  onClickTrack("CLC_Close_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup")
                }
                if (title == "Generate/ Upload Horoscope") {
                  onClickTrack(EventName.CLC_Close_Button_V3_Generate_Upload_Horoscope_Popup)
                }
                if (title == "Add Star & Rassi") {
                  onClickTrack(EventName.CLC_CloseButton);
                }
                closePopup();
              }}
            >
              <img className="w-5 h-5 " src={CloseIcon} alt="" />
            </button>}
        </div>
        <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? 'h-full' : 'mt-[0.5rem] rounded-md'}`}>
          <div className={`px-4 pb-4 sm:p-6 sm:pb-4 rounded-2xl relative py-8 flex-auto ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? 'pt-[1.5rem]' : 'pt-5 '}`}>
            <div className={`flex flex-col justify-center mb-5 mx-[1rem] ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? '' : 'items-center '}`}>
              {title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ?
                <>
                  <div className='flex justify-between'>
                    <OverflowToolTip as='p' className="truncate text-[#000000] font-bold text-[24px] md:text-[14px] lg:text-[18px] pb-[3rem] pt-8">
                      {/* Add Photo with <br /> Privacy Options */}
                      {trans('clcprivacy_popup.add_photo_with_privacy_options')}
                    </OverflowToolTip>
                    <img src={RedClose} className='w-6 h-6' alt=''
                      onClick={() => {
                        closePopup();
                        if (title == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
                          onClickTrack("CLC_Close_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup")
                        }
                      }}
                    />
                  </div>
                  <div className="rounded-full mx-auto bg-[#FEBABA] p-4 flex items-center justify-center">
                    <img src={image} className="w-12 h-12" alt="" />
                  </div>

                </> :
                isViewProfile ?
                  <>
                    <span className="text-[#D10A11] - flex  font-bold text-[11px] md:text-[14px] lg:text-[18px] py-3 text-center">
                      {title}
                    </span>
                    <div className={`rounded-full mx-auto p-4 flex items-center justify-center ${isViewProfile ? "" : "bg-[#FEBABA]"}`}>
                      <img src={image} className={`w-12 h-12 ${isViewProfile ? "md:w-[5rem] md:h-[5rem]" : ""} `} alt="" />
                    </div>
                  </>
                  :
                  <>
                    {/* <div className="rounded-full mx-auto bg-[#FEBABA] p-4 flex items-center justify-center">
                    <img src={image} className="w-12 h-12" alt="" />
                  </div> */}
                    <div className={`rounded-full mx-auto p-4 flex items-center justify-center ${isViewProfile ? "" : "bg-[#FEBABA]"}`}>
                      <img src={image} className={`w-12 h-12 ${isViewProfile ? "md:w-[5rem] md:h-[5rem]" : ""} `} alt="" />
                    </div>
                    <span className="text-[#D10A11] flex font-Poppins-Regular font-bold text-[11px] md:text-[14px] lg:text-[18px] py-3 text-center">
                      {title}
                    </span>
                  </>
              }
              {title == trans('clcdownload_app.download_our_app_to_get_instan') && (
                <div className="flex md:gap-3 lg:gap-6 xl:gap-10 lg:mt-[2rem] md:mt-[1rem]">
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href={RouteSegments.staticLinkForAppDownload.ios}
                  >
                    <img
                      className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]"
                      src={playstoreicon}
                      alt="ApptoreIcon"
                    />
                  </a>
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href={RouteSegments.staticLinkForAppDownload.android}
                  >
                    <img
                      className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]"
                      src={googleicon}
                      alt="PlayStoreIcon"
                    />
                  </a>
                </div>
              )}
              {isViewProfile ?
                <p className="text-[#575556] text-[14px] lg:text-[16px] font-semibold py-3 text-center">
                  <span className="text-[#D10A11]">{highlightext}</span>{remaintext}
                </p>
                :
                <span className={`text-[#575556] lg:text-[16px] font-semibold text-center ${title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ? 'py-[3rem]  text-[24px]' : ' py-3  text-[14px]'}`}>
                  {message}
                </span>
              }

            </div>
            {buttonName && (
              title == trans('clcprivacy_popup.add_photo_with_privacy_options') && windowWidth < 500 ?
                <div className="absolute bottom-[1rem] left-0 w-full py-4 bg-white">
                  <button
                    onClick={() => {
                      buttonClick();
                      if (title ==trans('clcprivacy_popup.add_photo_with_privacy_options')) {
                        onClickTrack("CLC_Add_Now_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup");
                      }
                      else if (title == "Generate/ Upload Horoscope") {
                        onClickTrack(EventName.CLC_Add_Horoscope_Now_Button_V3_Site_Generate_Upload_Horoscope_Popup)
                      }
                    }}
                    className="mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    <OverflowToolTip as='p' className='truncate'>{buttonName}</OverflowToolTip>
                  </button>
                </div>
                : isViewProfile ?
                  <>
                    <button
                      onClick={() => { onClickTrack(EventName.CLC_Add_Photo_Now_Button_Add_Photo_View_Matches_Popup); buttonClick("addPhoto") }}
                      className="gap-4 mx-auto flex items-center cursor-pointer justify-center my-3 h-[1.5rem] text-[#D10A11] border border-[#D10A11] px-[2.5rem] md:px-[2.5rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                    >
                      <img src={Images.RedCameraIcon} className="w-6 h-4" alt="" /> <OverflowToolTip as='p' className='truncate w-[6rem]'>{trans('photo_popup.add_photo_now')}!</OverflowToolTip>
                    </button>
                    <button
                      onClick={() => { onClickTrack(EventName.CLC_View_Matches_Button_Add_Photo_View_Matches_Popup); buttonClick("viewMatches") }}
                      className="gap-4 mx-auto flex items-center cursor-pointer justify-center my-3 h-[1.5rem] text-black border border-[#D10A11] px-[2.5rem] md:px-[2.5rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                    >
                      <img src={Images.EyeOpenIcon} className="w-6 h-4" alt="" />  <OverflowToolTip as='p' className='truncate w-[6rem]'>{trans('clcadd_photo__view_matches_pop.view_matches')}</OverflowToolTip>
                    </button>
                  </>
                  :
                  <button
                    onClick={() => {
                      buttonClick();
                      if (title == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
                        onClickTrack("CLC_Add_Now_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup");
                      }else if (title == trans('clcgenerate_upload_horoscope.add_horoscope_now')) {
                        onClickTrack(EventName.CLC_Add_Horoscope_Now_Button_V3_Site_Generate_Upload_Horoscope_Popup)
                      }

                    }}
                    className={`mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px] `}
                  >
                    <OverflowToolTip as='p' className='truncate md:w-auto w-[15rem]'>{buttonName}</OverflowToolTip>
                  </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



const CommonPhotoNewPopup = (props) => {
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  const { setMenuSelect ,successPopup, setSuccessPopup ,photopopupOpen, setPhotoPopupOpen} = useContext(NavBarContext);

  const isViewProfile = props?.buttonName == "WhoViewedMyprofile";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let textArray = props?.buttonName == "WhoViewedMyprofile" && props?.message ? props?.message?.split(".") : "";

  const highlightext = textArray != "" ? textArray[0] + "." : "";

  const remaintext = textArray != "" ? textArray[1] + "." + textArray[2] : "";

  const [successMessage, setsuccessMessage] = useState("");


  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const location = useLocation();


  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const handleCrop = (file, type) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
       


      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
    
  };

  const handleOriginalImageUpload = async (file) => {
   
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);

      },
    });
  };

  console.log(location?.state?.data?.notificationId,'location?.state?.data?.notificationId');
  
  const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    let notificationId;
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
    fileData.append("photorequesttypeid", 85);
    fileData.append("requestfulfilledfrom", getDeviceInfo());
    fileData.append("recieverprofileid", getGroupAuthId());

    if (props.isFrom === 'TrailPack') {
      fileData.append("editviatrialpack", props.editviatrialpack);
      fileData.append("notificationId", NotificationId);
    }
    notificationId = sessionStorage.getItem(
      Constants.loginLocalStorageKeys.notificationId
    )
    if (notificationId) {
      fileData.append("notificationId", notificationId);
    }
    if(props.isFrom=="wizardphoto"){
      fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
    }
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        if (props?.isFrom == 'TrailPack') {
          props.postData();
        }
        setShowCrop(true);
      } else {
        props.closePopup();
      }
    } else {
      props.closePopup();
    }
  };
const [showphoto,setShoPhoto] = useState(true)
  
console.log(showSuccessPopup,showphoto,'props?.close');

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup || props?.from || props.PrivacyOptionTitle) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      // fileData.append("notificationId", location?.state?.data?.notificationId);
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      if (props.isFrom === 'TrailPack') {
        fileData.append("editviatrialpack", props.editviatrialpack);
        fileData.append("notificationId", NotificationId);
      }
      if(props.isFrom=="wizardphoto"){
        fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
      }
      if(props.isFrom === 'AddPhoto'){
        fileData.append("notificationId", NotificationId);
      }
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());

      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {          
         
          setshowSuccessPopup(true)

          props?.onPhotoPopupClose(true , "AddPhoto");
         
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }   
          setShoPhoto(false)
          // props?.closePopup()
          props?.callBack();
          
        } else {
          props.closePopup();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.closePopup();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.closePopup();
    }
  };



  useEffect(() => { }, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    if (props.isFrom == 'TrailPack') {
      return;
    } else {
      props.close();
    }
  };

 
  return (
    <>

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
          />
        )}
    
    {notification.show && (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        )}
        {showCrop && (
        
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props?.closePopup();
                // setShowCrop(false)
              }

              if (props?.callBackReload) {
                props?.callBackReload();
              }
            }}
          />
        )}

       

    { (props?.isFrom == "AddPhoto"  && showphoto) &&
      <div className={`flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'overflow-y-hidden ' : 'overflow-y-auto bg-opacityblack '}`}>
      <div
        // className={`relative modal-container md:my-6 md:mx-6 ${isViewProfile ? "lg:w-[23vw] md:w-[42vw] w-[71vw]" :"lg:w-[40vw] md:w-[70vw] w-[90vw]"}`}
        className={`relative  modal-container md:my-6 mx-6 
          
          ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'w-full h-full lg:w-[40vw] md:w-[70vw] pt-[5rem]'
             : isViewProfile ? 'xl:w-[23vw] 2xl:w-[23vw] lg:w-[30vw] md:w-[42vw] w-[71vw]' : ' '}`}
        ref={closePopupRef}
      >
        <div className="">
          {props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? '' :
            <button
              className=" bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] left-0"
              onClick={() => {
                props?.closePopup();

              }}
            >
              <img className="h-5 w-5 " src={CloseIcon} alt="" />
            </button>}
        </div>
        <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'h-full' : 'mt-[0.5rem] rounded-md'}`}>
          <div className={`px-4 pb-4 sm:p-6 sm:pb-5 rounded-2xl relative py-8 flex-auto ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'pt-[1.5rem]' : 'pt-5 '}`}>
            <div className={` justify-center mb-2  items-center `}>
    
                   <div className="text-[#D10A11] flex border-b-2 border-dashed border-[#D10A11] justify-center items-center text-center font-bold text-[16px] md:text-[18px] lg:text-[21px] pb-5">
                      <h3>{props?.title}</h3>
                    </div>

                    <div className={`  w-[5rem] h-[5rem] mt-[1.5rem] rounded-full mx-auto p-4 flex items-center justify-center ${isViewProfile ? "" : "bg-[#FEBABA]"}`}>
                      <img src={props?.image} className={`w-12 h-12 ${isViewProfile ? "md:w-[5rem] md:h-[5rem]" : ""} `} alt="" />
                    </div>
                   
                    </div>
              
           
               <div className="flex justify-center "><span className={`text-[#000000] w-[19rem] md:w-[25rem] lg:text-[16px] text-center ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'py-[3rem]  text-[24px]' : ' py-3  text-[14px]'}`}>
                  {props?.message} 
                  
                 
                </span>
                </div> 
                <p className="border-b-2 border-dashed py-1 border-[#D10A11]"></p>


            {
          !showCrop && showLoader ? (
            <Loader
              className="loaderTransperancy"
              loaderClassName="loaderTransperancySpin"
            />
          )  : (
         <>
                
                  {
                    compressing ? (
                      <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                        <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                        <div className="text-[16px] font-semibold my-2">
                          Uploading...
                        </div>
                      </div>
                    )
                    :
                  isUploading ? (
                    <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                      <img
                        alt="loader"
                        src={LoaderGif}
                        className={`w-16 h-16`}
                      />
                      <div className="text-[16px] font-semibold my-2">
                        Uploading...
                      </div>
                    </div>
                  ) : (
                     
                    <>
                    <p className="text-[#000000] text-[19px] mx-[1rem] md:mx-[1rem] py-3 mt-2  md:mt-3 font-bold">Upload Photo Via</p>
                      <div className="hidden md:grid grid-cols-3 lg:gap-5 md:gap-12 2xl:gap-10 md:mx-[6rem]  xl:mx-[1rem] lg:mx-[1rem] 2xl:mx-[5rem]">

                
                              <label>
                              <div
                                className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
                                }}
                                onClick={() => {
                                  if (props.isFrom == "sign out") {
                                    setMenuSelect("2");
                                    navigate(
                                      RouteHelper.getEditProfileUrl(
                                        EditProfileMenu[2].urlPath
                                      ),
                                      { state: { tab: "3" } }
                                    );
                                    props?.closePopup();
                                  }

                                  onClickTrack(EventName.CLC_Gallery)
                                  props?.onPhotoPopupClose(true, "AddPhoto");
                                }}
                              >
                                <img
                                  src={Images.gallaryimage}
                                  className="w-[3rem] h-[3rem] object-none"
                                  loading="lazy"
                                  alt=""
                                />
                                <div className="flex items-center justify-center bg-white cursor-pointer">
                                  <p className="text-[#000000] font-medium font-Poppins text-[14px]">
                                    <a className="text-[#7A7A7A]  cursor-pointer" 
                                      >
                                      Add Photo
                                    </a>

                                    {props.isFrom === "sign out" ? (
                                      <></>
                                    ) : (
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) => {
                                          handleCrop(event.target.files[0]);
                                        }}
                                        accept="image/png, image/jpg, image/jpeg"
                                        data-max-size="10485760"
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                              </label>

                               
                          <SocialMediaUpload
                            closeModal={() => props.close()}
                            callBackImage={(e) => props?.callBackReload(e)}
                            isFrom={'addphotoclc'}
                            onPhotoPopupClose={props?.onPhotoPopupClose}
                          />
                            <div
                              className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem] bg-white shadow-md rounded-lg cursor-pointer"
                              style={{
                                boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
                              }}
                              onClick={() => {
                                window.open(
                                  Constants.kalyanSupportWhatsappUrl(
                                    userData?.smProfileId
                                  )
                                );
                                onClickTrack(EventName.CLC_Whatsapp)
                                props?.onPhotoPopupClose(true, "AddWhatsapp");
                              }}
                            >
                              <img
                                src={Images.whatsappimage}
                                className="w-[3rem] h-[3rem] object-none"
                                loading="lazy"
                                alt=""
                              />
                              <div className="flex items-center justify-center bg-white cursor-pointer">
                                <p className="text-[#7A7A7A]  font-Poppins text-[14px] cursor-pointer">
                                  WhatsApp
                                </p>
                              </div>
                            </div>

                          
                         
                        </div>

                        <div className="block md:hidden  gap-4 grid grid-cols-3 mx-[2rem]">

                       
                     
                        <div
                                className="flex flex-col space-y-2 items-center justify-center  py-[0.5rem] px-[0.5rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
                                }}>
                          <label>
                          <div
                            className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                            
                            onClick={() => {
                              onClickTrack(EventName.CLC_Gallery)
                              props?.onPhotoPopupClose(true, "AddPhoto");
                            }}
                          >
                            <img
                              src={Images.gallaryimage}
                              className="w-[2rem] h-[2rem] object-contain"
                              loading="lazy"
                              alt=""
                            />
                            <div className="flex items-center justify-center bg-white cursor-pointer">
                              <p className="text-[#000000] font-medium font-Poppins text-[10px]">
                                <a className="text-[#7A7A7A]   cursor-pointer" >
                                  Add Photo
                                </a>

                                {props.isFrom === "sign out" ? (
                                  <></>
                                ) : (
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(event) => {
                                      handleCrop(event.target.files[0]);
                                    }}
                                    accept="image/png, image/jpg, image/jpeg"
                                    data-max-size="10485760"
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                          </label>
                  </div>

                  <div
                                className="flex flex-col space-y-2 items-center justify-center  py-[0.5rem] px-[0.5rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
                                }}>
                          <SocialMediaUpload
                        closeModal={() => props.close()}
                        callBackImage={(e) => props?.callBackReload(e)}
                        isFrom={'addphotoclc'}
                        onPhotoPopupClose={props?.onPhotoPopupClose}
                        />
                        </div>

                        <div
                                className="flex flex-col space-y-2 items-center justify-center  py-[0.5rem] lg:px-[0.5rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
                                }}>
                <div
                        className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                       
                        onClick={() => {
                          window.open(
                            Constants.kalyanSupportWhatsappUrl(
                              userData?.smProfileId
                            )
                          );
                          onClickTrack(EventName.CLC_Whatsapp)
                          props?.onPhotoPopupClose(true, "AddWhatsapp");                        }}
                      >
                        <img
                          src={Images.whatsappimage}
                          className="w-[2rem] h-[2rem] object-contain"
                          loading="lazy"
                          alt=""
                        />
                        <div className="flex items-center justify-center  bg-white cursor-pointer">
                          <p className="text-[#7A7A7A] font-Poppins text-[10px] cursor-pointer">
                            WhatsApp
                          </p>
                        </div>
                      </div>
                      </div>

                    </div>


                       </>

                     
                  )}
                  </>
          )
       
        }
          
{/*              
              <PhotoPopuplite 

              callBack={() => {
                setsuccessMessage("Image uploaded succesfully")
                setshowSuccessPopup(true)
                }}
               
              
              callBackReload={() => { }}
              isFrom={"contentcard"}
              close={closePopup} onPhotoPopupClose={onPhotoPopupClose} buttonClick={buttonClick} />

                   */}
                
            
          </div>
        </div>
      </div>
    </div>

  
    
          }
          </>
  );
};

// const PhotoPopuplite = (props) => {
//   const [showCrop, setShowCrop] = useState(false);
//   const [currentimage, setCurrentImage] = useState();
//   const [originalImage, setOriginalImage] = useState();
//   const [showSuccessPopup, setshowSuccessPopup] = useState(false);
//   const [showLoader, setShowLoader] = useState(false);
//   const { loginDetail, logout } = useContext(LoginContext);
//   const [notification, setNotification] = useState({
//     show: false,
//     title: "",
//     message: "",
//   });
//   const navigate = useNavigate();
//   const [isUploading, setIsUploading] = useState(false);
//   const [compressing, setCompressing] = useState(false);
//   const [photoId, setPhotoId] = useState();
//   const { setMenuSelect ,successPopup, setSuccessPopup ,photopopupOpen, setPhotoPopupOpen} = useContext(NavBarContext);
//   const location = useLocation();
//   const { onClickTrack } = useAnalytics()
// console.log(photopopupOpen,'photopopupOpen');


//   useEffect(() => {
//     console.log(props, "contentResponseData");
//   }, [showCrop, currentimage, originalImage]);

//   const showNotification = ({ description = "", title = "Status!" }) => {
//     setNotification({ show: true, title: title, message: description });
//   };

//   const userData = useSelector(
//     (state) => state?.DashBoardAPI?.ProfileData?.data
//   );

//   const handleCrop = (file, type) => {
//     console.log("handle Crop", file);
//     validateImage({
//       file,
//       onSizeError: (img) => {
//         showNotification({
//           title: "Invalid image",
//           description: "Height and Width must be more than 100px",
//         });
//       },
//       onExtensionError: (fileType) => {
//         showNotification({
//           title: "Invalid image",
//           description: "Invalid extension!",
//         });
//       },
//       onFileSizeError: (img) => {
//         showNotification({
//           title: "Invalid image",
//           description: "Image size should not be greater than 10mb",
//         });
//       },
//       onSuccess: (img, readerStr) => {
//         // handleOriginalImageUpload(file);
//         imageSizeReducer(file, originalImageUpload);
//         setCompressing(true);
//         // handleImageSizeCompress(file,originalImageUpload,setCompressing)
//         setCurrentImage(readerStr || "");
       


//       },
//     });
//   };
//   const cropedImage = (cropedImg) => {
//     // handleUpload(originalImage, cropedImg);
//     imageSizeReducer(cropedImg, handleUpload);
//     setShowCrop(false);
    
//   };

//   const handleOriginalImageUpload = async (file) => {
   
//     const image = file;
//     new Compressor(image, {
//       quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
//       success: (compressedResult) => {
//         // compressedResult has the compressed file.
//         // Use the compressed file to upload the images to your server.
//         // setCompressedFile(res)
//         console.log(compressedResult, "compressedResult");
//         originalImageUpload(compressedResult);

//       },
//     });
//   };
//   const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
//   const originalImageUpload = async (compressedImageUrl) => {
//     console.log(compressedImageUrl, "compressedImageUr");
//     let notificationId;
//     setOriginalImage(compressedImageUrl);
//     let fileData = new FormData();
//     fileData.append("email", loginDetail()[0]);
//     fileData.append("userId", loginDetail()[1]);
//     fileData.append("photoUploadFrom", getDeviceInfo());
//     fileData.append("uploadfrom", "Gallery");
//     fileData.append("original", compressedImageUrl);
//     fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
//     fileData.append("contentId", props?.contentId ? props?.contentId : "");
//     fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
//     fileData.append("photorequesttypeid", 85);
//     fileData.append("requestfulfilledfrom", getDeviceInfo());
//     fileData.append("recieverprofileid", getGroupAuthId());

//     if (props.isFrom === 'TrailPack') {
//       fileData.append("editviatrialpack", props.editviatrialpack);
//       fileData.append("notificationId", NotificationId);
//     }
//     notificationId = sessionStorage.getItem(
//       Constants.loginLocalStorageKeys.notificationId
//     )
//     if (notificationId) {
//       fileData.append("notificationId", notificationId);
//     }
//     if(props.isFrom=="wizardphoto"){
//       fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
//     }
//     setIsUploading(true);
//     let data = await axios.post(
//       `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
//       fileData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Accept: "application/json",
//         },
//       }
//     );

//     setIsUploading(false);
//     console.log(data, "datadatadatadata");
//     if (data.data.statusCode === 200) {
//       if (data.data.data.status === "Success") {
//         setPhotoId(data.data.data.photoId);
//         if (props?.isFrom == 'TrailPack') {
//           props.postData();
//         }
//         setShowCrop(true);
//       } else {
//         props.close();
//       }
//     } else {
//       props.close();
//     }
//   };
// console.log(props?.close,'props?.close');

  
//   const handleUpload = async (file, cropedFile) => {
//     //console.log("file.size", file.size);
//     if (file.size / 1024 / 1024 < 10) {
//       let notificationId = null;
//       const loginUrl = sessionStorage.getItem(
//         Constants.loginLocalStorageKeys.loginUrl
//       );

//       if (loginUrl?.includes("photo") || props?.isFromInitialPopup || props?.from || props.PrivacyOptionTitle) {
//         notificationId = sessionStorage.getItem(
//           Constants.loginLocalStorageKeys.notificationId
//         );
//         sessionStorage.removeItem(
//           Constants.loginLocalStorageKeys.notificationId
//         );
//       }
//       console.log(notificationId, "notificationId");
//       let fileData = new FormData();
//       fileData.append("email", loginDetail()[0]);
//       fileData.append("userId", loginDetail()[1]);
//       fileData.append("photoUploadFrom", getDeviceInfo());
//       fileData.append("uploadfrom", "Gallery");
//       fileData.append("photoId", photoId);
//       fileData.append("croped", file);
//       if (notificationId) {
//         fileData.append("notificationId", notificationId);
//       }
//       if (props.isFrom === 'TrailPack') {
//         fileData.append("editviatrialpack", props.editviatrialpack);
//         fileData.append("notificationId", NotificationId);
//       }
//       if(props.isFrom=="wizardphoto"){
//         fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
//       }
//       fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
//       fileData.append("photorequesttypeid", 85);
//       fileData.append("requestfulfilledfrom", getDeviceInfo());
//       fileData.append("recieverprofileid", getGroupAuthId());

//       setIsUploading(true);
//       let data = await axios.post(
//         `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
//         fileData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       setIsUploading(false);
//       if (data.status === 200) {
        
//         if (data.data.data.status === "SUCCESS") {
//           setshowSuccessPopup(true);

//           onPhotoPopupClose(true , "");
         
//           if (props.isFrom == "contentcard") {
//             props?.callBackReload();
//           }   
//           // Close popup after a delay
           
//           props?.callBack();
          
//         } else {
//           props.close();
//         }
//       } else {
//         alert("Please upload file less than 10 mb");
//         props.close();
//       }
//     } else {
//       alert("Please upload file less than 10 mb");
//       props.close();
//     }
//   };



//   useEffect(() => { }, [props?.menu]);

//   const closeNotification = () => {
//     setNotification({ show: false, message: "", title: "" });
//     if (props.isFrom == 'TrailPack') {
//       return;
//     } else {
//       props.close();
//     }
//   };

//   const closePopup = () => {
//     // Add any additional logic to close the popup
//     props.close();
//   };

//   console.log(props?.count, "props?.countprops?.count");

//   const helplineClickTrack = () => {
//     onClickTrack(EventName.web_addphotopopup_callexecutive)
//   }

//   const renderhelpline = () => {
//     return <div className="border rounded-lg flex space-x-2 px-2.5 py-2 mx-auto w-fit items-center">
//       <img alt="call" src={Images.PhoneIcon} className="w-6 h-6 mx-2" />
//       <div className=" space-y-1.5">
//         <p className="text-center text-black-500 font-Poppins-SemiBold text-[12px] md:text-[14px]">Call Us!</p>
//         <p className="text-center text-[#D10A11] font-Poppins-Bold text-[12px] md:text-[14px]">{Constants.helpLinePhNo}</p>
//       </div>
//     </div>
//   }

//   return (
//     <>
//       <>
//         {notification.show && (
//           <NotificationPopup
//             close={closeNotification}
//             title={notification.title}
//             content={notification.message}
//           />
//         )}
//         {showCrop && (
//           // <ImageCroperComponent
//           //   close={() => setShowCrop(false)}
//           //   image={currentimage}
//           //   imgType={originalImage.type}
//           //   callBackCroper={cropedImage}
//           // />
//           <ImageCropper
//             onCropClick={cropedImage}
//             image={currentimage}
//             onClose={(isCropped) => {
//               if (isCropped) {
//                 setShowCrop(false);
//               } else {
//                 // props.close();
//                 setShowCrop(false)
//               }

//               if (props?.callBackReload) {
//                 props?.callBackReload();
//               }
//             }}
//           />
//         )}

//         {showSuccessPopup && (
//           <CommonSuccessPopup
//             close={() => {
//               setshowSuccessPopup(false);
//             }}
//             title={"Success!"}
//             content={"Image upload success"}
//           />
//         )}
//         {
//           !showCrop && showLoader ? (
//             <Loader
//               className="loaderTransperancy"
//               loaderClassName="loaderTransperancySpin"
//             />
//           )  : (
//          <>
                
//                   {isUploading ? (
//                     <div className="p-6 flex-auto flex items-center flex-col  justify-center">
//                       <img
//                         alt="loader"
//                         src={LoaderGif}
//                         className={`w-16 h-16`}
//                       />
//                       <div className="text-[16px] font-semibold my-2">
//                         Uploading...
//                       </div>
//                     </div>
//                   ) : (
                     
//                     <>
//                     <p className="text-[#000000] text-[19px] mx-[1rem] md:mx-[1rem] py-3 mt-2  md:mt-3 font-bold">Upload Photo Via</p>
//                       <div className="hidden md:grid grid-cols-3 gap-12 md:mx-[6rem] lg:mx-[8rem]">

                
//                               <label>
//                               <div
//                                 className="flex flex-col space-y-4 items-center justify-center  py-[1rem] lg:px-[0.5rem]  bg-white shadow-md rounded-lg cursor-pointer"
//                                 style={{
//                                   boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
//                                 }}
//                                 onClick={() => {
//                                   if (props.isFrom == "sign out") {
//                                     setMenuSelect("2");
//                                     navigate(
//                                       RouteHelper.getEditProfileUrl(
//                                         EditProfileMenu[2].urlPath
//                                       ),
//                                       { state: { tab: "3" } }
//                                     );
//                                     closePopup();
//                                   }

//                                   // props?.buttonClick("AddPhoto")
//                                   onClickTrack(EventName.CLC_Gallery)
//                                   onPhotoPopupClose(true, "AddPhoto");
//                                 }}
//                               >
//                                 <img
//                                   src={Images.gallaryimage}
//                                   className="w-[3rem] h-[3rem]"
//                                   loading="lazy"
//                                   alt=""
//                                 />
//                                 <div className="flex items-center justify-center bg-white cursor-pointer">
//                                   <p className="text-[#000000] font-medium font-Poppins text-[14px]">
//                                     <a className="text-[#7A7A7A] font-bold  cursor-pointer" 
//                                       >
//                                       Gallary
//                                     </a>

//                                     {props.isFrom === "sign out" ? (
//                                       <></>
//                                     ) : (
//                                       <input
//                                         style={{ display: "none" }}
//                                         type="file"
//                                         onChange={(event) => {
//                                           handleCrop(event.target.files[0]);
//                                         }}
//                                         accept="image/png, image/jpg, image/jpeg"
//                                         data-max-size="10485760"
//                                       />
//                                     )}
//                                   </p>
//                                 </div>
//                               </div>
//                               </label>
//                             <div
//                               className="flex flex-col space-y-4 items-center justify-center  py-[1rem] lg:px-[0.5rem] bg-white shadow-md rounded-lg cursor-pointer"
//                               style={{
//                                 boxShadow: "0px 0px 5px rgba(254, 186, 186, 1)",
//                               }}
//                               onClick={() => {
//                                 window.open(
//                                   Constants.kalyanSupportWhatsappUrl(
//                                     userData?.smProfileId
//                                   )
//                                 );
//                                 onClickTrack(EventName.CLC_Whatsapp)
//                                 onPhotoPopupClose(true, "AddWhatsapp");
//                               }}
//                             >
//                               <img
//                                 src={Images.whatsappimage}
//                                 className="w-[3rem] h-[3rem]"
//                                 loading="lazy"
//                                 alt=""
//                               />
//                               <div className="flex items-center justify-center bg-white cursor-pointer">
//                                 <p className="text-[#7A7A7A] font-medium font-Poppins text-[14px] cursor-pointer">
//                                   WhatsApp
//                                 </p>
//                               </div>
//                             </div>

                          
                          
//                           <SocialMediaUpload
//                             closeModal={() => props.close()}
//                             callBackImage={(e) => props?.callBackReload(e)}
//                             isFrom={'addphotoclc'}
//                             onPhotoPopupClose={onPhotoPopupClose}
//                           />
//                         </div>

//                         <div className="block md:hidden grid grid-cols-3 mx-2rem] ">

                       
                     

//                           <label>
//                           <div
//                             className="flex flex-col space-y-4 items-center justify-center  cursor-pointer"
                            
//                             onClick={() => {
//                               onClickTrack(EventName.CLC_Gallery)
//                                   onPhotoPopupClose(true, "AddPhoto");
//                             }}
//                           >
//                             <img
//                               src={Images.gallaryimage}
//                               className="w-[2rem] h-[2rem]"
//                               loading="lazy"
//                               alt=""
//                             />
//                             <div className="flex items-center justify-center bg-white cursor-pointer">
//                               <p className="text-[#000000] font-medium font-Poppins text-[13px]">
//                                 <a className="text-[#7A7A7A] font-bold  cursor-pointer" >
//                                   Add Photo
//                                 </a>

//                                 {props.isFrom === "sign out" ? (
//                                   <></>
//                                 ) : (
//                                   <input
//                                     style={{ display: "none" }}
//                                     type="file"
//                                     onChange={(event) => {
//                                       handleCrop(event.target.files[0]);
//                                     }}
//                                     accept="image/png, image/jpg, image/jpeg"
//                                     data-max-size="10485760"
//                                   />
//                                 )}
//                               </p>
//                             </div>
//                           </div>
//                           </label>
                  


//                     <SocialMediaUpload
//                     closeModal={() => props.close()}
//                     callBackImage={(e) => props?.callBackReload(e)}
//                     isFrom={'addphotoclc'}
//                     />


//                 <div
//                         className="flex flex-col space-y-4 items-center justify-center  cursor-pointer"
                       
//                         onClick={() => {
//                           window.open(
//                             Constants.kalyanSupportWhatsappUrl(
//                               userData?.smProfileId
//                             )
//                           );
//                           onClickTrack(EventName.CLC_Whatsapp)
//                           onPhotoPopupClose(true, "AddWhatsapp");                        }}
//                       >
//                         <img
//                           src={Images.whatsappimage}
//                           className="w-[2rem] h-[2rem]"
//                           loading="lazy"
//                           alt=""
//                         />
//                         <div className="flex items-center justify-center  bg-white cursor-pointer">
//                           <p className="text-[#7A7A7A] font-medium font-Poppins text-[13px] cursor-pointer">
//                             WhatsApp
//                           </p>
//                         </div>
//                       </div>

//                     </div>


//                        </>

                     
//                   )}
//                   </>
//           )
       
//         }
//       </>
//     </>
//   );
// };
const PaymentWhatsAppPopup = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");
  const [groupNumber, setGroupNumber] = useState([]);
  const [dropdownDisable, setDropdownDisable] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  const [numberEnable, setNumberEnable] = useState(true);
  const [typeofNumber, setTypeofNumber] = useState(true);
  const [offlineLoader, setOfflineLoader] = useState(false);

  const submitRef = useRef(null);

  const { t:trans } = useTranslation();

  const isV3User = localStorage.getItem("isV3User");

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const { setShowSearch, setShowMedia } = useContext(NavBarContext);

  const familyChatList = useSelector((state) => state?.Chats?.groupChatList);

  const dropdownData = useSelector((state) => state?.Home?.rData?.data);

  const { ProfileData } = useSelector((state) => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data,
  }));

  const formik = useFormik({
    initialValues: {
      whatsappisdcode: "+91",
      whatsappnumber: "",
      relationId: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      relationId: Yup.string().required("Please select relative"),
      whatsappnumber: Yup.string()
        .min(10, "Length should be 10")
        .test(
          "india",
          "whatsapp number should have 10 digits",
          function (value) {
            const { isdCode } = this.parent;
            if (isdCode === "+91") {
              return !value || value.length === 10
                ? true
                : this.createError({
                  message: "Whatsapp number should have 10 digits",
                  path: "whatsappnumber",
                });
            }
            return true;
          }
        )
        .test("india", "number error", function (value) {
          // if (userData?.parentsMobileNumber == value) {
          // return this.createError({ message: "Number should not be same as parents number", path: "whatsappnumber" });
          // } else if (userData?.userphoneno == value) {
          // return this.createError({ message: "Number should not be same as profile number", path: "whatsappnumber" });
          // }
          return true;
        })
        .required("Please enter whatsapp number"),
    }),
    onSubmit: (values) => {
      setOfflineLoader(true);
      submitWhatsappNumber(values);
    },
  });

  const handleOptionChange = async (event, number, type, relative) => {
    setSelectedOption(event.target.value);
    setTypeofNumber(type);

    if (type == "input") {
      await formik.setFieldValue("whatsappnumber", number);
      const isGroup = groupNumber.filter(
        (e) => e.parentsMobileNumber == number
      );
      if (
        isGroup.length > 0 ||
        userData?.parentsMobileNumber == number ||
        userData?.userphoneno == number
      ) {
        const selectedId =
          userData?.userphoneno == number
            ? "8"
            : userData?.parentsMobileNumber == number
              ? "7"
              : dropdownData?.relationShip
                .filter((e) => e.name == isGroup[0].relationshipName)
                .map((e) => e.id)[0];
        await formik.setFieldValue("relationId", selectedId);
        let optnumber =
          userData?.userphoneno == number
            ? "-1"
            : groupNumber.findIndex(
              (obj) => obj.parentsMobileNumber === number
            );
        setSelectedOption("option" + optnumber);
        setDropdownDisable(true);
      } else {
        // const formik = useFormik();
        const fieldValue = formik.values.relationId;
        if (fieldValue == "8" || fieldValue == "7") {
          await formik.setFieldValue("relationId", null);
        }
        setDropdownDisable(false);
      }
    } else {
      const selectedId =
        relative == "Myself"
          ? "8"
          : relative == "Parent"
            ? "7"
            : dropdownData?.relationShip
              .filter((e) => e.name == relative)
              .map((e) => e.id)[0];
      console.log("whatsappnumber", number);
      await formik.setFieldValue("whatsappnumber", number, false);
      formik.setFieldValue("relationId", selectedId);
      setDropdownDisable(true);
    }
    if (number?.length > 0) {
      setNumberEnable(false);
    } else {
      setNumberEnable(true);
    }
  };

  const createGroupConversation = async () => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Constants.loginLocalStorageKeys.chatLogin
        )}`,
      },
      data: {
        profile_id: userData?.profileId,
        auth_id: getGroupAuthId(),
        get_starred_message: false,
        page: 1,
        page_size: 1,
      },
    };
    const createConversation = await Family_Chats_Detail(axiosConfig);
  };

  const submitWhatsappNumber = async (values) => {
    let request = {
      profileId: ProfileData?.profileId,
      whatsappnumber: values?.whatsappnumber,
      whatsappisdcode: values?.whatsappisdcode,
      relationId: values?.relationId == "8" ? "8" : values?.relationId,
      isverified:
        typeofNumber == "input"
          ? groupNumber.filter(
            (e) => e.parentsMobileNumber == values?.whatsappnumber
          ).length > 0 || userData?.userphoneno == values?.whatsappnumber
            ? "Y"
            : "N"
          : "Y",
    };

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.saveWhatsappDetails()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setOfflineLoader(false);
        props.close();
        if (submitRef.current == "addmore") {
          navigate(RouteSegments.MATCH_GROUP, { state: { isFrom: "payment" } });
        } else {
          props?.setwhatsAppSuccessPopup(true);
        }
        setShowSearch(false);
        setShowMedia(false);
        createGroupConversation();
      }
    }
  };

  const fetchGroupData = async () => {
    const profileId = ProfileData?.profileId;
    if (!profileId) {
      console.error("ProfileId not available");
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        // console.log(data.data.groupMembers, 'groupMembers');
        setGroupNumber(
          data.data.groupMembers?.filter(
            (member) => member?.verifiedstatus == "Y"
          )
        );
      }
    }
  };

  useEffect(() => {
    fetchGroupData();
    dispatch(registerDetail());
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
        <div
          className={`relative ${props?.isClose == false
            ? "w-[95vw] md:w-[25rem] lg:w-[25rem]"
            : "w-[95vw] md:w-auto lg:w-auto"
            } my-6 mx-6`}
        >
          {offlineLoader ? (
            <Loader
              className="loaderTransperancy"
              loaderClassName="loaderTransperancySpin"
            />
          ) : (
            <>
              {props?.isClose == false ? (
                <>
                  <div className="">
                    <button
                      className=" bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
                      onClick={() => {
                        props.close();
                      }}
                    >
                      <img className="w-5 h-5 " src={CloseIcon} alt="" />
                    </button>
                  </div>
                  <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center p-3 justify-center border-b border-dashed border-[#1F6700] mx-2 rounded-t ">
                      <h3 className="text-[12px] md:text-[14px] lg:text-[16px] text-[#1F6700] align-middle bg-[#03951130] p-3 rounded-xl">
                        {props.heading}
                      </h3>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {props?.isClose ? (
                <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {props?.isFrom == "Match" ? (
                    <></>
                  ) : (
                    <div className="flex items-center p-3 justify-center border-b border-dashed border-[#1F6700] mx-2 rounded-t ">
                      <h3 className="text-[12px] md:text-[14px] lg:text-[16px] text-[#1F6700] align-middle bg-[#03951130] p-3 rounded-xl">
                        {props.heading}
                      </h3>
                    </div>
                  )}

                  <>
                    <div className="flex items-center py-2 justify-center border-b border-dashed border-[#D10A11] mx-2 rounded-t ">
                      <h3 className="text-[14px] md:text-[16px] lg:text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                        {props.title}
                      </h3>
                    </div>
                    <div className="flex items-center justify-center gap-3 px-4 py-1 pt-4 mx-2 rounded-t">
                      <div>
                        <img
                          src={whatsappIcon}
                          className="w-[3rem] h-[3rem]"
                          alt=""
                        />
                      </div>
                      <div className="w-[18rem] md:w-[25rem] lg:w-[25rem]">
                        <p className="text-[#000000] text-[12px] md:text-[12px] lg:text-[12px] font-bold">
                          To get profiles in WhatsApp, add a WhatsApp enabled
                          Number. Profiles will be shared to this WhatsApp
                          Number only!
                        </p>
                      </div>
                    </div>

                    <div className="relative flex-auto p-6">
                      <form
                        onSubmit={formik?.handleSubmit}
                        className="flex flex-col "
                      >
                        <div className="flex flex-col items-center justify-center">
                          <div className="flex my-2 space-x-2 ">
                            <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[8rem]">
                              <select
                                name="whatsappisdcode"
                                id="whatsappisdcode"
                                defaultValue={"+91"}
                                value={formik?.values?.whatsappisdcode}
                                onChange={formik.handleChange}
                                className=" md:p-[0.4rem] lg:p-2.5 my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                              >
                                {dropdownData?.isdcode?.map((e, i) => (
                                  <option key={i} value={e.isdcode}>
                                    {e.isdcode} , {e.country}
                                  </option>
                                ))}
                              </select>
                            </label>

                            <label className="">
                              <input
                                name={"whatsappnumber"}
                                id={"whatsappnumber"}
                                type={"tel"}
                                maxLength={10}
                                placeholder="Enter WhatsApp Number"
                                value={formik?.values?.whatsappnumber}
                                onChange={(e) => {
                                  if (validateNumber(e.target.value)) {
                                    handleOptionChange(
                                      e,
                                      e.target.value,
                                      "input",
                                      ""
                                    );
                                    setNumberEnable(false);
                                  }
                                }}
                                onKeyPress={handleKeyPress}
                                className="no-spinner p-2 my-1 w-[11.3rem] md:w-[12.3rem] lg:w-[12.3rem] text-[13px] font-Poppins !text-[#575556] rounded-[3px] border border-[#C4C4C4] placeholder:text-[#575556] font-semibold"
                              />
                              {formik.errors.whatsappnumber && (
                                <p className="text-[#D10A11] text-[12px]">
                                  {formik.errors.whatsappnumber}
                                </p>
                              )}
                            </label>

                            <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[9rem]">
                              <select
                                name="relationId"
                                id="relationId"
                                value={
                                  formik.values.relationId
                                    ? formik?.values?.relationId
                                    :trans('family.select_relation')
                                }
                                defaultValue={null}
                                disabled={dropdownDisable}
                                onChange={formik?.handleChange}
                                className=" md:p-[0.4rem] lg:p-2.5 my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px] placeholder:text-[#575556]"
                              >
                                <option defaultValue hidden>
                                  {trans('family.select_relation')}
                                </option>
                                <option value="7" hidden>
                                  Parent
                                </option>
                                <option value="8" hidden>
                                  {trans('family.myself')}
                                </option>
                                {dropdownData?.relationShip?.map((e, i) => (
                                  <option key={i} value={e.id}>
                                    {e.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.relationId &&
                                formik.errors.relationId && (
                                  <p className="text-[#D10A11] text-[12px]">
                                    {formik.errors.relationId}
                                  </p>
                                )}
                            </label>
                          </div>
                          {isV3User && (
                            <div className="flex flex-col justify-between gap-2 md:gap-8 lg:gap-8 md:flex-row">
                              <div className="flex items-center">
                                <p className="text-gray-600 text-[14px] md:text-[12px] lg:text-[12px]">
                                  *This number will be added to your family
                                </p>
                              </div>
                              <div className="flex items-center justify-end gap-2">
                                <p
                                  className={` text-[14px] md:text-[14px] lg:text-[14px] ${numberEnable
                                    ? "text-gray-600"
                                    : "text-[#D10A11] cursor-pointer"
                                    } flex items-center gap-2`}
                                  disabled={numberEnable}
                                  onClick={(e) => {
                                    if (numberEnable == false) {
                                      submitRef.current = "addmore";
                                      formik?.submitForm();
                                    }
                                  }}
                                >
                                  <img
                                    src={AddGroupIcon}
                                    alt=""
                                    className="w-3 h-3"
                                  />{" "}
                                  Add more numbers to family
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center items-center py-[1.5rem]">
                          <hr className="w-[75%] pr-4" />
                          <div className="items-center text-[#D10A11] text-[18px] flex justify-center px-4">
                            {trans('generate_horoscope.or')}
                          </div>
                          <hr className="w-[75%] pl-4" />
                        </div>
                        <div className="">
                          <p className="text-[12px] md:text-[14px] lg:text-[14px] text-[#717171]">
                            Choose from below number or add new number
                          </p>
                          <div className="h-[15vh] overflow-y-scroll scrollbar-none">
                            <div className="flex items-center space-x-3 mb-4 bg-[#FFE4E4] p-4 w-[17rem] rounded-sm">
                              <img
                                src={DefaultIcon}
                                alt=""
                                className={"w-4 h-4"}
                              />
                              <input
                                type="radio"
                                id={"option-1"}
                                value={"option-1"}
                                checked={selectedOption == "option-1"}
                                onChange={(e) => {
                                  handleOptionChange(
                                    e,
                                    userData?.userphoneno,
                                    "radio",
                                    "Myself"
                                  );
                                  setNumberEnable(false);
                                }}
                                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 relative cursor-pointer"
                              />
                              <label
                                htmlFor={"option-1"}
                                className="ml-2 text-[13px] text-[#575556]"
                              >
                                {userData?.userphoneno} | {trans('family.myself')}
                              </label>
                            </div>

                            {groupNumber.map((element, i) => (
                              <div
                                className="flex items-center space-x-3 mb-4 bg-[#FFE4E4] p-4 w-[17rem] rounded-sm"
                                key={i}
                              >
                                <img
                                  src={DefaultIcon}
                                  alt=""
                                  className={"w-4 h-4"}
                                />
                                <input
                                  type="radio"
                                  id={"option" + i}
                                  value={"option" + i}
                                  checked={selectedOption == "option" + i}
                                  onChange={(e) => {
                                    handleOptionChange(
                                      e,
                                      element.parentsMobileNumber,
                                      "radio",
                                      element.relationshipName
                                    );
                                    setNumberEnable(false);
                                  }}
                                  className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 relative cursor-pointer"
                                />
                                <label
                                  htmlFor={"option" + i}
                                  className="ml-2 text-[13px] text-[#575556]"
                                >
                                  {element.parentsMobileNumber} |{" "}
                                  {element.relationshipName}
                                </label>
                              </div>
                            ))}
                          </div>
                          {isV3User && (
                            <div className="flex justify-end pr-4 md:pr-0">
                              <div className="flex items-center gap-2">
                                <p
                                  className={` text-[14px] ${numberEnable
                                    ? "text-gray-600"
                                    : "text-[#D10A11] cursor-pointer"
                                    } flex items-center gap-2`}
                                  disabled={numberEnable}
                                  onClick={(e) => {
                                    if (numberEnable == false) {
                                      submitRef.current = "addmore";
                                      formik.submitForm();
                                    }
                                  }}
                                >
                                  <img
                                    src={AddGroupIcon}
                                    alt=""
                                    className="w-3 h-3"
                                  />{" "}
                                  Add more numbers to family
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center pt-3">
                          <button
                            disabled={numberEnable}
                            type="submit"
                            className={`text-[#FFFFFF] bg-[#D10A11] text-[12px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] w-[5rem] md:w-[5rem] lg:w-24 cursor-pointer font-segeo font-semibold ${numberEnable
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                              }`}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const CommonCastPopup = ({ close, profileDate }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { loginDetail } = useContext(LoginContext);
  const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);
  const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);
  const [casteName, setCasteName] = useState([]);
  const dispatch = useDispatch();
  const { casteList, subcasteList, domainList } = useSelector((state) => ({
    casteList: state?.Home?.cList?.data?.data?.castes ?? [],
    subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
  }));
  const isLoading = casteList.length == 0;
  console.log(showOtherCasteInput, "showOtherCasteInput");
  console.log(close, "close");

  const { t:trans } = useTranslation();


  const othervalue = casteList[0]?.name === "Others";

  console.log(othervalue, "othervalue");

  const [inputCasteValue, setInputCasteValue] = useState("");
  const [matchedCastes, setMatchedCastes] = useState([]);

  const rearrangeOthersToLast = (list) => {
    const othersOption = list.find(
      (option) => option.name.toLowerCase() === "others"
    );
    const filteredList = list.filter(
      (option) => option.name.toLowerCase() !== "others"
    );

    if (othersOption) {
      othersOption.className = "dropdown-option-others"; // Optionally add a className
      return [...filteredList, othersOption];
    }
    return filteredList;
  };

  const handleCasteSelection = (selectedCaste) => {
    setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("casteId", selectedCaste.id);
    dispatch(registerSubCasteList(selectedCaste.id));
  };

  const handleSubCasteSelection = (selectedSubCaste) => {
    setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
  };

  useEffect(() => {
    if (profileDate) {
      dispatch(
        registerCasteList(
          profileDate?.mothertongueid,
          profileDate?.religionId ? profileDate?.religionId : ""
        )
      );
      dispatch(
        registerSubCasteList(profileDate?.caste ? profileDate?.caste[0] : "")
      );
    }
  }, [dispatch, profileDate]);

  const casteOptions = useMemo(
    () => rearrangeOthersToLast(casteList),
    [casteList]
  );

  const subcasteOptions = useMemo(
    () => rearrangeOthersToLast(subcasteList),
    [subcasteList]
  );
  console.log(profileDate, "bbnn");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      casteId: profileDate?.cleanclearcaste?.caste
        ? profileDate?.cleanclearcaste?.caste[0]
        : "",
      subcaste: profileDate?.cleanclearcaste?.subcaste
        ? profileDate?.cleanclearcaste?.subcaste[0]
        : "",
      otherSubcaste: profileDate?.otherSubcaste ?? "",
      otherCaste: profileDate?.otherCaste ?? "", 
    },
    validationSchema: Yup.object().shape({
      casteId: Yup.string()
        .notOneOf([Constants.casteNotKnowId], "Please select caste")
        .required("Please select caste"),
      otherCaste: Yup.string().when("casteId",
        (cast, schema) => {
          if (selectedCaste?.name.trim() == "Others") {
            return schema
              .required('Please select othercaste')
              .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
              .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))
          } else {
            return schema;
          }
        }
      ),
      otherSubcaste: Yup.string().when("subcaste",
        (subcast, schema) => {
          if (selectedSubCaste?.name.trim() == "Others") {
            return schema
              .required('Please select subcaste')
              .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
              .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))
          } else {
            return schema;
          }
        }
      ),
    }),
    onSubmit: (values) => {
      console.log("ReligionMigrationPopup", values);
      apiCallSubmit(values);
    },
  });

  useEffect(() => {
    setInputCasteValue(formik.values.otherCaste);
    setInputSubCasteValue(formik.values.otherSubcaste);
  }, [formik.values.otherCaste, formik.values.otherSubcaste]);

  console.log(formik.values.otherSubcaste, formik.values.otherCaste, "formik.values.otherSubcaste");
  const handleCasteInputChange = (e) => {
    const CasteValue = e.target.value;
    const CasteInput = CasteValue.replace(/^\s+/, '')                // Remove leading spaces
      .replace(/[^\w\s]/gi, '')            // Remove special characters
      .replace(/  +/g, ' ')                // Replace multiple spaces with a single space
      .replace(/[^a-zA-Z0-9 .]/g, '');
    setInputCasteValue(CasteInput);
    formik.setFieldValue("otherCaste", CasteValue);

    console.log(CasteValue, "CasteValue");

    if (CasteValue.trim() === "") {
      // Clear matched castes when input value is empty
      // setMatchedCastes([]);
    } else {
      // Filter caste names starting with the input value
      const filteredCastes = casteList?.filter((caste) =>
        caste.name.toLowerCase().includes(CasteValue.toLowerCase())
      );

      // Check if "Others" is present in the filtered castes
      const othersCaste = filteredCastes.find(
        (caste) => caste.name.toLowerCase() === "others"
      );

      // Remove "Others" from the filtered castes if present
      const filteredCastesWithoutOthers = othersCaste
        ? filteredCastes.filter(
          (caste) => caste.name.toLowerCase() !== "others"
        )
        : filteredCastes;

      // Sort the filtered castes alphabetically
      const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Add "Others" as the last option if present
      const finalFilteredCastes = othersCaste
        ? [...sortedFilteredCastes, othersCaste]
        : sortedFilteredCastes;

      setMatchedCastes(finalFilteredCastes);
    }
  };

  const [inputSubCasteValue, setInputSubCasteValue] = useState("");
  const [matchedSubCastes, setMatchedSubCastes] = useState([]);

  const handleSubCasteInputChange = (e) => {
    const SubCasteValue = e.target.value;
    const SubCasteInput = SubCasteValue.replace(/^\s+/, '')                // Remove leading spaces
      .replace(/[^\w\s]/gi, '')            // Remove special characters
      .replace(/  +/g, ' ')                // Replace multiple spaces with a single space
      .replace(/[^a-zA-Z0-9 .]/g, '');
    setInputSubCasteValue(SubCasteInput);
    formik.setFieldValue("otherSubcaste", SubCasteInput); // Ensure correct field name here

    console.log("SubCasteValue", SubCasteInput);
    if (SubCasteValue.trim() === "") {
      formik.setFieldValue("otherSubcaste", "");
      setMatchedSubCastes([]);
    } else {
      const filteredSubCastes = subcasteList?.filter((subcaste) =>
        subcaste.name.toLowerCase().includes(SubCasteValue.toLowerCase())
      );
      setMatchedSubCastes(filteredSubCastes);
    }
  };

  const apiCallSubmit = async (values) => {
    console.log(values, "valuesvalues");
    const request = {
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
      casteId: values.casteId,
      subcasteId: values?.subcaste ? values?.subcaste : undefined,
      otherCaste: values.otherCaste,
      othersubcaste: values.otherSubcaste,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getCasteEdit()}`,
      request
    );

    if (statusCode == 200) {
      window.location.reload()
      close();
    }
  };

  const subcasteAvailable = subcasteList?.length !== 0;

  const selectedCaste = useMemo(() => {
    return casteList
      ?.filter((option) => option.id === formik.values.casteId)
      ?.at(0);
  }, [casteList, formik.values.casteId]);

  const selectedSubCaste = useMemo(() => {
    return subcasteList
      ?.filter((option) => option.id === formik.values.subcaste)
      ?.at(0);
  }, [subcasteList, formik.values.subcaste]);

  console.log(selectedSubCaste?.name, "selectedCaste");

  const apiCallConfirm = async () => {
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.confirmcasteinfosaveUrl()}`,
      {
        isconfirmed: "Yes",
      }
    );

    if (statusCode == 200) {
      close();
    }
  };

  const onConfirm = () => {
    if (formik.values.casteId == Constants.casteNotKnowId) {
      setIsEdit(true);
    } else {
      formik.submitForm();
    }
    close();
    apiCallConfirm()
  };

  const isOthersPresent = subcasteList.some(
    (subcaste) => subcaste.name === "Others"
  );

  console.log(isOthersPresent, "isOthersPresent");

  const onCancel = () => {
    setIsEdit(false);
  };

  const [name, setname] = useState(false);

  console.log(name, "namename");

  const handleOtherSubcasteChange = (e) => {
    const value = e.target.value
      .replace(/^\s+/, '')                // Remove leading spaces
      .replace(/[^\w\s]/gi, '')            // Remove special characters
      .replace(/  +/g, ' ')                // Replace multiple spaces with a single space
      .replace(/[^a-zA-Z0-9 ]/g, '');      // Ensure only alphanumeric and spaces

    formik.setFieldValue("otherSubcaste", value);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
      <div className="relative w-auto mx-6 my-6">
        <div className=""></div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {trans('clean__clear_popup.please_confirm_caste_details')}
            </h3>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32">
              <Loader />
            </div>
          ) : (
            <div className="relative flex-auto p-6">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col items-center ">
                  <div className="flex flex-col w-full space-y-2">
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        {trans('clean__clear_popup.caste')}
                      </label>
                      {isEdit ? (
                        <RawSelectInput
                          name="casteId"
                          title={trans('view_profile.caste')}
                          value={selectedCaste}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("casteId", selected.id);
                            // formik.setFieldValue("subcaste", "");
                            // formik.setFieldValue("otherSubcaste", "");
                            // formik.setFieldValue("otherCaste", );
                            dispatch(registerSubCasteList(selected.id));
                            setShowOtherCasteInput(
                              selected.id === Constants.casteOtherId
                            );
                          }}
                          error={formik.errors.casteId}
                          options={[
                            {
                              id: null,
                              name: "Select Caste",
                              isDisabled: true,
                            },
                            ...casteOptions.map((option) => ({
                              ...option,
                              className: option.name.includes("Others")
                                ? "dropdown-option-others"
                                : "",
                            })),
                          ]}
                          required={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />
                      ) : (
                        // <label className="font-segeo text-[14px] font-semibold">
                        // {(() => {
                        //   const cleanclearcaste = profileDate?.cleanclearcaste;
                        //   if (Array.isArray(cleanclearcaste)) {
                        //     for (const item of cleanclearcaste) {
                        //       if (Array.isArray(item?.caste) && item.caste[0]?.name != "Others") {
                        //         return item.caste[0].name;
                        //       } else if (item.caste[0].name === "Others") {
                        //         return item.othercaste ?? trans('my_profile.not_specified');
                        //       }
                        //     }
                        //   }
                        //   return trans('my_profile.not_specified');
                        // })()}
                        // </label>

                        <label className="font-segeo text-[14px] font-semibold">
                          {(() => {
                            const cleanclearcaste =
                              profileDate?.cleanclearcaste;
                            if (Array.isArray(cleanclearcaste)) {
                              for (const item of cleanclearcaste) {
                                if (
                                  Array.isArray(item?.caste) &&
                                  item.caste[0]?.name === "Others"
                                ) {
                                  // Handle "Others" case here
                                  return item?.othercaste || trans('my_profile.not_specified');
                                } else if (
                                  Array.isArray(item?.caste) &&
                                  item.caste[0]?.name
                                ) {
                                  // Display the caste name if it exists
                                  return item.caste[0].name;
                                }
                              }
                            }
                            return trans('my_profile.not_specified');
                          })()}
                        </label>
                      )}
                    </div>

                    {formik.values.casteId == "433" && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            {trans('clean__clear_popup.other_caste')}
                          </p>
                        </div>
                        <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="othercaste"
                              autoComplete="off"
                              placeholder="Enter Your Caste"
                              className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
                              value={inputCasteValue}
                              // value={formik.values.otherCaste}
                              // onChange={formik.handleChange}
                              // onChange={handleCasteInputChange}
                              onChange={(e) => {
                                // formik.setFieldValue("casteId", "");
                                // formik.setFieldValue("subcaste", "");
                                // formik.setFieldValue("otherSubcaste", "");
                                // formik.setFieldValue("otherCaste",e.id)
                                handleCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              error={formik.errors.otherCaste}
                            />
                            {inputCasteValue?.trim() !== "" &&
                              matchedCastes?.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedCastes.map((caste) => (
                                      <li
                                        key={caste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleCasteSelection(caste)
                                        }
                                      >
                                        {caste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            {formik.errors?.otherCaste &&
                              formik?.touched?.otherCaste && (
                                <p className="text-[red] text-[12px]">
                                  {formik.errors?.otherCaste}
                                </p>
                              )}{" "}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        {trans('clean__clear_popup.sub_caste')}
                      </label>
                      {isEdit ? (
                        subcasteAvailable ? (
                          <RawSelectInput
                            name="subcaste"
                            title="SubCaste"
                            value={selectedSubCaste}


                            onChange={(selected, ev) => {
                              formik.setFieldValue("subcaste", selected.id);

                              setShowOtherSubCasteInput(
                                selected.name === "Others"
                              );
                            }}
                            error={formik.errors.subcaste}
                            options={[
                              {
                                id: null,
                                name: "Select SubCaste",
                                isDisabled: true,
                              },
                              ...subcasteOptions,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            getOptionClassName={(option) =>
                              option.name.toLowerCase() === "others"
                                ? "dropdown-option-others"
                                : ""
                            }
                          />
                        ) : (
                          <>
                            <input
                              type="text"
                              value={formik.values.otherSubcaste}
                              onChange={handleOtherSubcasteChange}
                              name="otherSubcaste"
                              className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
                            />

                          </>
                        )
                      ) : (

                        <label className="font-segeo text-[14px] font-semibold">
                          {(() => {
                            const cleanclearcaste =
                              profileDate?.cleanclearcaste;
                            if (Array.isArray(cleanclearcaste)) {
                              for (const item of cleanclearcaste) {
                                if (
                                  Array.isArray(item?.subcaste) &&
                                  (item.subcaste.length === 0 ||
                                    item.subcaste[0]?.name === "Others")
                                ) {
                                  // Handle "Others" case or null subcaste here
                                  return item?.othersubcaste || trans('my_profile.not_specified');
                                } else if (
                                  Array.isArray(item?.subcaste) &&
                                  item.subcaste[0]?.name
                                ) {
                                  // Display the subcaste name if it exists
                                  return item.subcaste[0].name;
                                }
                              }
                            }
                            return trans('my_profile.not_specified');
                          })()}
                        </label>
                      )}
                    </div>

                    {selectedSubCaste?.name == "Others" && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            {trans('clean__clear_popup.other_sub_caste')}
                          </p>
                        </div>
                        <div className="col-span-7 pl-2 mt-2 md:mt-5 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="otherSubcaste"
                              autoComplete="off"
                              placeholder="Enter Your Sub-Caste"
                              className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                              value={inputSubCasteValue}
                              onChange={(e) => {
                                // formik.setFieldValue("casteId", "");
                                // formik.setFieldValue("subcaste", "");
                                // formik.setFieldValue("otherCaste", );
                                // formik.setFieldValue("otherSubcaste",e.id)
                                handleSubCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              // onChange={handleSubCasteInputChange}
                              error={formik.errors.otherSubcaste}
                            />

                            {inputSubCasteValue?.trim() !== "" &&
                              matchedSubCastes.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedSubCastes.map((subcaste) => (
                                      <li
                                        key={subcaste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleSubCasteSelection(subcaste)
                                        }
                                      >
                                        {subcaste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            {formik.errors?.otherSubcaste &&
                              formik?.touched?.otherSubcaste && (
                                <p className="text-[red] text-[12px]">
                                  {formik.errors?.otherSubcaste}
                                </p>
                              )}{" "}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isEdit ? (
                    <div className="flex w-full mt-8 space-x-8">
                      <button
                        disabled={formik.isSubmitting}
                        type="submit"
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
                      >
                        {formik.isSubmitting ? trans('my_profile.saving') :trans('clean__clear_popup.save')}
                      </button>
                    </div>
                  ) : (
                    <div className="flex w-full px-4 mt-8 space-x-8">
                      <button
                        disabled={formik.isSubmitting}
                        onClick={onConfirm}
                        type="button"
                        className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        {trans('caste_restrurcture.confirm')}
                      </button>
                      <button
                        onClick={() => setIsEdit(true)}
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        {trans('caste_restrurcture.edit')}
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AddFamilyMemberPopup = (props) => {

  const dropdownData = useSelector(
    (state) => state?.Home?.rData?.data,
  );
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  
  const closePopupRef = useRef();
  const dispatch = useDispatch();
  const { t:trans } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const [parentError, setParentError] = useState("");
  const [maxError, setMaxError] = useState("");

  const [familyData, setFamilyData] = useState([]);
  const [addMoreData, setAddMoreData] = useState([])

  const familyDataLength = familyData?.length;
  const isParentNumberAvailable = userData?.parentsNumberAvailable == "No";


  useEffect(() => {
    fetchGroupData()
    dispatch(registerDetail());
  }, [])

  const fetchGroupData = async () => {
    const profileId = userData?.profileId;
    if (!profileId) {
      console.error('ProfileId not available');
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );


    if (statusCode === 200) {
      if (data.status === 'Success') {
        setFamilyData(data.data.groupMembers);
      }
    }
  }

  const addedNumber = [
    ...familyData?.map(data => data?.parentsMobileNumber),
    ...addMoreData
  ]



  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        if (window.innerWidth < 768) {
          if (isSuccess) {
            props.close("success");
          } else {
            props.close()
          }
        }
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const initialValues = {
    fields: [
      {
        isdcode: "+91",
        relationid: "",
        number: "",
        parentcheck: false
      }
    ],
  };

  const validationSchema = Yup.object({
    fields: Yup.array().of(
      Yup.object({
        relationid: Yup.string().required(trans('family.please_select_relation')),
        number: Yup.string()
          .test("india", "number should have 10 digits", function (value, fieldData) {
            const { isdcode } = this.parent;
            if (isdcode === '+91') {
              return !value || value.length === 10 ? true : fieldData.createError({ message: trans('add_members_popup.number_should_have_10_digits'), path: fieldData?.path });
            }
            return true;
          }).test("other", "Please enter valid Phone Number", function (value, fieldData) {
            const { isdcode } = this.parent;
            if (isdcode !== '+91') {
              return !value || (value.length >= 7 && value.length <= 15) ? true : fieldData.createError({ message: trans('add_members_popup.please_enter_valid_phone_numbe'), path: fieldData?.path });
            }
            return true;
          })
          .notOneOf(addedNumber, "Mobile Number Already Exist")
          .required(trans('add_members_popup.please_enter_phone_number')),
      })
    ).test('unique', 'Duplicate numbers are not allowed', (value, fieldData) => {

      let arrayofvalues = value?.map(data => data?.number);

      const duplicates = arrayofvalues.filter((item, index) => arrayofvalues.indexOf(item) < index);

      if (duplicates.length > 0) {
        setParentError("Mobile Number Already Exist")
        return false;
      } else {
        setParentError("")
        return true;
      }

    })
    ,
  });

  const handleSubmit = async (values) => {

    var count = 0;

    setParentError("")
    values?.fields?.forEach((val) => {

      if (val?.parentcheck == true) {
        count = count + 1;
      }

    })
    if (count > 1) {
      setParentError("Please Check Only One Parent Checkbox");
      return;
    }

    if (isParentNumberAvailable) {
      let containsParent = values?.fields?.filter(val => val.parentcheck == true)
      if (containsParent?.length == 0) {
        addfamilymember(values?.fields);
      } else {
        values?.fields?.forEach((val) => {
          if (val?.parentcheck == true) {
            duplicateParentsNumber(val?.number, values?.fields)
          }
        })
      }
    } else {
      addfamilymember(values?.fields);
    }

  }

  const duplicateParentsNumber = async (number, fields) => {

    let request = {
      parentsMobileNumber: number
    };

    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateParentsNumberUrl()}`,
      request
    );
    if (data.data.status === "Success") {
      if (data.data.data.Messagge) {
        setParentError(data.data.data.Messagge);
        return;
      } else {
        setParentError("");
        setLoading(true);
        setShow(false);
        addfamilymember(fields);
      }
    }
  }

  console.log(props.microisFrom,'props.microisFrom');
  

  const addfamilymember = async (values) => {

    let famarray = [];

    values.map((member) => {
      famarray.push({
        kmcmessageid: props?.messageId,
        fromchat: props?.from === "family-ad" ? "Y" : null,
        parentsMobileNumber: member.number,
        parentsIsdcode: member.isdcode,
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        relationshipId: member.relationid,
        isParentsNumber: member.parentcheck ? member.parentcheck : false,

      })

    })

    const request = {
      familyGroup: famarray,
      ...(props.microisFrom == "microParentsNumber" && {microtrackId: props?.microtrackidData})

    };

    try {
      const response = await POST(
        `${config.api.API_URL}${EndPoints.createfamilygroupUrl()}`,
        request
      );

      console.log('API Response:', response);

      if (response.statusCode === 200) {
        setLoading(false);
        setIsSuccess(true);
        setShow(false);

      } else if (response.statusCode === 401) {

      } else {
        console.error(`Unexpected status code: ${response.statusCode}`);

      }
    } catch (error) {
      console.error('Error during API call:', error);

    }
  };

  return (
    <>
      {loading ?
        <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
        :
        isSuccess ?
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
            <div className="relative w-auto mx-6 my-6" ref={closePopupRef}>
              <div className="hidden md:block lg:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                  onClick={() => {
                    props.close("success");
                  }}
                >
                  <img className="w-5 h-5 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center py-1 mx-2 rounded-t ">
                  <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">

                  </h3>
                </div>
                <div className='relative flex-auto p-6'>
                  <div className='flex flex-col justify-center items-center w-[60vw] md:w-[30vw] '>
                    <span className='text-[13px] md:text-[16px] lg:text-[16px] font-semibold text-center'>
                      {trans('family_invite_success_popup.family_members_added_successfu')}
                    </span>
                    <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <></>
      }
      {show &&
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center lg:items-center focus:outline-none">
          <div className="relative lg:w-[50vw] md:w-[70vw] w-full md:my-6 lg:my-6 mx-6" ref={closePopupRef}>
            <div className="hidden md:block lg:block">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                onClick={() => { props.close(); }}
              >
                <img className="w-5 h-5 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="rounded-t-[3rem] md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-start px-4 pt-8 mx-2 rounded-t ">
                <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                  {props.title}
                </h3>
              </div>
              <div className='relative flex-auto px-6 pt-3 pb-6'>
                <div className='items-center flex-auto'>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, touched, handleChange, setFieldValue, validateForm, setFieldError, setFieldTouched }) => (
                      <Form>
                        <FieldArray name="fields">
                          {({ insert, remove, push }) => (
                            <div>
                              <div className={`overflow-y-auto  ${values.fields.length > 3 ? "h-[17rem] md:h-[17rem] lg:h-[17rem] xl:h-[17rem] lite-scrollbar" : "h-auto md:h-auto lg:h-auto xl:h-auto scrollbar-none"} `}>
                                {values?.fields?.length > 0 ?
                                  values.fields.map((field, index) => (
                                    <div key={index} className="py-2">
                                      <div className="items-center justify-center flex-1">
                                        <div className="flex my-2 space-x-2 ">
                                          <label className="selectheight font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[9rem] xl:w-[10rem] 2xl:w-[10rem]">
                                            <select
                                              name={`fields.${index}.isdcode`}
                                              defaultValue={"+91"}
                                              onChange={handleChange}
                                              className="!min-h-[2.8rem] my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                                            >
                                              {dropdownData?.isdcode?.map((e, i) => (
                                                <option key={i} value={e.isdcode}>
                                                  {e.isdcode} , {e.country}
                                                </option>
                                              ))}
                                            </select>
                                            {errors.fields?.[index]?.isdcode && touched.fields?.[index]?.isdcode ? (
                                              <p className="text-[#D10A11] text-[12px]">{errors.fields[index].isdcode}</p>
                                            ) : null}
                                          </label>

                                          <label className="flex-1 w-full">
                                            <input
                                              name={`fields.${index}.number`}
                                              value={field.number}
                                              type={"tel"}
                                              onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                  handleChange(e)
                                                }
                                                setParentError("")
                                              }}
                                              placeholder={trans('family.enter_phone_number')}
                                              className="truncate no-spinner p-3 my-1 w-[11.3rem] md:w-full  text-[13px] font-Poppins !text-[#575556]  rounded-[3px] border border-[#C4C4C4] placeholder:!text-[#575556] font-semibold"
                                            />
                                            {errors.fields?.[index]?.number && touched.fields?.[index]?.number ? (
                                              <p className="text-[#D10A11] text-[12px]">{errors.fields[index].number}</p>
                                            ) : null}
                                          </label>

                                          <label className="selectheight select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[12rem]">
                                            <select
                                              name={`fields.${index}.relationid`}
                                              defaultValue={null}
                                              onChange={(e) => {
                                                if (isParentNumberAvailable) {
                                                  if (e.target.value == "5" || e.target.value == "6") {

                                                    setFieldValue(`fields.${index}.parentcheck`, true);

                                                    values?.fields.map((val, indexes) => {
                                                      if (indexes != index) {
                                                        setFieldValue(`fields.${indexes}.parentcheck`, false);
                                                      }
                                                    })


                                                  } else {
                                                    setFieldValue(`fields.${index}.parentcheck`, false);
                                                  }
                                                }
                                                setParentError("")
                                                handleChange(e)
                                              }}
                                              value={field.relationid ?? trans('family.select_relation')}
                                              className="truncate !min-h-[2.8rem] my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  placeholder:text-[#575556]"
                                            >
                                              <option defaultValue hidden>{trans('family.select_relation')}</option>
                                              {dropdownData?.relationShip?.map((e, i) => (
                                                <option key={i} value={e.id}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                            {errors.fields?.[index]?.relationid && touched.fields?.[index]?.relationid ? (
                                              // <p className="text-[#D10A11] text-[12px] font-medium">{errors.fields[index].relationid}</p>
                                              <div className="max-w-[2rem] md:max-w-full lg:max-w-full">
                                              <OverflowToolTip as="p" className="text-[#D10A11] text-[12px] font-medium truncate">{errors.fields[index].relationid}</OverflowToolTip>
                                              </div>
                                            ) : null}
                                          </label>
                                        </div>
                                      </div>
                                      {isParentNumberAvailable &&
                                        <div className={`flex justify-between gap-2 md:gap-8 lg:gap-8 flex-col md:flex-row ${field.parentcheck ? "" : field?.relationid == "5" || field?.relationid == "6" ? "" : "hidden"}`}>
                                          <div className='flex items-center gap-2'>
                                            <input
                                              type="checkbox"
                                              name={`fields.${index}.parentcheck`}
                                              checked={field.parentcheck}
                                              onChange={(e) => {
                                                // setFieldValue(`fields.${index}.parentcheck`, false);
                                                values?.fields.map((val, indexes) => {
                                                  if (indexes != index) {
                                                    setFieldValue(`fields.${indexes}.parentcheck`, false);
                                                  } else {
                                                    setFieldValue(`fields.${index}.parentcheck`, true);
                                                  }
                                                })
                                                setParentError("")
                                                handleChange(e)
                                              }}
                                              className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative cursor-pointer`}
                                            />
                                            <p className="text-gray-600 text-[14px] md:text-[12px] lg:text-[12px]" >
                                              {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                                            </p>
                                          </div>
                                        </div>
                                      }

                                    </div>
                                  )) : <Loader />}
                              </div>
                              <div className="flex justify-end">
                                <div className="flex items-center gap-2">
                                  <p className={` text-[13px] text-gray-600" text-[#D10A11] cursor-pointer flex items-center gap-2 font-semibold`}
                                    onClick={async () => {
                                      const errors = await validateForm();

                                      if (Object.keys(errors).length == 0 || errors?.fields?.length == 0) {
                                        if ((familyDataLength + values.fields.length) < 6) {
                                          push({ isdcode: "+91", number: "", relationid: "" })
                                        } else {
                                          setMaxError(trans('family.you_can_add_upto_number_family',{number : 6}));
                                        }
                                      } else if ((!(familyDataLength + values.fields.length < 6))) {
                                        setMaxError(trans('family.you_can_add_upto_number_family',{number : 6}));
                                      } else {
                                        if ((familyDataLength + values.fields.length) < 6) {
                                          errors?.fields?.forEach((field, index) => {
                                            if (field !== undefined) {
                                              setFieldError(`fields.${index}.relationid`, field?.relationid);
                                              setFieldError(`fields.${index}.number`, field?.number);
                                              setFieldTouched(`fields.${index}.relationid`, true);
                                              setFieldTouched(`fields.${index}.number`, true);
                                            }
                                          })
                                        } else {
                                          setMaxError(trans('family.you_can_add_upto_number_family',{number : 6}));
                                        }
                                      }
                                    }
                                    }
                                  >
                                    <img src={AddGroupIcon} alt="" className="w-4 h-4" />
                                    {trans('add_members_popup.add_more_members')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                        {parentError && <p className="text-[#D10A11] text-[12px]">{parentError}</p>}
                        {maxError && <p className="text-[#D10A11] text-[12px]">{maxError}</p>}
                        <div className="flex items-center justify-center py-4 space-x-8 ">
                          <button
                            type="submit"
                            className="flex items-center cursor-pointer gap-2 justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                          >
                            <span>{trans('family.send_invite')}
                            </span> <img alt="" className="w-4 h-4" src={Inviteicon} />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

const ReInviteModal = ({ onClose, handleSmsShare, handleWhatsAppShare, userData, parentsNumber }) => {

  console.log(userData?.reinvitecontent, "reinvitecontent");

  const { t:trans } = useTranslation();

  const reinvitecontent = userData?.reinvitecontent;




  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none">
      <div
        className="relative lg:w-[25vw] md:w-[39vw] mb-[3rem] md:mb-0 w-[90vw] modal-container md:my-6 md:mx-6"
      >

        <div className="border-0 rounded-md shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto px-4 py-8 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-2xl">
            <div className="flex flex-col">
              <div className="grid md:grid lg:flex grid-cols-3 gap-5 items-center pt-[0.5rem]">
                <div className="flex lg:hidden">
                  <button
                    // onClick={handleSmsShare}
                    onClick={() =>
                      window.open(
                        Constants.kalyanSupportMessageLink(
                          parentsNumber, reinvitecontent
                        )
                      )
                    }

                    className="flex flex-col items-center w-full text-white cursor-pointer"
                  >
                    <img className="w-[2rem] h-[2rem]" src={messageicon} alt="" />
                    <p className="text-[#000000] font-medium   font-Poppins pt-1 text-[8px]">Messages</p>
                  </button>
                </div>
                <div className="flex flex-col items-center w-full text-white cursor-pointer ">
                  <button
                    // onClick={handleWhatsAppShare}
                    onClick={() =>
                      window.open(
                        Constants.kalyanSupportWhatsappLink(
                          parentsNumber, reinvitecontent
                        )
                      )
                    }


                    className="flex flex-col items-center cursor-pointer "
                  >
                    <img className="w-[2rem] h-[2rem]" src={whatsappIcon} alt="" />
                    <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">WhatsApp</p>
                  </button>

                </div>
                <div className="flex flex-col items-center block w-full text-white cursor-pointer lg:hidden">
                  <button
                    onClick={() =>
                      window.open(
                        `mailto:?subject=Join Kalyan Matrimony&body=${reinvitecontent}`
                      )
                    }
                    className=""
                  >
                    <img className="w-[2rem] h-[2rem]" src={emailIcon} alt="" />
                    <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">Gmail</p>
                  </button>
                </div>
                <LoginSocialFacebook
                  isOnlyGetToken
                  appId="606506484678232"
                  onResolve={({ provider, data }) => {
                    // getPhotos(data?.accessToken);
                    // setToken(data?.accessToken);
                    // setProvider("facebook")
                  }}
                  scope="user_photos"
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <div className="flex flex-col items-center block w-full text-white cursor-pointer lg:hidden" onClick={() => { }}>
                    <img src={facebookIcon} className="w-[2rem] h-[2rem]" loading="lazy" alt="" />
                    <p className="text-[#000000] font-medium   font-Poppins pt-1 text-[8px]">
                      <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct"
                        className="cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >Facebook</a>
                    </p>
                  </div>
                </LoginSocialFacebook>

                <InstagramLogin
                  clientId="266374166312609"
                  buttonText={trans('photo_popup.upload_from_instagram')}
                  scope="user_profile,user_media"
                  cssClass="lg:hidden h-[35px] text-[10px] md:text-[12px] lg:text-[14px] text-[white] font-bold cursor-pointer rounded-xl"
                  onSuccess={(response) => {
                    console.log("LoginSocialInstagram", response);
                    // getInstaAccessToken(response)
                    // setProvider("instagram")
                  }}
                  onFailure={(data) => {
                    console.log("LoginSocialInstagram - fail", data);
                  }}
                >
                  <div className="flex flex-col items-center block w-full text-white cursor-pointer" onClick={() => { }}>
                    <img src={instragramIcon} className="w-[2rem] h-[2rem]" loading="lazy" alt="" />

                    <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">
                      <a href="https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0"
                        className="cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >Instagram
                      </a>
                    </p>

                  </div>
                </InstagramLogin>
              </div>


            </div>
            <div className="border-t mt-[2rem]">
              <button
                onClick={onClose}
                className="mx-auto flex items-center cursor-pointer justify-center  text-[#D10A11]  whitespace-nowrap rounded-[5px] font-segoe font-semibold pt-[1rem] md:text-[12px] lg:text-[12px]"
              >
                {trans('family.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}


const RemovePopup = ({ title, OnClose, parentsNumber, relationshipid, setIsLoading, apiCallFamilyData }) => {
  const popupRef = useRef(null);

  const handleRemoveFamilyNumber = async () => {
    setIsLoading(true);
    try {
      const request = {
        parentsMobileNumber: parentsNumber,
        relationshipId: relationshipid,
      };

      console.log('Remove Family Request:', request);

      const response = await POST(
        `${config.api.API_URL}${EndPoints.removefamilygroupUrl()}`,
        request
      );

      console.log('API Response:', response);

      if (response.statusCode === 200) {
        // Handle success

        apiCallFamilyData();
      } else if (response.statusCode === 401) {
        // Handle unauthorized error
      } else {
        console.error(`Unexpected status code: ${response.statusCode}`);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setIsLoading(false); // Ensure loading state is cleared
      OnClose();
    }
  };

  // const handleClickOutside = (event) => {
  //   if (
  //     popupRef.current &&
  //     !popupRef.current.contains(event.target) &&
  //     window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
  //   ) {
  //     OnClose();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const { t:trans } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%] md:w-[55%] lg:w-[35%] md:my-6 md:mx-6"
        ref={popupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={OnClose}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <p className=" font-semibold text-[#1A1A1A] text-[16px]">
                {title}
              </p>

              <p className="text-[12px] text-[#7A7A7A] pt-[0.6rem]">{trans('family.do_you_want_to_remove_the_fami')}</p>
            </div>

            <div className="flex py-3 justify-center gap-5 pt-[2rem]">
              <button
                onClick={OnClose}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                {trans('family.cancel')}
              </button>

              <button
                onClick={()=>{handleRemoveFamilyNumber()}}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] bg-[#D10A11] xl:w-[10rem] text-[#ffffff] rounded-[4px] border border-[#D10A11]"
              >
                {trans('family.submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrailNotificationPopup = ({close , continueButton}) => {

  const { t:trans } = useTranslation();
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });


  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="space-y-2">
              <div className="text-center flex justify-center">
              <img src={Images.successImage} className="text-center"/>
              </div>
              <p className="text-[#7A7A7A] text-[13px] text-center pb-2">
                {trans('trial_pack_popup__2nd_popup.notifications_turned_on_succes')}
              </p>
            </div>

          
            <div className="flex justify-center gap-5 pt-4">
            
              <button
                onClick={() => continueButton()}
                className="text-[13px] font-bold text-[#FFFFFF] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem]  rounded-[4px] border border-[#D10A11] bg-[#D10A11]"
              >Continue
               
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonQualityPopup = ({ OnClose  ,ShowpopupData ,message ,ShowpopupDataNotification,KMNotificationId, messageTypeId, kmcareId}) => {

  const popupRef = useRef(null);
  const [packdata, setPackData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completeNow, setCompleteNow] = useState(false)
  const [openpopup, setopenpopup] = useState(true)

  console.log(ShowpopupDataNotification,'openpopupopenpopup');
  
  // const { openpopup, setopenpopup } = useContext(NavBarContext);

  const isMobile = useMediaQuery({ maxWidth: 678 });
  const { loginDetail, logout } = useContext(LoginContext);

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const {initialMatchDataPopup} = useSelector(MatchSelector.getRecommendedMatches)

  const  liteNotification = initialMatchDataPopup?.currentMatch?.matchdata?.popup[0]?.notificationId;

  const { onClickTrack } = useAnalytics();


  const { t:trans } = useTranslation();

console.log(userData?.profileId,message,'userData7');

  useEffect(() => {
    if (isMobile) {
      let handler = (e) => {
        if (!popupRef.current.contains(e.target)) {
          OnClose()
        }
      }

      if (openpopup) {
        document.addEventListener('mousedown', handler)

        return () => {
          document.removeEventListener('mousedown', handler)
        }

      }
    }
  })

  const handelCompleteClick = () => {
    setCompleteNow(true)
    setopenpopup(false)
  }

  const onCompleteNow = async (data) => {

    let notificationId = null;

    if(message){
      notificationId = message;
    }else if(liteNotification){
      notificationId = liteNotification;
    }else if(userData?.popup[0]?.notificationId){
      notificationId = userData?.popup[0]?.notificationId;
    }else{
      notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    }

    console.log('props.isFromTrailPopup',notificationId);


    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": true,
      "notificationId": notificationId,

    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }

  const [showdatas,setShowDatas] = useState([])

  console.log(showdatas,'showdatas12');
  
//   useEffect(() => {
//     // Define the function to fetch data
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(EndPoints.trialpackbenefitDetails());
//         if (response.status === 200 && response.data.status === "Success") {
//           setPackData(response.data.data);
//         } else {
//           setError('Failed to fetch data');
//         }
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };


// const apiCallSameDay = async () => {
//   let request = {
//     email: loginDetail()[0],
//     userId: loginDetail()[1],
   
//     loginFrom: localStorage.getItem(
//       Constants.loginLocalStorageKeys.loginDevice
//     ),
//     profileId:userData?.profileId,
//   };

//   let { statusCode, data } = await POST(
//     `${config.api.API_URL}${EndPoints.samedaypopuptrackUrl()}`,
//     request
//   );

//   if (statusCode === 200) {
//     setShowDatas(data?.data?.notificationId)

//   } 
// };

//     // Call the function to fetch data
//     fetchData();
//     if(ShowpopupData ==="paymentpagevisited"){
//     apiCallSameDay();
//     }

//   }, []);
  
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(EndPoints.trialpackbenefitDetails());
      if (response.status === 200 && response.data.status === "Success") {
        setPackData(response.data.data);
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const apiCallSameDay = async () => {
    if (!userData?.profileId) {
      console.warn("userData.profileId is not available yet");
      return; // Exit early if profileId is not available
    }

    const request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      profileId: userData?.profileId,
    };

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.samedaypopuptrackUrl()}`,
      request
    );

    if (statusCode === 200) {
      setShowDatas(data?.data?.notificationId);
    }
  };

  // Fetch data for the first API
  fetchData();

  // Call apiCallSameDay only when userData.profileId is available
  if (ShowpopupData === "TrialPack" && userData?.profileId) {
    apiCallSameDay();
  }
}, [userData, ShowpopupData]); 



  const RupeeSymbol = () => <span className="rupeefont">₹</span>;

  const amount = 5000;
  const validity = packdata?.validity;
  const phonecount = packdata?.phonecount;
  const Messagecount = packdata?.Messagecount
  

  

  const isAlternate = Math.random() > 0.5;
  return (
    <>
      {completeNow &&
        <CommonRewardPopup ShowpopupDataNotification={ShowpopupDataNotification} message={message} showdatas={showdatas} close={() => setopenpopup(false)}
         openpopup={openpopup} setpopup={setopenpopup} KMNotificationId={KMNotificationId} messageTypeId={messageTypeId} kmcareId={kmcareId} />}
      {openpopup &&
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">

          <div ref={popupRef} className="relative w-[100%]  md:w-[82%] lg:w-[45%] md:my-6 md:mx-6">
            <div className="">
              <button
                className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                onClick={() => {
                  OnClose();
                  setopenpopup(false)
                }}
              >
                <img className="w-6 h-6 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto p-6">
                <div className="flex items-center justify-center space-x-0 space-y-2 text-center md:space-x-4 lg:space-x-8">
                  <p className="text-[#1A1A1A] flex bg-[#10B21B30] rounded-[3px] text-[13px] items-center px-5 py-1 ">
                    <img className="w-[1.5rem] h-[1.5rem]" src={kalyanremovebg} />{" "}
                    <span>{trans('kalyan_rewards_popup.kalyan_rewards')}</span>
                  </p>
                </div>
                <hr class="h-[3px] mt-5 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

                <div className="text-center justify-center font-semibold text-[15px] mx-[3rem] md:mx-[7rem] py-[1rem] md:py-[2rem]">
                  <p className="text-[#000000]">
                    {trans('trial_pack_popup.complete_your_profile_and_get', { amount })}  {" "}

                    {/* Complete your profile and get <RupeeSymbol />5000 worth{" "} */}

                  </p>
                </div>

                {/* <div className="relative w-full h-full flex justify-center items-center">
                 <div className="relative top-[-39px] left-0"> <img
                    src={Images.freegif}
                    alt="Free Gif"
                    className=" w-[4.5rem] h-[4.5rem]"
                  /></div>
                  <div className="relative top-[-39px] right-0"> <img
                    src={Images.freegif}
                    alt="Free Gif"
                    className=" w-[4.5rem] h-[4.5rem]"
                  /></div>
                  <img
                    src={gifticon}
                    alt="Gift Icon"
                    className="w-[5rem] h-[6rem]"
                  />
                     <div className="relative bottom-[-27px] left-0">
                  <img
                    src={Images.freegif}
                    alt="Free Gif"
                    className=" w-[4.5rem] h-[4.5rem]"
                  />
                  </div>

                  <div className="relative bottom-[-27px] right-0">
                  <img
                    src={Images.freegif}
                    alt="Free Gif"
                    className=" w-[4.5rem] h-[4.5rem]"
                  />
                  </div>
                </div> */}

           <GiftImageWithGif alternate={isAlternate} />


                <div className="md:grid grid-cols-3 gap-2  py-[2rem] mx-[2rem] font-semibold hidden ">
                  <div className="flex items-center gap-2">
                    <img className="w-5 h-5" src={validityicon} />
                    
                    <span>:</span>
                    <span className="text-[#000000] text-[12px]"> 
                      {/* {packdata?.validity}{" "}Days Validity */}
                      {trans('trial_pack_popup.day_number_days_validity', { day_number: validity })}
                    </span>
                    {/* <OverflowToolTip as='p' className="text-[#000000] text-[12px] truncate">
                      {trans('trial_pack_popup.day_number_days_validity', { day_number: validity })}
                    </OverflowToolTip> */}
                  </div>
                  <div className="flex items-center gap-2">
                    <img className="w-5 h-5" src={phonenoicon} />
                    <span>:</span>
                    <span className="text-[#000000] text-[12px]">
                      {/* {packdata?.phonecount}{" "}Phone Number */}
                      {trans('trial_pack_popup.number_phone_number', { number: phonecount })}

                    </span>
                    {/* <OverflowToolTip as='p' className="text-[#000000] text-[12px] truncate">
                      {trans('trial_pack_popup._number_phone_number', { number: phonecount })}
                    </OverflowToolTip> */}
                  </div>
                  <div className="flex items-center gap-2">
                    <img className="w-5 h-5" src={chaticon} />
                    
                    <span>:</span>
                    <span className="text-[#000000] text-[12px]">
                      {/* {packdata?.Messagecount}{" "}Chat Messages */}
                      {trans('trial_pack_popup.number_chat_messages', { number: Messagecount })}

                    </span>
                    {/* <OverflowToolTip as='p' className="text-[#000000] text-[12px] truncate">
                      {trans('trial_pack_popup._number_chat_messages', { number: Messagecount })}
                    </OverflowToolTip> */}
                  </div>
                </div>
                <div className="flex justify-center items-center  mt-[1.5rem] md:mt-3">
                  <p className="text-[#000000] font-semibold text-[15px] md:hidden">
                    {/* {trans('trial_pack_popup.talk_to_your_matches_for_free')}{" "} */}
                    Talk to your matches for{" "}
                    <span className="text-[#D10A11]">FREE!</span>
                  </p>
                </div>
                <div className=" gap-8 space-y-6 pt-[1rem] pb-[2rem] mx-[2rem] font-semibold md:hidden ">
                  <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
                    <img className="w-5 h-5" src={validityicon} />
                    <span className="text-[#000000] text-[12px]">
                      :  {trans('trial_pack_popup.day_number_days_validity' , {day_number : validity})}{" "}
                    </span>
                  </div>
                  <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
                    <img className="w-5 h-5" src={phonenoicon} />
                    <span className="text-[#000000] text-[12px]">
                      : {trans('trial_pack_popup.number_phone_number' , {number : phonecount})}{" "}
                    </span>
                  </div>
                  <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
                    <img className="w-5 h-5" src={chaticon} />
                    <span className="text-[#000000] text-[12px]">
                      :  {trans('trial_pack_popup.number_chat_messages' , {number : Messagecount})}
                    </span>
                  </div>
                </div>

                <div className="flex justify-center items-center text-[15px] pb-[1rem] md:pb-[1.5rem] mx-[3rem] md:mx-0 text-center">
                  <p className="text-[#000000] font-semibold">
                    {/* {trans('trial_pack_popup.a_complete_profile_gets_more')} */}
                    A Complete Profile gets more
                    <span className="text-[#D10A11] font-semibold">
                      {" "}
                      Responses!
                      {/* {trans('trial_pack_popup.a_complete_profile_gets_more_r')} */}

                    </span>
                  </p>
                </div>

                <div className="flex justify-center gap-5 pb-1 md:pt-2">
                  <button
                    onClick={()=>{
                      onClickTrack(EventName.Trial_Pack_popup_Profile_Completion_Completed_Now_Button)
                      onCompleteNow(); 
                      handelCompleteClick();
                    }
                    }
                    
                    className="text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] rounded-[6px] font-bold bg-[#D10A11]"
                  >
                    {trans('trial_pack_popup.complete_now')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

// const CommonRewardPopup = ({ props, OnClose, close, closePopup, setpopup ,showdatas ,message ,ShowpopupDataNotification,KMNotificationId, messageTypeId, kmcareId}) => {

const CommonRewardPopup = ({ props, OnClose, closePopup,setpopup ,showdatas ,message,ShowpopupDataNotification, KMNotificationId, messageTypeId, kmcareId,close}) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const popupRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { openpopup, setopenpopup } = useContext(NavBarContext);

  const {initialMatchDataPopup} = useSelector(MatchSelector.getRecommendedMatches)

console.log(showdatas ,message ,ShowpopupDataNotification,'showdatas ,message ,ShowpopupDataNotification');


  const { onClickTrack } = useAnalytics()
  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      handelCloseClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleOnclick = () => {
    setShowPopup(true);
  };
  const { completeNow, setCompleteNow, clickAddHabits, setClickAddHabits,
    clickAddFamily, setClickAddFamily, clickAddAstro, setClickAddAstro,
    clickAddHoro, setClickAddHoro } = useContext(NavBarContext);

  const [completed, setCompleted] = useState(false);
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({
    url: "",
    flag: "",
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [packStatusData, setPackStatusData] = useState(null)
  const { packStatusData, setPackStatusData } = useContext(NavBarContext)
  const [monthlyPopup, setMonthlyPopup] = useState(false)
  const [descriptionPopup, setDescriptionPopup] = useState(false)
  const [hobbiesPopup, setHobbiesPopup] = useState(false)
  const handleCompleteNow = () => {
    // Handle completion logic here
    setCompleted(true);
  };
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const Notificationsid = userData?.popup[0]?.notificationId;
  
  const NotificationId = KMNotificationId ? KMNotificationId :
  localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
      ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
        showdatas ||
        message ||
        ShowpopupDataNotification
      : userData?.popup[0]?.notificationId ||
        message ||
        ShowpopupDataNotification;


        console.log(NotificationId,'NotificationIdNotificationI00');
 

  // const NotificationId = KMNotificationId ? KMNotificationId : localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const notificationId = sessionStorage.getItem(
    Constants.loginLocalStorageKeys.notificationId
  )
  useEffect(()=>{
    setCompleteNow(true)
  },[])

  const handelCloseClick = () => {
    setCompleteNow(false)
    if(messageTypeId){
      OnClose()
    }else{
      setopenpopup(false)
    }
  }



  console.log(userData?.popup[0]?.notificationId, "notificationId");


  const AstroOnClick = () => {
    setClickAddAstro(true);
    setCompleteNow(false)

  };


  console.log(packStatusData, "packStatusData");
  const handleAddFamilyClick = () => {
    setClickAddFamily(true);
    setCompleteNow(false)
  };


  const handleAddHabitsClick = () => {
    setClickAddHabits(true);
    setCompleteNow(false)
  };

  const handelAddHoroClick = () => {
    setClickAddHoro(true);
    setCompleteNow(false)
  };

  const handleAddMonthlyClick = () => {
    setMonthlyPopup(true);
    setCompleteNow(false)
  };

  const handleAddDescriptionClick = () => {
    setDescriptionPopup(true);
    setCompleteNow(false)
  };

  const handleAddHobbiesClick = () => {
    setHobbiesPopup(true);
    setCompleteNow(false)
  };


  console.log(clickAddAstro, 'clickAddAstro');

  useEffect(() => {
    console.log("clickAddHoro changed:", clickAddHoro); // This should log the updated state
  }, [clickAddHabits]);

  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);

      if (setHoroscope) {
        setgeneratedHoroscopeurl({
          url: data?.data?.horoscope,
          flag: data?.data?.horoflag,
          show: false,
        });
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };

  useEffect(() => {
    apiCallEditProfile();

  }, []);


  const postData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        EndPoints.trialpackstatus(),
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json', // Adjust headers if necessary
          },
        }
      );
      if (response.status === 200 && response.data.status === "Success") {
        setPackStatusData(response.data.data);
      } else {
        setError('Failed to fetch data');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {

    postData();

  }, []);

  const [userDetails, setUserDetails] = useState(false)

  const [showClaimSuccessPopup, setshowClaimSuccessPopup] = useState(false)


  const ClaimNowClick = async () => {
    // Extract the notificationId
    const notificationId = NotificationId;

    // Check if notificationId is available
    if (!notificationId) {
      console.error("Notification ID is missing");
      return;
    }

    let request = {
      notificationId: notificationId, // Add notificationId to the request
      ...(messageTypeId && {messageTypeId: messageTypeId}),
      ...(kmcareId && {kmcareId: kmcareId})
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.claimChottaPack()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "Success") {
        setshowClaimSuccessPopup(true);
        setCompleteNow(false)
      }
      // Handle success
    } else if (statusCode === 401) {
      logout();
    }
  };


  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const { setMenuSelect } = useContext(NavBarContext);
  const location = useLocation();


console.log(showdatas,'showdatas');

  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const handleCrop = (file) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.height_and_width_must_be_more'),
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description:trans('upload_photos.image_size_should_not_be_great'),
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {

    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);
      },
    });
  };

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
    fileData.append("photorequesttypeid", 85);
    fileData.append("requestfulfilledfrom", getDeviceInfo());
    fileData.append("recieverprofileid", getGroupAuthId());
    
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());

      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setshowSuccessPopup(true);
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }
          props?.close();
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  const logoutUsignAPI = async () => {
    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status === 200) {
      setShowLoader(false);
      logout();
    }
  };



  useEffect(() => { }, [props?.menu]);


  const [isIdsProofUploading, setIsIdsProofUploading] = useState(false)
  const [isEducationProofUploading, setIsEducationProofUploading] = useState(false)
  const [isIncomeProofUploading, setIsIncomeProofUploading] = useState(false)
  const [successPopup, setSuccessPopup] = useState({ show: false, content: "" })

  const handleUploadDoc = async (file, type) => {
    //console.log("file.size", file.size);
    if (type === "idproof") {
      setIsIncomeProofUploading(true)
    }
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", getDeviceInfo());
      fileData.append("documents", file);
      fileData.append("type", type);
      fileData.append("editviatrialpack", packStatusData?.editviatrialpack);
      fileData.append("notificationId", NotificationId);
      fileData.append("trustrequesttypeid", 88);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("recieverprofileid", getGroupAuthId());
      fileData.append("messageTypeId", props.messageTypeId);
      fileData.append("kmcareId", props.kmcareId);

      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setIsIncomeProofUploading(false)
          postData();
          setSuccessPopup({ show: true, content: trans('trust_mark.document_upload_succesfully') })
          props.callBackReload();
          apiCallEditProfile()

        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
    setIsIdsProofUploading(false)
    setIsEducationProofUploading(false)
    setIsIncomeProofUploading(false)
    apiCallEditProfile()
  };

  const onHoroScopeFileChange = (file, type) => {
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description:trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError: (img) => {
        showNotification({ title: "Invalid image", description:trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: (img, readerStr) => {
        handleUploadDoc(file, type)
      }
    })
  }

  const TrustMarkImageUpload = (props) => {

    return (
      <label >
        {props?.menu == "mobile" ?
          <div >

            <p className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
            >
              {props.title}
            </p>


          </div>
          :
          <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
            <div className={props.from == 'trailPack' ? 'col-span-5' : 'col-span-4'}>
              <p className={props.from == 'trailPack' ? 'text-[#000000] text-[13px] xl:text-[13px] 2xl:text-[14px]  font-bold' : 'text-[#000000] text-[13px] xl:text-[13px] 2xl:text-[14px] flex justify-center font-bold'}>
                {props.title}
              </p>
            </div>
            <div className={props.from == 'trailPack' ? 'col-span-2' : 'col-span-3'}>
              <img
                className="w-5 h-5"
                src={props.icon}
                alt=""
                onClick={() => {
                  if (props.from === 'trailPack') {
                    onClickTrack(EventName.Trial_Pack_popup_Add_an_ID_Proof_Add_Now_Button);
                    onClickTrack(EventName.Trial_Pack_popup_Add_an_ID_Proof_Upload_Button);
                  }
                }}

              />
            </div>
          </div>
        }

        <input
          style={{ display: "none" }}
          type="file"
          onChange={(event) => {
            if (event.target.files[0]) {
              onHoroScopeFileChange(event.target.files[0], props.type);
            }
          }}
          accept="image/jpeg, image/png"
        />

      </label>
    );
  };

  const [uploadPhotos, setUploadPhotos] = useState(false)
  const [NotificationPopup, setNotificationPopup] = useState(false)

  const photoUpload = () => {
    setUploadPhotos(true)
    setCompleteNow(false)
  }

  const RupeeSymbol = () => <span className="rupeefont">₹</span>;
  const { t:trans } = useTranslation();

  const amount = 5000;

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
  };


  const [showNotifiPopup,setShowNotifiPopup]= useState(false)
  const [opennotifipopup,setOpennotifipopup]= useState(false)

  const OpenNotifiPopup = ()=>{
    setShowNotifiPopup(true)
    setCompleteNow(false)
    setOpennotifipopup(true)

  }

  const Notifi = location?.state?.data?.notificationId
  const NotificationIds = localStorage.getItem(Constants.loginLocalStorageKeys.Notification)
    
    console.log(NotificationIds,message,'notificationsId');
    
  const onClickApp = async (type) => {
    const notificationsId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
    
    console.log(notificationsId,message,'onClickApp');

    const request = {
      email: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      userId: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      isClicked: true,
      notificationId:   message || Notificationsid ,
      clickButton: type === "App" ? "App_Notification" : "Browser_Notification", // Dynamically set based on type
    };
  
    Update_Notification_Status(request);
    if (!notificationsId) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId);
    }
  };

  const appRedirection = async () => {

    const  liteNotification = initialMatchDataPopup?.currentMatch?.matchdata?.popup[0]?.notificationId


    let request = {
      profileId : userData?.profileId,
      loginFrom : getDeviceInfo(),
      popupFrom : "web",
      notificationId:   message ? message  : liteNotification ? liteNotification : Notificationsid 
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getTrialPackUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "SUCCESS") {
        onOpenApp(data.data.url);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const onOpenApp = async (Url) => {
    
    const res = await axios.post(`${config.api.API_URL}${EndPoints.clcnotificationurl()}`, {
        longurl: Url
    })
    if (res.data?.statusCode == 200) {
        window.open(res?.data?.data)
        setCompleteNow(true)
        setNotificationPopup(false);
    }
  }

  const updateDeviceToken = async (token) => {
    try {
      const request = {
        "webKey" : token
      };
      const { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.UpdateDeviceTokenUrl()}`,
        request
      );

      if (statusCode === 200) {
        if (data.data.status === "SUCCESS") {
        }

      } else if (statusCode === 401) {
        logout();
      }
    } catch (error) {
      console.error("Error fetching update device token data:", error);
    }
  };

  const notificationUpdate = async () => {

    if(!localStorage.getItem(Constants.loginLocalStorageKeys.fcmToken)){
      return false;
    }

    let request = {
      profileId: userData?.profileId,
      webKey: localStorage.getItem(Constants.loginLocalStorageKeys.fcmToken),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.notificationPopupUpdate()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "Success") {
        // Success
      }
    } else if (statusCode === 401) {
      logout();
    }
  };



  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await window.Notification.requestPermission();

    if (permission === "granted") {
      window.navigator.serviceWorker.register(process.env.PUBLIC_URL + '/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" }).then(async () => {
        const token = await getToken(messaging, {
          vapidKey: "BOkYKLBpw9iIkK7p3rSiRiOUvvaTj77ZBIueLdsSRy9BeiragVXvww6zR1IawhNGDhJjsxB0JolYTzBwqapVGyI",
        });

        console.log("Token generated : ", token);
        localStorage.setItem(Constants.loginLocalStorageKeys.fcmToken, token)
        updateDeviceToken(token);
        if(localStorage.getItem("AppPopup") == "true"){
          setNotificationPopup(true);
          setCompleteNow(false)
          // updateDeviceToken(token)
        }else{
          // updateDeviceToken(token)
          localStorage.setItem(Constants.loginLocalStorageKeys.showNotifyPopup,"true")
          notificationUpdate()
        }
        postData();
      })
      .catch(function (err) {
        console.log("Service Worker Failed", err);
      })
      //We can send token to server
    } else if (permission === "denied") {
      localStorage.setItem(Constants.loginLocalStorageKeys.showNotifyPopup,"true")
      //notifications are blocked
      // alert("You denied for the notification");
    }
  }

  // useEffect(()=>{
  //   requestPermission()
  // },[])

  const showRequestForPermissions = async () => {
    await requestPermission()
  }
  




  return (
    <>
      {isIncomeProofUploading ? (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      ) : (
        ""
      )}
      {
        NotificationPopup &&
          <TrailNotificationPopup 
            close={()=> setNotificationPopup(false)}
            continueButton={()=>{appRedirection()}}
            
          />
      }
      {
        notification.show
          ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} 
          />
          : null
      }
      {monthlyPopup &&
        <CommonMonthlyPopup
          close={() => { setMonthlyPopup(false) }}
          editMyProfileData={editMyProfileData}
          editviatrialpack={packStatusData?.editviatrialpack}
          setCompleteNow={setCompleteNow}
          postData={postData}
          notificationId={notificationId}
          showdatas={showdatas} message={message}
          ShowpopupDataNotification={ShowpopupDataNotification}
          KMNotificationId={KMNotificationId}
        />
      }
      {descriptionPopup &&
        <CommonDescriptionPopup
          close={() => { setDescriptionPopup(false) }}
          editMyProfileData={editMyProfileData}
          editviatrialpack={packStatusData?.editviatrialpack}
          setCompleteNow={setCompleteNow}
          postData={postData}
          notificationId={notificationId}
          showdatas={showdatas} message={message}
          ShowpopupDataNotification={ShowpopupDataNotification}
          KMNotificationId={KMNotificationId}
        />
        // <DescriptionPopup 
        //   onClose={()=>{setDescriptionPopup(false)}}
        //   moreInfo={editMyProfileData?.moreInfo}
        //   descriptionEdit={editMyProfileData?.descriptionEdit}
        //   isTrail="trailPack"
        //   isFrom="viewprofile"
        //   editviatrialpack={packStatusData?.editviatrialpack}
        //   setCompleteNow={setCompleteNow}
        //   postData={postData}
        //   ApiCallEditProfile={apiCallEditProfile}
        // />
      }

      {showNotifiPopup &&
        <CommonNotificationAlertPopup
          content={"Get updates on your profile and matches from Kalyan Matrimony. Click Allow to get updates"}
          close={()=>{setShowNotifiPopup(false)}} 
          isFrom="trail"
          showRequestForPermissions={showRequestForPermissions}
          notificationUpdate={notificationUpdate}
          postData={postData}
          setNotificationPopup={setNotificationPopup}
          NotificatiorequestPermission={()=>{requestPermission()}}
         />
      }


     
      {hobbiesPopup &&
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
          <div ref={popupRef} className="relative w-[100%]  md:w-[75%] xl:w-[49%] 2xl:w-[40%] lg:w-[61%] md:my-6 md:mx-6">
            <HobbyEdit
              onClose={() => {
                setHobbiesPopup(false)
              }}
              isFrom="trailpack"
              editviatrialpack={packStatusData?.editviatrialpack}
              setCompleteNow={setCompleteNow}
              postData={postData}
              notificationId={notificationId}
              editMyProfileData={editMyProfileData}
              apiCallEditProfile={apiCallEditProfile}
              ShowpopupDataNotification={ShowpopupDataNotification}
              showdatas={showdatas} message={message}
              KMNotificationId={KMNotificationId}
            />
          </div>
        </div>
      }
      {showCrop && (
        // <ImageCroperComponent
        //   close={() => setShowCrop(false)}
        //   image={currentimage}
        //   imgType={originalImage.type}
        //   callBackCroper={cropedImage}
        // />
        <ImageCropper
          onCropClick={cropedImage}
          image={currentimage}
          onClose={(isCropped) => {
            if (isCropped) {
              setShowCrop(false);
            } else {
              props?.close();
            }

            if (props?.callBackReload) {
              props?.callBackReload();
            }
          }}
        />
      )}

      {showSuccessPopup && (
        <CommonSuccessPopup
          close={() => {
            setshowSuccessPopup(false);
          }}
          title={trans('photos.success')}
          content={"Image upload success"}
        />
      )}

      {showClaimSuccessPopup && (
        <CommonSuccessPopup
          close={() => {
            setshowClaimSuccessPopup(false);
          }}
          title={trans('photos.success')}
          content={"Chota Pack Successfully Credited"}
        />
      )}
      {clickAddAstro &&
        <CommonAstroPopup ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} KMNotificationId={KMNotificationId} editMyProfileData={editMyProfileData} postData={postData} OnClose={() => setClickAddAstro(false)} isFrom={'TrailPack'} editviatrialpack={packStatusData?.editviatrialpack} notificationId={notificationId} apiCallEditProfile={apiCallEditProfile} />}

      {clickAddHabits &&
        <CommonHabitPopup ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} KMNotificationId={KMNotificationId} editMyProfileData={editMyProfileData} postData={postData} OnClose={() => setClickAddHabits(false)} isFrom={'TrailPack'} editviatrialpack={packStatusData?.editviatrialpack} notificationId={notificationId} apiCallEditProfile={apiCallEditProfile} />}

      {clickAddFamily &&
        <CommonFamilyPopup ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} KMNotificationId={KMNotificationId} editMyProfileData={editMyProfileData} postData={postData} OnClose={() => setClickAddFamily(false)} isFrom={'TrailPack'} editviatrialpack={packStatusData?.editviatrialpack} notificationId={notificationId} apiCallEditProfile={apiCallEditProfile} />}

      {clickAddHoro &&
        <CommonHoroPopup ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} KMNotificationId={KMNotificationId} editMyProfileData={editMyProfileData} postData={postData} OnClose={() => setClickAddHoro(false)} isFrom={'TrailPack'} editviatrialpack={packStatusData?.editviatrialpack} notificationId={notificationId} apiCallEditProfile={apiCallEditProfile} />}

      {uploadPhotos && <UploadPhotoPopuplite title={trans('photo_popup.add_photo')} ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} close={() => setUploadPhotos(false)} isFrom={'TrailPack'} editviatrialpack={packStatusData?.editviatrialpack} postData={postData} setCompleteNow={setCompleteNow} KMNotificationId={KMNotificationId}/>}

      {completeNow &&



        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">


          <div  className="relative w-[100%]  md:w-[75%] xl:w-[49%] 2xl:w-[40%] lg:w-[61%] md:my-6 md:mx-6 rewardpopup" style={{height:'-webkit-fill-available'}}>
            <div className="">

              <button
                className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                onClick={() => handelCloseClick()}
              >
                <img className="w-6 h-6 " src={CloseIcon} alt="" />
              </button>

            </div>
            
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto p-6">
                <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />


                <div className="items-center justify-center text-center ">

                  {packStatusData?.showclaimbutton == "Y" ?
                    <div>
                      <p className="text-[#1A1A1A] flex    font-semibold justify-center  text-center  text-[15px] items-center px-5 py-1 ">
                        Congrats! Click Claim Now and <br />
                      </p>
                      <span className="flex font-semibold justify-center text-[15px] text-center gap-2"> Claim Your
                        <p className="text-[#D10A11] items-center font-semibold flex justify-center  text-[15px] text-center ">
                          Reward
                        </p> </span>
                    </div>
                    :


                    <div className="flex md:block">
                      <p className="text-[#1A1A1A] flex    font-semibold justify-center  text-center  text-[16px] items-center px-5 py-1 ">
                        {trans('trial_pack_popup__2nd_popup.a_complete_profile_gets_more_r')} <br />
                      </p>
                      {/* <p className="text-[#D10A11] items-center font-semibold flex justify-center  text-[16px] text-center ">
                        Responses!
                      </p> */}

                 <img src={RedClose} className='w-6 h-6 md:hidden' alt=''
                   onClick={() => { 
                    handelCloseClick() ;
                  }} 
                    />
                    </div>}


                  <div>
                    <div className="text-center justify-center text-[14px] font-semibold  md:mx-[7rem] pb-[1rem] py-[1rem]">
                      <p className="text-[#6D6D6D]">{trans('trial_pack_popup__2nd_popup.your_profile_quality_score')}</p>
                    </div>

                    <div className="cursor-pointer ">
                      <StaticCircularProgressBar completed={completed} value={packStatusData?.trailpackscore} packStatusData={packStatusData} />
                    </div>

                    <div className="md:grid text-start md:grid-cols-3 gap-8  text-[13px] xl:text-[13px] 2xl:text-[14px] ml-[1rem] font-semibold mt-[2rem] hidden md:block">
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.add_your_photo')}
                          </p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.photostatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.photostatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button> :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Your_Photo_Add_Now_Button);
                                  photoUpload();
                                }
                                }>
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.photostatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_your_family_details')}</p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.familystatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.familystatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Your_Family_Details_Add_Now_Button);
                                  handleAddFamilyClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.familystatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial ">
                        <div className="col-span-5 lg:col-span-5 text-initial">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.uploadgenerate_horoscope')}
                          </p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.horoscopestatus == "Y" ?

                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.horoscopestatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <button
                              onClick={() => {
                                onClickTrack(EventName.Trial_Pack_popup_Add_Horoscope_Add_Now_Button);
                                handelAddHoroClick();
                              }}
                            >
                              <img
                                className="w-5 h-5 cursor-pointer"
                                src={`${packStatusData?.horoscopestatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                            target="_blank"
                            className=" text-[#000000]"
                          >
                            {trans('trial_pack_popup__2nd_popup.download_app')}
                          </a>
                        </div>
                        <div className="col-span-2">
                          <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1">
                            <img className="w-5 h-5 cursor-pointer" src={`${packStatusData?.appstatus == "Y" ? redtickicon : resplusicon}`} /></a>
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.set_your_expectations')}
                          </p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.partnerexpectationstatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.partnerexpectationstatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button> :
                            <ActionRestrict remove={false}>
                              <button onClick={() => {
                                onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Add_Now_Button);
                                navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), {
                                  state: { isFromTrailPopup: packStatusData?.editviatrialpack , ShowpopupDataNotification: ShowpopupDataNotification ,
                                    showdatas:showdatas , message:message
                                  }
                                })
                                handelCloseClick();
                              }}>
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.partnerexpectationstatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.add_astrological_information')}
                          </p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.astrostatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.astrostatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button> :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Astrological_Information_Add_Now_Button);
                                  AstroOnClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.astrostatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_your_habit')}</p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.habitstatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.habitstatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Habit_Details_Add_Now_Button);
                                  handleAddHabitsClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.habitstatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_monthly_income')}</p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.incomestatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.incomestatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Monthly_Income_Add_Now_Button);
                                  handleAddMonthlyClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.incomestatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_hobbies__interests')}</p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.hobbiesstatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.hobbiesstatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_HobbiesInterests_Add_Now_Button);
                                  handleAddHobbiesClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.monthlystatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="">


                        <TrustMarkImageUpload
                          icon={packStatusData?.idproofstatus == "Y" ? redtickicon : resplusicon}
                          title={trans('trial_pack_popup__2nd_popup.add_an_id_proof')}
                          type={"idproof"}
                          isUploading={isIncomeProofUploading}
                          from={"trailPack"}
                        />

                      </div>
                      <div className="grid items-center grid-cols-7 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-5">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_profile_description')}</p>
                        </div>
                        <div className="col-span-2">
                          {packStatusData?.descriptionstatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.descriptionstatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Description_Add_Now_Button);
                                  handleAddDescriptionClick();
                                }}
                              >
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.descriptionstatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>

                    </div>
                    <div className="  ml-[1rem]  mt-[2rem] hidden md:block">
                      <p className="text-[#000000] font-bold text-start text-[18px] mb-[1rem]">{trans('trial_pack_popup__2nd_popup.turn_on_notifications')}</p>

                      <div className="grid grid-cols-3 gap-5 spacex-x-2">
                        <div className="grid items-center gap-4 grid-cols-9 text-start">
                        <div className="col-span-6">
                           <p className="text-[#000000] text-[13px] xl:text-[13px] 2xl:text-[14px]  font-bold">
                            {/* App notification */} {trans('trial_pack_popup__2nd_popup.app_notification')}
                            </p></div>
                          <div className="col-span-2">
                          {packStatusData?.appNotificationStatus == "Y" ?
                            <button >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.appNotificationStatus == "Y" ? redtickicon : resplusicon}`}
                                alt=""
                              />
                            </button> :
                            <ActionRestrict remove={false}>
                              
                              <button  onClick={() => {
                                  onClickApp("App")
                                }
                                } >
                              <a
                              href={RouteSegments.staticLinkForAppDownload.android}
                              target='_blank'>
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.appNotificationStatus == "Y" ? redtickicon : resplusicon}`}
                                />
                                </a>
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                        </div>
                        <div className="grid items-center grid-cols-9 gap-4  text-start">
                        <div className="col-span-6"> <p className="text-[#000000] text-[13px] xl:text-[13px] 2xl:text-[14px]  font-bold">{trans('trial_pack_popup__2nd_popup.browser_notification')}</p></div>
                          <div className="col-span-2">
                          {packStatusData?.webNotificationStatus == "Y" ?
                            <button  >
                              <img
                                className="w-5 h-5"
                                src={`${packStatusData?.webNotificationStatus == "Y" ? redtickicon : resplusicon}`}
                              />
                            </button> :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  OpenNotifiPopup();
                                  onClickApp("Browser")
                                }
                                }>
                                <img
                                  alt=""
                                  className="w-5 h-5 cursor-pointer"
                                  src={`${packStatusData?.webNotificationStatus == "Y" ? redtickicon : resplusicon}`}
                                />
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-start md:grid-cols-3 space-y-6 gap-8 text-[11px] ml-[1rem] font-semibold mt-[2rem] md:hidden">
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_your_photo')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">

                          {packStatusData?.photostatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')} <img className="w-5 h-5" src={redtickicon} /></span>
                            :

                            <div className="flex gap-8 space-x-1 cursor-pointer">
                              <button onClick={() => { photoUpload(); onClickTrack(EventName.Trial_Pack_popup_Add_Your_Photo_Add_Now_Button) }} className="flex gap-8 space-x-1 cursor-pointer">

                                <p className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md" >{trans('trial_pack_popup__2nd_popup.add')} </p>
                              </button>
                            </div>
                          }

                        </div>  
                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_your_family_details')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.familystatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>

                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Your_Family_Details_Add_Now_Button);
                                  handleAddFamilyClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.uploadgenerate_horoscope')}
                          </p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.horoscopestatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <button
                              onClick={() => {
                                onClickTrack(EventName.Trial_Pack_popup_Add_Horoscope_Add_Now_Button);
                                handelAddHoroClick();
                              }}
                              className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                            >
                              {trans('trial_pack_popup__2nd_popup.add')}
                            </button>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                            target="_blank"
                            className=" text-[#000000]"
                          >
                             {trans('trial_pack_popup__2nd_popup.download_app')}
                          </a>  </div>

                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.appstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}<img className="w-5 h-5" src={`${packStatusData?.appstatus == "Y" ? redtickicon : resplusicon}`} />
                            </span> :
                            <button
                              className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                            >
                              <a
                                href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                                target="_blank"
                                className=" text-[#ffffff]"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </a>
                            </button>}

                        </div>


                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.set_your_expectations')}
                          </p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.partnerexpectationstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Add_Now_Button);
                                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), {
                                    state: { isFromTrailPopup: packStatusData?.editviatrialpack }
                                  })
                                  handelCloseClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                                {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">
                          {trans('trial_pack_popup__2nd_popup.add_astrological_information')}
                          </p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.astrostatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')} <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Astrological_Information_Add_Now_Button);
                                  AstroOnClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__habits_detai.add_habit_details')} </p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.habitstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')} <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Habit_Details_Add_Now_Button);
                                  handleAddHabitsClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_monthly_income')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.incomestatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Monthly_Income_Add_Now_Button);
                                  handleAddMonthlyClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_hobbies__interests')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.hobbiesstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_HobbiesInterests_Add_Now_Button);
                                  handleAddHobbiesClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                                {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_an_id_proof')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.idproofstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')} <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <TrustMarkImageUpload
                              icon={packStatusData?.idproofstatus == "Y" ? redtickicon : resplusicon}
                              title={"Add"}
                              type={"idproof"}
                              menu="mobile"
                              isUploading={isIncomeProofUploading}
                            />
                            //    <button
                            //    onClick={handelAddHoroClick}
                            //    className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                            //  >
                            //    Add
                            //  </button>
                          }
                        </div>

                      </div>
                      <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-initial">
                        <div className="col-span-4">
                          <p className=" text-[#000000]">{trans('trial_pack_popup__2nd_popup.add_profile_description')}</p>
                        </div>
                        <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.descriptionstatus == "Y" ?
                            <span className="flex items-center gap-3">{trans('trial_pack_popup__2nd_popup.done')}  <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  onClickTrack(EventName.Trial_Pack_popup_Add_Description_Add_Now_Button);
                                  handleAddDescriptionClick();
                                }}
                                className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.add')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                      </div>

                      <div className="  ">
                      <p className="text-[#000000] font-bold text-start text-[17px] mb-[1rem]">{trans('trial_pack_popup__2nd_popup.turn_on_notifications')}</p>

                      <div className="space-y-6">
                        <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-start">
                        <div className="col-span-4">
                           <p className="text-[#000000] ">{trans('trial_pack_popup__2nd_popup.app_notification')}</p></div>
                          <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.appNotificationStatus == "Y" ?
                            <span className="flex items-center gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                                onClick={() => {
                                  // AppClick()
                                  onClickApp("App")
                                  appRedirection()
                                }}
                                className="bg-[#D10A11] px-[1.3rem] py-1 text-white rounded-md cursor-pointer"
                              >
                              {trans('trial_pack_popup__2nd_popup.turn_on')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                        </div>
                        <div className="grid items-center grid-cols-7 gap-6 space-x-2 lg:spacex-3 text-start">
                        <div className="col-span-4"> <p className="text-[#000000] ">{trans('trial_pack_popup__2nd_popup.browser_notification')}</p></div>
                          <div className="flex col-span-3 gap-3 ">
                          {packStatusData?.webNotificationStatus == "Y" ?
                            <span className="flex items-center gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                            :
                            <ActionRestrict remove={false}>
                              <button
                               onClick={() => {
                                OpenNotifiPopup();
                                onClickApp("Browser")
                              }
                              }
                                className="bg-[#D10A11] px-[1.3rem] py-1 text-white rounded-md cursor-pointer"
                              >
                               {trans('trial_pack_popup__2nd_popup.turn_on')}
                              </button>
                            </ActionRestrict>
                          }
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    {packStatusData?.showclaimbutton == "Y" ? (
                      ""
                    ) : (
                      <div className="flex justify-center items-center text-center text-[15px] py-[1rem]">
                        <p className="text-[#000000] font-semibold">

                          Complete your profile and get chota pack worth <RupeeSymbol />5000 for
                          {/* {userData?.popup?.content} */}

                          <span className="text-[#D10A11] font-semibold">
                            {" "}
                            FREE!
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className={`flex pt-2 pb-2 ${packStatusData?.showclaimbutton == "Y" ? "mt-[2rem]" : ""
                      } justify-center gap-5`}
                  >

                    {packStatusData?.showclaimbutton == "Y" ? 
                    <button onClick={() => { ClaimNowClick(); 
                    onClickTrack(EventName.Trial_Pack_popup_Claim_Trial_Pack_Claim_Now_Button) }}
                      className={`text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] ${packStatusData?.showclaimbutton == "N" ? "cursor-not-allowed" : "cursor-pointer"} rounded-[6px] font-bold ${packStatusData?.showclaimbutton == "Y" ? "bg-[#039412]" : "bg-[#D10A11]"
                        } `}
                    >
                      {trans('trial_pack_popup__2nd_popup.claim_now')}
                    </button>
                      :
                      <button
                        // onClick={onClickTrack(EventName.Trial_Pack_popup_Profile_Completion_Completed_Now_Button)}
                        className={`text-[13px] py-1.5 px-[4rem] bg-[#E78387] text-[#FFFFFF] cursor-not-allowed  rounded-[6px] font-bold  `}
                      >
                        {/* {"Complete Now"} */}
                        {/* {trans('clctrial_pack.complete_now')} */}
                       {trans('trial_pack_popup.complete_now')}
                      </button>
                    }

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>


      }



    </>
  );
};
const ShareItem = ({ onClick, text, logo }) => {
  return <div onClick={onClick} className="flex flex-col items-center justify-center gap-4 cursor-pointer">
    <img src={logo} alt="" className="w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem]" />
    <p className="text-black text-[12px] md:text-[14px]">{text}</p>
  </div>
}

const SharePopup = ({ close, shareContent, copyContent }) => {

  const closePopupRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        if (window.innerWidth < 768) {
          close()
        }
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const onWhatsAppClick = () => {
    window.open(Constants.kalyanSupportWhatsappLink(
      "", shareContent
    ))
  }

  const onFbClick = () => {
    window.open(
      Constants.kalyanSupportFb(
        "", shareContent
      )
    )
  }

  const onSmsClick = () => {
    window.open(
      Constants.kalyanSupportMessageLink(
        "", shareContent
      )
    )
  }

  const onMailClick = () => {
    window.open(Constants.kalyanSupportMail(shareContent))
  }



  const handleCopyLink = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
    navigator.clipboard.writeText(copyContent);
  }

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-end overflow-x-hidden overflow-y-auto outline-none md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center focus:outline-none">
        <div className="mt-[3rem] relative w-auto mx-auto " ref={closePopupRef}>
          <div className="bg-white h-auto rounded-t-[2rem] md:rounded-[1rem] lg:md:rounded-[1rem] w-[100vw] md:max-w-[60vw] lg:max-w-[45vw]">
            <div className="px-6 py-8 sm:p-6 sm:pb-4 rounded-2xl">
              <div className="hidden md:block lg:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-16px] right-[-14px]"
                  onClick={close}
                >
                  <img className="w-8 h-8 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="">
                <p className="text-[#D10A11] flex justify-start font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  Share Link to Download App
                </p>
              </div>
              <div>
                <div className="flex items-center gap-2 py-4">
                  <img src={Images.LiteIcon} alt="" className="w-[5rem] h-[5rem]" />
                  <div className="flex flex-col">
                    <p className="font-bold text-black text-[18px] md:text-[20px] lg:text-[22px]">Kalyan Matrimony &reg; Marriage App</p>
                    <p className="text-[#717171] text-[12px]">Share this app</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 py-4 lg:grid-cols-6">
                  <ShareItem logo={Images.FaceBookIcon} onClick={onFbClick} text="Facebook" />
                  <ShareItem logo={Images.WhatsAppIcon} onClick={onWhatsAppClick} text="WhatsApp" />
                  <ShareItem logo={messageicon} onClick={onSmsClick} text="SMS" />
                  <ShareItem logo={emailIcon} onClick={onMailClick} text="Email" />
                </div>
                <div className="border-[2px] border-[#ACACAC] rounded-lg p-2 md:p-4 lg:p-4 flex gap-0 md:gap-5 lg:gap-5 justify-between items-center">
                  <p className="text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap w-[50vw] md:w-full overflow-hidden text-ellipsis">{copyContent}</p>
                  {isCopied
                    ? <p className="text-[#01875F] text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap">Copied</p>
                    : <div className="flex items-center gap-2 mr-4 cursor-pointer" onClick={handleCopyLink}>
                      <p className="text-[#01875F] text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap">Copy Link</p>
                      <img src={Images.CopyIcon} alt="" className="w-4 h-4 " />
                    </div>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonAstroPopup = ({ OnClose, setOpenPopup, postData, editMyProfileData, isFrom, editviatrialpack, notificationId, KMNotificationId,showdatas,message ,ShowpopupDataNotification }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const { completeNow, setCompleteNow, submitStatus, setSubmitStatus, clickAddAstro, setClickAddAstro, } = useContext(NavBarContext);

  const userData = useSelector((state) => state?.DashBoardAPI?.ProfileData?.data);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility


  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddAstro(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };




  const astropopupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (astropopupRef.current && !astropopupRef.current.contains(event.target) && window.innerWidth <= 768) {
      OnClose();
      setCompleteNow(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const { t:trans } = useTranslation();

  return (
    <>
      {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}

      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
        <div ref={astropopupRef} className="relative w-[100%] md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
          <div>
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={handelCloseClick}
            >
              <img className="w-6 h-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <hr className="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />
              <p className={`text-[#000000] items-center text-[18px] font-semibold pt-2 ${isFrom == 'TrailPack' ? 'lg:px-6 px-3' : 'justify-center text-center'}`}>
                {isFrom == 'TrailPack' ? trans('trial_pack_popup__2nd_popup.add_astrological_information') : trans('trial_pack_popup__astro_inform.astrological_information')}
              </p>
              <AstroBasic menu="addastro" ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} postData={postData} data={editMyProfileData} isFrom={isFrom} editviatrialpack={editviatrialpack} notificationId={notificationId} OnClose={OnClose} setCompleteNow={setCompleteNow} KMNotificationId={KMNotificationId}/>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

const CommonHabitPopup = ({ props, OnClose, close, setopenpopup, postData, editMyProfileData, isFrom, editviatrialpack, notificationId, apiCallEditProfile, KMNotificationId,showdatas,message ,ShowpopupDataNotification }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);

  const { submitStatus, setSubmitStatus, completeNow, setCompleteNow, openModel, setOpenModel, clickAddAstro, setClickAddAstro, clickAddHabits, setClickAddHabits } = useContext(NavBarContext);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility


  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddHabits(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );



  const { t:trans } = useTranslation();
  const habitpopupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      habitpopupRef.current &&
      !habitpopupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
      setCompleteNow(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>

      {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}

      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">


        <div ref={habitpopupRef} className="relative w-[100%]  md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
          <div className="">

            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => handelCloseClick()}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>

          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />


              <p className={`text-[#000000] text-[18px] items-center font-semibold  pt-2 ${isFrom == 'TrailPack' ? 'lg:px-6 px-3' : 'justify-center text-center'}`}>
                {isFrom == 'TrailPack' ? trans('trial_pack_popup__habits_detai.add_habit_details') : 'Habits'}
              </p>
              <HabitsBasic ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} postData={postData} isFrom={isFrom} handelCloseClick={handelCloseClick} editviatrialpack={editviatrialpack} notificationId={notificationId} apiCallEditProfile={apiCallEditProfile} setCompleteNow={setCompleteNow} OnClose={OnClose} editMyProfileData={editMyProfileData} KMNotificationId={KMNotificationId} />
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

const CommonHoroPopup = ({ props, OnClose, close, setopenpopup, postData, editMyProfileData, isFrom, notifyId, setShowingSuccessPopup, editviatrialpack, notificationId,isFromSms, showdatas ,message ,ShowpopupDataNotification,messageTypeId , kmcareId, KMNotificationId }) => {
  
  const { completeNow, setCompleteNow, isGenerating, setIsGenerating,
    clickAddHoro, setClickAddHoro } = useContext(NavBarContext);

  const { loginDetail, logout } = useContext(LoginContext);
  const { onClickTrack } = useAnalytics()
  const isMobile = window.innerWidth <= 768;
  
  const horopopupRef = useRef(null);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility

  // const handelCloseClick = () => {
  //   if (isGenerating) {
  //     setCompleteNow(true);
  //     OnClose();
  //     setClickAddHoro(false);
  //   } else {
  //     setShowErrorPopup(true); // Show the error popup
  //   }
  // };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


  const handelCloseClick = () => {
    
    if (isFrom == "clcRedirection") {
      onClickTrack(EventName.CLC_Close_Button_V3_Site_Generate_Upload_Horoscope_Popup)
    }
    setCompleteNow(true)
    // setopenpopup(true)
    setClickAddHoro(false)
    OnClose()
  }

  const handleClickOutside = (event) => {
    if (
      horopopupRef.current &&
      !horopopupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
      setCompleteNow(true);
    }
  };
  

  useEffect(() => {
    if(isFromSms != "yes"){
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }
  }, []);

  return (
    <>

      {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}

      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">


        <div ref={horopopupRef} className={`relative w-[100%]  ${isFrom == "clcRedirection" ? "md:w-[80%]" : "md:w-[83%]"} lg:w-[48%] md:my-6 md:mx-6`}>        
            <div className="">

          <button
            className={`${isFromSms == "yes" && isMobile ? "block absolute top-3 right-[2px]" : "hidden"} md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]`}
            onClick={() => handelCloseClick()}
          >
            <img className="w-6 h-6 " src={CloseIcon} alt="" />
          </button>

        </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

      <HoroBasic ShowpopupDataNotification={ShowpopupDataNotification} showdatas={showdatas} message={message} OnClose={OnClose} notifyId={notifyId} isFrom={isFrom} menu="addhoro" postData={postData} data={editMyProfileData} setShowingSuccessPopup={setShowingSuccessPopup}  editviatrialpack={editviatrialpack } notificationId={notificationId} from="trailpack" setClickAddHoro={setClickAddHoro} isFromSms={isFromSms} messageTypeId={messageTypeId} kmcareId={kmcareId} KMNotificationId={KMNotificationId}/>
            
            </div>
          </div>
        </div>
      </div>


    </>
  );
};
const CommonFamilyPopup = ({ props, OnClose, close, setopenpopup,ShowpopupDataNotification, editMyProfileData, postData, isFrom, editviatrialpack, notificationId, apiCallEditProfile, KMNotificationId,showdatas,message }) => {
  const [showPopup, setShowPopup] = useState(false);

  const { completeNow, setCompleteNow,
    clickAddFamily, setClickAddFamily,
    submitStatus, setSubmitStatus } = useContext(NavBarContext);
  const { loginDetail, logout } = useContext(LoginContext);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility

  const { t:trans } = useTranslation();

  console.log(showdatas ,message ,ShowpopupDataNotification,"ShowpopupDataNotification");

  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddFamily(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <>
      {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}


      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">


        <div className="relative w-[100%]  md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
          <div className="">

            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => handelCloseClick()}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>

          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />


              <p className={`text-[#000000] text-[18px] items-center font-semibold  pt-2 ${isFrom == 'TrailPack' ? 'lg:px-6 px-3' : 'justify-center  text-center'}`}>
                {isFrom == 'TrailPack' ? trans('trial_pack_popup__family_detai.add_family_details') : 'Family Details'}
              </p>

              <FamilyBasic 
                ShowpopupDataNotification={ShowpopupDataNotification} 
                showdatas={showdatas} 
                message={message}
                menu="addfamily" 
                OnClose={OnClose} 
                postData={postData} 
                data={editMyProfileData} 
                isFrom={isFrom} 
                editviatrialpack={editviatrialpack} 
                notificationId={notificationId} 
                setCompleteNow={setCompleteNow} 
                apiCallEditProfile={apiCallEditProfile} 
                KMNotificationId={KMNotificationId}
              />
            
            </div>
          </div>
        </div>
      </div>

    </>
  );
};


const GiftImageWithGif = ({ alternate }) => {

  const [showFirstSet, setShowFirstSet] = useState(true);

  useEffect(() => {

    
    const interval = setInterval(() => {
      setShowFirstSet((prev) => !prev); 
    }, 5000); // Change every 3 seconds (adjust time as needed)

    return () => clearInterval(interval);
  }, []);

  return (

    <div className="relative w-full h-full flex justify-center items-center">

    {showFirstSet && (
      
        <div className="relative top-[-39px] left-0">
          <img
            src={Images.freegif}
            alt="Free Gif"
            className="w-[4.5rem] h-[4.5rem]"
          />
        </div>
        )}

   {!showFirstSet && ( 
        <div className="relative top-[33px] left-0">
          <img
            src={Images.freegif}
            alt="Free Gif"
            className="w-[4.5rem] h-[4.5rem]"
          />
        </div>
        )}
    <img
      src={gifticon}
      alt="Gift Icon"
      className="w-[5rem] h-[6rem]"
    />

        {showFirstSet && (
        <div className="relative bottom-[-27px] left-0">
          <img
            src={Images.freegif}
            alt="Free Gif"
            className="w-[4.5rem] h-[4.5rem]"
          />
        </div>
      
    )}
{!showFirstSet && ( 
        <div className="relative bottom-[38px] right-0">
          <img
            src={Images.freegif}
            alt="Free Gif"
            className="w-[4.5rem] h-[4.5rem]"
          />
        </div>
    )}
   

  
         
  </div>
  );
};



const StaticCircularProgressBar = ({ value, page, completed, packStatusData }) => {
  const radius = 45;
  const strokeWidth = 6;
  const circumference = 2 * Math.PI * radius;
  const progress = (value / 100) * circumference; // Calculate the progress for right-to-left
  const [isShowDropDown, setSetShowDropDown] = useState(false);
  const { onClickTrack } = useAnalytics();

  useEffect(() => { }, [isShowDropDown]);

  const pointerRadius = 4; // Set the radius for the pointer dot
  const outerColorStrokeWidth = 10; // Set the desired width for the outer circle
  const innerColorStrokeWidth = 6; // Set the desired width for the inner progress bar

  return (
    <>
      <div
        className="flex flex-col items-center justify-center gap-5"
        onClick={(e) => {
          e.stopPropagation();
          onClickTrack("View_Profile_Match_Score_Match_Score_Button");
          document
            .getElementById("match-score-section")
            ?.scrollIntoView({ behavior: "smooth" });
        }}
      >

        <div
          className={
            "w-[127px] flex justify-center items-center"
          }
        >
          <svg viewBox="0 0 100 100">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: "#ffffff" }} />
                <stop offset="100%" style={{ stopColor: "#ffffff" }} />
              </linearGradient>
            </defs>
            <circle
              cx="50"
              cy="50"
              r={radius}
              fill="none"
              stroke="#ddd"
              strokeWidth={outerColorStrokeWidth}
            />
            <circle
              cx="50"
              cy="50"
              r={radius}
              fill="none"
              stroke="#ddd" // Outer circle color
              strokeWidth={strokeWidth}
            />
            <circle
              cx="50"
              cy="50"
              r={radius}
              fill="url(#gradient)" // Use linear gradient
              stroke="#039511" // Inner progress bar color
              strokeWidth={innerColorStrokeWidth}
              strokeDasharray={`${circumference} ${circumference}`}
              strokeDashoffset={circumference - progress} // Adjust progress for right-to-left
              strokeLinecap={value < 100 ? "round" : "butt"}
              transform="rotate(-90 50 50)" // Rotate the progress bar to start from the top
            />
            {/* Pointer Dot */}
            {value < 100 && (
              <circle
                cx={
                  50 +
                  radius * Math.cos((value / 100) * 2 * Math.PI - Math.PI / 2)
                }
                cy={
                  50 +
                  radius * Math.sin((value / 100) * 2 * Math.PI - Math.PI / 2)
                }
                r={pointerRadius}
                fill="#ffffff"
              />
            )}
          </svg>
          <div
            className="flex flex-col items-center justify-center"
            style={{
              position: "absolute",
              // top:"1rem"
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              // fontWeight: "bold",
              // width: "80%",
            }}
          >
            <img
              className="w-[6rem] h-[6rem] rounded-full object-cover"
              src={`${packStatusData?.profileurl ? packStatusData?.profileurl : DefaultAvatar}`}
              alt="no image"
            />
          </div>
        </div>
        <div className="absolute ">
          <p className="text-[#D10A11] relative pl-[13rem] text-[23px] md:text-[23px] lg:text-[23px] font-segoe-ui font-bold mt-1">
            {" "}
            {value}{"%"}
          </p>
        </div>
      </div>

    </>
  );
};



const ErrorPopup = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg md:w-1/3">
        <p className="text-lg font-semibold text-red-500">{message}</p>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 text-black bg-gray-300 rounded-lg hover:bg-gray-400"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};



export const KalyanRewardsPopup = ({ close }) => {

  const [active, setActive] = useState(0);
  const [isSkippedAfter, setIsSkippedAfter] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { t:trans } = useTranslation();

  const RewardPoints = [
    {
      id: 1,
      points: "50",
      imgsrc: Images.TrophyIcon,
      imgtext: trans('kalyan_rewards_popup.congrats_you_got_points_count',{points_count:50}),
    },
    {
      id: 2,
      points: "5",
      imgsrc: Images.LoginIcon,
      imgtext: trans('kalyan_rewards_popup.log_in_to_app_daily_and_earn_p',{points_count:5}),
    },
    {
      id: 3,
      points: "20",
      imgsrc: Images.EyeIcon,
      imgtext: trans('kalyan_rewards_popup.view_any_profile_and_earn_poin',{points_count:20}),
    },
    {
      id: 4,
      points: "30",
      imgsrc: Images.SelectIcon,
      imgtext: trans('kalyan_rewards_popup.select_any_profile_and_earn_po',{points_count:30}),
    },
    {
      id: 7,
      points: "10",
      imgsrc: Images.DocumentIcon,
      imgtext: trans('kalyan_rewards_popup.request_photo_parents_number_h',{points_count:10}),
    },
    {
      id: 5,
      points: "40",
      imgsrc: Images.MessageIcon,
      imgtext: trans('kalyan_rewards_popup.earn_points_count_points_by_se',{points_count:40}),
    },
    {
      id: 6,
      points: "50",
      imgsrc: Images.ShareIcon,
      imgtext: trans('kalyan_rewards_popup.earn_points_count_points_for_r',{points_count:50}),
    },
    {
      id: 11,
      points: "60",
      imgsrc: Images.MessageIcon,
      imgtext: trans('my_profile_missed.earn_points_count_points_by_se',{points_count:60}),
    },
    {
      id: 8,
      points: "70",
      imgsrc: Images.CallIcon,
      imgtext: trans('kalyan_rewards_popup.call_your_matches_and_e_arn_po',{points_count: 70 }),
    },
    {
      id: 10,
      points: "200",
      imgsrc: Images.HeartIcon,
      isComingSoon: true,
      imgtext: trans('kalyan_rewards_popup.earn_points_by_submitting_your'),
    },
    {
      id: 9,
      points: "100",
      imgsrc: Images.RingIcon,
      isComingSoon: true,
      imgtext: trans('kalyan_rewards_popup.purchase_jewelry_or_gold_schem'),
    },
  ];

  const isLast = (RewardPoints.length - 1) == active || isSkippedAfter


  const handleActive = () => {
    if (isLast) {
      close();
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath), {
        replace: true,
      });
    } else {
      setActive(prev => prev + 1);
    }
  }

  const handleSkip = () => {
    if (active == 0) {
      close();
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath));
    } else {
      setIsSkippedAfter(true)
      setActive(0);
    }
  }

  useEffect(() => {
    dispatch(rewardPopupShown())
  }, [])




  return (
    <div className='flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>

      <div className='relative w-[90vw] md:w-auto  my-[0.5rem] md:my-6 lg:my-6 mx-2'>
        <button
          className=' bg-transparent z-40 border-0 text-black  absolute  top-0 -right-1.5'
          onClick={close}>
          <img className='w-6 h-6 ' src={CloseIcon} alt='' />
        </button>
        <div className='border-0 rounded-2xl shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none py-4 md:py-8 lg:py-8'>
          <div className='flex items-center justify-center'>
            <div className='flex items-center justify-center bg-[#D10A11] rounded-lg py-2 px-[2rem]'>
              <img src={KalyanLeafLogo} alt='' className='w-6 h-6 md:h-8 md:w-8 lg:h-8 lg:w-8' />
              <p className='font-extrabold text-white '>{trans('kalyan_rewards_popup.kalyan_rewards')}</p>
            </div>
          </div>
          <div className='flex items-center justify-center pt-4'>
            <p className="text-black text-[18px] font-bold text-center">{trans('kalyan_rewards_popup.earn_points_and_claim_rewards')}</p>
          </div>

          {/* <div className="px-[1rem] md:px-[5rem] lg:px-[5rem]">
            {RewardPoints.map((val,index) => {
              return (
              <div className={`grid grid-cols-6 md:grid-cols-12 gap-4 md:gap-0  py-4 md:py-4 lg:py-4`}>
                <div className="flex items-center col-span-6 gap-4">
                    <img src={val.firstpointsrc} alt="" className="w-[3rem] h-[3rem]"/>
                    <p className="text-[#000000] text-[12px]">{val.firstpoint}</p>
                </div>
                {index == RewardPoints.length - 1 ? <></> : 
                  <div className="flex items-center col-span-6 gap-4">
                      <img src={val.secondpointsrc} alt="" className="w-[3rem] h-[3rem]"/>
                      <p className="text-[#000000] text-[12px]">{val.secondpoint}</p>
                  </div>
                }
              </div>
              )
            })}
          </div> */}

          {RewardPoints.map((val, index) => {
            return (
              <>
                <div className={`${index == active ? "" : "hidden"} font-Poppins-Regular`}>
                  <div className="flex items-center justify-center">
                    <div className="relative">
                      <img src={Images.PopperIcon} alt='' className="w-full h-[11rem]" />
                      <div className={`absolute  ${val.id == 4 ? "left-0 -top-[13%]" : val.id == 7 ? "left-[0] top-[2%]" : "left-[29%] top-[2%]"}`}>
                        <img src={val.imgsrc} alt='' className={`${val.id == 4 ? "w-[11rem] h-[13rem]" : val.id == 7 ? "w-[12rem] h-[10rem]" : "w-[5rem] h-[10rem]"} `} />
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col items-center justify-center gap-2 py-2'>
                    {val?.isComingSoon
                      ? <p className={`text-[#8292A8] font-Poppins-SemiBold text-[18px]`}>{trans('kalyan_rewards_popup.coming_soon')}</p>
                      : <p className={`${val.id == 1 ? "text-[#D10A11]" : "text-[#8292A8]"} font-Poppins-Bold text-[26px] lg:text-[25px]`}>{val.points} {trans('kalyan_rewards_popup.points')}<span className="text-[#D10A11] font-bold"> *</span></p>}
                    <p className="text-black font-semibold text-[12] w-[20rem] md:w-[25rem] px-4 text-center">{val.imgtext}</p>
                    <div className={`flex items-center gap-2 cursor-pointer ${(isLast) ? "hidden" : ""}`} onClick={handleSkip}>
                      <p className="text-[#8D9AAD] text-[14px] font-bold underline">{trans('kalyan_rewards_popup.skip')}</p>
                      <img src={Images.SkipIcon} alt='' className='w-3 h-3' />
                    </div>
                  </div>
                  <div className='flex items-center justify-center py-2'
                    onClick={handleActive}>
                    <button className={`flex items-center justify-center bg-[#D10A11] rounded-md py-2 cursor-pointer ${trans('kalyan_rewards_popup.know_more') ? 'px-[4.5rem] ' : 'px-[6.5rem] '}`}>
                      <p className=' text-white font-extrabold text-[18px]'>{isLast ? trans('kalyan_rewards_popup.know_more') : trans('kalyan_rewards_popup.next')}</p>
                    </button>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <PaperBlast />
    </div>
  )
}

const PromptPopup = (props) => {
  const { onClickTrack } = useAnalytics();
  const closePopupRef = useRef();
  const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
  console.log(props.promptFrom, 'ppp')
  //for parents number
  const isParentsNumberAvailble = profileData?.profiledetails?.profileparentsnumber != "";
  const isParentsNumberRequested = profileData?.profiledetails?.parentnumberrequestcheck;

  //for photo
  const isProfilePicAvailable = !profileData?.profiledetails?.isAvatarPic;
  const isProfilePicRequested = profileData?.profiledetails?.profilerequestcheck;

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  // Set all checkboxes to be selected by default
  const [selectedRequests, setSelectedRequests] = useState({
    photo: true,
    trust: true,
    parentNumber: true,
    horoscope: true,
  });

  const { t:trans } = useTranslation();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [props]);

  const handleCheckboxChange = (requestType) => {
    setSelectedRequests((prevRequests) => ({
      ...prevRequests,
      [requestType]: !prevRequests[requestType],
    }));
  };

  const promptRequest = () => {
    if (profileData?.horoscopeRequest === "N") {
      if (selectedRequests.horoscope) {
        props.sendRequestApi("Horoscope");
      }
    }
    if (profileData?.trustRequest === "N") {
      if (selectedRequests.trust) {
        props.sendRequestApi("Trust");
      }
    }
    if (isProfilePicAvailable === false && isProfilePicRequested === false) {
      if (selectedRequests.photo) {
        props.sendRequestApi("Photo");
      }
    }
    if (isParentsNumberAvailble === false && isParentsNumberRequested === false) {
      if (selectedRequests.parentNumber) {
        props.sendRequestApi("Parents Number");
      }
    }
    if (props.promptFrom === "Share to Family Yes") {
      props.sendRequest();
      props.setPrompt();
    }
    if (props.promptFrom === "Share to Family") {
      props.shareToFamily();
    }
    props.close();
    onClickTrack(EventName.ViewProfilePromptPopupRequestButton);
  };



  return (
    <>
      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none">
        <div
          className="relative w-[100%] md:w-[55%] lg:w-[36%] modal-container md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
                if (props.promptFrom === "Share to Family Yes") {
                  props.sendRequest();
                  props.setPrompt();
                }
                if (props.promptFrom === "Share to Family") {
                  props.shareToFamily();
                }
              }}
            >
              <img className="w-6 h-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <div className="space-y-1">
                <p className="text-[#D10A11] font-bold text-[18px]">
                  {trans('request_popup.know_them_more')}
                </p>
                <p className="text-[#7A7A7A] pl-1 text-[13px] pt-2 pb-2 leading-[24px]">
                  {trans('request_popup.know_more_about_this_profile_b')}
                  <br />
                  {trans('request_popup.request')} :
                </p>
                <div className="grid grid-cols-2 gap-2">
                  {(isProfilePicAvailable === false && isProfilePicRequested === false) && (
                    <div className="flex items-center mt-2 lg:mt-3">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.photo}
                        onChange={() => handleCheckboxChange("photo")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                        {trans('request_popup.photo')}
                      </label>
                    </div>
                  )}
                  {profileData?.trustRequest === "N" && (
                    <div className="flex items-center mt-2 lg:mt-3">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.trust}
                        onChange={() => handleCheckboxChange("trust")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                      {trans('request_popup.trust_document')}
                      </label>
                    </div>
                  )}
                  {(isParentsNumberAvailble === false && isParentsNumberRequested === false) && (
                    <div className="flex items-center mt-2 lg:mt-3">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.parentNumber}
                        onChange={() => handleCheckboxChange("parentNumber")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                      {trans('request_popup.parents_number')}
                      </label>
                    </div>
                  )}
                  {profileData?.horoscopeRequest === "N" && (
                    <div className="flex items-center mt-2 lg:mt-3">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.horoscope}
                        onChange={() => handleCheckboxChange("horoscope")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                      {trans('request_popup.horoscope')}
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-center gap-5 pt-3 pb-4">
                <button
                  onClick={() => {
                    props.close();
                    if (props.promptFrom === "Share to Family Yes") {
                      props.sendRequest();
                      props.setPrompt();
                    }
                    if (props.promptFrom === "Share to Family") {
                      props.shareToFamily();
                    }
                    onClickTrack(EventName.ViewProfilePromptPopupCancelButton);
                  }}
                  className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] rounded-[4px] border text-[#7A7A7A] border-[#707070]"
                >
                  {trans('request_popup.cancel')}
                </button>
                <button
                  className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
                  onClick={promptRequest}
                >
                  {trans('request_popup.request')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CustomerCareTemplateMsgPopup = (props) => {


  console.log(props?.conversations, props?.customerTemplate, "conversationspro");
  const closePopupRef = useRef();
  const dispatch = useDispatch();


  const userData = useSelector((state) => state?.DashBoardAPI?.ProfileData?.data);
  const { currentConversationId } = useSelector((state) => ({ currentConversationId: state?.Chats?.currentChat?.conversationId }));

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        if (window.innerWidth < 768) {
          props.close();
        }
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  // const handleTextClick = (e, text, imageSrc) => {
  //   // Fetch the image as a binary file
  //   fetch(imageSrc)
  //     .then(res => res.blob())
  //     .then(blob => {
  //       const imageFile = new File([blob], "image.png", { type: blob.type });

  //       dispatch(
  //         sendCustomerServiceMessage({
  //           conversation_id: currentConversationId,
  //           file: text, // Pass only the text content as 'file'
  //           message_type_id: 7,
  //           template_image: imageFile, // Pass the binary image file as 'template_image[]'
  //         }),
  //       );
  //     });

  //   // Update the `is_popup_show` in the Redux state to false
  //   dispatch({
  //     type: 'UPDATE_IS_POPUP_SHOW',
  //     payload: {
  //       conversationId: currentConversationId,
  //       is_popup_show: false,
  //     },
  //   });

  //   props.close(); // Close the popup
  // };




  const handleTextClick = (e, text, imageSrc, templateId) => {


    const messageRequest = {
      conversation_id: currentConversationId,
      file: text,
      message_type_id: 7,
      message_template_id: templateId,
        };
    
        if (props?.locationFrom === "microcare") {
          messageRequest.microtrackId = localStorage.getItem('microtrackId');
        } else{
          localStorage.removeItem('microtrackId')
        }

    dispatch(
      sendCustomerServiceMessage(messageRequest),
    );

    // Update the `is_popup_show` in the Redux state to false
    dispatch({
      type: 'UPDATE_IS_POPUP_SHOW',
      payload: {
        conversationId: currentConversationId,
        is_popup_show: false,
      },
    });

    props.close(); // Close the popup
  };


  console.log(props.showpopup, 'props.showpopup')

  const handleChatClick = () => {
    document.getElementById("customerSupport")?.focus();
    props.close();
  }

  const handleClose = () => {
    props.close();
    dispatch({
      type: 'UPDATE_IS_POPUP_SHOW',
      payload: {
        conversationId: currentConversationId,
        is_popup_show: false,
      },
    });
  };

  useEffect(() => {
    props?.setCustomerTemplate(true)
  }, [props?.customerTemplate])


  const { t:trans } = useTranslation();


  console.log(props?.isConversationLoading, "props?.isConversationLoading")
  return (
    <>
      {props?.isConversationLoading ?
        <Loader />
        :
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center lg:items-center focus:outline-none">
          <div className="relative lg:w-[30vw] md:w-[55vw] w-full md:my-6 lg:my-6" ref={closePopupRef}>
            <div className="hidden md:block lg:block">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                onClick={handleClose}
              >
                <img className="w-5 h-5 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="rounded-t-[3rem] md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-center px-4 pt-8 mx-2 rounded-t ">
                <h3 className="text-[20px] ml-2 font-semibold align-middle text-center">
                  {/* Welcome To <span className="text-[#D10A11]">Kalyan Matrimony!</span>
                  <p className="flex justify-center">We are here to hear your queries.</p>
                  <p className="flex justify-center">Feel free to ask.</p> */}
                  {trans('km_care_popup.welcome_to_kalyan_matrimonywe')}
                </h3>
              </div>
              <div className='relative flex-auto px-6 pt-3 pb-6'>
                <div className='items-center flex-auto '>
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={(e) => handleTextClick(e, "How to Search Matches?", Images.RedLensIcon, 1)}
                    >
                      How to <img src={Images.RedLensIcon} className="w-4 h-4 space-x-4" alt="" />
                      Search Matches?
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={(e) => handleTextClick(e, "How to Search with Family?", Images.FamilyIcon, 2)}
                    >
                      How to <img src={Images.FamilyIcon} className="w-4 h-4 space-x-4" alt="" />Search with Family?
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={(e) => handleTextClick(e, "How to Add a Profile Photo?", Images.RedCameraIcon, 3)}
                    >
                      How to <img src={Images.RedCameraIcon} className="w-4 h-4 space-x-4" alt="" />Add a Profile Photo?
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={(e) => handleTextClick(e, "How to Complete My Profile?", Images.UserRedIcon, 4)}
                    >
                      How to <img src={Images.UserRedIcon} className="w-4 h-4 space-x-4" alt="" />Complete My Profile?
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={(e) => handleTextClick(e, "How to Call Matches?", Images.PhoneIconRed, 5)}
                    >
                      How to <img src={Images.PhoneIconRed} className="w-4 h-4 space-x-4" alt="" />Call Matches?
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-center py-4">
                    <p
                      className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                      onClick={() => handleChatClick()}
                    >
                      Chat<img src={Images.ChatMsgIconRed} className="w-4 h-4 space-x-4" alt="" />with us!
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}



const RankingStartPopup = (props) => {
  const closePopupRef = useRef();
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate for potential future routing needs
  localStorage.setItem('ratingClicked', JSON.stringify(
    'Rating link clicked'
  ));

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 768px)");

    const handler = (e) => {
      if (closePopupRef.current && !closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };

    const addListeners = () => {
      if (mobileQuery.matches) {
        document.addEventListener("mousedown", handler);
      }
    };

    const removeListeners = () => {
      document.removeEventListener("mousedown", handler);
    };

    addListeners();
    return () => {
      removeListeners();
    };
  }, [props]);

  const handleFeedbackChange = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setFeedback(value);
      setError('');
    } else {
      setError('Feedback cannot exceed 250 characters');
    }
  };

  const handleSendClick = () => {
    if (rating > 0) { // Ensure rating is provided
      dispatch(
        RankingPopup({
          profile_id: localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
          agent_id: localStorage.getItem(Constants.loginLocalStorageKeys.admUserId),
          message_id: localStorage.getItem(Constants.loginLocalStorageKeys.messageId),
          ratings: rating,
          comments: feedback,
        })
      );
      if (rating === 4 || rating === 5) {
        window.open(RouteSegments.staticLinkForAppDownload.android, '_blank');
        props.close();
        localStorage.setItem(Constants.loginLocalStorageKeys.RankingClick, 'No')
      } else {
        props.close(); // Optionally close the popup after sending feedback
        localStorage.setItem(Constants.loginLocalStorageKeys.RankingClick, 'No')
      }
    } else {
      setError('Rating is required');
    }
  };

  const RatingStars = () => {
    const colors = {
      red: "#D71920",
      grey: "#C4C4C4"
    };

    const [hover, setHover] = useState(undefined);

    const handleClick = (value) => {
      setRating(value);
    };

    const handleMouseOver = (value) => {
      setHover(value);
    };

    const handleMouseLeave = () => {
      setHover(undefined);
    };

    const stars = Array(5).fill(0);

    return (
      <div className="flex space-x-4">
        {stars.map((_, index) => {
          const starColor = (hover || rating) > index ? colors.red : colors.grey;

          return (
            <FaStar
              key={index}
              size={24}
              style={{ marginRight: 10, cursor: "pointer", color: starColor }}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none">
        <div
          className="relative w-[100%] md:w-[55%] lg:w-[42%] xl:w-[36%] modal-container md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={props.close}
            >
              <img className="w-6 h-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none px-9">
            <div className="flex items-center py-6 lg:justify-center justify-start border-b border-[#DBDBDB]">
              <h3 className="text-[19px] text-[#D10A11] ml-2 font-Poppins-Bold align-middle">
                Rate Us
              </h3>
            </div>
            <div className="flex flex-col items-center py-5 justify-center border-b border-[#DBDBDB] space-y-2">
              <h3 className="text-[14px] text-[#1A1A1A] font-Poppins-SemiBold text-center">
                Rate Your Experience
              </h3>
              <div className="flex items-center pt-4 space-x-4">
                <img src={SadImage} className="w-[2rem] h-[2rem]" alt="Sad" />
                <RatingStars />
                <img src={HappyImage} className="w-[2rem] h-[2rem]" alt="Happy" />
              </div>
            </div>

            <div className="flex-col items-center justify-center px-5 pt-4 text-center">
              <h3 className="text-[11px] text-[#1A1A1A] font-Poppins-Reqular lg:mx-[3.5rem] mx-2">
                We would love to hear from you. Feel free to share your valuable feedback/suggestions here
              </h3>
            </div>

            <div className="flex-col items-center justify-center pt-2 text-center lg:px-8">
              <div className="pt-4">
                <input
                  type="text"
                  className="border border-[#C4C4C4] w-full rounded-md font-Poppins font-medium text-[#000000] py-2 px-2 text-[12px] lg:w-[20rem]"
                  id="feedback"
                  placeholder="Feedback/suggestions"
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
                {error && <p className="text-[#D10A11] text-xs">{error}</p>}
              </div>
            </div>

            <div className="flex items-center justify-center py-4">
              <button
                onClick={handleSendClick}
                className={`text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#000000] font-Poppins-Bold rounded-[4px] ${rating > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                disabled={rating === 0} // Disable the button if rating is not given
                style={{
                  background: 'linear-gradient(90deg, #FDC300 0%, #E59903 100%)'
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


// 
const ValidationNotificationPopup = (props) => {
  const message = props?.message
  const dispatch = useDispatch()

  useEffect(() => {
    if (message) {
      toast(
        <div className='flex items-center'>
          <img src={Images.NotificatationTick} className='w-10 h-10 mr-4' alt='' />
          {message}
        </div>,
        {
          hideProgressBar: true,
          onClose: () => {
            props?.onClose()
          },
          // style: { backgroundColor: '#2f9e44',color:"white" }
        }
      )
    }
  }, [message])

  useEffect(() => {
    if (props?.isFrom === "chatPage") {
      dispatch(
        getConversationList({
          receiver_id: props?.datas?.receiverId,
          receiver_status: props?.datas?.loggedInProfileStatus,
          isMessageFrom: "pushNotification"
        }),
      );
    }
  }, [props?.isFrom])


  return <></>
};

const CommonUploadSuccessfull = (props) => {
  const closePopupRef = useRef();
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const handleClickOutside = (event) => {
    if (
      closePopupRef.current &&
      !closePopupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      props.close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack focus:outline-none">
        <div
          className="relative w-[90%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0  md:rounded-lg shadow-lg rounded-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <div className=''>
                <div className="flex items-center justify-center py-4 mx-2 rounded-t">
                  <p className="text-[#D10A11] flex justify-center font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                    Photo Uploaded Successfully
                  </p>
                </div>
                <div className="flex justify-center items-center pb-[1rem]">
                  <img className="w-16 h-16" alt="" src={SuccessIcon} />
                </div>
                <div className="hidden lg:block md:block">
                  <div className="flex justify-center gap-5 pb-1 md:pt-2">
                    <button
                      onClick={() => {
                        props.close();
                        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath), {
                          state: { isFromInitialPopup: true, notificationId: props.notificationId },
                        })
                      }
                      }
                      className="text-[13px] py-1.5 px-[4rem] text-[#D10A11] rounded-[6px] font-bold border border-[#000000]"
                    >
                      View Privacy Settings
                    </button>
                  </div>
                </div>
                <div className="lg:hidden md:hidden">
                  <div className="flex justify-center gap-5 pb-1 md:pt-2">
                    <button
                      onClick={() => {
                        props.close();
                        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath), {
                          state: { isFromInitialPopup: true, notificationId: props.notificationId }
                        })
                      }
                      }
                      className="text-[13px] py-1.5 px-[4rem] text-[#ffffff] rounded-[6px] font-bold bg-[#D10A11]"
                    >
                      View Privacy Settings
                    </button>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>

  );
};


const StarRaasiPopup = ({ OnClose, icon, title, content, notificationId }) => {
  const { showAstroPopup, setShowAstroPopup } = useContext(NavBarContext);

  const [editMyProfileData, setEditMyProfielData] = useState();
  const [showpopup, setShowpopup] = useState(true)
  const { loginDetail, logout } = useContext(LoginContext);
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);

  console.log(showAstroPopup, "showAstroPopup");
  const { t:trans } = useTranslation();
  // const [notificationId, setNotificationId] = useState()

  // useEffect(() => {
  //   setNotificationId(ProfileData?.popup[0]?.notificationId)
  //   console.log(notificationId, 'notificationIdb');


  // }, [])
  console.log(notificationId, "notificationIdmn");

  const { onClickTrack } = useAnalytics();


  const onStarRaasiPopupClose = async (data) => {
    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": true,
      "notificationId": notificationId,

    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
    }
  }
  const buttonClick = async (data) => {
    // onStarRaasiPopupClose()
    setShowAstroPopup(true)
    setShowpopup(false)
    onClickTrack(EventName.CLCAddNowButton);
    onStarRaasiPopupClose()
  }

  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);


    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };


  // const onStarPopupClose = async (data) => {
  //   setShowStarRaasiPopup(false)
  //   const request = {
  //     "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
  //     "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
  //     "isClicked": data,
  //     "notificationId": notificationId
  //   }
  //   Update_Notification_Status(request);

  //   if (!data) {
  //     sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
  //   }

  // }

  useEffect(() => {
    apiCallEditProfile()
  }, [])
  const closePopupRef = useRef();
  return (

    <div>
      {showAstroPopup &&
        <AstroPopup menu="addastro" data={editMyProfileData} OnClose={() => setShowAstroPopup(false)} />

      }
      {showpopup &&
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
          <div
            className="relative lg:w-[40vw] md:w-[70vw] w-[90vw] modal-container md:my-6 md:mx-6"
            ref={closePopupRef}
          >
            <div className="">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] left-0"
                onClick={() => {
                  OnClose();
                  onClickTrack(EventName.CLC_CloseButton);
                }}
              >
                <img className="w-5 h-5 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="border-0 rounded-md shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto px-4 py-8 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-2xl">
                <div className="flex flex-col justify-center items-center mb-5 mx-[1rem]">
                  <div className="rounded-full mx-auto bg-[#FEBABA] p-4 flex items-center justify-center">
                    <img src={icon} className="w-12 h-12" alt="" />
                  </div>
                  <span className="text-[#D10A11] flex  font-bold text-[16px] md:text-[14px] lg:text-[18px] py-3">
                    {title}
                  </span>

                  <span className="text-[#575556] text-[14px] lg:text-[16px]  py-3 text-center">
                    {content}
                  </span>
                </div>
                {
                  <button
                    onClick={buttonClick}
                    className="mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    {trans('matches.add_now')}!
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const AstroPopup = ({ data, OnClose, notificationListId,messageTypeId,kmcareId }) => {


  const { openAstroPopup, setOpenAstroPopup } = useContext(NavBarContext)
  const { onClickTrack } = useAnalytics();

  console.log(openAstroPopup, "openAstroPopup");

  const handelCloseClick = () => {
    OnClose();
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && window.innerWidth <= 768) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {openAstroPopup &&
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
          <div ref={popupRef} className="relative w-[100%] md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
            <div>
              <button
                className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
                onClick={() => {
                  handelCloseClick()
                  onClickTrack(EventName.CLC_AstroCloseButton);
                }}
              >
                <img className="w-6 h-6" src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto md:p-6">
                <hr className="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />
                <p className="text-[#000000] py-3 items-center text-[18px] font-semibold pt-2 justify-center text-center">
                  Astrological Information
                </p>
                <AstroPopupDetails menu="addastro" type="astropopup" data={data} notificationListId={notificationListId} messageTypeId={messageTypeId} kmcareId={kmcareId} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )

}

const CommonMonthlyPopup = (props) => {
  const closePopupRef = useRef();
  const { loginDetail, logout } = useContext(LoginContext);
  const { showValidationMessage, setShowValidationMessage, submitStatus ,completeNow, setCompleteNow} = useContext(NavBarContext)
  const [professionalInfo, setProfessionalInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { onClickTrack } = useAnalytics()
  const {t:trans} = useTranslation();

  const [editMyProfielData, setEditMyProfielData] = useState()

  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    // setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);

    } else if (statusCode === 401) {
      logout();
    }
    // setIsEditProfileLoading(false);
  };

  useEffect(() => {
    apiCallEditProfile()
  }, [])

  const fetchProfeesionlInfo = useMemo(() => {
    return async () => {
      try {
        let { statusCode, data } = await GET(
          `${config.api.API_URL}${EndPoints.educationinfofreshUrl()}`,
          {} // Replace `request` with an empty object or provide necessary parameters
        );

        if (statusCode === 200) {
          if (data?.status === "Success") {
            setProfessionalInfo(data?.data);
          } else {
            setError('Unexpected response structure');
          }
        } else if (statusCode === 401) {
          logout(); // Handle unauthorized access
        } else {
          setError('Failed to fetch family info');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  }, [logout]);
  useEffect(() => {

    fetchProfeesionlInfo()

  }, []);
  const formik = useFormik({
    initialValues: {
      income: editMyProfielData?.monthlyIncome || "", // Cleaner optional value check
      currency: editMyProfielData?.currency || "",    // Cleaner optional value check
    },
    validationSchema: Yup.object({
      income: Yup.string()
        .required('Monthly Income is required')
        .matches(/^\d+$/, 'Monthly Income must be a valid number'), // Add a pattern to ensure it's a number if needed
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        await apiCallMonthlyIncome(values);
      } catch (error) {
        console.error("Error updating monthly income:", error); // Optional error handling
      }
    },
  });

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
    
  );


  // showdatas={showdatas} message={message}

  // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ?
  //  sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId



  //  const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
  //  const Notificationsid = userData?.popup[0]?.notificationId;
   
   const NotificationId =
    localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
        ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
          props?.showdatas ||
          props?.message ||
          props?.ShowpopupDataNotification
        : userData?.popup[0]?.notificationId ||
          props?.message ||
          props?.ShowpopupDataNotification;


          console.log(NotificationId,'NotificationIdNotificationIdj');


  const apiCallMonthlyIncome = async (values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      monthly: values.income,
      currencyName: values.currency,
      monthlyincomerequesttypeid: 20,
      requestfulfilledfrom: "Desktop",
      requestfulfilledvia: "chat",
      recieverprofileid: getGroupAuthId(),
      editviatrialpack: props.editviatrialpack,
      notificationId: NotificationId ? NotificationId : props.KMNotificationId
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.monthlyincomeinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setShowValidationMessage(true)
        setTimeout(() => {
          props.close();
          setCompleteNow(true)
          props?.postData()
        }, 2000);

      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const ClosePopup = ()=>{
    props.close();
    setCompleteNow(true);
  }

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[30%] xl:w-[30%] md:my-6 md:mx-6"
        // ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
            onClick={ClosePopup}
          >
            <img className="w-6 h-6" src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="flex-col">
              <div className='md:hidden'>
                <div className='flex items-center justify-between pb-4'>
                  <p className="text-[#000000] text-[18px] items-center font-semibold  pt-2 lg:px-6 md:px-3">{trans('trial_pack_popup__professional.add_professional_details')} </p>
                  <img src={RedClose} className='w-6 h-6' alt=''
                   onClick={() => { 
                    props.close() ;
                    setCompleteNow(true);}} />
                </div>
              </div>
              <div className='hidden lg:block md:block'>
                <p className={`text-[#000000] text-[18px] items-center font-semibold  pt-2 pb-4`}>{trans('trial_pack_popup__professional.add_professional_details')}</p>
              </div>
              <p className="flex  text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1  font-semibold">{trans('trial_pack_popup__professional.monthly_income')}</p>
              <form onSubmit={formik.handleSubmit}>
                <label className="relative block" name="professional">
                  <div className="mx-1">
                    <div className="flex items-center space-x-3">
                      <label className="select font-bold text-[#575556] w-[8rem]">
                        <select
                          name="currency"
                          id="currency"
                          value={formik.values.currency}
                          onChange={formik.handleChange}
                          className="md:p-[0.4rem] lg:p-2.5 border !mt-[2px] border-[#8F8F8F] font-semibold text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px] w-full"
                        >
                          {professionalInfo.currency?.map((e, i) => (
                            <option key={i} value={e.currency}>
                              {e.currency}
                            </option>
                          ))}
                        </select>
                      </label>
                      <input
                        type="tel"
                        id="income"
                        name="income"
                        className="RegPhone border border-[#8F8F8F] text-sm rounded-[3px] h-[35px] block w-full p-[0.4rem] lg:p-2.5 font-semibold text-[#575556]"
                        value={formik.values.income}
                        onChange={(e) => {
                          if (validateNumber(e.target.value)) {
                            formik.handleChange(e);
                          }
                        }}
                      />
                    </div>
                    <p className="text-[#D71920] text-[12px] pt-1">
                      *{trans('trial_pack_popup__professional.your_monthly_income_will_refle')}
                    </p>
                  </div>
                </label>

                <div className="flex justify-center gap-5 pt-6">
                  <button onClick={() => {
                    props.close();
                    setCompleteNow(true);

                  }} className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] rounded-md border border-[#707070]">
                   {trans('trial_pack_popup__professional.cancel')}
                  </button>
                  <button type="submit" onClick={onClickTrack(EventName.Trial_Pack_popup_Add_Monthly_Income_Submit_Button)}
                    className={`text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#FFFFFF] font-bold rounded-md ${!formik.dirty ? "cursor-not-allowed bg-[#8E8E8E]" : 'bg-red-600'}`}
                  >
                    {formik.isSubmitting ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" />
                    <span>{trans('my_profile.saving')}</span></div> : <span> {trans('trial_pack_popup__professional.save')}</span>}
                  </button>
                </div>
              </form>
            </div>

          </div>
          {/* </form> */}
        </div>
      </div>
    </div>

  )
}

const CommonDescriptionPopup = (props) => {
  const closePopupRef = useRef();
  const { loginDetail, logout } = useContext(LoginContext);
  const { showValidationMessage, setShowValidationMessage, submitStatus } = useContext(NavBarContext)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { onClickTrack } = useAnalytics()
  const [editMyProfielData, setEditMyProfielData] = useState()

  const {t:trans} = useTranslation();
  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    // setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);

    } else if (statusCode === 401) {
      logout();
    }
    // setIsEditProfileLoading(false);
  };

  useEffect(() => {
    apiCallEditProfile()
  }, [])
  const formik = useFormik({
    initialValues: {
      moreInfo: editMyProfielData?.moreInfo ? editMyProfielData?.moreInfo : "",
    },
    validationSchema: Yup.object({
      moreInfo: Yup.string().required("Description is required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        await apiCallDescription(values);
      } catch (error) {
        console.error("Error updating monthly income:", error); // Optional error handling
      }
    },
  });
  const onInfoChange = (e) => {
    let text = e.target.value ?? "";

    text = text.replace(/  +/g, " ")

    formik.setFieldValue('moreInfo', text)
  }

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? 
  // sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
 
  // const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
  // const Notificationsid = userData?.popup[0]?.notificationId;
  
  const NotificationId =
    localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
        ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
          props?.showdatas ||
          props?.message ||
          props?.ShowpopupDataNotification
        : userData?.popup[0]?.notificationId ||
          props?.message ||
          props?.ShowpopupDataNotification;


          console.log(NotificationId,'NotificationIdNotificationIddis');
 
  const apiCallDescription = async (values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      more_info: values.moreInfo,
      descriptionrequesttypeid: 43,
      requestfulfilledfrom: "Desktop",
      requestfulfilledvia: "viewprofile",
      recieverprofileid: getGroupAuthId(),
      editviatrialpack: props.editviatrialpack,
      notificationId: NotificationId ? NotificationId : props.KMNotificationId
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editdescriptioninfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // props.callBackReload()
        setShowValidationMessage(true)
        setTimeout(() => {
          props.close();
          props.setCompleteNow(true)
          props?.postData()
        }, 2000);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[30%] xl:w-[30%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
              // if(submitStatus){
              props.setCompleteNow(true);
              // }
            }}
          >
            <img className="w-6 h-6" src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative flex-auto p-6">
            <div className="flex-col">
              <div className='md:hidden'>
                <div className='flex items-center justify-between pb-4'>
                  <p className="text-[#000000] text-[18px] items-center font-semibold  pt-2 lg:px-6 px-3">{trans('trial_pack_popup__description.add_description')}</p>
                  <img src={RedClose} className='w-6 h-6' alt='' onClick={() => { 
                    props.close() ;
                    props.setCompleteNow(true)}} />
                </div>
              </div>
              <div className='hidden lg:block md:block'>
                <p className={`text-[#000000] text-[18px] items-center font-semibold  pt-2 pb-4`}>{trans('trial_pack_popup__description.add_description')}</p>
              </div>
              <>
                <form onSubmit={formik.handleSubmit}>
                  <label className="relative block" name="moreInfo">
                    <textarea
                      name="moreInfo"
                      id="moreInfo"
                      rows="4"
                      className={`form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[7px] focus:ring-[#2D2C6F] border-[1px] border-[#D9D9D9] py-2 px-1 md:py-2 md:px-2  w-full block`}
                      value={formik.values.moreInfo.replace(/\s\s+/g, ' ')}
                      onChange={onInfoChange}
                      placeholder={trans('trial_pack_popup__description.tell_about_you_your_family_and')} 
                    />
                  </label>
                  <p className="text-[#D71920] text-[12px] pt-2">
                    *{trans('trial_pack_popup__description.your_description_will_reflect')}
                  </p>
                  <div className="flex justify-center gap-5 pt-6">
                    <button onClick={() => {
                      props.close();
                      // if(submitStatus){
                      props.setCompleteNow(true);
                      // }
                    }} className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] rounded-md border border-[#707070]">
                      {trans('trial_pack_popup__description.cancel')}
                    </button>
                    <button type="submit" onClick={() => { onClickTrack(EventName.Trial_Pack_popup_Add_Description_Submit_Button) }}
                      disabled={!formik.dirty}
                      className={`text-[12px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#FFFFFF] font-bold rounded-[4px]  ${!formik.dirty ? "cursor-not-allowed bg-[#8E8E8E]" : "bg-red-600"}`}
                    >
                      {formik.isSubmitting ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>{trans('my_profile.saving')}</span></div> : <span>{trans('trial_pack_popup__description.save')}</span>}
                    </button>
                  </div>
                </form>
              </>
            </div>

          </div>
          {/* </form> */}
        </div>
      </div>
    </div>

  )
}

const CommonRegisterPopup = (props) => {
  const closePopupRef = useRef();
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const { onClickTrack } = useAnalytics();

  const handleClickOutside = (event) => {
    if (
      closePopupRef.current &&
      !closePopupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      props.close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className="fixed inset-0 z-50 items-center justify-center hidden overflow-x-hidden overflow-y-auto outline-none md:flex bg-opacityblack focus:outline-none">
        <div
          className="relative w-[90%]  md:w-[80%] lg:w-[60%] xl:w-[55%] md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
                onClickTrack(EventName.Home_Page_New_Register_Popup_Close_Button)
              }}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0  md:rounded-lg shadow-lg rounded-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto p-6">
              <div className='flex items-center gap-5'>
                <div className="flex flex-col items-center justify-center py-4 mx-2 space-y-3">
                  <p className="text-[#000000] text-center font-bold text-[23px] md:text-[23px] lg:text-[25px] xl:text-[28px]  pr-[1rem] ">
                    Your Perfect Match is Waiting!
                  </p>
                  <p className="text-[#000000] text-center text-[12px] md:text-[14px] lg:text-[16px] xl:text-[18px] pr-[1rem] ">
                    Join Lakhs of mobile-verified profiles from different communities, religions, and parts of the world.
                  </p>
                  <button
                    onClick={() => {
                      props.close();
                      props?.handleRegisterClick()
                      onClickTrack(EventName.Home_Page_New_Register_Popup_Register_Now_Button)
                    }
                    }
                    className="text-[14px] lg:text-[16px] xl:text-[18px] py-1.5 px-[4rem] bg-[#D10A11] text-white rounded-[30px] font-bold"
                  >
                    Register Now
                  </button>
                  <p className="text-[#000000] text-center font-bold text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] pr-[1rem] ">
                    Start your search today!
                  </p>
                </div>
                <div>
                  <img src={Images.MarriagePhoto} className="w-[24rem] xl:w-[26rem] h-[14rem] xl:h-[15rem] rounded-[1rem]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const CommonAddPhotoPopup = ({ close, notificationId }) => {

  const [ShowUploadPhoto, setShowUploadPhoto] = useState(false)
  const [openAddPhoto, setOpenAddPhoto] = useState(true)
  const [successMessage, setsuccessMessage] = useState("");
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);

  const { t:trans } = useTranslation();

  const handleClick = () => {
    setShowUploadPhoto(true)
    setOpenAddPhoto(false)
    onSaveClick()

  }


  const onSaveClick = async (data) => {
    const notificationsId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": true,
      "notificationId": notificationId ? notificationsId : localStorage.getItem(Constants.loginLocalStorageKeys.Notification),
      "clickButton": "Wizard_Photo",

    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
    }
  }
  return (
    <>

      {showSuccessPopup && (
        <CommonSuccessPopup
          close={() => setshowSuccessPopup(false)}
          title={trans('photos.success')}
          content={successMessage}
        />
      )}
      {ShowUploadPhoto &&
        <UploadPhotoPopuplite
          title={trans('photo_popup.add_photo')}
          callBack={() => {
            setsuccessMessage(trans('photos.image_uploaded_successfully'))
            setshowSuccessPopup(true)
          }}
          onSaveClick={onSaveClick}
          close={() => setShowUploadPhoto(false)}
          isFrom="wizardphoto"
        />
      }
      {openAddPhoto &&
      <>
      <div className='hidden lg:block md:block'>
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center lg:items-center focus:outline-none">
          <div className="relative w-[100%] md:w-[30rem] lg:w-[30rem] md:my-6 lg:my-6" >
          {/* <div className='fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack75 md:items-center focus:outline-none'>
            <div className='relative w-[100%] md:w-[30rem] lg:w-[32rem] md:my-6 md:mx-auto max-h-[70vh]'> */}
            <div className="hidden md:block lg:block">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-0 left-[-6px]"
                onClick={close}
              >
                <img className="w-5 h-5 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="rounded-tl-[3rem] md:py-5 lg:py-4  md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
             
              <div className="flex justify-center py-2"><div className="bg-[#FDEEBE] rounded-full p-4 w-fit justify-center flex">
                <img className="w-[3rem] h-[3rem] object-contain" src={Images.addphoto}></img>
              </div>
              </div>
              <div className="items-center justify-center hidden px-4 py-3 mx-2 rounded-t md:flex ">
                <h3 className="text-[16px] ml-2 font-bold align-middle text-center text-[#D10A11]">
                  {trans('clc_wizard_popup__photo.add_photo_to_your_profile')}
                </h3>
              </div>
              <p className="text-[#000000] lg:text-[14px] semibold md:text-[15px] text-center px-[2rem] md:px-[5rem] py-2 md:py-1">
              {trans('clc_wizard_popup__photo.adding_photo_to_your_profile_i')}
              </p>

              <div className='relative px-6 lg:px-[3rem] md:px-[3rem] pb-6 pt-3 flex-auto '>
                <div className='flex-auto items-center bg-[#F0F8FEDE] border-[1px] border-[#D9D9D9] px-[1rem] md:px-[3rem] py-4 lg:space-y-2 md:space-y-4 rounded-md'>
                  <p className="text-[#1A1A1A] font-bold">{trans('clc_wizard_popup__photo.steps')}</p>
                  <p className="text-[#1A1A1A] text-[13px] font-Poppins-SemiBold">1. Click the "Add Photo Now!" button below.</p>
                  <p className="text-[#1A1A1A] text-[13px] font-Poppins-SemiBold">2. Select any one option to upload photo</p>
                  <div className="px-4 space-y-3">
                    <p className="flex gap-3 text-[#1A1A1A] text-[12px]"> <img className="w-5 h-5" src={Images.photoadd} /> Camera</p>
                    <p className="flex gap-3 text-[#1A1A1A] text-[12px]"><img className="w-5 h-5" src={addphotoIcon} />Gallery</p>
                    <p className="flex gap-3 text-[#1A1A1A] text-[12px]"><img className="w-5 h-5" src={whatsappIcon} />Via WhatsApp</p>
                    <p className="flex gap-3 text-[#1A1A1A] text-[12px]"> <img className="w-5 h-5" src={facebookIcon} />Via Facebook</p>
                    <p className="flex gap-3 text-[#1A1A1A] text-[12px]"> <img className="w-5 h-5" src={Images.instagramicon} />Via Instagram</p>
                  </div>

                  <p className="text-[#1A1A1A] text-[13px] font-Poppins-SemiBold">3. Choose and upload your photo.</p>
                </div>
              </div>

              <div className="flex justify-center "><button onClick={handleClick} className="text-[#FFFFFF] font-bold text-[13px] 
          bg-[#D10A11] px-5 py-2 rounded-[6px]">{trans('clc_wizard_popup__photo.add_photo_now')}</button></div>
            </div>
          </div>
        </div>
      </div>
      <div className='lg:hidden md:hidden'>
        <div className={'flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none overflow-y-hidden '}>
          <div
            // className={`relative modal-container md:my-6 md:mx-6 lg:w-[40vw] md:w-[70vw] w-[90vw]`}
            className={`relative  modal-container md:my-6 md:mx-6 w-full h-full lg:w-[40vw] md:w-[70vw] pt-[5rem]`}
          >
            <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none h-full`}>
              <div className={`px-4 pb-4 sm:p-6 sm:pb-4 rounded-2xl relative py-8 flex-auto pt-[1.5rem]`}>
                <div className={`flex flex-col justify-center mb-5 mx-[1rem] `}>
                    <>
                      <div className='flex justify-between'>
                        <span className="text-[#000000] flex  font-bold text-[22px] md:text-[14px] lg:text-[18px] pb-[1.5rem] pt-2">
                          {trans('clc_wizard_popup__photo.add_photo_to_your_profile')}
                        </span>
                        <img src={RedClose} className='w-6 h-6' alt=''
                          onClick={close}
                        />
                      </div>
                      <div className="rounded-full mx-auto bg-[#FDEEBE] p-4 flex items-center justify-center">
                        <img src={Images.addphoto} className="w-12 h-12" alt="" />
                      </div>
                    </> 
                    <div className="flex-auto overflow-y-auto lite-scrollbar scroll-Container scrollbar-none" >
                      <p className="text-[#000000] text-[14px] pt-[1.5rem] pb-[1rem]  text-center">
                      {trans('clc_wizard_popup__photo.adding_photo_to_your_profile_i')}
                      </p>
                      <div className='relative flex-auto pt-3 pb-6 '>
                        <div className='flex-auto items-center bg-[#F0F8FEDE] border-[1px] border-[#D9D9D9] px-[1rem] md:px-[3rem] py-4 space-y-4 rounded-md'>
                          <p className="text-[#1A1A1A] font-bold">{trans('clc_wizard_popup__photo.steps')}</p>
                          <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">1.{trans('clc_wizard_popup__photo.click_the_add_photo_now_button')}</p>
                          <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">2.{trans('clc_wizard_popup__photo.select_any_one_option_to_uploa')}</p>
                          <div className="px-4 space-y-3">
                            <p className="flex gap-3 text-[#1A1A1A] text-[14px]"> <img className="w-5 h-5" src={Images.photoadd} /> {trans('clc_wizard_popup__photo.camera')}</p>
                            <p className="flex gap-3 text-[#1A1A1A] text-[14px]"><img className="w-5 h-5" src={addphotoIcon} />{trans('clc_wizard_popup__photo.gallery')}</p>
                            <p className="flex gap-3 text-[#1A1A1A] text-[14px]"><img className="w-5 h-5" src={whatsappIcon} />{trans('clc_wizard_popup__photo.via_whatsapp')}</p>
                            <p className="flex gap-3 text-[#1A1A1A] text-[14px]"> <img className="w-5 h-5" src={facebookIcon} />{trans('clc_wizard_popup__photo.via_facebook')}</p>
                            <p className="flex gap-3 text-[#1A1A1A] text-[14px]"> <img className="w-5 h-5" src={Images.instagramicon} />{trans('clc_wizard_popup__photo.via_instagram')}</p>
                          </div>

                          <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">{trans('clc_wizard_popup__photo.choose_and_upload_your_photo')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="absolute bottom-[1rem] left-0 w-full py-4 pb-[1rem] bg-white">
                      <button
                        onClick={handleClick}
                        className="mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                      >
                       {trans('clc_wizard_popup__photo.add_photo_now')}
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      }
    </>
  )
}

const CommonAddParentNumberPopup = ({ notificationId, close, userData }) => {
 

  const [openParentNumber, setopenParentNumber] = useState(false)
  const [showParentNumber, setShowParentNumber] = useState(true)

  const handleClick = () => {
    setopenParentNumber(true)
    setShowParentNumber(false)
    onSaveClick()
  }

  const{t:trans} = useTranslation();
  const onSaveClick = async (data) => {
    const notificationsId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);

    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": true,
      "notificationId": notificationId ? notificationId : localStorage.getItem(Constants.loginLocalStorageKeys.Notification),
      "clickButton": "Wizard_Parent’s Number",
    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
    }
  }
  return (
    <>
      {openParentNumber &&
        <ParentsPhonePopupLite close={() => setopenParentNumber(false)}
          title={trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
          callBackApply={() => { }}
          parentsIsdCode={userData?.parentsIsdCode}
          parentsMobileNumber={userData?.parentsMobileNumber}
          onSaveClick={onSaveClick}
          notificationId={localStorage.getItem(Constants.loginLocalStorageKeys.Notification)}
        />
      }
      {showParentNumber &&
      <>
      <div className='hidden lg:block md:block'>
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center lg:items-center focus:outline-none">
          <div className="relative w-[100%] md:w-[30rem] lg:w-[32rem] md:my-6 lg:my-6" >

            <div className="hidden md:block lg:block">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-0 left-[-6px]"
                onClick={close}
              >
                <img className="w-5 h-5 " src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="rounded-tl-[3rem] py-5 md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between mx-3">
                <div className="items-start px-4 md:hidden">
                  <h3 className="text-[20px] ml-2 font-bold  text-[#1A1A1A]">
                   {trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
                  </h3>
                </div>

                <img src={RedClose} className='w-6 h-6 md:hidden' alt=''
                  onClick={close}
                />
              </div>
              <div className="flex justify-center py-2 md:py-0"><div className="bg-[#FDEEBE] rounded-full p-6 w-fit justify-center flex">
                <img className="w-[2rem] h-[2rem] object-contain" src={Images.callimage}></img>
              </div>
              </div>
              <div className="items-center justify-center hidden px-4 py-2 pt-3 mx-2 rounded-t md:flex ">
                <h3 className="text-[20px] ml-2 font-semibold align-middle text-center text-[#D10A11]">
                {trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
                </h3>
              </div>
              <p className="text-[#000000] text-[15px] text-center  px-[2rem] py-3 md:py-1 ">
              {trans('clc_wizard_popup__parents_numb.add_your_parents_mobile_number')}</p>

              <p className="text-[#000000] text-[15px] text-center px-[2rem]  py-1 ">{trans('clc_wizard_popup__parents_numb.your_parents_can_also_search_v')}</p>

              <div className='relative px-[2rem] md:px-[4rem] pb-6 pt-3 flex-auto '>
                <div className='flex-auto items-center bg-[#F0F8FEDE] border-[1px] border-[#D9D9D9] px-[1rem] md:px-[3rem] py-4 space-y-4 rounded-md'>
                  <p className="text-[#1A1A1A] font-bold">{trans('clc_wizard_popup__parents_numb.steps')}</p>
                  <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">1. {trans('clc_wizard_popup__parents_numb.click_the_add_now_button_below')} </p>
                  <p className="text-[#1A1A1A] text-[14px] flex gap-2 whitespace-nowrap font-Poppins-SemiBold">2.{trans('clc_wizard_popup__parents_numb.enter_your_parents')} <img className="w-5 h-5" src={Images.groupicon} /> {trans('clc_wizard_popup__parents_numb.mobile_number')}</p>
                  <p className="text-[#1A1A1A] text-[14px] flex gap-2 font-Poppins-SemiBold">3.{trans('clc_wizard_popup__parents_numb.select')}<img className="w-5 h-5" src={Images.familygroup} />{trans('clc_wizard_popup__parents_numb.father_mother')}</p>
                  <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">4.{trans('clc_wizard_popup__parents_numb.click_submit_button')}</p>

                </div>
              </div>

              <div className="flex justify-center "><button onClick={handleClick}
                className="text-[#FFFFFF] font-bold text-[13px] bg-[#D10A11] px-[2rem] py-2 rounded-[6px]">{trans('clc_wizard_popup__parents_numb.add_now')}</button></div>
            </div>
          </div>
        </div>
      </div>
      <div className='md:hidden lg:hidden'>
        <div className={'flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none overflow-y-hidden '}>
          <div
            // className={`relative modal-container md:my-6 md:mx-6 lg:w-[40vw] md:w-[70vw] w-[90vw]`}
            className={`relative  modal-container md:my-6 md:mx-6 w-full h-full lg:w-[40vw] md:w-[70vw] pt-[5rem]`}
          >
            <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none h-full`}>
              <div className={`px-4 pb-4 sm:p-6 sm:pb-4 rounded-2xl relative py-8 flex-auto pt-[1.5rem]`}>
                <div className={`flex flex-col justify-center mb-5 mx-[1rem] `}>
                    <>
                      <div className='flex justify-between'>
                        <span className="text-[#000000] flex  font-bold text-[22px] md:text-[14px] lg:text-[18px] pb-[1.5rem] pt-2">
                        {trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
                        </span>
                        <img src={RedClose} className='w-6 h-6' alt=''
                          onClick={close}
                        />
                      </div>
                      <div className="rounded-full mx-auto bg-[#FDEEBE] p-4 flex items-center justify-center">
                        <img src={Images.callimage} className="w-12 h-12" alt="" />
                      </div>
                    </> 
                    <div className="flex-auto overflow-y-auto lite-scrollbar scroll-Container scrollbar-none" >
                      <p className="text-[#000000] text-[14px] py-[1.5rem]  text-center">
                      {trans('clc_wizard_popup__parents_numb.add_your_parents_mobile_number')} </p>

                      <p className="text-[#000000] text-[14px] py-[0.5rem]  text-center">
                      {trans('clc_wizard_popup__parents_numb.your_parents_can_also_search_v')}
                        </p>

                      <div className='relative flex-auto pt-3 pb-6 '>
                        <div className='flex-auto items-center bg-[#F0F8FEDE] border-[1px] border-[#D9D9D9] px-[1rem] md:px-[3rem] py-4 space-y-4 rounded-md'>
                          <p className="text-[#1A1A1A] font-bold">{trans('clc_wizard_popup__parents_numb.steps')}</p>
                          <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">1. Click the "Add Now!" button below. </p>
                          <p className="text-[#1A1A1A] text-[14px] flex gap-2 whitespace-nowrap font-Poppins-SemiBold">2.{trans('clc_wizard_popup__parents_numb.2_enter_your_parents')} <img className="w-5 h-5" src={Images.groupicon} /> {trans('clc_wizard_popup__parents_numb.mobile_number')}</p>
                          <p className="text-[#1A1A1A] text-[14px] flex gap-2 font-Poppins-SemiBold">3.{trans('clc_wizard_popup__parents_numb.3_select')} <img className="w-5 h-5" src={Images.familygroup} /> {trans('clc_wizard_popup__parents_numb.father_mother')} </p>
                          <p className="text-[#1A1A1A] text-[14px] font-Poppins-SemiBold">4.{trans('clc_wizard_popup__parents_numb.4_click_submit_button')} .</p>

                        </div>
                      </div>
                    </div>
                    <div className="absolute bottom-[1rem] left-0 w-full py-4 pb-[1rem] bg-white">
                      <button
                        onClick={handleClick}
                        className="mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                      >
                     {trans('clc_wizard_popup__parents_numb.add_now')}
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      }
    </>
  )
}

const CommonNotificationAlertPopup = ({setNotificationPopup, close, content, icon, isFrom, showRequestForPermissions ,notificationUpdate ,postData ,requestPermission ,NotificatiorequestPermission}) => {
  const { completeNow, setCompleteNow } = useContext(NavBarContext);
 const [showContinuePopup,setShowContinuePopup]= useState(false)
  const OnClose = () =>{
    
    if(isFrom == "trail"){
      setCompleteNow(true)
      showRequestForPermissions()
      notificationUpdate()
      postData()
    }
 

    close();
    NotificatiorequestPermission()
  }
  return (
    <>
    {showContinuePopup &&
      <TrailNotificationPopup />
    }
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%] md:w-[70%] lg:w-[45%] xl:w-[40%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative px-6 flex-auto">
            <div className="space-y-2 text-center py-4 xl:py-6">
              <div className="flex items-center justify-center mb-4 border-b border-dashed border-gray-400 pb-2">
                <p className="text-[#D10A11] font-bold text-[18px] ">Don't Miss Any Updates! Turn on Notifications!</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <img
                  src={icon ? icon : DangerIcon}
                  className="w-12 h-12"
                  alt="Danger Icon"
                />
              </div>
              <div className="py-3">
                <p className="text-[#000000] xl:text-[16px] text-[14px]">{content}</p>
              </div>
            </div>

            <div className="flex justify-center gap-5 pb-4">
              <button
                onClick={OnClose}
                className="text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600"
              >
                {isFrom == "notification" || isFrom == "trail"?
                "Allow"
                :
                "OK"
                }
                
              </button>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};


const DescriptionPopup = ({ onClose , moreInfo, isFrom ,descriptionEdit,isTrail,editviatrialpack,setCompleteNow,postData,ApiCallEditProfile,data, setIsDescriptionEdited}) => {


  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [messageOptions, setmessageOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);
  const [exampleContent, setExampleContent] = useState([]);
  const { loginDetail, logout } = useContext(LoginContext);
  const { onClickTrack } = useAnalytics();
  const [editMyProfielData,setEditMyProfielData] = useState([])
  const [changeDescription,setChangeDescription] = useState(false)
  const [selectedOption,setSelectedOption] = useState()
  const isSmallMobile = useMediaQuery({ query: "(max-height: 666px)" });
  const{t:trans} = useTranslation();

console.log(editMyProfielData,"messageOptions");


  const formik = useFormik({
    initialValues: {
      moreInfo: editMyProfielData?.moreInfo ? editMyProfielData?.moreInfo : "",
      descriptionEdit: descriptionEdit ? descriptionEdit : "",
      descriptionContentId:"",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      moreInfo: Yup.string(),
    }),

    onSubmit:async (values) => {
      await apiCallEditProfile(values);
    },
  });
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId



  useEffect(() => {
    console.log(data,'datamm');
    
  }, [data]);

  const callEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    // setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);

    } else if (statusCode === 401) {
      logout();
    }
    // setIsEditProfileLoading(false);
  };

  const apiCallEditProfile = async (values) => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      requestfulfilledfrom:getDeviceInfo(),
      recieverprofileid:getGroupAuthId(),
      more_info: values.moreInfo,
      requestfulfilledvia:isFrom,
      descriptionEdit:values?.descriptionEdit,
      descriptionContentId: values?.descriptionContentId ? Number(values.descriptionContentId) : undefined,
      ...(values.moreInfo != "" && {descriptionrequesttypeid:43}),
      ...(isTrail=="trailPack" && {editviatrialpack: editviatrialpack}),
      ...(isTrail=="trailPack" && {notificationId: NotificationId}),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editdescriptioninfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {

        if(isTrail=="trailPack"){
          setTimeout(() => {
            onClose();
            setCompleteNow(true)
            postData()
          }, 2000);
        }else{
          onClickTrack(EventName.profileDescriptionSaveButton , {[EventKeys.profileDescription]:values });
          onClose();
        }
        if(isFrom=="viewprofile"){
          setIsDescriptionEdited(true)
        }
      }

      if (values.descriptionEdit === "Y") {
        toast.success(
          "Detail added successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony"
        );
      } else {
        toast.success("Detail added successfully. The added detail is reflected in your profile.");
      }
    } else if (statusCode === 401) {
      logout();
    }
  };


  const descriptionOptions = async () => {

    let request = {
      userId: localStorage.getItem("LOGIN_ID")
    };

    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.descriptionPopupUrl()}`,
      request
    );
    if (data?.data?.status === "Success") {
      if (data?.data?.data?.descriptions) {
        setmessageOptions(data?.data?.data?.descriptions.map(option => ({ ...option, id : option.descriptionContentId, checked : false,label : option.description})));
      } 
    }else {
      console.log("Error while fetching description popup options");
    }
  }

  const descriptionFormat = async () => {

    try{
      let data = await axios.get(
        `${config.api.API_URL}${EndPoints.getdescriptiontooltipUrl()}`
      );
      if (data?.data?.status === "Success") {
          setFormatOptions(data?.data?.data?.content_for_tooltip);
          setExampleContent(data?.data?.data?.example_content)
  
      }else {
        console.log("Error while fetching description tooltip");
      }
    }catch(error){
      console.log("Error while fetching description tooltip",error);
    }
    
  }

  useEffect(()=>{
    descriptionOptions();
    descriptionFormat();
    if(isTrail=="trailPack"){
      callEditProfile()
    }
    else if(isFrom=="viewprofile"){
      callEditProfile()
    }
  },[])


  const onOptionChange = (e) => {
    const { id } = e.target;
    setChangeDescription(true)
    setSelectedOption(id)
    setmessageOptions(prev => prev.map(option => ({ ...option, checked: id == option.id })));
    formik.setFieldValue("moreInfo", messageOptions?.filter(option => option.id == id)[0]?.label);
    formik.setFieldValue("descriptionContentId", id);
    formik.setFieldValue('descriptionEdit',"N")
  };

  
  const onInfoChange = (e)=>{
    let text = e.target.value ?? "";
    setChangeDescription(true)
    text = text.replace(/  +/g, " ")

    formik.setFieldValue('moreInfo',text)
    formik.setFieldValue('descriptionEdit',"Y")
    formik.setFieldValue("descriptionContentId", "")

    setmessageOptions((prevOptions) =>
      prevOptions.map((option) => ({ ...option, checked: false }))
    );
  }

  const Example = () => {

    return (
      <div className="flex flex-col p-4 overflow-y-auto h-[70dvh] lite-scrollbar md:h-[55vh]">
        <div className="relative">
          <div className="absolute right-0">
            <img src={RedClose} className='w-6 h-6 cursor-pointer' alt='' onClick={() => { 
              setOpen(false);
              setShowTooltip(false)
             }} />
          </div>
        </div>
        <div>
          <p className="font-bold text-[14px] md:text-[16px] mr-3">
            {trans('add_description_popup.include_the_following_for_your')}:
          </p>
            <ul className="px-1 text-[#575556] text-[11px] md:text-[13px] py-3 space-y-1">
              {formatOptions.map((option,index) => {
                return (
                  <li key={index} className="flex gap-2">
                  <span className="text-[#D10A11] text-xl leading-none">&bull;</span> {/* Custom marker */}
                    {option}
                  </li>
                )
              })}
            </ul>
            <div className="py-4">
              <div className="border-t-2 border-[#D10A11] border-dashed flex w-full"></div>
            </div>
            {/* <p className="font-bold text-[16px] py-2">
              Example:
            </p> */}
            <p className="text-[#575556] text-[11px] md:text-[13px] text-justify	w-full">
              <span className="text-[#000000] font-bold text-[14px] md:text-[16px] py-2">{trans('add_description_popup.sample')} : {" "}</span>
              {exampleContent}
            </p>
        </div>
      </div>
    )
  }

  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef?.current && !popoverRef?.current?.contains(event.target)) {
        setOpen(false);
        setVisible(false);
        setShowTooltip(false)
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set visible to true after 4 seconds
    const showTimeout = setTimeout(() => {
        setVisible(true);

        // Then set visible to false after an additional 4 seconds
        const hideTimeout = setTimeout(() => {
            setVisible(false);
        }, 4000);

        // Cleanup hide timeout if component unmounts
        return () => clearTimeout(hideTimeout);

    });

    // Cleanup show timeout if component unmounts
    return () => clearTimeout(showTimeout);
  }, []);

  return (
    <>
      <div className="md:flex md:justify-center bg-opacityblack overflow-y-hidden  overflow-x-hidden md:overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto md:my-4 md:mx-6">
      <div className="border-0 shadow-lg relative md:mt-[0.5rem] lg:mt-0 mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
      <div className="grid grid-cols-6 items-center gap-3 border-b-[.5rem] border-b-[#D10A11] rounded-t px-2 hidden md:grid">
  {/* Left Image */}
  <div className="col-span-2 flex justify-center md:justify-start">
    <img
      className="w-[7rem] h-[3rem] md:w-[10rem] md:h-[3.5rem] lg:w-[9rem] lg:h-[3.5rem] xl:w-[10rem] xl:h-[4.5rem] 2xl:w-[14rem] 2xl:h-[5.5rem]"
      src={Images.kalyanLogo}
      alt=""
    />
  </div>

  {/* Center Text */}
  <div className="col-span-2 flex justify-center">
    <h3 className="text-[22px] text-[#D10A11] font-semibold whitespace-nowrap">{trans('add_description_popup.add_description')}</h3>
  </div>

  {/* Close Icon */}
  <div className="col-span-2 flex justify-center md:justify-end">
    <img
      className="h-[2.5rem] w-[3.5rem] 2xl:h-[3rem] 2xl:w-[4rem] cursor-pointer"
      src={RedClose}
      alt=""
      onClick={()=>{
        onClose()
        if(isTrail=="trailPack"){
        setCompleteNow(true)
        }
      }}
    />
  </div>
</div>

            <div className={` items-center justify-between block md:hidden rounded-b border-b-2 border-[#D10A11] border-dashed  `}>
            <div className="pl-2 pt-2">
                <img className="h-[2rem] w-[2rem] cursor-pointer" src={RedClose} alt="" onClick={() => {
                  onClose();
                  if(isTrail=="trailPack"){
                  setCompleteNow(true)
                  }
                }} />
              </div>
              <div className="pb-2">
                <h3 className="text-[22px] text-[#D10A11] text-center font-semibold ">
                  {trans('add_description_popup.add_description')}
                </h3>
              </div>
             
            </div>
            <div className="relative flex-auto overflow-y-auto">
            <div className="flex flex-col w-[100%] lg:w-[86vw] lite-scrollbar overflow-y-auto  px-[1rem] md:px-[3rem] pt-[1rem] pb-[1rem] md:pb-[2rem]">
            <form onSubmit={formik.handleSubmit}>
                <div className={`${isSmallMobile ? "" : "md:max-h-[73vh] lg:max-h-[58vh] xl:max-h-[62vh]"} h-[90dvh] overflow-y-auto scrollbar-none pb-[1rem] md:pb-0`}>
                  <div className="flex flex-col gap-2 md:gap-4 ">
                    <div className="flex items-center gap-3">
                      <p className="font-bold text-[16px] md:text-[20px]">
                        {trans('add_description_popup.write_your_description')}
                      </p>
                      <div ref={popoverRef}>
                      <Popover
                          trigger={"click"}
                          placement="bottom"
                          content={<Example />}
                          overlayClassName="absolute lg:!top-[12.5rem] xl:!top-[13.5rem] 3xl:!top-[24rem] 2xl:!top-[14.5rem] lg:!left-[27rem] xl:!left-[28rem] 2xl:!left-[29rem] md:!top-[197px] popover-arrow md:w-[70%] w-[90%] !left-[4rem] lg:w-[50%]"
                          onClick={()=>{ setVisible(!visible);setOpen(!open);setShowTooltip(!showTooltip) }}
                          open={open}
                      >
                        <Tooltip
                          placement="topLeft"
                          overlayClassName="custom-tooltip"
                          open={!showTooltip && visible}
                          onVisibleChange={(v) => setVisible(v)}
                          title={<p className="font-semibold text-[14px] text-[#000000] placeholder:text-[#585858] placeholder:opacity-50 flex">{trans('add_description_popup.click_here_to_know_how_to')} <br className="block md:hidden" /> {trans('add_description_popup.write_your_description')}.</p>}
                        >
                          <div>
                            <img className="h-[1.5rem] w-auto cursor-pointer" src={Images.InfoIcon} alt="" />
                          </div>
                        </Tooltip>
                      </Popover>
                      </div>
                      <span className="font-bold text-[20px]">:</span>
                    </div>
                    <textarea
                      rows="3"
                      id="description"
                      name="moreInfo"
                      value={formik.values.moreInfo.replace(/\s\s+/g, ' ')}
                      onChange={onInfoChange}
                      placeholder={trans('add_description_popup.click_here_to_type_your_descri')}
                      className="form-control shadow-sm  bg-[#FFFFFF] text-[#585858] text-[13px] focus:outline-none rounded-[7px] focus:ring-[#2D2C6F] border-[1px] border-[#606060] py-2 px-1 md:py-2 md:px-2  w-full block  placeholder:text-[#585858] placeholder:opacity-50"
                    />
                  </div>
                    <div className="relative flex items-center justify-center gap-2 py-4">
                      <div className="border-t-2 border-[#D10A11] border-dashed flex w-[50%]"></div>
                      <div className=" border-[4px] border-[#D10A11] rounded-full">
                        <p className="text-[#D10A11] text-[20px] p-[0.4rem] font-bold">
                          {trans('add_description_popup.or')}
                        </p>
                      </div>
                      <div className="border-t-2 border-[#D10A11] border-dashed flex w-[50%]"></div>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold text-[16px] md:text-[20px] pb-3 md:pb-6">
                        {trans('add_description_popup.based_on_your_profile_we_sugge')} :
                      </p>
                      
                      {messageOptions.map((val, i) => (
                          <div
                          key={i}
                          className={`p-3 mb-3  lg:w-full md:w-full rounded ${selectedOption == val?.id ? "border-2 border-green-700 bg-[#ECFEE4]" : "bg-[#F1F1F1]"}`}>
                          <label htmlFor={val.id} className='flex text-start items-center'>
                            <input
                              id={val.id}
                              type='radio'
                              name='message'
                              checked={val.checked}
                              onChange={onOptionChange}
                              className='accent-green-700 mt-0'
                            />
                            <p className='font-segeo font-semibold ml-2 text-[13px] text-[#575556]'>
                              {val.label}
                            </p>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className=" md:hidden lg:hidden pb-8">
                      <p className="text-[#585858] text-[12px] pt-2">
                      {/* <i>*You can also edit this in My profile page under About me</i> */}
                      <i>*{trans('add_description_popup.you_can_edit_your_description')}</i>
                      </p>
                      <div className="flex justify-center space-x-8 mt-2 md:mt-4">
                        <button
                          disabled={!changeDescription}
                          type="submit"
                          className={`text-[18px] xl:text-[20px] font-segeo font-bold text-[#FFFFFF] ${!changeDescription ? "bg-[#6F6F6F] cursor-not-allowed" : "bg-[#D10A11]"} rounded-[5px] w-[12rem] py-2`}
                        >
                          {trans('add_description_popup.save')}
                        </button>
                      </div>
                    </div>
                    </div>
                    <div className={`${isSmallMobile ? "max-h-[17vh]" : "max-h-[15vh] md:max-h-[10vh]"} hidden md:block`}>
                      <p className="text-[#585858] text-[12px] pt-2">
                      {/* <i>*You can also edit this in My profile page under About me</i> */}
                      <i>*{trans('add_description_popup.you_can_edit_your_description')}</i>
                      </p>
                      <div className="flex justify-center space-x-8 mt-2 md:mt-4">
                        <button
                          disabled={!changeDescription}
                          type="submit"
                          className={`text-[18px] xl:text-[20px] font-segeo font-bold text-[#FFFFFF] ${!changeDescription ? "bg-[#6F6F6F] cursor-not-allowed" : "bg-[#D10A11]"} rounded-[5px] w-[12rem] py-2`}
                        >
                          {trans('add_description_popup.save')}
                        </button>
                      </div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const WhatsAppNumperPopup = (props) =>{

  const { loginDetail, logout } = useContext(LoginContext);

  const [isdCodeList, setIsdCodeList] = useState([]);

  const [onSubmitPopup, setOnSubmitPopup] = useState(false)
  const [showpopup, setShowpopup] = useState(false)

  const [isShowMessage, setIsShowMessage] = useState("")
  const [errorMsg, setErrorMsg] = useState('')
  
  const closePopupRef = useRef();
  
  const isMobile = window.innerWidth <= 768;

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const [relationDropDown, setRelationDropDown] = useState(userData?.whatsapprelation);


  useEffect(() => {
    apicallIsdCode();
  }, []);

  const formik = useFormik({
    initialValues: {
      isdCode: "+91",
      whatsAppMobileNumber: "",
      relationshipId: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      isdCode: Yup.string().required("Please Select Isd code"),
      whatsAppMobileNumber: Yup.string()
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode === "+91") {
            if(!value){
              return this.createError({
                message: "Please enter a Phone Number",
                path: "whatsAppMobileNumber",
              })
            }else{
              return value && value?.length === 10 ? 
                true :
                this.createError({
                  message: "Please enter valid Phone Number",
                  path: "whatsAppMobileNumber",
                })
            }
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode !== "+91") {
            if(!value){
              return this.createError({
                message: "Please enter a Phone Number",
                path: "whatsAppMobileNumber",
              })
            }else{
              return (value && (value?.length >= 7 && value?.length <= 15)) ? 
                true :
                this.createError({
                  message: "Please enter valid Phone Number",
                  path: "whatsAppMobileNumber",
                })
            }
          }
          return true;
        }),
        relationshipId: Yup.string().required("Please select relation"),

    }),

    onSubmit: (values) => {
      apicallSubmitParentsNum(values);
    },
  });



  const apicallIsdCode = async () => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.gteIsdCodeUrl()}`
    );

    if (statusCode === 200) {
      setIsdCodeList(data?.data?.isdcode);
    } else if (statusCode === 401) {
      logout();
    }
  };


  const apicallSubmitParentsNum = async (value) => {
    
    let request = {
      profile_id:userData?.profileId,
      isdcode: value.isdCode,
      mobilenumber: value.whatsAppMobileNumber,
      relationship_id: value.relationshipId,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getPhotoViaWhatsappUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data?.data?.status == "SUCCESS") {
        setShowpopup(true);
        props.setWhatsAppSuccess(true);
        props.close();
      } 
    } else if (statusCode === 401) {
      logout();
    }
  };



  const handleClickOutside = (event) => {
    if (closePopupRef.current && !closePopupRef.current.contains(event.target) && window.innerWidth <= 768) {
      props.close();
    }
  };

  useEffect(() => {

      document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6" ref={closePopupRef}>
      <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
      <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
        <div className="relative p-6 flex-auto">
            <p className="flex justify-center text-[18px] text-[#D10A11]  font-semibold font-poppins border-b-2 border-dashed border-[#CCCCCC] pb-4">
              Add WhatsApp Number
            </p>
            <form  onSubmit={formik.handleSubmit} className="lg:space-y-3 xl:space-y-3 md:space-y-4 lg:pt-[0.5rem] xl:pt-[1rem] md:pt-[0.5rem] pt-[0.5rem]">
              <div className="flex space-x-2 lg:space-x-3 my-2 lg:ml-2 md:ml-2 ml-1">
                <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[5rem] md:w-[8rem] lg:w-[7rem] xl:w-[9rem]">
                  <select
                    name="isdCode"
                    id="isdCode"
                    value={formik.values.isdCode}
                    // onChange={changeStatus}
                    onChange={formik.handleChange}
                    className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[6px] leading-[20px]  "
                  >
                    <option >Select</option>
                    {isdCodeList?.map((e, i) => (
                      <option key={i} value={e.isdcode}>
                        {`${e.isdcode} - ${e.country}`}
                      </option>
                    ))}
                  </select>
                  {formik.errors.isdCode && (
                    <p className="text-[red] text-[12px] whitespace-nowrap">
                      {formik.errors.isdCode}
                    </p>
                  )}
                </label>

                <label>
                  <input
                    type={"tel"}
                    id="whatsAppMobileNumber"
                    name="whatsAppMobileNumber"
                    placeholder="Enter Phone Number"
                    value={formik.values.whatsAppMobileNumber}
                    onChange={(e) => {
                      if (validateNumber(e.target.value)) {
                        formik.setFieldValue("whatsAppMobileNumber", e.target.value);
                      }
                    }}
                    className="number no-spinner w-[11rem] md:w-full lg:w-full p-2 md:p-2.5 lg:p-2.5 my-1 md:text-[13px] xl:text-[14px] font-Poppins-Regular text-[#575556]  rounded-[6px] border border-[#C4C4C4]"
                  />
                  {formik.errors.whatsAppMobileNumber && (
                    <div className="h-2 mb-2">
                      <p className="text-[red] text-[12px] whitespace-nowrap">
                        {formik.errors.whatsAppMobileNumber}
                        </p>
                    </div>
                  )}
                </label>

                <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[6rem] md:w-[8rem] lg:w-[9rem] xl:w-[11rem]">
                  <select
                    id="relationshipId"
                    name="relationshipId"
                    placeholder="Select Relation"
                    value={formik.values.relationshipId}
                    onChange={formik.handleChange}
                    className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  placeholder:!text-[#575556]"
                  >
                    <option value="" disabled>Select Relation</option>
                      {relationDropDown?.map((e, i) => (
                        <option key={i} value={e.id}

                        disabled={formik.values.alternateRelationshipId == e.id} className={formik.values.alternateRelationshipId == e.id ? 'hidden' : ''}
                        >
                          {`${e.name}`}
                        </option>
                      ))}
                  </select>
                  {formik.errors.relationshipId && (
                    <p className="text-[red] text-[10px] md:text-[12px] lg:text-[12px] font-medium whitespace-nowrap">
                      {formik.errors.relationshipId}
                    </p>
                  )}
                </label>
              </div>
              
              <div className="flex  justify-center items-center space-x-8 w-full lg:mt-1 md:mt-1 mt-4 mb-2">
                <button
                  type="submit" 
                  className="w-auto flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-bold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                >
                    {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const WhatsAppNumperSuccessPopup = (props) =>{


  const closePopupRef = useRef();
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth < 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [windowWidth]);

  const handleClickOutside = (event) => {
    if (closePopupRef.current && !closePopupRef.current.contains(event.target)) {
      props.close();
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
    const navigate = useNavigate();
    const handleClickContinue = () => {
      sessionStorage.removeItem("photoPage");
        if (isCommunitySite) {
            if (localStorage.getItem("submitpref")) {
                localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
                navigate(RouteSegments.MATCH_GROUP)
            }
        } else {
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.DOWNLOAD_PAGE)
            navigate(RouteSegments.DOWNLOAD_PAGE)
        }
      }

  return (
    <div>
       <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        >
          {/* <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="h-6 w-6 " src={CloseIcon} alt="" />
            </button>
          </div> */}
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="flex justify-center whitespace-nowrap text-center border-b-2 border-dashed border-[#CCCCCC] pb-4">
              <p className="text-[#D10A11] font-bold font-Poppins text-[18px]">Whatsapp Number Added Successfully!</p>
            </div>
              <div className="flex-col justify-center space-y-3 mt-2">
                <div className="flex py-2 justify-center">
                  <img src={WhatsAppSuccesssIcon} className="w-12 h-12" alt=""/>
                </div>
                
                <p className="text-[#000000] text-center font-semibold font-Poppins text-[14px]">
                  We have sent a whatsapp message to your whatsapp 
                  number send your photo there and we will add it for you!
                </p>
              
            </div>
            <div className='flex justify-center pt-5'>
              <button onClick={handleClickContinue} className="rounded-[10px] flex px-7 py-2 bg-[#FCF5EB] text-[#000000] text-center border border-[#000000] text-[15px]">
                <span className="px-3 whitespace-nowrap">Close & Continue</span>
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

const UploadHoroscopeViaPopup = (props) =>{

  const userData = useSelector((state) => state?.DashBoardAPI?.ProfileData?.data);

  const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId ;

  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  const{  t:trans } = useTranslation();


  const { loginDetail, logout } = useContext(LoginContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [dangerPopupData, setdangerPopupData] = useState({ show: false, content: "" })
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  

  
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [windowWidth]);

  const handleClickOutside = (event) => {
    if (closePopupRef.current && !closePopupRef.current.contains(event.target)) {
      props.close();
    }
  };

  const captureImage = () => {

      navigator.permissions.query({ name: 'push' }).then(function (permissionStatus) {
        if (permissionStatus.state === "granted") {
          setShowCameraPopup(true)
        } else if (permissionStatus.state === "denied") {
          showWarningMessage("Please grant camera permission for capture image from your webcam")
        } else {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
              setShowCameraPopup(true)
            })
            .catch(function (err) {
              showWarningMessage("Please grant camera permission for capture image from your webcam")
            });
        }
      })

      navigator.permissions.query({ name: 'camera' }).then(function (permissionStatus) {
        if (permissionStatus.state === "granted") {
          setShowCameraPopup(true)
        } else if (permissionStatus.state === "denied") {
          showWarningMessage("Please grant camera permission for capture image from your webcam")
        } else {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
              setShowCameraPopup(true)
            })
            .catch(function (err) {
              showWarningMessage("Please grant camera permission for capture image from your webcam")
            });
        }
      })
  }

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const showWarningMessage = (message) => {
    setdangerPopupData({ show: true, content: message })
  }

  const closeWarning = () => {
    setdangerPopupData({ show: false, content: "" })
  }

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
  };

  const compressImage = (file) => {
    console.log("compressImage", file);

    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        mimeType: 'image/jpeg',
        success(result) {
          var tempFile = new File([result], file.name);
          resolve(tempFile);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const onImageConfirm = async (img) => {

    const response = await fetch(img);

    if (response.ok) {
      const imageBlob = await response.blob();
      const file = new File([imageBlob], 'image.jpg', { type: 'image/jpeg' });
      const compressedFile = await compressImage(file);
      handleUpload(compressedFile,"horoscope","via_camera")
    }

    setShowCameraPopup(false);
  };

  const onHoroScopeFileChange = (file,type)=>{
    validateImage({
      file,
      onSizeError: () => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: () => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError:()=>{
        showNotification({ title: "Invalid image", description: trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: () => {
        handleUpload(file,type,"via_gallery")
      }
    })
  }

  const handleUpload = async (file,type,via) => {

    setIsUploading(true)
    
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", getDeviceInfo());
      fileData.append("documents", file);
      fileData.append("type", type);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia",  props?.isFrom == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("recieverprofileid", getGroupAuthId());
      fileData.append("horoscoperequesttypeid", 86);
      fileData.append("messageTypeId", "2");
      fileData.append("kmcareId", "1");

      if(notificationId){
        fileData.append("notificationId", notificationId);
      }

      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          if(via == "via_camera"){
            onClickTrack(EventName.CLC_Popup_Generate_Upload_Horoscope_Upload_Via_Camera_Button);
          }else{
            onClickTrack(EventName.CLC_Popup_Generate_Upload_Horoscope_Upload_Via_Gallery_Button);
          }
          props?.setShowingSuccessPopup({data:data?.data?.data?.fileUploadpath,show:true})
          props.close();
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }

    setIsUploading(false)
  };



  return (
    <>
    {showCameraPopup && 
      <CameraPopup 
        onImageConfirm={onImageConfirm} 
        close={() => {
            setShowCameraPopup(false);
            onClickTrack(EventName.chatCameraButtonCancelClick);
          }} 
        track={() => {}}
        from="horoscope" 
        />
      }

      {dangerPopupData.show &&
        <CommonDangerPopup
          close={closeWarning}
          content={dangerPopupData.content}
        />
      }
      {notification.show && (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        )}
      <div>
        <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6">
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative p-6 flex-auto">
                <div className="flex whitespace-nowrap text-center pb-4">
                  <p className="text-black font-bold font-Poppins text-[18px]">{trans('my_profile.upload_horoscope_via')}</p>
                </div>
                <div className="flex justify-center">
                  <div className="grid md:grid lg:flex grid-cols-3 gap-[3rem] items-center pt-[0.5rem]">
                    <div className="flex">
                      <button
                        onClick={() => { captureImage() }}
                        className="flex flex-col items-center w-full text-white cursor-pointer">
                        <img className="w-[2.3rem] h-[2rem]" src={Images.cameraimage} alt="" />
                        <p className="text-[#8292A8] font-medium   font-Poppins pt-1 text-[12px]">{trans('my_profile.camera')}</p>
                      </button>
                    </div>
                    <label>
                      <div className="flex flex-col items-center w-full text-white cursor-pointer">
                        <input
                          style={{ display: "none" }}
                          type="file"
                          disabled={isUploading}
                          onChange={(event) => {
                            if (event.target.files[0]) {
                              onHoroScopeFileChange(event.target.files[0], "horoscope");
                            }

                          }}
                          accept="image/jpeg, image/png"
                        />
                        <img className="w-[2rem] h-[2rem]" src={Images.GallaryIcon} alt="" />
                        <p className="text-[#8292A8] font-medium  font-Poppins pt-1 text-[12px]">{trans('my_profile.gallery')}</p>
                      </div>
                    </label>
                    <div className="flex flex-col items-center w-full text-white cursor-pointer ">
                      <button
                        onClick={() => {
                          onClickTrack(EventName.CLC_Popup_Generate_Upload_Horoscope_Upload_Via_WhatsApp_Button);
                          window.open(Constants.kalyanWhatsappHoroscope(userData?.smProfileId))
                          props.close();
                        }}
                        className="flex flex-col items-center cursor-pointer"
                      >
                        <img className="w-[2rem] h-[2rem]" src={whatsappIcon} alt="" />
                        <p className="text-[#8292A8] font-medium  font-Poppins pt-1 text-[12px]">{trans('my_profile.whatsapp')}</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CommonClcPhotoPopup = (props) => {
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();
  const { setMenuSelect ,successPopup, setSuccessPopup ,photopopupOpen, setPhotoPopupOpen} = useContext(NavBarContext);
  const { t:trans } = useTranslation();

  const isViewProfile = props?.buttonName == "WhoViewedMyprofile";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let textArray = props?.buttonName == "WhoViewedMyprofile" && props?.message ? props?.message?.split(".") : "";

  const highlightext = textArray != "" ? textArray[0] + "." : "";

  const remaintext = textArray != "" ? textArray[1] + "." + textArray[2] : "";

  const [successMessage, setsuccessMessage] = useState("");


  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const location = useLocation();
  const [showCameraPopup, setShowCameraPopup] = useState(false);


  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const handleCrop = (file, type) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
       


      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
    
  };

  const handleOriginalImageUpload = async (file) => {
   
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);

      },
    });
  };

  console.log(location?.state?.data?.notificationId,'location?.state?.data?.notificationId');
  
  const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    let notificationId;
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
    fileData.append("photorequesttypeid", 85);
    fileData.append("requestfulfilledfrom", getDeviceInfo());
    fileData.append("recieverprofileid", getGroupAuthId());

    if (props.isFrom === 'TrailPack') {
      fileData.append("editviatrialpack", props.editviatrialpack);
      fileData.append("notificationId", NotificationId);
    }
    notificationId = sessionStorage.getItem(
      Constants.loginLocalStorageKeys.notificationId
    )
    if (notificationId) {
      fileData.append("notificationId", notificationId);
    }
    if(props.isFrom=="wizardphoto"){
      fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
    }
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        if (props?.isFrom == 'TrailPack') {
          props.postData();
        }
        setShowCrop(true);
      } else {
        props.closePopup();
      }
    } else {
      props.closePopup();
    }
  };
const [showphoto,setShoPhoto] = useState(true)
  
console.log(showSuccessPopup,showphoto,'props?.close');

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup || props?.from || props.PrivacyOptionTitle) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      // fileData.append("notificationId", location?.state?.data?.notificationId);
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      if (props.isFrom === 'TrailPack') {
        fileData.append("editviatrialpack", props.editviatrialpack);
        fileData.append("notificationId", NotificationId);
      }
      if(props.isFrom=="wizardphoto"){
        fileData.append("notificationId", localStorage.getItem(Constants.loginLocalStorageKeys.Notification));
      }
      if(props.isFrom === 'AddPhoto'){
        fileData.append("notificationId", NotificationId);
      }
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());

      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {          
         
          if(props?.title == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
            props.setshowUploadSuccessPopup(true);
          }else{
            setshowSuccessPopup(true)
          }

          props?.onPhotoPopupClose(true , "AddPhoto");
         
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }   
          setShoPhoto(false)
          // props?.closePopup()
          props?.callBack();
          
        } else {
          props.closePopup();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.closePopup();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.closePopup();
    }
  };



  useEffect(() => { }, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    if (props.isFrom == 'TrailPack') {
      return;
    } else {
      props.close();
    }
  };

  const captureImage = () => {

    navigator.permissions.query({ name: 'push' }).then(function (permissionStatus) {
      if (permissionStatus.state === "granted") {
        setShowCameraPopup(true)
      } else if (permissionStatus.state === "denied") {
        showWarningMessage("Please grant camera permission for capture image from your webcam")
      } else {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(function (stream) {
            setShowCameraPopup(true)
          })
          .catch(function (err) {
            showWarningMessage("Please grant camera permission for capture image from your webcam")
          });
      }
    })

    navigator.permissions.query({ name: 'camera' }).then(function (permissionStatus) {
      if (permissionStatus.state === "granted") {
        setShowCameraPopup(true)
      } else if (permissionStatus.state === "denied") {
        showWarningMessage("Please grant camera permission for capture image from your webcam")
      } else {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(function (stream) {
            setShowCameraPopup(true)
          })
          .catch(function (err) {
            showWarningMessage("Please grant camera permission for capture image from your webcam")
          });
      }
    })
}

  const [dangerPopupData, setdangerPopupData] = useState({ show: false, content: "" })

  const showWarningMessage = (message) => {
    setdangerPopupData({ show: true, content: message })
  }

  const closeWarning = () => {
    setdangerPopupData({ show: false, content: "" })
  }

  const onImageConfirm = async (img) => {

    const response = await fetch(img);

    if (response.ok) {
      const imageBlob = await response.blob();
      const file = new File([imageBlob], 'image.jpg', { type: 'image/jpeg' });
      const compressedFile = await compressImage(file);
      handleUpload(compressedFile,"horoscope","via_camera")
    }

    setShowCameraPopup(false);
  };

  const compressImage = (file) => {
    console.log("compressImage", file);

    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        mimeType: 'image/jpeg',
        success(result) {
          var tempFile = new File([result], file.name);
          resolve(tempFile);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  return (
    <>

        {showCameraPopup && 
          <CameraPopup 
            onImageConfirm={onImageConfirm} 
            close={() => {
                setShowCameraPopup(false);
                onClickTrack(EventName.chatCameraButtonCancelClick);
              }} 
            track={() => {}}
            from="horoscope" 
            />
        }

        {dangerPopupData.show &&
          <CommonDangerPopup
            close={closeWarning}
            content={dangerPopupData.content}
          />
        }

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
          />
        )}
    
    {notification.show && (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        )}
        {showCrop && (
        
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props?.closePopup();
                // setShowCrop(false)
              }

              if (props?.callBackReload) {
                props?.callBackReload();
              }
            }}
          />
        )}

       

    { (props?.isFrom == "AddPhoto"  && showphoto) &&
      <div className={`flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none scrollbar-none ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'overflow-y-hidden ' : 'overflow-y-auto bg-opacityblack '}`}>
      <div
        // className={`relative modal-container md:my-6 md:mx-6 ${isViewProfile ? "lg:w-[23vw] md:w-[42vw] w-[71vw]" :"lg:w-[40vw] md:w-[70vw] w-[90vw]"}`}
        className={`relative  modal-container md:my-2 mx-6 max-h-[95vh] overflow-y-auto overflow-x-hidden scrollbar-none
          
          ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'w-full lg:w-[40vw] md:w-[70vw] pt-[5rem]'
             : isViewProfile ? 'xl:w-[23vw] 2xl:w-[23vw] lg:w-[30vw] md:w-[42vw] w-[71vw]' : ' '}`}
        ref={closePopupRef}
      >

        <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none mt-[0.5rem] rounded-md`}>
          <div className="">
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-[-0.1rem] right-0"
                onClick={() => {
                  props?.closePopup();

                }}
              >
                <img className="h-5 w-5 " src={CloseIcon} alt="" />
              </button>
          </div>
          <div className={`px-4 pb-4 sm:p-6 sm:pb-5 rounded-2xl relative py-8 flex-auto pt-5`}>
            <div className={` justify-center mb-2  items-center `}>
    
                   <div className="text-[#D10A11] flex border-b-2 border-dashed border-[#D10A11] justify-center items-center text-center font-bold text-[16px] md:text-[18px] lg:text-[21px] pb-4">
                      <h3>{props?.title}</h3>
                    </div>
                    {props?.title == 'Add Photo with Privacy Options' ?
                    <div className="px-3 md:px-8">
                      <p className="text-[#000000] font-bold text-[13px] md:text-[16px] mt-2">Add and make your photo visible to:</p>
                      <div className="flex items-center gap-1 mt-2">
                        <p className="text-[#000000] text-[13px] md:text-[15px]"><span classname="font-bold">-</span>{" "}Profiles you contact.</p>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <p className="text-[#000000] text-[13px] md:text-[15px]"><span classname="font-bold">-</span>{" "}Profiles from your community.</p>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <p className="text-[#000000] text-[13px] md:text-[15px]"><span classname="font-bold">-</span>{" "}Profiles who match your partner expectations.</p>
                      </div>
                    </div>
                    :
                    <div className="px-3 md:px-8">
                      <p className="text-[#000000] font-bold text-[13px] md:text-[16px] mt-2">Tips to Add a Great Profile Picture!</p>
                      <p className="text-[#000000] font-bold text-[12px] md:text-[14px] mt-2">Here are some tips to help you shine:</p>
                      <div className="flex items-center gap-1 mt-2">
                        <img src={Images.TickIconGreen} alt="" className="w-3 h-3" />
                        <p className="text-[#000000] text-[13px] md:text-[15px]">Add a photo with proper lighting.</p>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <img src={Images.TickIconGreen} alt="" className="w-3 h-3" />
                        <p className="text-[#000000] text-[13px] md:text-[15px]">Add a recent photo with  good  background.</p>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <img src={Images.TickIconGreen} alt="" className="w-3 h-3" />
                        <p className="text-[#000000] text-[13px] md:text-[15px]">Add more than one photo, such as selfies, family photos, and traditional photos.</p>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <p className="text-[#7A7A7A] text-[13px] md:text-[15px] italic">*You can add Unlimited Photos to your profile!</p>
                      </div>
                    </div>
                    }
                   
                   {props?.title != 'Add Photo with Privacy Options'&&
                    <>
                      <div className="flex items-center justify-center mt-5">
                        <img src={Images.ClcAddPhotoIcon} alt="" className="w-[90%] md:w-[65%] h-[6rem]" />
                      </div>
                      <div  className="flex items-center justify-center gap-2 mt-3">
                        <img src={Images.GreenTickIcon} alt="" className="w-5 h-5" />
                        <p className="text-[#000000] text-[15px] font-bold">Good Photos</p>
                      </div>
                    </>
                    }
                    </div>


                  {
                    !showCrop && showLoader ? (
                      <Loader
                        className="loaderTransperancy"
                        loaderClassName="loaderTransperancySpin"
                      />
                    )  : (
                  <>
                
                  {
                    compressing ? (
                      <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                        <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                        <div className="text-[16px] font-semibold my-2">
                          Uploading...
                        </div>
                      </div>
                    )
                    :
                  isUploading ? (
                    <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                      <img
                        alt="loader"
                        src={LoaderGif}
                        className={`w-16 h-16`}
                      />
                      <div className="text-[16px] font-semibold my-2">
                        Uploading...
                      </div>
                    </div>
                  ) : (
                     
                    <>
                    <p className={`py-3 mt-2 text-[#000000] font-bold text-[13px] md:text-[16px] px-3 md:px-8`}>Upload Photo Via :</p>
                      <div className="w-[60%] mx-auto">
                        <div className="hidden md:grid grid-cols-2 lg:gap-5 md:gap-6 md:mx-[1rem]">

                
                              <label>
                              <div
                                className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                style={{
                                  boxShadow: "0px 0px 5px #0000004F",
                                }}
                                onClick={() => {
                                  if (props.isFrom == "sign out") {
                                    setMenuSelect("2");
                                    navigate(
                                      RouteHelper.getEditProfileUrl(
                                        EditProfileMenu[2].urlPath
                                      ),
                                      { state: { tab: "3" } }
                                    );
                                    props?.closePopup();
                                  }

                                  onClickTrack(EventName.CLC_Gallery)
                                  props?.onPhotoPopupClose(true, "AddPhoto");
                                }}
                              >
                                <img
                                  src={Images.GallaryIcon}
                                  className="w-[3rem] h-[3rem] object-none"
                                  loading="lazy"
                                  alt=""
                                />
                                <div className="flex items-center justify-center bg-white cursor-pointer border border-[#D10A11] rounded  w-full py-1.5 rounded-[14px]">
                                  <p className="text-[#D10A11] font-bold font-Poppins text-[14px] ">
                                    <a className="cursor-pointer text-[10px]" 
                                      >
                                      Add Photos Now!
                                    </a>

                                    {props.isFrom === "sign out" ? (
                                      <></>
                                    ) : (
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) => {
                                          handleCrop(event.target.files[0]);
                                        }}
                                        accept="image/png, image/jpg, image/jpeg"
                                        data-max-size="10485760"
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                              </label>

                               
                          {/* <SocialMediaUpload
                            closeModal={() => props.close()}
                            callBackImage={(e) => props?.callBackReload(e)}
                            isFrom={'addphotoclc'}
                            onPhotoPopupClose={props?.onPhotoPopupClose}
                          /> */}
                            <div
                              className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem] bg-white shadow-md rounded-lg cursor-pointer"
                              style={{
                                boxShadow: "0px 0px 5px #0000004F",
                              }}
                              onClick={() => {
                                window.open(
                                  Constants.kalyanSupportWhatsappUrl(
                                    userData?.smProfileId
                                  )
                                );
                                onClickTrack(EventName.CLC_Whatsapp)
                                props?.onPhotoPopupClose(true, "AddWhatsapp");
                              }}
                            >
                              <img
                                src={whatsappIcon}
                                className="w-[3rem] h-[3rem] object-cover"
                                loading="lazy"
                                alt=""
                              />
                              <div className="flex items-center justify-center bg-white cursor-pointer border border-[#4AC958] rounded w-full py-1.5  rounded-[14px]">
                                <p className="text-[#47C757] font-bold font-Poppins py-0.5 text-[10px]">
                                Add Photos via WhatsApp!
                                </p>
                              </div>
                            </div>

                          
                         
                        </div>
                      </div>

                        <div className="block md:hidden  gap-4 grid grid-cols-3 mx-[2rem]">


                        
                            <div className="flex">
                              <button
                                onClick={() => { captureImage() }}
                                className="flex flex-col items-center w-full text-white cursor-pointer">
                                <img className="w-[2rem] h-[2rem] object-contain" src={Images.cameraimage} alt="" />
                                <p className="text-[#7A7A7A] font-medium   font-Poppins pt-2 text-[14px]">{trans('my_profile.camera')}</p>
                              </button>
                            </div>

                          <label>
                          <div
                            className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                            
                            onClick={() => {
                              onClickTrack(EventName.CLC_Gallery)
                              props?.onPhotoPopupClose(true, "AddPhoto");
                            }}
                          >
                            <img
                              src={Images.GallaryIcon}
                              className="w-[2rem] h-[2rem] object-contain"
                              loading="lazy"
                              alt=""
                            />
                            <div className="flex items-center justify-center bg-white cursor-pointer">
                              <p className="text-[#000000] font-medium font-Poppins text-[10px]">
                                <a className="text-[#7A7A7A] font-medium font-Poppins text-[14px]" >
                                  Gallery
                                </a>

                                {props.isFrom === "sign out" ? (
                                  <></>
                                ) : (
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(event) => {
                                      handleCrop(event.target.files[0]);
                                    }}
                                    accept="image/png, image/jpg, image/jpeg"
                                    data-max-size="10485760"
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                          </label>


                      <div
                        className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                       
                        onClick={() => {
                          window.open(
                            Constants.kalyanSupportWhatsappUrl(
                              userData?.smProfileId
                            )
                          );
                          onClickTrack(EventName.CLC_Whatsapp)
                          props?.onPhotoPopupClose(true, "AddWhatsapp");                        }}
                      >
                        <img
                          src={whatsappIcon}
                          className="w-[2rem] h-[2rem] object-contain"
                          loading="lazy"
                          alt=""
                        />
                        <div className="flex items-center justify-center  bg-white cursor-pointer">
                          <p className="text-[#7A7A7A] font-medium font-Poppins text-[14px]">
                            WhatsApp
                          </p>
                        </div>
                      </div>

                        </div>

                        <div className="w-[95%] mx-auto bg-[#E6E6E6A1] flex md:hidden items-center justify-center gap-2 pl-3 pr-1 py-1 rounded mt-3">
                          <img src={Images.ReportIcon} alt="warning" className="w-5 h-5" />
                          <span className="text-[#575556] text-[12px]">The Photo uploaded will reflect in your profile after verification by Kalyan Matrimony</span>
                        </div>
                        
                        <div class="flex items-center justify-center w-full mt-5">
                          <div class="border-t border-dashed border-red-500 flex-grow"></div>
                          <span
                              className={`text-[#D10A11] font-bold bg-white z-[1] text-center ${ (trans("photo_popup.or")
                              || trans("logout_popup.or")) == 'OR' ? 'px-5' : ''}`}
                            >
                              {props.title === trans("photo_popup.add_photo")
                                ? trans("photo_popup.or")
                                : trans("logout_popup.or")}
                            </span>
                          <div class="border-t border-dashed border-red-500 flex-grow"></div>
                        </div>


                        <div className="w-[95%] md:w-[55%] mx-auto mt-5 mb-2 space-y-5 md:space-y-8">
                          <div className="flex items-center justify-center">
                            <img alt="call" src={Images.GreenCallIcon} className="w-8 h-8 mx-2" />
                            <p className="text-center font-Poppins font-bold text-[13px] md:text-[16px]">
                              {props.title == "Add Photo" ?
                                trans('photo_popup.call_our_expert_to_know_what_i')
                                :trans('logout_popup.call_our_expert_to_know_what_i')}
                            </p>
                          </div>
                          
                          <div className="flex justify-center items-center space-x-2 border-2 border-[#707070] w-max mx-auto px-4 py-2 rounded">
                          <a href="tel:04440146969" className="flex items-center space-x-2">
                            <img
                              className="h-[1rem] md:h-[1.8rem] lg:h-8 mt-0 md:mt-[.5rem]"
                              src={Images.GreenCallIcon}
                              alt="CallIcon"
                            />
                            <div className="hidden md:block leading-[15px] md:leading-[20px] ">
                              <p className="text-[13px] text-center text-[#039511]">{trans('photo_popup.call_us')}</p>
                              <h5 className="md:text-[1rem] lg:text-[1rem] text-[#D10A11]">
                                044 4014 6969
                              </h5>
                            </div>
                            <div className="block md:hidden leading-[15px] md:leading-[20px] ">
                              <p className="text-[13px] text-center text-[#039511]">{trans('upload_photos.call_now')}</p>
                            </div>
                          </a>
                          </div>
                        </div>
                       </>
                     
                  )}
                  </>
                )
            
              }              
            
          </div>
        </div>
      </div>
    </div>

  
    
          }
          </>
  );
};


export {
  CommonAddPhotoPopup,
  CommonAddParentNumberPopup,
  AstroPopup,
  ErrorPopup,
  CommonAstroPopup,
  RemovePopup,
  ReInviteModal,
  CommonCastPopup,
  LoginVia,
  ViewPhotoPasswordPopup,
  CommonPopupWithoutHeader,
  CommonSuccessPopup,
  CommonPopup,
  MessageSuccessPopup,
  CommonErrorPopup,
  UploadPhotoPopup,
  NotificationPopup,
  SuspendedPopup,
  FilterPopup,
  RegSuccessPopup,
  ImageSuccessPopup,
  ReligionMigrationPopup,
  EmailAddPopup,
  CommonCameraPopup,
  CommonMutePopup,
  CommonBlockPopup,
  CommonReportPopup,
  CommonPayNowPopup,
  CommonPayBackPopup,
  CommonDownloadmediaPopup,
  CommonConfrimPopup,
  CommonHelpPopup,
  CommonUnBlockPopup,
  ApplyEditPreferencePopup,
  CommonDangerPopup,
  CommonMessagePopup,
  CommonParentPopup,
  CommonValidPopup,
  CommonNoMatchesPopup,
  CommonSuccessfullUpload,
  LogoutPopup,
  CommonFamilyFriendPopup,
  CommonChooseFamilyPopup,
  CommonNewPopup,
  UploadPhotoPopuplite,
  CommonProfilePopup,
  PaymentWhatsAppPopup,
  AddFamilyMemberPopup,
  SharePopup,
  PromptPopup,
  CommonRewardPopup,
  StaticCircularProgressBar,
  CommonQualityPopup,
  CommonHabitPopup,
  CommonFamilyPopup,
  CommonHoroPopup,
  CustomerCareTemplateMsgPopup,
  RankingStartPopup,
  ValidationNotificationPopup,
  StarRaasiPopup,
  CommonUploadSuccessfull,
  CommonDescriptionPopup,
  CommonRegisterPopup,
  DescriptionPopup,
  CommonNotificationAlertPopup,
  WhatsAppNumperPopup,
  WhatsAppNumperSuccessPopup,
  CommonPhotoNewPopup,
  TrailNotificationPopup,
  GiftImageWithGif,
  UploadHoroscopeViaPopup,
  CommonClcPhotoPopup

};

