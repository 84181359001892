import React, { useContext, useEffect, useState } from 'react';
import KMLogo from "../../Images/KM logo@2x.png"
import { addmore, addparent, addphotoimage, callpack, ChatMsgIconRed, customercare, groupiconred, partner, RedCameraIcon, rightarrowicon, viewprofile } from '../Kalyanlite/assets/images';
import RouteSegments, { RouteHelper, RoutePaths } from '../../Routes/RouteSegment';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useNavigate
import { UploadPhotoPopuplite } from '../CommonPopup';
import Constants, { EditProfileMenu } from '../../Constants/constants';
import { NavBarContext } from '../../Contexts/NavBarContext';
import EndPoints from '../../Constants/EndPoints';
import { POST } from '../../Services/api_services';
import config from '../../config';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';

const MicrositePage = () => {
    const [openphoto, setOpenPhoto] = useState(false)
    const { setScrollToAdd, customerChatClicked, microtrackidData, setMicrotrackidData
    } = useContext(NavBarContext);

    const dispatch = useDispatch()
    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    useEffect(() => {
        dispatch(dashboardProfileData())
    }, [dispatch])


    useEffect(() => {
        if (userData) {
            console.log(userData, 'userDatauserData'); // Logs when userData is available
            console.log(userData?.profileId, 'userData?.profileId'); // Safely logs profileId if userData exists
        } else {
            console.log('User data not yet available'); // Logs during the initial render
        }
    }, [userData]);

    const navigate = useNavigate(); // Initialize useNavigate hook

    console.log(openphoto, customerChatClicked, 'openphoto');

    const [searchParams, setSearchParams] = useSearchParams()

    const handleNavigation = () => {
        navigate(RouteSegments.editProfileTab(0), {
            state: { scrollToAdd: "ADDITIONAL", isFrom: "microAdditionalDetails" },
        });
    };



    const onClickButton = async (type) => {
        let subject;
        localStorage.setItem("isFromMicrosite", "Yes")

        // Map the type to the corresponding subject
        switch (type) {
            case "AddPhoto":
                subject = "faq_add_photo";
                break;
            case "AddParents":
                subject = "faq_add_numbers";
                break;
            case "SetPartnerExpectations":
                subject = "faq_partner_exc";
                break;
            case "AddMoreDetails":
                subject = "faq_add_details";
                break;
            case "ViewProfile":
                subject = "faq_my_profile";
                break;
            case "ContactCustomerCare":
                subject = "faq_kmc";
                break;
            case "KnowMoreCallingPacks":
                subject = "faq_pay_now";
                break;
            default:
                subject = "faq_default"; // Default case if none of the above matches
        }

        console.log(subject, 'subject');

        const request = {
            profileId: userData?.profileId?userData?.profileId:localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            subject: subject,
        };

        try {
            // Make the POST request to the API endpoint
            let { statusCode, data } = await POST(
                `${config.api.API_URL}${EndPoints.trackforquikreply()}`,
                request
            );

            if (statusCode === 200) {
                // Check if the API response indicates success
                if (data.data.status === "Success") {
                    // Handle success, extract microtrackId from response
                    const microtrackId = data.data.microtrackId;  // Assuming microtrackId is in this location
                    setMicrotrackidData(microtrackId);  // Store the microtrackId in state
                    localStorage.setItem('microtrackId', microtrackId); // Save to localStorage

                    console.log('microtrackId:', microtrackId);  // Log the microtrackId
                } else {
                    localStorage.removeItem('microtrackId', microtrackidData);
                    console.log('Failed response:', data.data.status);
                }
            } else {
                console.error('Request failed with statusCode:', statusCode);
                localStorage.removeItem('microtrackId')

            }
        } catch (error) {
            console.error('Error while making the API call:', error);
        }
    };

    console.log(microtrackidData, "microtrackid");


    useEffect(() => {

        if (searchParams.get("microsite")) {
            navigate(RouteSegments.MICROSITE_PAGE, { replace: true })
        }
    }, [])

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Example API call simulation
        const fetchData = async () => {
            setLoading(true);
            try {
                // Simulating a delay for API call
                setTimeout(() => {
                    setLoading(false); // Hide the loader once data is fetched
                }, 1000); // 3 seconds delay
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false); // Hide the loader even if there is an error
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {openphoto &&
                <UploadPhotoPopuplite
                    microtrackid={microtrackidData || ''}
                    isFrom="Addphoto" />
            }

            <div className="bg-white">
                <a href="" className="flex items-center  py-3 shadow-[0_21px_11px_-15px_#BE363629]">
                    <img src={KMLogo} className="h-12 md:h-[4rem] mx-5  md:pl-[3.8rem] lg:h-[3.5rem]" alt="" />
                </a>
            </div>
            {loading
                ? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
                :
                <>      <div className="flex flex-col justify-center md:items-center items-start px-[1rem] md:px-0">

                    <div className=' mt-[2rem] lg:pr-[35rem] md:pr-[26rem]'>
                        <p className='text-[#D71920] text-[18px]  md:text-[19px] font-bold whitespace-nowrap'>Welcome to Kalyan Matrimony!
                        </p>
                        <p className='text-[17px] font-bold whitespace-nowrap'>Select an  option  to continue.</p>
                    </div>
                </div>
                    <div className="flex justify-center items-center rounded-[20px]">

                        <div className=" rounded-[20px] md:mx-[3rem] lg:mx-0 max-w-fit md:mt-[1rem] md:mb-5 md:shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
                            <div className="rounded-[20px] md:p-[1.25rem]  md:bg-[#FFFFFF]">


                                <div className='px-[1rem] md:px-[2rem]  mt-[1rem]  divide-y divide-[#D0D0D0]'>
                                    <div className='flex justify-between space-x-8 cursor-pointer py-[1rem]' onClick={() => {
                                        onClickButton("AddPhoto")

                                        navigate(RouteSegments.MATCH_LITE, {
                                            state: { openPhotoPopup: true, isFrom: "Addphoto", microtrackidData } // Pass the flag to show popup
                                        });
                                    }}>
                                        <div className='flex  md:gap-5 items-center gap-3'>
                                            <img src={addphotoimage} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Add Photo
                                                <p className='font-medium md:text-[15px] text-[11px] text-[#2A2A2A]'>Add photo to your profile and get topped in the search results.
                                                </p>
                                            </span>
                                        </div>

                                        <button>
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>

                                    </div>


                                    <div className='flex justify-between  md:space-x-8  py-[1rem] cursor-pointer' onClick={() => {
                                        navigate(RouteSegments.MATCH_GROUP, {
                                            state: { openParentPopup: true, isFrom: "microParentsNumber", isAdd: true } // Pass the flag to show popup
                                        });
                                        onClickButton("AddParents")
                                    }}>
                                        <div className='flex  md:gap-5 items-center gap-3'>
                                            <img src={addparent} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Add Parents and Family Members
                                                <p className='font-medium md:text-[15px] text-[11px]'>Add your family members to search, select, share, chat, and call matches on your behalf.

                                                </p>
                                            </span>
                                        </div>
                                        <button>
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />

                                        </button>
                                    </div>

                                    <div className='flex justify-between  md:space-x-8   py-[1rem] cursor-pointer' onClick={() => {
                                        navigate(
                                            RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath),
                                            { state: { tab: "3", isFrom: "micropartner" } }
                                        );
                                        onClickButton("SetPartnerExpectations")
                                    }}>
                                        <div className='flex  md:gap-5 items-center gap-3'  >

                                            <img src={partner} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Set My Partner Expectations
                                                <p className='font-medium md:text-[15px] text-[11px]'>Set your partner expectations and get more accurate matches.

                                                </p>
                                            </span>
                                        </div>
                                        <button
                                        >
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>
                                    </div>

                                    <div className='flex justify-between   md:space-x-8  py-[1rem] cursor-pointer ' onClick={() => {
                                        handleNavigation();
                                        onClickButton("AddMoreDetails");

                                    }
                                    }>
                                        <div className='flex  md:gap-5 items-center gap-3'>

                                            <img src={addmore} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Add More Details to Profile
                                                <p className='font-medium md:text-[15px] text-[11px]'>Enrich your profile my adding more details.

                                                </p>
                                            </span>
                                        </div>

                                        <button

                                        >
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>
                                    </div>

                                    <div className='flex justify-between  md:space-x-8  py-[1rem] cursor-pointer' onClick={() => {
                                        navigate(RouteSegments.editProfileTab(0), {
                                            state: {
                                                isFrom: "microview"
                                            }
                                        });

                                        onClickButton("ViewProfile")
                                    }

                                    }>
                                        <div className='flex  md:gap-5 items-center gap-3'>
                                            <img src={viewprofile} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>View My Profile
                                                <p className='font-medium md:text-[15px] text-[11px]'>View your profile and add missing details to your profile.

                                                </p>
                                            </span>
                                        </div>
                                        <button
                                        >
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>
                                    </div>

                                    <div className='flex justify-between   md:space-x-8  py-[1rem] cursor-pointer' onClick={() => {
                                        navigate(RouteSegments.CHAT_LITE, {
                                            state: { isFrom: "customercare", opencustomercare: true, isType: "microcare" }
                                        })

                                        onClickButton("ContactCustomerCare")

                                    }}>
                                        <div className='flex  md:gap-5 items-center gap-3'>

                                            <img src={customercare} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Contact Customer Care
                                                <p className='font-medium md:text-[15px] text-[11px]'>Have a query? Talk to our customer care executive and get it resolved.

                                                </p>
                                            </span>
                                        </div>
                                        <button
                                        >
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>

                                    </div>

                                    <div className='flex  justify-between  md:space-x-8  py-[1rem] cursor-pointer' onClick={() => {
                                        navigate(RouteSegments.MEMBERSHIP_REGISTER_LITE, { state: { isType: "microcall", microtrackid: microtrackidData } })
                                        onClickButton("KnowMoreCallingPacks")

                                    }}>
                                        <div className='flex  items-center md:gap-5 gap-3'>

                                            <img src={callpack} className='md:w-[3rem] md:h-[3rem] w-[2.7rem] h-[2.7rem]' />
                                            <span className='text-[#000000] font-bold md:text-[17px] text-[16px] w-[15rem] md:w-full'>Know More About Calling Packs
                                                <p className='font-medium md:text-[15px] text-[11px]'>View and buy a pack.

                                                </p>
                                            </span>
                                        </div>
                                        <button
                                        >
                                            <img src={rightarrowicon} className='w-[2rem] h-[2rem]' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default MicrositePage;