import { call, put, takeEvery } from "redux-saga/effects";
import { BLOCK_PROFILE, GET_ASTRO_BALANCE, GET_HOROSCOPE_MATCH, MESSAGE_THREAD, SENT_MESSAGE } from "./ActionTypes";
import { blockProfileApiError, blockProfileSuccess, getAstroBalanceApiError, getAstroBalanceSuccess, getHoroscopeMatchApiError, getHoroscopeMatchSuccess, getMessageApiError, getMessageSuccess, sentMessageApiError, sentMessageSuccess } from "./Action";
import { Block_profile, Get_Astro_Balance, Get_Horoscope_Match, Message_Thread, Sent_Message } from "../../Helpers/api_helpers";
import Constants from "../../Constants/constants";
import { Unblock_profile } from "../../Helpers/api_helpers";


function* getMessageData({ payload: { data, id, email, type, notificationId, messageTypeId, kmcareId  } }) {
  //console.log(data,id,email,type,"datadata");
  try {
    const response = yield call(Message_Thread,{
      threadId:data,
      status:type,
      email:email ?? null,
      userId: id,
      notificationId: notificationId ?? undefined,
      messageTypeId : messageTypeId ?? undefined, 
      kmcareId : kmcareId ?? undefined,
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getMessageSuccess(response));
    } else {
      yield put(getMessageApiError(response));
    }
  } catch (error) {
    yield put(getMessageApiError(error));
  }
}


function* sentMessageData({ payload: { id, email, msg, toProfileId, notificationId } }) {
  //console.log(id,email, msg, toProfileId,"datadata");
  try {
    const response = yield call(Sent_Message,{
      toProfileId:toProfileId,
      email:email,
      message:msg.message,
      notificationId: notificationId ?? undefined,
      userId:id,
      ...msg.requestTypes
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(sentMessageSuccess(response));
    } else {
      yield put(sentMessageApiError(response));
    }
  } catch (error) {
    yield put(sentMessageApiError(error));
  }
}

function* blockProfile({ payload: {status, toProfileId} }) {
  //console.log(status, toProfileId,"datadata");
  try {
    const response = yield call(status == 0 ? Unblock_profile : Block_profile,{
      toProfileId:toProfileId,
      email:localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
      userId:localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
    });
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(blockProfileSuccess(response));
    } else {
      yield put(blockProfileSuccess(response));
    }
  } catch (error) {
    yield put(blockProfileApiError(error));
  }
}

function* astroBalance() {
  try {
    const response = yield call(Get_Astro_Balance);
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getAstroBalanceSuccess(response));
    } else {
      yield put(getAstroBalanceSuccess(response));
    }
  } catch (error) {
    yield put(getAstroBalanceApiError(error));
  }
}

function* horoscopeMatch({ payload: { toProfileId} }) {
  //console.log(toProfileId,"datadata");
  try {
    const response = yield call(Get_Horoscope_Match,toProfileId);
    //console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(getHoroscopeMatchSuccess(response));
    } else {
      yield put(getHoroscopeMatchSuccess(response));
    }
  } catch (error) {
    yield put(getHoroscopeMatchApiError(error));
  }
}



function* conversationSaga() {
  yield takeEvery(MESSAGE_THREAD, getMessageData);
  yield takeEvery(SENT_MESSAGE, sentMessageData);
  yield takeEvery(BLOCK_PROFILE, blockProfile);
  yield takeEvery(GET_ASTRO_BALANCE, astroBalance)
  yield takeEvery(GET_HOROSCOPE_MATCH, horoscopeMatch)
}

export default conversationSaga;
