import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import EndPoints from "../../Constants/EndPoints";
import Constants, {
  EditProfileMenu,
  loginFrom,
  EventName,
} from "../../Constants/constants";
import { POST } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";
import { useNavigate, useLocation } from "react-router";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";
import { NavLink, useSearchParams, useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import { NavBarContext } from "../../Contexts/NavBarContext";
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { getDenominationList } from "../../Store/Home/Action";
import {
  dashboardLeftPanelData,
  dashboardProfileData,
} from "../../Store/DashboardAPI/Action";
import { RoutePaths } from "../../Routes/RouteSegment";
import useModule from "../../Hooks/useModule";
import { useFormik } from "formik";
import AstroComponent from "./AstroComponent";

const AstroBasic = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loginDetail, logout } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);

  const [isFromInitialPopup, setIsFromInitialPopup] = useState(
    location?.state?.isFromInitialPopup
  );
  const { tab } = useParams();
  const [selectedProfileMenu, setSelectedProfileMenu] = useState(
    EditProfileMenu.find((menu) => menu.urlPath == tab)?.id
  );
  const editProfileMenu = EditProfileMenu.find((menu) => menu.urlPath == tab);

  const [managePreferenceData, setPreferenceData] = useState();
  const [myPlansData, setMyPlansData] = useState();
  const [horoscopeData, setHoroscopeData] = useState();
  const [settingsData, setSettingsData] = useState();
  const [viewedPhoneProfileData, setViewedPhoneProfileData] = useState([]);
  const [viewedPhoneProfilePage, setViewedPhoneProfilePage] = useState({
    profilesviewedbymePage: 0,
    phonesviewedbymePage: 0,
    profilesviewedbyothersPage: 0,
    phonesviewedbyothersPage: 0,
  });
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [imageList, setImageList] = useState([]);

  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({
    url: "",
    flag: "",
    show: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isPartnerPrefLoading, setIsPartnerPrefLoading] = useState(true);
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);
  const [accordianSelect, setAccordianSelect] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [rejectedData, setRejectedData] = useState();
  const [addBanner, setAddBanner] = useState();
  const [contentCardData, setContentCardData] = useState({});

  const [hobbiesInterests, setHobbiesInterests] = useState([]);
  const { isEditProfileMatch } = useModule();

  let { viewByIdProfileStatus } = useSelector((state) => ({
    viewByIdProfileStatus: state.KalyanLite?.viewByIdError,
  }));
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const profileData = useSelector((state) => {
    const userId = state.Chats?.currentChat?.receiverId;
    return state.KalyanLite?.matchProfiles[userId];
  });

  console.log(profileData, "userData12345", userData);
  const loggedInUserPaid = userData?.isPaid === "Y";
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    // apicallDashboard();
    apiCallEditProfile();
    apiCallManagePreference();
    setContentCardData(location?.state);
    dispatch(getDenominationList());
  }, []);

  useEffect(() => {
    //console.log("imageList", imageList);
  }, [
    editMyProfileData,
    myPlansData,
    viewedPhoneProfileData,
    viewedPhoneProfilePage,
    imageList,
    horoscopeData,
    settingsData,
    generatedHoroScopeUrl,
    managePreferenceData,
    selectedProfileMenu,
    accordianSelect,
  ]);


  //Left Panel API Call and Response
  const { leftPanelData } = useSelector((state) => ({
    leftPanelData: state.DashBoardAPI?.leftPanelData?.data,
  }));

  //Profile Data API Call and Response
  const { ProfileData } = useSelector((state) => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data,
  }));

  useEffect(() => {

    if (ProfileData?.popup[0]?.showpopup == "RejectedProfile") {
      setShowRejectPopup(true);
      setRejectedData(ProfileData?.popup[0]);
    }
  }, [ProfileData]);

  const apiCallManagePreference = async () => {
    setIsPartnerPrefLoading(true);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencesdataUrl()}`,
      request
    );

    if (statusCode === 200) {
      setPreferenceData(data?.data);
      setLoading(true);
    } else if (statusCode === 401) {
      logout();
    }
    setIsPartnerPrefLoading(false);
  };
  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
      setAddBanner(data?.data?.bannerWeb);
      if (setHoroscope) {
        setgeneratedHoroscopeurl({
          url: data?.data?.horoscope,
          flag: data?.data?.horoflag,
          show: false,
        });
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };
  useEffect(() => {
    // apicallDashboard();
    apiCallEditProfile();
    apiCallManagePreference();
    setContentCardData(location?.state);
    dispatch(getDenominationList());
  }, []);


  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("phoneNumberEdit");
    };
  }, []);

  useEffect(() => {
    dispatch(dashboardLeftPanelData());
    if (location?.state?.isfrom == "dashboard") {
      console.log(location?.state?.isfrom, "isFrom");
    } else {
      dispatch(dashboardProfileData());
    }
  }, []);



  const getHobbiesandInterests = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getprofilehobbiesUrl()}`,
      request
    );

    if (statusCode === 200) {
      setHobbiesInterests(data?.data);
      apiCallEditProfile();
      setIsLoading(false);
      formik.setSubmitting(false);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const formik = useFormik({
    initialValues: {
      hobbiesInterest: hobbiesInterests.hobbiesAndInterest
        ? hobbiesInterests.hobbiesAndInterest
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        : [],
      musicListen: hobbiesInterests.musicListen
        ? hobbiesInterests.musicListen
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        : [],
      musicDirectors: hobbiesInterests.favoriteMusicDirectors
        ? hobbiesInterests.favoriteMusicDirectors
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        : [],
      booksRead: hobbiesInterests.hobbiesAndInterest
        ? hobbiesInterests.TypeofBooksYouRead.filter(
          (e) => e.selected === "Y"
        ).map((e, i) => e.id)
        : [],
      moviesWatch: hobbiesInterests.TypeOfMoviesYouWatch
        ? hobbiesInterests.TypeOfMoviesYouWatch.filter(
          (e) => e.selected === "Y"
        ).map((e, i) => e.id)
        : [],
      movieDirectors: hobbiesInterests.FavoriteMovieDirectors
        ? hobbiesInterests.FavoriteMovieDirectors.filter(
          (e) => e.selected === "Y"
        ).map((e, i) => e.id)
        : [],
      sportsFitnessActivitiesGames:
        hobbiesInterests.SportsAndFitnessActivitiesAndGames
          ? hobbiesInterests.SportsAndFitnessActivitiesAndGames.filter(
            (e) => e.selected === "Y"
          ).map((e, i) => e.id)
          : [],
      favouriteCuisine: hobbiesInterests.FavouriteCuisine
        ? hobbiesInterests.FavouriteCuisine.filter(
          (e) => e.selected === "Y"
        ).map((e, i) => e.id)
        : [],
      languagesKnow: hobbiesInterests.languagesYouKnow
        ? hobbiesInterests.languagesYouKnow
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        : [],
      languagesWishToKnow: hobbiesInterests.languagesThatIwishToKnow
        ? hobbiesInterests.languagesThatIwishToKnow
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
        : [],
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      saveHobbiesandInterests(values);
    },
  });

  const saveHobbiesandInterests = async (values) => {
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getprofilehobbiesSaveUrl()}`,
      values
    );

    if (statusCode === 200) {
      getHobbiesandInterests();
    } else if (statusCode === 401) {
      logout();
    }
  };

  useEffect(() => { }, [hobbiesInterests]);

  return (
    <div >
      {/* {loading ? ( */}
      <>
        <main>
          {props.type == "astropopup" ?

            <div>
              <AstroComponent
                data={editMyProfileData}
                // dashboard1Data={ProfileData}
                proileCount={leftPanelData}
                callBackReload={() => {
                  apiCallEditProfile();
                  apiCallManagePreference();
                }}
                managePreferenceData={managePreferenceData}
                type="astroshow"
              />
            </div> :
            <div>
              <AstroComponent
                data={editMyProfileData}
                dashboard1Data={ProfileData}
                proileCount={leftPanelData}
                callBackReload={() => {
                  apiCallEditProfile();
                  apiCallManagePreference();
                }}
                managePreferenceData={managePreferenceData}
                postData={props?.postData}
                isFrom={props.isFrom}
                editviatrialpack= {props.editviatrialpack} 
                notificationId={props.notificationId}
                OnClose={props.OnClose} 
                setCompleteNow={props.setCompleteNow}
                showdatas={props?.showdatas} message={props?.message}
                ShowpopupDataNotification={props?.ShowpopupDataNotification}
                KMNotificationId={props.KMNotificationId}
              />
            </div>}


        </main>
      </>
      {/* ) : (
        ""
      )} */}
    </div>
  );
};

export default AstroBasic;
