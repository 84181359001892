import {
  CALLBACK_DETAIL,
    CALLBACK_DETAIL_API_ERROR,
    CALLBACK_DETAIL_SUCCESS,
    CLEAR_DYNAMIC_PIXEL,
    COUPEN_CODE,
    COUPEN_CODE_API_ERROR,
    COUPEN_CODE_SUCCESS,
    DYNAMIC_PIXEL,
    DYNAMIC_PIXEL_API_ERROR,
    DYNAMIC_PIXEL_SUCCESS,
    MEMBERSHIP_DETAIL, MEMBERSHIP_DETAIL_API_ERROR, 
    MEMBERSHIP_DETAIL_SUCCESS, PAY_NOW, PAY_NOW_SUCCESS,
    OFFLINE_PROFILE,OFFLINE_PROFILE_SUCCESS,OFFLINE_PROFILE_FAIL
  } from "./ActionTypes";


  //membership onload
  export const getMemberShipDetail = (id, email,fromPage ,microtrackId) => {
    return {
      type: MEMBERSHIP_DETAIL,
      payload: {id, email,fromPage ,microtrackId}
    };
  };

  export const getMemberShipDetailSuccess = data => {
    //console.log(data,"12344");
    return {
      type: MEMBERSHIP_DETAIL_SUCCESS,
      payload: data,
    };
  };
  
  export const getMemberShipDetailApiError = error => {
    //////console.log(error,"12345");
    return {
      type: MEMBERSHIP_DETAIL_API_ERROR,
      payload: error,
    };
  };


    //offline onload
 
// export const getOfflineDetail = 
// (id) => {
//   return {
//     type: OFFLINE_PROFILE,
//     payload: "1678208"
//   };
// };
// export const getOfflineDetailSuccess = data => {
//   //console.log(data,"12344");
//   return {
//     type: OFFLINE_PROFILE_SUCCESS,
//     payload: data,
//   };
// };
// export const getOfflineDetailFail = error => {
//   //////console.log(error,"12345");
//   return {
//     type: OFFLINE_PROFILE_FAIL,
//     payload: error,
//   };
// };


   //call back 
   export const callBackDetail = (values,id) => {
    //console.log(values,id,"12345");
    return {
      type: CALLBACK_DETAIL,
      payload: {values,id}
    };
  };

  export const callBackDetailSuccess = data => {
    //console.log(data,"12344");
    return {
      type: CALLBACK_DETAIL_SUCCESS,
      payload: data,
    };
  };
  
  export const callBackDetailApiError = error => {
    //////console.log(error,"12345");
    return {
      type: CALLBACK_DETAIL_API_ERROR,
      payload: error,
    };
  };

   //cuopen code 
   export const coupenCode = (values,id, discountCode) => {
    //console.log(values,id,"12345");
    return {
      type: COUPEN_CODE,
      payload: {values,id, discountCode}
    };
  };

  export const coupenCodeSuccess = data => {
    //console.log(data,"12344");
    return {
      type: COUPEN_CODE_SUCCESS,
      payload: data,
    };
  };
  
  export const coupenCodeApiError = error => {
    return {
      type: COUPEN_CODE_API_ERROR,
      payload: error,
    };
  };

  //pay now code 
  export const payNow = (values,id, discountCode) => {
    //console.log(values,id,"12345");
    return {
      type: PAY_NOW,
      payload: {values,id, discountCode}
    };
  };

  export const payNowSuccess = data => {
    //console.log(data,"12344");
    return {
      type: PAY_NOW_SUCCESS,
      payload: data,
    };
  };
  
  export const payNowApiError = error => {
    return {
      type: PAY_NOW_SUCCESS,
      payload: error,
    };
  };

  //dynamic pixel code 
  export const dynamicPixel = () => {
    //console.log(values,id,"12345");
    return {
      type: DYNAMIC_PIXEL,
      payload: {}
    };
  };

    //dynamic pixel code 
    export const clearDynamicPixel = () => {
      //console.log(values,id,"12345");
      return {
        type: CLEAR_DYNAMIC_PIXEL,
        payload: {}
      };
    };

  export const dynamicPixelSuccess = data => {
    //console.log(data,"12344");
    return {
      type: DYNAMIC_PIXEL_SUCCESS,
      payload: data,
    };
  };
  
  export const dynamicPixelApiError = error => {
    return {
      type: DYNAMIC_PIXEL_API_ERROR,
      payload: error,
    };
  };

  