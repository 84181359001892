import React, { Suspense, useContext, useEffect, useRef, useState, lazy } from "react";
import { Routes, Route, Navigate, useNavigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToken, onMessage } from 'firebase/messaging';
// import loadable from '@loadable/component'

import NotificationIcon from "./Images/Group 653.svg"


//routes
import PrivateRoute from "./Routes/PrivateRoute";
import ProtectedRoute from "./Routes/ProtectedRoute";
import RouteSegments, { RouteHelper, RoutePaths } from "./Routes/RouteSegment"


//pages
import RegisterLayout from "./Components/RegisterLayout/RegisterLayout";
import Dashboard from "./Pages/Dashboard";
import SearchPage from "./Pages/BasicAdvanceSearch"
import ViewMessage from "./Pages/ViewMessage";
import ViewAllPage from "./Pages/SearchResultpage";
import MatchesProfile from "./Pages/MatchesProfile";
import ProfileRegisterationOtp from "./Pages/ProfileRegisterationOtp";
import EditProfile from "./Pages/EditProfile";
import MembershipRegister from "./Pages/MembershipRegister";




//more link registration
import Notification from "./Pages/Notification";



import Constants, { InboxFilters } from "./Constants/constants";
import Redirect from "./Pages/Redirect";
import { BulkRequestPopup, CommonCastPopup, CommonDangerPopup, CommonPopup, CommonPopupWithoutHeader, CommonQualityPopup, PaymentBannerPopup } from "./Components/CommonPopup";
import { POST, GET } from "./Services/api_services";
import config from "./config";
import endPoints from "./Constants/EndPoints";
import { LoginContext } from "./Contexts/LoginContext";


//After registration flow
import ImageHoroscopePopupBackground from "./Pages/AfterRegistation/ImageHoroscopePopupBackground";
import useSeoMeta from "./Hooks/useSeoMeta";

import ConditionalRoute from "./Routes/ConditionalRoute";


import LiteLayout from "./Components/Kalyanlite/Common/LiteLayout";
import MatchGroupPage from "./Pages/KalyanlitePages/MatchGroupPage";

import Register from "./Pages/Register";
import ProfileHobbies from "./Pages/AfterRegistation/ProfileHobbies";
import { messaging } from "./firebase/firebaseConfig";
import OfflieProfilePage from "./Pages/KalyanlitePages/OfflieProfilePage";
import StartNewGroupPage from "./Components/Kalyanlite/MatchGroup/StartNewGroupPage";
import EndPoints from "./Constants/EndPoints";
import axios from "axios";
import $ from "jquery"
import PointEarnedNotification from "./Components/KalyanRewards/PointEarnedNotification";
import { NavBarContext } from "./Contexts/NavBarContext";
import { useDispatch, useSelector } from "react-redux";
import FamilyDetails from "./Pages/AfterRegistation/FamilyDetails";
import { extractCommunity, getDeviceInfo } from "./Components/Kalyanlite/utils";
import NRIRegister from "./Pages/NRIRegister";
import { loginFromSMSUser } from "./Store/Login/Action";
import { useTranslation } from "react-i18next";
import { getIsLoginFromAdmin } from "./Utils";
import UploadPhotoPage from "./Pages/AfterRegistation/UploadPhotoPage";
import PaymentPage from "./Pages/AfterRegistation/PaymentPage";
import MicrositePage from "./Components/Microsite/MicrositePage";

const AfterRegistration = lazy(() => import('./Pages/AfterRegistation/AfterRegistration'));
const GetPassword = lazy(() => import("./Components/GetPassword"));

const FilterPage = lazy(() => import("./Pages/KalyanlitePages/FilterPage"));
const ChatPage = lazy(() => import("./Pages/KalyanlitePages/ChatApp"));
const MatchPage = lazy(() => import("./Pages/KalyanlitePages/MatchPage"));
//gold scheme
const RegisteredSchemeListPage = lazy(() => import("./Components/GoldScheme/RegisteredSchemePage/RegisteredSchemeListPage"));
const NewRegisteration = lazy(() => import("./Components/GoldScheme/NewRegisteration/NewRegisteration"));
const PaymentHistory = lazy(() => import("./Components/GoldScheme/PaymentHistory/PaymentHistory"));
const PaymentView = lazy(() => import("./Components/GoldScheme/PaymentView/PaymentView"));
const CustomerInformation = lazy(() => import("./Components/GoldScheme/CustomerInformation/CustomerInformation"));
const CustomerUpdateAddress = lazy(() => import("./Components/GoldScheme/CustomerUpdateAddress/CustomerUpdateAddress"));
const CustomerUpdateIDproff = lazy(() => import("./Components/GoldScheme/CustomerUpdateProff/CustomerUpdateIDproff"));

const AboutKalyanJewellers = lazy(() => import("./Pages/Footer/AboutKalyanJewellers"));
const AboutUs = lazy(() => import("./Pages/Footer/AboutUs"));
const ContactUs = lazy(() => import("./Pages/Footer/ContactUs"));
const Feedback = lazy(() => import("./Pages/Footer/Feedback"));
const HelpAndFAQ = lazy(() => import("./Pages/Footer/HelpAndFAQ"));
const HowItWorks = lazy(() => import("./Pages/Footer/HowItWorks"));
const PrivacyPolicy = lazy(() => import("./Pages/Footer/PrivacyPolicy"));
const SafetyAndSecurity = lazy(() => import("./Pages/Footer/SafetyAndSecurity"));
const TermsAndCondition = lazy(() => import("./Pages/Footer/TermsAndCondition"));
const DeletePhoto = lazy(() => import("./Pages/Footer/DeletePhoto"));

const KalyanPremier = lazy(() => import("./Pages/KalyanPremier"));
const Jewellerypage = lazy(() => import("./Pages/JewelleryPage"));

const StoreRegistration = lazy(() => import("./Components/StoreRegistration/StoreRegistration"));
const DownloadPage = lazy(() => import('./Pages/AfterRegistation/DownloadPage'));

const PaymentResponse = lazy(() => import("./Pages/PaymentResponse"));
const SavingScheme = lazy(() => import("./Pages/SavingScheme"));
const ViewLedger = lazy(() => import("./Components/GoldScheme/Ledger/ViewLedger"));
const BannerRedirection = lazy(() => import("./Pages/BannerRedirection/BannerRedirectionDeveloper"));
const CampaignLandingPage = lazy(() => import("./Pages/CampaignLandingPage "));
const MobileAboutUs = lazy(() => import("./Pages/Footer/MobileAboutUs"));
const MobileTermsAndCondition = lazy(() => import("./Pages/Footer/MobileTermsAndCondition"));

const HomePage = lazy(() => import("./Pages/HomePage"));
const GoogleLandingPage = lazy(() => import("./Pages/GoogleLandingPage"));
const ProfileDeletion = lazy(() => import("./Pages/ProfileDeletion"));

const ProfileRegistration = lazy(() => import("./Pages/ProfileRegistration"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPassword"));


const ProtectedLiteRoute = () => {
  const isV3User = localStorage.getItem("isV3User")

  if (isV3User) {
    return <Navigate to={RouteSegments.MATCH_LITE} replace={true} />
  }
  return <Outlet />
}

async function requestPermission() {
  //requesting permission using Notification API
  const permission = await window.Notification.requestPermission();

  if (permission === "granted") {
    window.navigator.serviceWorker.register(process.env.PUBLIC_URL + '/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" }).then(async () => {
      const token = await getToken(messaging, {
        vapidKey: "BOkYKLBpw9iIkK7p3rSiRiOUvvaTj77ZBIueLdsSRy9BeiragVXvww6zR1IawhNGDhJjsxB0JolYTzBwqapVGyI",
      });

      localStorage.setItem(Constants.loginLocalStorageKeys.fcmToken, token)
      console.log("Token generated : ", token);
    })
    //We can send token to server
  } else if (permission === "denied") {
    //notifications are blocked
    alert("You denied for the notification");
  }
}


function App() {
  const navigate = useNavigate()
  useSeoMeta()
  const location = useLocation()
  const isV3User = localStorage.getItem("isV3User")
  const viewAllRef = useRef(null)
  const paymentBannerRef = useRef(null)
  const casteRef = useRef(null)
  const trailRef = useRef(null)
  const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })
  const [showPaymentBannerPopupData, setShowPaymentBannerPopupData] = useState({ show: false, title: "", content: "", message: "" })
  const [showTrailPopupData, setShowTrailPopupData] = useState({ show: false, title: "", content: "", message: "" })
  const [showCastePopupData, setShowCastePopupData] = useState({ show: false, title: "", content: "", message: "" })
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({ url: "", flag: "", show: false });
  const [switchPopupData, setSwitchPopupData] = useState({ show: false, switch: "" })
  const { loginDetail, logout, isLoggedIn } = useContext(LoginContext);
  const [showNotificationPermission, setShowNotificationPermission] = useState(false)
  const [previousLocation, setPreviousLocation] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const showCelebration = useSelector(state => state?.KalyanRewards?.showCelebration)
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()

  const { t: trans, i18n } = useTranslation();


  let text = window.location.href
  let host = window.location.host.replace("www.", "")
  // let host = "uat.vanniyarkalyanmatrimony.com"
  let isCommunitySite = host.includes("v3-uat") || host === "kalyanmatrimony.com" || host === "kalyan.localhost" || host === "kln.localhost" ? false : true

  let fromAdminSite = ["loginfromsms", "loginfromadmin", "loginfromadminPartial", "loginfromemail", "akhayatritiya", "offline-profile"]
  let isFromAdminSite = text.includes(fromAdminSite[0]) || text.includes(fromAdminSite[1]) || text.includes(fromAdminSite[2]) || text.includes(fromAdminSite[3]) || text.includes(fromAdminSite[4]) || text.includes(fromAdminSite[5])



  useEffect(() => {
    let loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop"
    const finalLoginDevice = loginDevice || "Unknown";
    localStorage.setItem(Constants.loginLocalStorageKeys.loginDevice, finalLoginDevice)
  }, [])

  // useEffect(() => {

  //   if (window.Notification.permission !== "granted") {
  //     setShowNotificationPermission(true)
  //   } else {
  //     requestPermission()
  //   }

  // }, []);

  useEffect(() => {
    console.log(location, "locationll", currentLocation)

    setPreviousLocation(currentLocation?.pathname);
    setCurrentLocation(location?.pathname);
    if (currentLocation) {
      if (currentLocation.includes("/membershipregister")) {
        paymentVisitTime();
      }

    }

  }, [location, currentLocation]);


  const paymentVisitTime = async () => {

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.payemtPageVisitEndtime(localStorage.getItem('assistId'))}`,
    );

    if (statusCode === 200) {
      if (data.status === "Success") {

      }
    } else if (statusCode === 401) {

    }
  }

  useEffect(() => {
    const unsub = onMessage(messaging, (payload) => {
      console.log(
        '[App.js] Received foreground message ',
        payload
      );
      if (document.hidden) return;
      const notification = new window.Notification(payload.notification?.title, {
        icon: "/favicon.ico",
        body: payload.notification?.body
      })
      notification.onclick = (event) => {
        const data = payload?.data;
        event.preventDefault();
        let path = data?.path;
        console.log(path, "pathpathpath");

        const queryString = new URLSearchParams(data).toString();
        sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationPath, path)
        console.log({ data, queryString }, "notifications");
        console.log(data, queryString, "notificationnn");

        if (path == "DownloadApp") {
          window.open(RouteSegments.staticLinkForAppDownload.android, "_blank");
          return;
        } else {
          if (isLoggedIn()) {
            // if (path == 'PrivacyOption') {
            //   let values = {
            //     profiletoken: data?.profiletoken,
            //     jwt: data?.jwt,
            //     notificationId: data?.notificationId,
            //     // messageId: data?.messageId,
            //     privacypopup: data?.privacypopup, 
            //   }
            //   dispatch(loginFromSMSUser(values, navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
            // }
            // else if (path == 'Horoscope') {
            //   let values = {
            //     profiletoken: data?.profiletoken,
            //     jwt: data?.jwt,
            //     notificationId: data?.notificationId,
            //     messageId: data?.messageId,
            //     horoscopepopup: data?.horoscopepopup, 
            //   }
            //   dispatch(loginFromSMSUser(values, navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
            //   navigate(`/new/matches?addHoroscope=Y`, { replace: true })
            // } else if (path == 'StarRaasi') {
            //   let values = {
            //     profiletoken: data?.profiletoken,
            //     jwt: data?.jwt,
            //     notificationId: data?.notificationId,
            //     // messageId: data?.messageId,
            //     starraasipopup: data?.starraasipopup,
            //   }
            //   dispatch(loginFromSMSUser(values, navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
            // } else 
            if (path == "WhoViewedMyprofile") {
              sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, data?.notificationId)
              navigate(`/new/match-family?WhoViewedMyprofile=Y`, {
                replace: true, state: {
                  data: {
                    notificationContent: data?.notificationContent,
                    notificationId: data?.notificationId,
                  },
                  isFrom: "chromenotif"
                }
              })
            }
            else if (path == "StarRaasi") {
              sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, data?.notificationId)
              navigate(`/new/matches?${queryString}`, {
                replace: true, state: {
                  data: {
                    notificationId: data?.notificationId,
                  },
                  isFrom: "chromenotification"
                }
              })
            }
            else {
              sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, data?.notificationId)
              navigate(`/new/matches?${queryString}`, {
                replace: true, state: {
                  data: {
                    notificationId: data?.notificationId,
                  }
                }
              })
            }
          } else {
            let values = {
              profiletoken: data?.profiletoken,
              jwt: data?.jwt,
              horoscopepopup: data?.horoscopepopup,
              starraasipopup: data?.starraasipopup,
              whoviewedmyprofile: data?.whoviewedmyprofile,
              notificationId: data?.notificationId,
              messageId: data?.messageId,
              privacypopup: data?.privacypopup,
              receiverId : data?.receiverId ,
              requesttypeid : data?.requesttypeid ,
              smslogId : data?.smslogId ,
              loginFrom : getDeviceInfo(),
            }
            dispatch(loginFromSMSUser(values, navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
          }
        }

        // else {
        //   navigate(`/new/matches?${queryString}`, { replace: true })
        // }
      }
    })

    return () => {
      unsub()
    }

  }, [])


  useEffect(() => {
    const handleVersionSwitch = e => {
      console.log("handleVersionSwitch", e);
      if (e.key === 'isV3User' && e.newValue == "true" && isLoggedIn) {
        setSwitchPopupData({ show: true, switch: e.newValue })
      }
    }
    window.addEventListener('storage', handleVersionSwitch)
    return function cleanup() {
      window.removeEventListener('storage', handleVersionSwitch)
    }
  }, [])

  const bulrequestPopup = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "bulktrigger": true
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequestpopup()}`,
      request
    );

    if (statusCode === 200) {
      if (data?.data?.recomm_count > 0) {
        setShowBulkRequestPopupData({
          show: true,
          title: trans('send_bulk_request.send_request'),
          content: "",
          message: data?.data?.bulkrequestcontent,
        })
      }
    } else if (statusCode === 401) {
      logout();
    }
  }

  const closeBulkRequestPopup = () => {
    viewAllRef.current = null;
    setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setShowBulkRequestPopupData({
        show: true,
        title: trans('send_bulk_request.send_request'),
        content: "Request Sent Successfully",
      })
    }

  }


  const onBulkRequestSend = (status) => {
    viewAllRef.current = null;
    if (status) {
      apiCallBulkRequest()
    } else {
      closeBulkRequestPopup()
    }
  }

  const closePaymentBannerPopup = () => {
    paymentBannerRef.current = null;
    setShowPaymentBannerPopupData({ show: false, content: "", message: "", title: "" })


  }
  const closeCastePopup = () => {
    casteRef.current = null;
    setShowCastePopupData({ show: false, content: "", message: "", title: "" })


  }

  const closeTrialPopup = () => {
    trailRef.current = null;
    setShowTrailPopupData({ show: false, content: "", message: "", title: "" })


  }

  const viewAllCallback = () => {
    if (viewAllRef.current == null) {
      const t = setTimeout(() => {
        bulrequestPopup()
      }, 60000);
      viewAllRef.current = t
    }
  }

 

  const PaymentPopupCallback = () => {
    if (paymentBannerRef.current == null) {
      const t = setTimeout(() => {
        setShowPaymentBannerPopupData({
          show: true,
          title: "",
          content: "",
          message: "",
        })
      }, 30000); //30 seconds

      paymentBannerRef.current = t
    }
  }

  


  const CastePopupCallback = () => {
    if (casteRef.current == null) {
      const t = setTimeout(() => {
        setShowCastePopupData({
          show: true,
          title: "",
          content: "",
          message: "",
        })
      }, 20000); //30 seconds
      casteRef.current = t
    }
  }

  const [showNotificationId,setShowNotificationId] = useState([])

  console.log(showNotificationId,"showNotificationId");
  
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const apiCallSameDay = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      profileId:userData?.profileId,
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.samedaypopuptrackUrl()}`,
      request
    );

    if (statusCode === 200) {
      setShowNotificationId(data?.data?.notificationId)
    } 
  };

  
  // const TrailPopupCallback = () => {
  //   if (trailRef.current == null) {
  //     const t = setTimeout(() => {
  //       setShowTrailPopupData({
  //         show: true,
  //         title: "",
  //         content: "",
  //         message: "",
  //       })
  //     }, 30000); //7 miniuts
  //     trailRef.current = t
  //   }
  // }

  const TrailPopupCallback = () => {
  
    if (trailRef.current == null) {
      const t = setTimeout(async () => {

        let request = {
          email: loginDetail()[0],
          userId: loginDetail()[1],
          profileId:userData?.profileId,
          loginFrom: localStorage.getItem(
            Constants.loginLocalStorageKeys.loginDevice
          ),
        };
    
        let { statusCode, data } = await POST(
          `${config.api.API_URL}${EndPoints.samedaypopuptrackUrl()}`,
          request
        );
    
        if (statusCode === 200) {
          const NotificationId = data?.data?.notificationId; // Extract message here
        console.log(NotificationId,"NotificationIdbb");
          setShowTrailPopupData({
            show: true,
            title: "",
            content: "",
            message: NotificationId,
          });
        } 
        // Display the popup
      
      }, 420000); // 7 minutes (adjust the time if necessary)
      trailRef.current = t;
    
    }
  };
  

  const horoscopeGeneratedCallback = () => {
  }

  useEffect(() => {
    if (!isLoggedIn() && viewAllRef.current != null) {
      clearTimeout(viewAllRef.current)
      viewAllRef.current = null
    }
    if (!isLoggedIn() && paymentBannerRef.current != null) {
      clearTimeout(paymentBannerRef.current)
      paymentBannerRef.current = null
    }

    if (!isLoggedIn() && casteRef.current != null) {
      clearTimeout(casteRef.current)
      casteRef.current = null
    }

    if (!isLoggedIn() && trailRef.current != null) {
      clearTimeout(trailRef.current)
      trailRef.current = null
    }
  }, [isLoggedIn])

  const bannerRedirection = () => {
    navigate(RouteSegments.MEMBERSHIP_REGISTER(), { state: { isFrom: "paynowpage" } }); // Pass state here

    closePaymentBannerPopup()
  }
 
  const switchSite = () => {
    if (switchPopupData.switch) {
      navigate(RouteSegments.MATCH_LITE)
    } else {
      navigate(RouteSegments.DASHBOARD)
    }
    setSwitchPopupData({ show: false })
  }


  const closePermission = () => {
    requestPermission()
    setShowNotificationPermission(false)
  }



  useEffect(() => {
    if (isCommunitySite) {
      const request = {
        "domain": window.location.host,
        "slug": window.location.pathname,
      }
      axios.post(`${config.configCommunity}${EndPoints.getPixelScript()}`, request).then(res => {
        if (res.data?.data?.length > 0) {
          const scriptToInject = `<div class="communitypixelScript">${res.data?.data?.map(data => data?.pageScripts)}</div>`
          console.log({ scriptToInject });
          if ($('.communitypixelScript').length > 0) {
            $('.communitypixelScript').remove()
            $('head').append(scriptToInject)
          } else {
            $('head').append(scriptToInject)
          }
        }
      })
    }

    return () => {
      $('.communitypixelScript').remove()
    }
  }, [isCommunitySite, location.pathname])

  const fontcss = i18n.language == "ta"
  ? `
    .font-Poppins-Regular, .font-segeo, .font-segoe, .font-sans {
  font-family: Tamil-Regular !important;
}

.font-Poppins-SemiBold, .font-seoge-semibold, .custom-font-family{
font-family: Tamil-SemiBold !important;
}

`:""


  return (
    <>
      {switchPopupData.show ?
        <CommonPopup title={trans('matches.kalyan_matrimony')} close={switchSite}>
          <p>You switched to new version</p>
        </CommonPopup> : null}
      {
        showBulkRequestPopupData.show && (
          <BulkRequestPopup
            close={closeBulkRequestPopup}
            onSend={onBulkRequestSend}
            content={showBulkRequestPopupData.content}
            message={showBulkRequestPopupData?.message}
            imageSrc={showBulkRequestPopupData.imageSrc}
            title={showBulkRequestPopupData.title} />
        )
      }

      {showNotificationPermission &&
        <CommonDangerPopup
          icon={NotificationIcon}
          close={closePermission}
          content={"Please grant notification permission for better experience"}
        />
      }

      {
        showPaymentBannerPopupData.show && (
          <CommonPopupWithoutHeader close={closePaymentBannerPopup} >
            <img className="w-[100%]" src={`${config.paidBannerUrl}`} onClick={bannerRedirection} />
          </CommonPopupWithoutHeader>
        )
      }

      {showCastePopupData.show && (
        <CommonCastPopup close={closeCastePopup} >
        </CommonCastPopup>

      )}

      {showTrailPopupData.show && (
        <CommonQualityPopup OnClose={closeTrialPopup} 
        
        message={showTrailPopupData?.message}> 
        </CommonQualityPopup>
      )}

      {generatedHoroScopeUrl.show ? (
        <ImageHoroscopePopupBackground
          console={console.log("123456")}
          close={() => setgeneratedHoroscopeurl(prev => ({ ...prev, show: false }))}
          title={trans('chat.view_horoscope')}
          deleteImage={true}
          isShowEditBtn={false}
        >
          {generatedHoroScopeUrl.flag === "GenerateHoroscope" ? (
            <div className="relative">
              <iframe
                title={trans('view_profile.horoscope')}
                src={generatedHoroScopeUrl.url}
                className="w-[70vw] lg:w-[60vw] h-[50vh]"
              />
              {/* {showHoroScopeLoader === true? <div className="absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>:null} */}
            </div>
          ) : (
            <img
              alt=""
              src={generatedHoroScopeUrl.url}
              className="w-[70vw] lg:w-[60vw] h-[50vh]"
            />
          )}
        </ImageHoroscopePopupBackground>
      ) : (
        <></>
      )}

      <Suspense fallback={null}>
        <Routes>
          {isCommunitySite ?
            <>
              <Route path="/" element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_ADMIN} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_ADMIN_OFFLINE} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_EMAIL} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_SMS} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_PARTIAL_ADMIN} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FOR_AKSHAYA} element={<GoogleLandingPage page="exclusive" />} />
              <Route path={RouteSegments.LOGIN_FROM_OFFLINE} element={<GoogleLandingPage page="exclusive" />} />
            </>
            :
            !isFromAdminSite ?
              // window.location.href?.includes('storeregistration')?
              <>
                <Route path={RouteSegments.STORE_REGISTRATION} element={<StoreRegistration />} />
                <Route path="/" element={<ProtectedRoute><RegisterLayout page="register" show={true} /></ProtectedRoute>}>

                  <Route path="/" element={<Register />} />
                </Route>
              </>
              :
              <>
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path="/" element={<Register />} />
                </Route>
                <Route path={RouteSegments.LOGIN_FROM_ADMIN} element={<Register from="admin" />} />
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path={RouteSegments.LOGIN_FROM_ADMIN_OFFLINE} element={<Register />} />
                </Route>
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path={RouteSegments.LOGIN_FROM_EMAIL} element={<Register />} />
                </Route>
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path={RouteSegments.LOGIN_FROM_SMS} element={<Register />} />
                </Route>
                <Route path={RouteSegments.LOGIN_FROM_PARTIAL_ADMIN} element={<Register from="admin" />} />
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path={RouteSegments.LOGIN_FOR_AKSHAYA} element={<Register />} />
                </Route>
                <Route path="/" element={<RegisterLayout page="register" show={true} />}>
                  <Route path={RouteSegments.LOGIN_FROM_OFFLINE} element={<Register />} />
                </Route>
              </>

          }
          <Route path="/" element={<RegisterLayout className="" />} >
            <Route path={RouteSegments.GET_OTP} element={<GetPassword />} />
          </Route>
          <Route path="/" element={<RegisterLayout page="home" className="forgotpass" />} >
            <Route path={RouteSegments.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={RouteSegments.OTP_VALIDATION_FOR_RESET} element={<ForgotPassword />} />
          </Route>
          <Route path="/" element={<RegisterLayout page="basic" menu="basic_data" />} >
            <Route path={RouteSegments.BASIC_INFO} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.BASIC_INFO || searchParams.get("paymentstatus")}> <ProfileRegistration /></ConditionalRoute>} />
          </Route>
          <Route path="/" element={<RegisterLayout page="basic" menu="footer" />} >
            {/* footer */}
            <Route path={RouteSegments.ABOUT_KALYAN_JEWELLERS} element={<AboutKalyanJewellers />} />
            <Route path={RouteSegments.ABOUT_US} element={<AboutUs />} />
            <Route path={RouteSegments.CONTACT_US} element={<ContactUs />} />
            <Route path={RouteSegments.FEEDBACK} element={<Feedback />} />
            <Route path={RouteSegments.HELP} element={<HelpAndFAQ menu="help" />} />
            <Route path={RouteSegments.HOW_IT_WORKS} element={<HowItWorks />} />
            <Route path={RouteSegments.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={RouteSegments.SAFETY_AND_SECURITY} element={<SafetyAndSecurity />} />
            <Route path={RouteSegments.TERMS_AND_CONDITION} element={<TermsAndCondition />} />
            <Route path={RouteSegments.DELETE_PHOTO} element={<DeletePhoto />} />

            {/* more link registration */}

            {/* mobile view */}

          </Route>
          <Route path="/" element={<RegisterLayout menu="mobilebasic" />} >
            <Route path={RouteSegments.MOBILE_ABOUT_US} element={<MobileAboutUs />} />
          </Route>
          <Route path="/" element={<RegisterLayout type="mobileBasicHeader" />} >
            <Route path={RouteSegments.MOBILE_TERMS_AND_CONDITION} element={<MobileTermsAndCondition />} />
          </Route>

          <Route path={RouteSegments.HELP_AND_FAQ_LITE} element={<HelpAndFAQ />} />

          {/* V3 help */}
          <Route path={RouteSegments.HELP_AND_FAQ()} element={isV3User
            ? <Navigate to={RouteSegments.HELP_AND_FAQ_LITE} replace={true} />
            : <HelpAndFAQ />} />

          <Route path="/redirect" element={<Redirect />} />
          <Route path={RouteSegments.KALYAN_PREMIER} element={<KalyanPremier />} />

          {/* Inbox Routes */}
          <Route path={RoutePaths.INBOX} element={<ProtectedLiteRoute />}>

            <Route index element={<Navigate replace to={RouteHelper.getInboxByTab(InboxFilters.message[0].urlPath)} />} />
            <Route path="*" element={<Navigate replace to={RouteHelper.getInboxByTab(InboxFilters.message[0].urlPath)} />} />

            <Route path=":tab" element={<PrivateRoute><ViewMessage menu="inbox" /></PrivateRoute>} />
            <Route path={`:tab/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={`:tab/${RoutePaths.CONVERSATION}`} element={<PrivateRoute><ViewMessage menu="inbox" /></PrivateRoute>} />
            <Route path={`:tab/${RoutePaths.CONVERSATION}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />
          </Route>
          {/* Inbox Routes */}



          <Route path={RouteSegments.PROFILE_OTP} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.PROFILE_OTP}><ProfileRegisterationOtp /></ConditionalRoute>} />
          <Route path={RouteSegments.SEARCH} element={<ProtectedLiteRoute />}>
            <Route index element={<PrivateRoute><SearchPage viewAllCallback={viewAllCallback} /></PrivateRoute>} />
            <Route path={RoutePaths.SAVEPARTNERPREFERENCES} element={<PrivateRoute><ViewAllPage menu="savepartnerpreferences" /></PrivateRoute>} />
            <Route path={`${RoutePaths.SAVEPARTNERPREFERENCES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />
          </Route>


          <Route element={<PrivateRoute> <ConditionalRoute conditionFor="afterRegPhotoPopup" to={RouteSegments.AFTER_REGISTRATION} allow={getIsLoginFromAdmin() ? true:localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.AFTER_REGISTRATION ? localStorage.getItem("isOriginalImageUploaded") === "true" : true}><LiteLayout /></ConditionalRoute></PrivateRoute>}>
            <Route path={RouteSegments.MATCH_LITE} element={<Suspense fallback={null}><MatchPage /></Suspense>} />
            <Route path={RouteSegments.MATCH_CONVERSATIONS_LITE} element={<Suspense fallback={null}><MatchPage /></Suspense>} />
            <Route path={RouteSegments.CHAT_LITE} element={<Suspense fallback={null}><ChatPage /></Suspense>} />
            <Route path={RouteSegments.FILTER_LITE} element={<Suspense fallback={null}><FilterPage viewAllCallback={viewAllCallback} /></Suspense>} />
            {/* <Route path={RouteSegments.HELP_AND_FAQ_LITE} element={<HelpAndFAQ />} /> */}
            {isV3User ? <Route path={RouteSegments.MEMBERSHIP_REGISTER_LITE} element={<PrivateRoute><MembershipRegister /></PrivateRoute>} /> : null}
            <Route path={RouteSegments.PAYMENT_RESPONSE} element={<PaymentResponse />} />
            {/* <Route path={RouteSegments.MATCH_GROUP} element={<MatchGroupPage />} /> */}
            <Route path={RouteSegments.MATCH_GROUP} element={<MatchGroupPage />} />
            {
              isV3User
                ? <>
                  <Route path={`/${RoutePaths.EDITPROFILE_LITE}/:tab`} element={<PrivateRoute><EditProfile menu="editprofile" isV3User={true} /></PrivateRoute>} />
                  <Route path={`/${RoutePaths.EDITPROFILE_LITE}/:tab/${RoutePaths.REMOVE_PROFILE}`} element={<PrivateRoute> <ProfileDeletion /> </PrivateRoute>} /></>
                : <>

                </>
            }
            {/* <Route path={RouteSegments.NOTIFICATION} element={<PrivateRoute><Notification menu="notification" /></PrivateRoute>} /> */}

          </Route>

          <Route path={RouteSegments.OFFLINE_PROFLIE} element={<PrivateRoute><OfflieProfilePage /></PrivateRoute>} />


          <Route path={RouteSegments.DASHBOARD} element={<ProtectedLiteRoute />}>

            <Route index element={<PrivateRoute><Dashboard viewAllCallback={viewAllCallback} /></PrivateRoute>} />
            <Route path={RoutePaths.PROFILE} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.WEEKLYVIEWALL} element={<PrivateRoute><ViewAllPage menu="weekly" /></PrivateRoute>} />
            <Route path={`${RoutePaths.WEEKLYVIEWALL}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.RECOMMENDED} element={<PrivateRoute><ViewAllPage menu="recommended" /></PrivateRoute>} />
            <Route path={`${RoutePaths.RECOMMENDED}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.ResponseReceived} element={<PrivateRoute><ViewAllPage menu="response" /></PrivateRoute>} />
            <Route path={`${RoutePaths.ResponseReceived}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.RECENTLYVIEW} element={<PrivateRoute><ViewAllPage menu="recentlyviewed" /></PrivateRoute>} />
            <Route path={`${RoutePaths.RECENTLYVIEW}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={`:list/${RoutePaths.RECENTLYVIEW}`} element={<PrivateRoute><ViewAllPage menu="recentlyviewed" /></PrivateRoute>} />
            <Route path={`:list/${RoutePaths.RECENTLYVIEW}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.SIMILARMATCHES} element={<PrivateRoute><ViewAllPage menu="similarmatches" /></PrivateRoute>} />
            <Route path={`${RoutePaths.SIMILARMATCHES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.PROFILELOOKINGFOR} element={<PrivateRoute><ViewAllPage menu="profilelookingfor" /></PrivateRoute>} />
            <Route path={`${RoutePaths.PROFILELOOKINGFOR}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.PROFILELOOKINGFORME} element={<PrivateRoute><ViewAllPage menu="profilelookingforme" /></PrivateRoute>} />
            <Route path={`${RoutePaths.PROFILELOOKINGFORME}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.PHOTOREQUESTRECEIVED} element={<PrivateRoute><ViewAllPage menu="photorequestreceived" /></PrivateRoute>} />
            <Route path={`${RoutePaths.PHOTOREQUESTRECEIVED}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.EXPLOREMATCHES} element={<PrivateRoute><ViewAllPage menu="exploreMatches" /></PrivateRoute>} />
            <Route path={`${RoutePaths.EXPLOREMATCHES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.MUTUALMATCHES} element={<PrivateRoute><ViewAllPage menu="mutualmatches" /></PrivateRoute>} />
            <Route path={`${RoutePaths.MUTUALMATCHES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.SELECTEDPROFILES} element={<PrivateRoute><ViewAllPage menu="selectedprofile" /></PrivateRoute>} />
            <Route path={`${RoutePaths.SELECTEDPROFILES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />

            <Route path={RoutePaths.SAVEPARTNERPREFERENCES} element={<PrivateRoute><ViewAllPage menu="savepartnerpreferences" /></PrivateRoute>} />
            <Route path={`${RoutePaths.SAVEPARTNERPREFERENCES}/${RoutePaths.PROFILE}`} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>} />
          </Route>

   
          <Route path={RouteSegments.MICROSITE_PAGE} element={<MicrositePage  />} />

          <Route path={RouteSegments.REMOVE_PROFILE} element={<PrivateRoute> <ProfileDeletion /> </PrivateRoute>} />

          {/* <Route path={RouteSegments.MATCHES} element={<PrivateRoute><MatchesProfile viewAllCallback={viewAllCallback} /></PrivateRoute>}/> */}
          {isV3User
            ? <Route path={RouteSegments.NOTIFICATION_LITE} element={<PrivateRoute><Notification menu="notification" /></PrivateRoute>} />
            : <Route path={RouteSegments.NOTIFICATION} element={<PrivateRoute><Notification menu="notification" /></PrivateRoute>} />}



          {
            isV3User
              ? null
              : <>
                <Route path={`/${RoutePaths.EDITPROFILE}/:tab`} element={<PrivateRoute><EditProfile  message={showTrailPopupData?.message} menu="editprofile" isV3User={false} /></PrivateRoute>} />
                <Route path={`/${RoutePaths.EDITPROFILE}/:tab/${RoutePaths.REMOVE_PROFILE}`} element={<PrivateRoute> <ProfileDeletion /> </PrivateRoute>} />
              </>
          }

          <Route path={RouteSegments.MEMBERSHIP_REGISTER()} element={isV3User
            ? <Navigate to={RouteSegments.MEMBERSHIP_REGISTER_LITE} replace={true} />
            : <PrivateRoute><MembershipRegister /></PrivateRoute>} />

          <Route path={RouteSegments.PAYMENT_RESPONSE} element={<PaymentResponse />} />


          {/* {after registation} */}
          <Route path={RouteSegments.AFTER_REGISTRATION} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.AFTER_REGISTRATION}> <AfterRegistration  PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} horoscopeGeneratedCallback={horoscopeGeneratedCallback} setgeneratedHoroscopeurl={setgeneratedHoroscopeurl} /> </ConditionalRoute>} />
          <Route path={RouteSegments.DOWNLOAD_PAGE} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.DOWNLOAD_PAGE}><DownloadPage TrailPopupCallback={TrailPopupCallback} PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} /></ConditionalRoute>} />
          <Route path={RouteSegments.HOBBIES_PAGE} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.HOBBIES_PAGE}><ProfileHobbies  PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} /></ConditionalRoute>} />
          <Route path={RouteSegments.FAMILY_PAGE} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.FAMILY_PAGE}><FamilyDetails  PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} /></ConditionalRoute>} />
          <Route path={RouteSegments.UPLOAD_PHOTO_PAGE} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.UPLOAD_PHOTO_PAGE}><UploadPhotoPage  PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} /></ConditionalRoute>} />
          {/* <Route path={RouteSegments.PAYMENT_PAGE} element={<ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.PAYMENT_PAGE}><PaymentPage  PaymentPopupCallback={PaymentPopupCallback} CastePopupCallback={CastePopupCallback} /></ConditionalRoute>} /> */}
          <Route path={RouteSegments.PAYMENT_PAGE} element={<PrivateRoute>
            <ConditionalRoute allow={localStorage.getItem(Constants.loginLocalStorageKeys.loginCurrentPage) == RouteSegments.PAYMENT_PAGE}>
              <PaymentPage />
            </ConditionalRoute>
          </PrivateRoute>} />
          {/* <Route path={RouteSegments.HOBBIES_PAGE} element={<ProfileHobbies  />} /> */}
          {/* <Route path={RouteSegments.UPLOAD_PHOTO_PAGE} element={<UploadPhotoPage  />} />  */}

          {/* NRI Page */}
          <Route path="/" element={<RegisterLayout page="register" show={true} isFrom={"NRI"} />}>
            <Route path={RouteSegments.LOGIN_FROM_NRI} element={<NRIRegister />} />
          </Route>

          {/* {Jewellery Microsite} */}

          <Route path={RouteSegments.JEWELLERY_MICROSITE} element={<Jewellerypage />} />



          {/* gold scheme */}
          <Route path={RouteSegments.GOLD_SAVING_SCHEME_ONLINE} element={<SavingScheme />} />
          <Route path={RouteSegments.VIEW_LEDGER} element={<ViewLedger />} />
          <Route path={RouteSegments.REGISTERED_SCHEME_LIST} element={<RegisteredSchemeListPage />} />
          <Route path={RouteSegments.NEW_SCHEME_REGISTER} element={<NewRegisteration />} />
          <Route path={RouteSegments.PAYMENT_HISTORY} element={<PaymentHistory />} />
          <Route path={RouteSegments.PAYMENT_VIEW} element={<PaymentView />} />
          <Route path={RouteSegments.CUSTOMER_INFORMATION} element={<CustomerInformation />} />
          <Route path={RouteSegments.CUSTOMER_UPDATE_ADDRESS} element={<CustomerUpdateAddress />} />
          <Route path={RouteSegments.CUSTOMER_UPDATE_IDPROFF} element={<CustomerUpdateIDproff />} />

          {/* <Route path={RouteSegments.GOLD_SAVING_SCHEME_ONLINE} element={<SavingScheme />} /> */}
          {/* <Route path={RouteSegments.VIEW_LEDGER} element={<ViewLedger />} /> */}

          {/* <Route path="/" element={<RegisterLayout  page="register"  show={true}/>}> */}
          {/* <Route path={RouteSegments.LOGIN_FROM_ADMIN} element={<LoginFromCRM />} />
        <Route path={RouteSegments.LOGIN_FROM_EMAIL} element={<LoginFromCRM  />} />
        <Route path={RouteSegments.LOGIN_FROM_SMS} element={<LoginFromCRM  />} />
        <Route path={RouteSegments.LOGIN_FROM_PARTIAL_ADMIN} element={<LoginFromCRM  />} />
        
        <Route path={RouteSegments.LOGIN_FOR_AKSHAYA} element={<LoginFromCRM  />} /> */}
          {/* </Route> */}

          {/* banner redirection */}
          <Route path={RouteSegments.BANNER_REDIRECTION_DEVELOPER} element={<BannerRedirection />} />
          <Route path={RouteSegments.BANNER_REDIRECTION_AKHAYATRITIYA} element={<BannerRedirection />} />
          {/* <Route path={RouteSegments.BANNER_REDIRECTION_GOLD} element={<BannerRedirection />} /> */}



          {(((new URL(window.location.href)).pathname).split("/")[1]) == "campaign" ?
            <Route path={(new URL(window.location.href)).pathname} element={<CampaignLandingPage page="google" />} />
            :
            (window.location.href.search("matrimonial")) > -1 ?
              <Route path={(new URL(window.location.href)).pathname} element={<HomePage />} />
              :
              window.location.pathname.split("/").includes(RoutePaths.EDITPROFILE) || window.location.pathname.split("/").includes(RoutePaths.INBOX) ?
                null : <Route path={(new URL(window.location.href)).pathname} element={<GoogleLandingPage page="google" />} />
          }






        </Routes>
      </Suspense>
      <style>
      {fontcss}
      </style>
      <ToastContainer className="md:w-[35rem]" />
      {showCelebration && <PointEarnedNotification />}
    </>
  );
}

export default App;
