import React, { useRef, useState, useCallback } from 'react'
import Webcam from "react-webcam";

function CameraPopup({ close, onImageConfirm ,track ,from }) {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef]);

    const videoConstraints = {
        facingMode: from == "horoscope" ? "environment" : "user",
      };


    return (
        <div className='font-Poppins-Regular flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none'>
            <div className='relative w-[100%] lg:w-[45%] lg-h-[45%] my-6 mx-6'>
                <div className='border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none'>
                    <div className='relative p-6 flex-auto'>
                        <div className='space-y-2'>
                            {imgSrc ? (
                                <img src={imgSrc} alt="webcam" />
                            ) : (
                                <Webcam height={600} width={600} ref={webcamRef} videoConstraints={videoConstraints}/>
                            )}
                        </div>


                        <div className='flex py-3 justify-center gap-5'>
                            <button
                                onClick={close}
                                className='text-[13px] py-1.5 px-[2rem] md:px-[3rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]'>
                                Cancel
                            </button>
                            {imgSrc && <button
                                onClick={() => setImgSrc("")}
                                className='text-[13px] py-1.5 px-[2rem] md:px-[3rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]'>
                                Retake
                            </button>}
                            {imgSrc
                                ? <button onClick={() =>{ 
                                    onImageConfirm(imgSrc);
                                    }} className='text-[13px] py-1.5 px-[2rem] md:px-[3rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]'>
                                    Confirm
                                </button>
                                : <button onClick={()=> {capture();
                                track()}} className='text-[13px] py-1.5 px-[2rem] md:px-[3rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]'>
                                    Capture
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CameraPopup