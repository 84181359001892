import React from "react";
import { MultiSelectView } from "./PartnerPreferenceUtils";
import { useTranslation } from "react-i18next";

const HabitsPreference = (props) => {

const { t:trans } = useTranslation();
  
  return (
    <div>
      {console.log(props.editfromadmin,'props.editfromadmin')}
      <MultiSelectView
        title={trans('my_profile.food_habits')}
        inputID={"foodhabits"}
        inputName={"foodhabits"}
        placeholder={trans('my_profile.any')}
        value={props.data.values.foodhabits}
        defalutValue={[props.data.values.foodhabits]}
        onChange={(e) => {
          props?.handleSelectInputChange(e)
          props.data.setFieldValue("foodhabits", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.foodhabits}
        options={props?.managePreferenceData?.food}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.smoking')}
        inputID={"smoking"}
        placeholder={trans('my_profile.any')}
        inputName={"smoking"}
        value={props.data.values.smoking}
        defalutValue={[props.data.values.smoking]}
        onChange={(e) => {
          props?.handleSelectInputChange(e)
          props.data.setFieldValue("smoking", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.smoking}
        options={props?.managePreferenceData?.smoking}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.drinking')}
        placeholder={trans('my_profile.any')}
        inputID={"drinking"}
        inputName={"drinking"}
        value={props.data.values.drinking}
        defalutValue={[props.data.values.drinking]}
        onChange={(e) => {
          props?.handleSelectInputChange(e)
          props.data.setFieldValue("drinking", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.drinking}
        options={props?.managePreferenceData?.drinking}
        isDisabled={props.editfromadmin == 'Y'}
      />
    </div>
  );
};

export default HabitsPreference;
