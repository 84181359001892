import { call, put, select, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./ActionTypes"
import { Initial_Matches, Matches_List, Matches_Profile, Matches_Score, Search_By_Id, Search_By_Name, Search_list, Selected_Matches, dynamicFilterMatches, sagaPost } from "../../Helpers/api_helpers";
import { getFilterMatchesFail, getFilterMatchesSuccess, getMatchesFail, getMatchesSuccess, getProfileMatchFail, getProfileMatchPrefFail, getProfileMatchPrefSuccess, getProfileMatchSuccess, getRecommendedFail, getRecommendedSuccess, getSelectedMatchesFail, getSelectedMatchesSuccess, searchMatchesByTextFail, searchMatchesByTextSuccess, searchMatchesFail, searchMatchesSuccess, setCurrentMatch, storeRequestTypeFail, storeRequestTypeSuccess, toggleProfileSelectedFail, toggleProfileSelectedSuccess, viewPhoneFail, viewPhoneSuccess, viewProfileByIdFail, viewProfileByIdSuccess } from "./Action";
import EndPoints from "../../Constants/EndPoints";
import Constants, { FilterOptions } from "../../Constants/constants";
import { post_method } from "../../Helpers/axios_helpers";
import config from "../../config";
import { setCurrentChat } from "../Chats/Action";
import { logActivity } from "../../Components/KalyanRewards/PointEarnedNotification";
import { addPoint } from "../KalyanRewards/Action";


function* getMatches({ payload }) {
    //console.log(user,"123");
    try { // 
        const response = yield call(Matches_List, payload);
        if (response.data.status === "Success") {
            yield put(getMatchesSuccess(response.data));
        } else {
            yield put(getMatchesFail(response));
        }
    } catch (error) {
        yield put(getMatchesFail(error));
    }
}

function* getRecommendedMatches({ payload, logout }) {
    try {
        const request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == "" ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "recomendationpageno": payload.page,
            "status": "new",
            webKey: localStorage.getItem(Constants.loginLocalStorageKeys.fcmToken),
            shortlisted: payload?.shortlisted,
            ignored: payload?.ignored,
            viewed: payload?.viewed,

        }
        const response = yield call(Initial_Matches, request);

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }

        if (response.data.status === "Success") {

            if (payload.openFirst && response.data?.data?.matcheslist?.length > 0) {
                console.log(" responsedata", response.data);
                yield put(setCurrentMatch({ match: response.data?.data?.matcheslist[0], matchdata: response.data?.data, category: Constants.matchCatogory.initial }))
            }
            if (response.data?.data) {
                const currentMatch = yield select((state) => state.KalyanLite?.currentMatch);                
                yield put(setCurrentMatch({ match:currentMatch.data, matchdata: response.data?.data, category:currentMatch?.category}))
            }
            if(response.data){
                response.data.isFrom = payload?.isFrom;
            }
            yield put(getRecommendedSuccess(response.data));
            console.log(response.data,'response.data');
            
        } else {
            yield put(getRecommendedFail(response));
        }
    } catch (error) {
        yield put(getRecommendedFail(error));
    }
}

function* getSelectedMatches({ payload, logout }) {
    try {
        const request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "selectedprofilespageno": payload.page,
            "status": "new",
            "limit": "10",
        }

        const response = yield call(Selected_Matches, request);

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }

        if (response.data.status === "Success") {
            if (payload.openFirst && response.data?.data?.Selectedprofiles?.length > 0) {
                console.log(" response.data", response.data);
                yield put(setCurrentMatch({ match: response.data?.data?.Selectedprofiles[0], category: Constants.matchCatogory.selectedMatch }))
            }
            yield put(getSelectedMatchesSuccess(response.data));
        } else {
            yield put(getSelectedMatchesFail(response));
        }
    } catch (error) {
        yield put(getSelectedMatchesFail(error));
    }
}

function* getMatchProfile({ payload, logout }) {
    try { // 
        const response = yield call(Matches_Profile, payload);

        if (response.data.statusCode === 401) {
            logout()
            return;
        }
        console.log("getMatchProfile", response);

        if (response.data?.data?.statusmessage == "same gender") {
            return;
        }

        if (response.data.status === "Success") {
            // response.data.data.profilePreference = response2?.data?.data;
            yield put(getProfileMatchSuccess(response.data));
        } else {
            yield put(getProfileMatchSuccess(response));
        }
        const response2 = yield call(Matches_Score, { ...payload, pageno: "2" });
        if (response2.data.status === "Success") {
            yield put(getProfileMatchSuccess({ isPagetwo: true, data: response2?.data?.data, userId: payload.viewprofileid }));
        }
    } catch (error) {
        yield put(getProfileMatchFail(error));
    }
}

function* getMatchProfilePref({ payload, logout }) {
    try { // 
        const response = yield call(Matches_Score, { ...payload });
        if (response.data.statusCode === 401) {
            logout()
            return;
        }
        if (response.data.status === "Success") {
            yield put(getProfileMatchPrefSuccess({ request: payload, data: response?.data?.data }));
        }
    } catch (error) {
        yield put(getProfileMatchPrefFail(error));
    }
}



// /api/selectedrejected

function* toggleProfileSelected({ payload }) {
    try { // 
        const data = {
            url: EndPoints.selectRejectUrl(),
            request: {
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "type": payload?.selectedStatus ? "Selected" : "Select",
                "toProfileId": payload.profileId
            }
        }
        const response = yield call(sagaPost, data);
        if (response.data.status === "Success") {
            if (payload?.selectedStatus) {
                console.log(response.data.data.status, 'jfdjfjdkfkdf');
                if (response.data.data.status === "success") {
                    put(addPoint({ activityId: 3 }))
                    yield put(toggleProfileSelectedSuccess(payload?.profileId, payload?.selectedStatus, response?.data?.data?.statusMessage));
                }
                else {
                    yield put(toggleProfileSelectedFail(payload?.profileId, payload?.selectedStatus, response?.data?.data?.statusMessage));
                }
            }
            else {
                yield put(toggleProfileSelectedSuccess(payload?.profileId, payload?.selectedStatus, response?.data?.data?.statusMessage));
            }
        }
        else {
            yield put(toggleProfileSelectedFail(payload?.profileId, payload?.selectedStatus, response?.data?.data?.statusMessage));
        }
    } catch (error) {
        yield put(toggleProfileSelectedFail(error));
    }
}

function* toggleProfileRejected({ payload }) {
    try { // 
        const data = {
            url: EndPoints.selectRejectUrl(),
            request: {
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "type": payload?.rejectedStatus ? "Rejected" : "Reject",
                "toProfileId": payload.profileId
            }
        }
        const response = yield call(sagaPost, data);

    } catch (error) {

    }
}


function* viewPhone({ payload }) {
    //console.log(user,"123");
    try { // 
        const data = {
            url: EndPoints.viewPhoneUrl(),
            request: {
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "phoneViewFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
                "toProfileId": payload.profileId
            }
        }
        const response = yield call(sagaPost, data);
        console.log("viewPhone", response);
        if (response.data.status === "Success") {
            yield put(viewPhoneSuccess(response.data?.data));
        } else {
            yield put(viewPhoneFail(response));
        }
    } catch (error) {
        yield put(viewPhoneFail(error));
    }
}

function* toggleProfileBlock({ payload }) {
    try { // 
        const data = {
            url: payload.isBlocked ? EndPoints.unblockprofile() : EndPoints.blockprofile(),
            request: {
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "toProfileId": payload.profileId
            }
        }
        const response = yield call(sagaPost, data);

    } catch (error) {

    }
}


function* viewProfileById({ payload, logout, onSuccess }) {
    //console.log(user,"123");
    try { // 

        const request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "pageno": "1",
            "toProfileId": payload.id,
            "loginFrom": payload.loginFrom,
            callFrom: "SearchById",
        }

        const response = yield call(Search_By_Id, request);

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }

        if (response.data?.data?.status === "SUCCESS") {
            yield put(viewProfileByIdSuccess({ data: response.data?.data, smProfileId: payload.id, isChat: payload.isChat }));
            if (payload.isChat) {
                yield put(setCurrentChat(response?.data?.data?.profiledetails?.profileid))
                onSuccess(response?.data?.data?.profiledetails?.profileid)
            }
        } else {
            yield put(viewProfileByIdFail(response?.data?.data?.statusmessage));
        }
    } catch (error) {
        yield put(viewProfileByIdFail(error));
    }
}

function* searchMatchesByText({ payload, logout, onSuccess }) {
    //console.log(user,"123");
    try { // 

        const request = {
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "pageno": payload.page,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "searchname": payload.text
        }


        const response = yield call(Search_By_Name, request);

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }

        if (response.data?.data?.status === "SUCCESS") {
            if (payload.openFirst && response.data?.data?.searchmatches?.length > 0 && !payload.isMobileView) {
                console.log(" response.data", response.data);
                yield put(setCurrentMatch({ match: response.data?.data?.searchmatches[0], category: Constants.matchCatogory.nameSearch }))
            }
            yield put(searchMatchesByTextSuccess({
                data: response.data?.data,
                smProfileId: payload.id,
                requestPayload: payload
            }));
            if (payload.isChat && !payload.isMobileView && payload.openFirst) {
                yield put(setCurrentChat(response?.data?.data?.searchmatches[0]?.profileid))
                onSuccess(response?.data?.data?.searchmatches[0]?.profileid)
            }
        } else {
            yield put(searchMatchesByTextFail(response?.data?.data?.statusmessage));
        }
    } catch (error) {
        yield put(searchMatchesByTextFail(error));
    }
}


function* getFilterMatches({ payload, logout }) {
    //console.log(user,"123");
    try { // 

        const currentFilter = FilterOptions.filter(data => data.key === payload.filterType)[0]

        const request = currentFilter?.getRequest({
            page: (currentFilter?.url === EndPoints.getviewedphoneprofileanddocumentv3() || currentFilter?.url === EndPoints.inboxRequestViewUrl() || currentFilter?.url === EndPoints.requestviewliteapp()) ? payload.page - 1 : payload.page,
            pageSize: Constants.matchRowCount,
            email: localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            userId: localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            shortlisted: payload.shortlisted,
            ignored: payload.ignored,
            viewed: payload.viewed,
        })

        const response = yield call(dynamicFilterMatches, { url: currentFilter?.url, request });

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }
        console.log("response", response);

        if (response.data?.status === "Success") {
            let responseData = response.data?.data[currentFilter.responseKey];



            if (currentFilter?.url === EndPoints.getviewedphoneprofileanddocumentv3() || currentFilter?.url === EndPoints.inboxRequestViewUrl() || currentFilter?.url === EndPoints.requestviewliteapp()) {
                responseData = responseData?.map(data => ({
                    ...data,
                    profileurl: data?.profileurl || data?.profilepic,
                    profilename: data?.profilename || data?.name,
                    profileage: data?.profileage || data?.age,
                    profileheight: data?.profileheight || data?.height,
                    profilecaste: data?.profilecaste,
                    profilequalification: data?.profilequalification,
                    profileoccupation: data?.profileoccupation,
                    profilecity: data?.profilecity || data?.city,
                    profilelastOnline: data?.lastOnline || data?.profilelastOnline,
                    profileid: data?.profileid || data?.profileId,
                    profileStatus: data?.profileStatus || data?.profileStatus,
                    profilefromPasswordProtected: data?.profilefromPasswordProtected || data?.fromPasswordProtected,
                    profileblurPhoto: data?.profileblurPhoto || data?.blurPhoto
                }))
            }

            yield put(getFilterMatchesSuccess({
                data: responseData,
                filterType: payload.filterType,
                bannerWeb: response?.data?.data?.bannerWebNew,
                contentCard: response?.data?.data?.content ? response?.data?.data?.content : response?.data?.data?.contentCard,
                showcastemigration: response?.data?.showcastemigration,
                castedata: response?.data?.castedata,
                showpopup:response?.data?.popup,
                subcastedata: response?.data?.subcastedata,
            }));
            if (payload.openFirst && responseData?.length > 0) {
                yield put(setCurrentMatch({ match: responseData[0], category: Constants.matchCatogory.filterMatches, matchType: currentFilter.key }))
            }
        } else {
            yield put(getFilterMatchesFail(response?.data));
        }
    } catch (error) {
        console.log("getFilterMatches", error);
        yield put(getFilterMatchesFail(error));
    }
}

function* searchMatches({ payload, logout }) {

    try {
        let request = {
            ...payload.searchTerms,
            limit: 10,
            pageno: payload.page,
            loginFrom: payload.loginFrom
        }

        const response = yield call(Search_list, request)

        if (response.data?.statusCode === 401) {
            logout();
            return;
        }

        if (response.data.status === "Success") {
            yield put(searchMatchesSuccess(response?.data));
            const data = response?.data?.data?.filtermatches;
            if (payload.openFirst && data?.length > 0) {
                yield put(setCurrentMatch({ match: data[0], category: Constants.matchCatogory.basicSearch }))
            }
        } else {
            yield put(searchMatchesFail(response));
        }
    } catch (error) {
        yield put(searchMatchesFail(error));
    }
}

// STORE REQUEST TYPE

function* storeRequestType({ payload }) {
    try {
        const data = {
            url: EndPoints.StoreRequestUrl(),
            request: {
                "sender_profileid": payload?.sender_profileid,
                "receiver_profileid": payload?.receiver_profileid,
                "requesttypeid": payload?.requesttypeid,
                "loginFrom": payload?.loginFrom,
                "requestsentvia": payload?.requestsentvia,
                "fromPage" : payload?.fromPage,
            }
        }
        const response = yield call(sagaPost, data);
        console.log(response.data, 'jfdjfjdkfkdf');
        if (response.data.status === "Success") {
            yield put(storeRequestTypeSuccess(payload));
        }
        else {
            yield put(storeRequestTypeFail(response));
        }
    } catch (error) {
        yield put(storeRequestTypeFail(error));
    }
}

// NOTIFICATION TRACKING
function* notificationClickedTracking({ payload }) {
    try {
        const data = {
            url: EndPoints.notificationClickedTrackingUrl(),
            request: {
                "recieverprofileid": payload?.recieverprofileid,
                "request_typeid": payload?.request_typeid
            }
        }
        const response = yield call(sagaPost, data);

    } catch (error) {

    }
}


function* kalyanLiteSaga() {
    yield takeEvery(actionTypes.GET_MATCHES, getMatches);
    yield takeEvery(actionTypes.GET_MATCH_PROFILE, getMatchProfile);
    yield takeEvery(actionTypes.TOGGLE_PROFILE_SELECTED, toggleProfileSelected)
    yield takeEvery(actionTypes.TOGGLE_PROFILE_REJECTED, toggleProfileRejected)
    yield takeEvery(actionTypes.TOGGLE_PROFILE_BLOCK, toggleProfileBlock)
    yield takeEvery(actionTypes.VIEW_PHONE, viewPhone)
    yield takeEvery(actionTypes.GET_RECOMMENDED_MATCHES, getRecommendedMatches)
    yield takeEvery(actionTypes.VIEW_PROFILE_BY_ID, viewProfileById)
    yield takeEvery(actionTypes.GET_SELECTED_MATCHES, getSelectedMatches)
    yield takeEvery(actionTypes.SEARCH_MATCHES_BY_TEXT, searchMatchesByText)
    yield takeEvery(actionTypes.GET_FILTER_MATCHES, getFilterMatches)
    yield takeEvery(actionTypes.SEARCH_MATCHES, searchMatches)

    yield takeEvery(actionTypes.GET_MATCH_PROFILE_PREF, getMatchProfilePref);
    yield takeEvery(actionTypes.STORE_REQUEST_TYPE, storeRequestType)
    yield takeEvery(actionTypes.NOTIFICATION_CLICKED_TRACKING, notificationClickedTracking)

    // yield takeEvery(actionTypes.GET_MATCH_PROFILE, getMatchProfile2);
}

export default kalyanLiteSaga;