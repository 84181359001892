import React, { useState } from "react";
import { useEffect } from "react";
import PlusIcon from "../../Images/Path 3979.svg"
import educationCheckImage from '../../Images/Group 890.svg'
import { SelectDropdownUtil, SelectDropdownUtilHeading } from "../../Components/Edit Profile/CustomSelect";
import RightArrow from '../../Images/right-arrow (1).svg'
import { Select } from "antd";
import { IoMdArrowDropdown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import {CheckOutlined } from '@ant-design/icons'
const PreferenceTextView = (props) => {

  useEffect(() => {
    //console.log(props.value,"string");
   }, [props.value]);

  //  let values=props?.value
  //  const result = values.replace(/,/g, ', ' );

   const text = props?.value

   const addSpaceAfterComma = (text) => {
     if (!text) {
       return ''; 
     }
     const result = text.replace(/,/g, ', ');
     return result;
   };
 
   const result = addSpaceAfterComma(text);
 
  return (
    <div className=" grid grid-cols-9 mt-[2rem]  ">
      <div className="col-span-9 md:col-span-4 pl-0 md:pl-[1rem]">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
        </p> 
      </div>
      <div className="md:col-span-5 col-span-5 md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] pl-0">
        <p className=" md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words">
          

           {/* {props.value.replace(/,/g, ', ' )} */}
           {result}

          

        </p>
      </div>
    </div>
  );
};

const MultiSelectView = (props) => {
console.log(props.value,"props.valueprops.value");

const { t:trans } = useTranslation();
  
  useEffect(() =>{

  } ,[props.placeholder])
  return (
    <div className=" md:grid md:grid-cols-9 pt-[0.1rem] space-y-2 md:space-y-0">
      <div
       className={"col-span-9 " }>
        <p className="text-#000000] text-[15px] font-semibold  pb-4">{props.title}<span className="text-[#D10A11]">*</span></p>
      </div>
      <div 
      className={`${ "col-span-9 " }` }

      // className="md:col-span-4 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]"
      >
        {/* <p className="w-full"> */}
          <div className="flex ">
            <label className=" font-bold text-[#575556] w-full ">
              {/* <select className="one"
            
            /> */}
              <SelectDropdownUtil
              disabled={props?.partnerpreference}
                className="new"
                menu="aferRegister"
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                placeholder={props.placeholder != null ? props.placeholder:`${trans('common_placeholder.any')} ${props.title}` }
                onChange={props.onChange}
                option={props?.options}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                // mode={"multiple"}
              />
            {/* <span className="relative float-right top-[-27px] right-[24px] "><img className="w-[13px]" src={RightArrow} /></span> */}

              {/* <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
            
            >{props?.options?.map((e, i) => (
                <option key={e.id} value={props.inputID =="parentsIsdCode"? e.isdcode:e.id} >{props.inputID =="parentsIsdCode"?e.isdcode+" - "+e.country:e.name}</option>
              ))}
            </select> */}
            </label>
          </div>
        {/* </p> */}
      </div>
    </div>
  );
};

const MultiSelectViewHeading = (props) => {

  const { t: trans } = useTranslation();

  
  useEffect(() =>{

  } ,[props.placeholder])
  return (
    <div className=" md:grid md:grid-cols-9 space-y-2 md:space-y-0">
       
      <div
       className={`${ props?.page ==="search"? "col-span-9 flex items-center pr-[0.1rem] "
      : props.page=='physical' ? "md:col-span-9 lg:col-span-9 col-span-9 lg:pl-[1rem] " :"md:col-span-9 lg:col-span-9 col-span-9 " }` } >
        <p className="text-#000000] text-[15px] font-semibold  pb-3">{props.title}<span className="text-[#D10A11]">*</span></p>
      </div>
      <div 
      className={"col-span-9 "}
      >
          <div className="flex ">
            <label className=" font-bold text-[#000000] w-full ">
              <SelectDropdownUtilHeading
              
                menu="aferRegister" 
                name={props.inputName}
                value={props?.title == trans('view_profile.raasi') ?props?.options?.length==1?props.options[0].name:props.value:props.value}
                id={props.inputID}
                placeholder={props.placeholder != null ? props.placeholder:`${trans('common_placeholder.any')} ${props.title}` }
                onChange={props.onChange}
                option={props?.options}
                disabled={props?.partnerpreference}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                // mode={"multiple"}
              />
            {/* <span className="relative float-right top-[-27px] right-[24px] "><img className="w-[13px]" src={RightArrow} /></span> */}

            </label>
          </div>
        {/* </p> */}
      </div>
    </div>
  );
};

const EducationCheckView = (props) => {
  console.log(props.value,"props.valueprops.value");
  const [isShowList, SetIsShowList] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState("");

  useEffect (() => {
    setSelectedHeading(props.value.filter((obj) => obj.selected === "Y").length)
  },[])
  return (
    <>
      <div className="flex cursor-pointer space-x-1 lg:space-x-3 items-center ">
        {isShowList?
        <img
          className=" w-[1rem] "
          src={educationCheckImage}
          alt=""
          onClick={() => SetIsShowList(!isShowList)}
        />
        :
        <img
          className=" w-[1rem] "
          src={PlusIcon}
          alt=""
          onClick={() => SetIsShowList(!isShowList)}
        />
}
        <label> 
          {props.title.selected === "P" ?
          <div className="partialCheck">
          <input
          type="checkbox"
          className="accent-red-500 border border-[#F18181] "
          name={props.title.id}
          value={props.title.id}
          checked={true}
          onChange={(e) => {
            console.log(props?.data?.values?.education,"props?.data?.values?.education");
            if(props?.fromPath == "basic_advance_search"){
              props?.setFilterOnchange(true)
              props?.setIsPPEdited("yes")
            }
            props.callBackSelectAll(e.target.value)
            // if(props?.data?.values?.education?.length == 1){
            //   props?.setEducationError(true)
            // }
            // else{
            //   props?.setEducationError(false)
            // }
          }}
        />
        </div>
        :
         props.value.filter((obj) => obj.selected === "Y").length>0 && props.value.filter((obj) => obj.selected === "Y").length!=props?.value?.length?
         <div className="partialCheck">
          <input
          type="checkbox"
          className="accent-red-500 border border-[#F18181] "
          name={props.title.id}
          value={props.title.id}
          checked={true}
          onChange={(e) => {
            console.log(props?.data?.values?.education,"props?.data?.values?.education");
            if(props?.fromPath == "basic_advance_search"){
              props?.setFilterOnchange(true)
              props?.setIsPPEdited("yes")
            }            
            props.callBackSelectAll(e.target.value)
            // if(props?.data?.values?.education?.length == 1){
            //   props?.setEducationError(true)
            // }
            // else{
            //   props?.setEducationError(false)
            // }          
          }}
        />
        </div>
        
          :
          <input
            type="checkbox"
            className="accent-red-500 border border-[#F18181] "
            checked={props.title.selected==="Y"}
            name={props.title.id}
            value={props.title.id}
            onChange={(e) => {
              console.log(props?.data?.values?.education,"props?.data?.values?.education");
              if(props?.fromPath == "basic_advance_search"){
                props?.setFilterOnchange(true)
                props?.setIsPPEdited("yes")
              }
              props.callBackSelectAll(e.target.value)
              // if(props?.data?.values?.education?.length == 1){
              //   props?.setEducationError(true)
              // }
              // else{
              //   props?.setEducationError(false)
              // }
            }}
          />
       } 
        </label> 
        <p className=" text-[#8F8F8F] text-[13px] md:text-[11px] lg:text-[14px] pl-2 font-semibold">
          {props.title.name}
        </p>
      </div>
      {isShowList ? (
        <>
          {props.value.map((e, i) => (
            <div key={i} className="flex space-x-1 lg:space-x-3 ml-8 items-center">
              <label>
                <input
                  type="checkbox"
                  className="accent-red-500 border border-[#F18181] "
                  checked={e.selected==="Y"}
                  name={e.id}
                  value={e.id}
                  onChange={(e) => {
                    console.log(props?.data?.values?.education,"props?.data?.values?.education");
                    if(props?.fromPath == "basic_advance_search"){
                      props?.setFilterOnchange(true)
                      props?.setIsPPEdited("yes")
                    }
                    props.callBackSingleSelect(e.target.value)
                    // if(props?.data?.values?.education?.length == 1){
                    //   props?.setEducationError(true)
                    // }
                    // else{
                    //   props?.setEducationError(false)
                    // }                  
                  }}
                />
              </label>
              <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                {e.name}
              </p>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const BasicCheckBox = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label>
      <input
        type="checkbox"
        checked={props.checked}
        // onChange={()=>{props.}}
        className="accent-red-500 border border-[#F18181] "
        // checked={}
        //   onChange={()=>}
      />
    </label>
  );
};

 const SelectFamilyInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  type,
  placeholder,
  disabled
}) => {

  const { t:trans } = useTranslation();
 
console.log("familyStatus",name);

console.log(placeholder,"defaultValue");
  return (
    <>
    {name == "relationshipId"?
        <div className="flex ">
          <label className={`${error ? '' : 'font-bold'} md:text-[12px]  lg:text-[14px] w-full`}>
            <Select
                showSearch
                name={name}
                value={value}
                options={options}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={"Relation"}
                onChange={onChange}
                showArrow={menu=="aferRegister"}  
                placeholder={"Relation"}
                className={` ${ type=="relation" ?"newones  xl:w-[97%] 2xl:w-[108%] md:w-[110%] w-[100%] !appearance-none familyone disabled":"new w-[100%] !appearance-none familyone disabled"} `}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                disabled={disabled}
              />
            {error && (
              <p className="text-[red] text-[12px] mb-2 pt-[0.3rem]">{error}</p>
            )}
          </label> 
        </div>
    :
    <div className={`   md:mt-[2rem] ${menu=="packastro"?"":menu== "aferRegisterPlace"?"grid grid-cols-9":"grid grid-cols-9"} mt-4    `}>
      <div className={`col-span-9  ${menu== "aferRegisterPlace"?"md:col-span-3":"md:col-span-3"}  flex  items-center   `}>
        <p className={` flex  ${menu=="packastro"?"text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1":" md:text-[12px] text-[#1F1F1F] lg:text-[17px] font-semibold  "} `}>
          {title}
          </p>
      </div>
      <div className="md:col-span-1 md:flex lg:flex items-center justify-center hidden">
        <p className="font-bold">:</p>
      </div>
      <div className={`col-span-9  md:col-span-4 ${menu=="packastro"?"md:pr-[1rem] lg:pr-[1rem]":"md:pr-[1rem] lg:pr-[2rem] xl:pr-[4rem]"} md:pl-[1rem]`}>
        <p className="pt-2 md:pt-0 lg:pt-0">
          <div className="flex ">
            <label className={` font-bold ${menu=="packastro"?"md:text-[12px]  lg:text-[12px]":"md:text-[12px]  lg:text-[14px]"} w-full `}>
              <Select
                showSearch
                blurInputOnSelect
                name={name}
                value={value }
                options={options}
                defaultValue={defaultValue}
                onBlur={onBlur}
                onChange={(e) => {onChange(e)}}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                className={menu=="aferRegister"  || menu== "aferRegisterPlace"?"new  w-[100%] md:w-[142%] lg:w-[107%] !appearance-none familyone disabled":""}
                showArrow={menu=="aferRegister" || menu =="aferRegisterPlace"} 
                placeholder={name=="familyStatus"? trans('family_details.select_family_status') :name=="familyType" ? "Select Family Type":
                name=="familyValues"? trans('family_details.select_family_value') :"Select" }
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                disabled={disabled}
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
}
    </>
  );
};

const FamilyBasicEditTextView = (props) => {
console.log(props.fatherNativeErrorMessage,'props.fatherNativeErrorMessage');
  useEffect(() =>{
    //console.log(props?.value,"othercaste");
  },[props?.value])
  return (
    <>
      <div className="flex-col">
        <div className={props?.inputID === "parentNumber"?"RegPhone  md:pl-2 lg:pl-0 2xl:pl-2 pl-2 lg:pr-[1rem]": ""}>
          <input
            type={props.inputType} 
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={ props?.menu=="addfamily" ?"border border-[#8F8F8F] text-[12px] text-[#575556] rounded-[3px] h-[46px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? ''):
             props?.menu == "Numbercode" ? " border-none bg-[#E6E6E6] text-[14px] text-[#575556] rounded-[8px] h-[46px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? '') : " border-none bg-[#E6E6E6] text-[14px] text-[#575556] rounded-[8px] h-[46px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? '')}
            placeholder={props?.placeholder}
            autoComplete="off"
            onChange={props.onChange}   
            disabled={props?.disabled} 
            onKeyDown={props?.onKeyDown}  
            suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
      
          />
          
          
          
        </div>
        {(props.inputName == 'mothersnativeplace' || props.inputName == 'fathersnativeplace') ? 
              ((props.fatherNativeErrorMessage !== '' && props.fatherNativeErrorMessage !== undefined ) ? 
                <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words">Please enter a valid location</p>
                : (props.motherNativeErrorMessage !== ''&& props.motherNativeErrorMessage !==  undefined) ? 
                <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words">Please enter a valid location</p> :
                (props.touched && props.error) && <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words">{props.error}</p>
              )
            :
          props.error && (
            props.inputName == "parentNumber" ?
            <>
              <div className="lg:block md:block hidden">
                <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words"> Please enter valid <br /> Mobile Number</p>
              </div>
              <div className="lg:hidden md:hidden block">
                <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words">{props.error}</p>
              </div>
            </>
            :<p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]  max-w-[200px] break-words">{props.error}</p>
          )}
       
      </div>
      {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
            <p className="text-[red] text-[12px] absolute mb-2 pt-[0.3rem]">{props.phoneNumberError}</p>
            )
          )}
      </>
  );
};

const SelectRelationInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
}) => {

  const { t:trans } = useTranslation();


  return (
   
          <div className="flex ">
            <label className={`font-bold ${menu=="packastro"?"md:text-[12px]  lg:text-[12px]":"md:text-[12px]  lg:text-[14px]"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={name=="familyStatus"?trans('family_details.select_family_status'):name=="familyType" ? "Select Family Type":
                  name=="familyValues"?trans('family_details.select_family_value'):"Select"
                }
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                className={"new disabled"}
                showArrow={menu=="aferRegister"} 

                // styles={customStyles}
                // menuIsOpen={true}
                // components={{
                //   IndicatorSeparator:()=>null,
                //   DropdownIndicator:()=><IoMdArrowDropdown className="mr-2" size={20} color="#575556"/>
                // }}
               
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        
  );
};
const FamilySelectCurrencyInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  menu
}) => {
  return (

  
     
      <div>
        <p>
          <div className="flex ">
            <label className={`font-bold ${menu=="addfamily" ?"md:text-[12px] lg:text-[12px] w-[5rem]":" md:text-[12px] lg:text-[14px] w-[6rem]"}   `}>
              <Select
                isSearchable={true}
                placeholder={title=="currency"?defaultValue:"Select"}
                name={name}
                value={value===undefined?"null":value}
                onChange={onChange}
                options={options}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                className="new"
                components={{
                  IndicatorSeparator:()=>null,
                  DropdownIndicator:()=><IoMdArrowDropdown className="mr-2" size={20} color="#575556"/>
                }}
                styles={{
                  control:(base)=>{
                    return {
                      ...base,
                      border:"1px solid #8F8F8F",
                      outline:"none",
                      color:"#575556"
                    }
                  },
                  option:(base,optionState)=>{
                    return {
                      ...base,
                      color:optionState.isSelected ?"white":"#575556"
                    }
                  },
                  singleValue:(base)=>{
                    return {
                      ...base,
                      color:"#575556"
                    }
                  },
                }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>

  );
};

const SelectFamilyNumberInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  disabled,
  placeholder,
  className,
}) => {


  return (
    <div className={`${menu=="famiylcode"?" md:!w-[5rem]  lg:!w-[5rem] xl:!w-[7rem] !w-[7rem]": name=="parentsCurrency"?"md:!w-[5rem] lg:!w-[7rem] !w-[7rem]":"grid  grid-cols-5"} `}>
    
    
      <div className={` ${menu=="famiylcode"?"":name="brothersIsdCode" ? "col-span-9  md:col-span-4" : "col-span-4  md:col-span-4"}`}>
      
            <label className={`font-bold ${menu=="packastro"?"md:text-[12px]  lg:text-[12px]":"md:text-[12px]  lg:text-[14px]"} w-full `}>
              <Select
                showSearch
                isSearchable={true}
                name={name}
                value={value}
                options={options}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                onChange={(e)=>{onChange(e);}}
                className={`${disabled ? "cursor-not-allowed familynew  disabled" : menu== 'Numbercode' ? "add  disabled" : "cursor-pointer familynew  disabled"} ${className}`}
                showArrow={menu=="famiylcode" || menu == "Numbercode"} 
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                disabled={disabled}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
       
    </div>
  );
};
 const SelectFamilyViewWithHeading = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  inputName,
  disabled,
}) => {

  const { t:trans } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <>
    <div className={`md:mt-[2rem] grid lg:grid-cols-9 ${menu == "Occupation" ? "grid-cols-10 md:grid-cols-9 flex items-center": "grid-cols-9 md:grid-cols-9"} mt-4 `}>
      <div className={`${menu == "Occupation" ? "col-span-9  md:col-span-3  lg:col-span-3" : name == 'asset' ? "col-span-9  md:col-span-3 flex items-center  lg:col-span-2" : "col-span-9  md:col-span-3 flex items-center  lg:col-span-3"}`}>
        <p className={` flex  md:text-[12px] text-[#1F1F1F]  font-semibold  ${inputName == "assetInfo" ? "text-[#D63838] md:text-[18px] lg:text-[20px]" : "lg:text-[17px]"}`}>
          {title}
          </p>
      </div>
      <div className="hidden md:flex lg:flex md:col-span-1 lg:col-span-1 items-center justify-center">
        <p className="font-bold">:</p>
      </div>
      <div className={`${menu == "Occupation" ? "col-span-10 md:col-span-5 lg:col-span-4 md:pl-[0.5rem] mt-2 md:mt-0" :"col-span-10  md:col-span-4  lg:col-span-4 md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem]"}    `}>
        <p className="">
          <div className="flex">
            <label className={` font-bold md:text-[12px] lg:text-[14px] w-full `}>
              {console.log(options,'options')}
              <Select
                showSearch
                name={name}
                value={value}
                disabled={disabled}
                options={ menu=="aferRegister" ?
                  options?.map(option => ({
                    value: option.value,
                    label: (
                      <div className="flex justify-between items-center">
                        {option.label} {/* Render the option label */}
                        {/* Conditionally show grey tick */}
                        {!value?.includes(option.value) && (
                          <CheckOutlined style={{ color: 'grey' }} />
                        )}
                      </div>
                    ),
                  })) : options}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                onChange={(e) =>{onChange(e)}}
                showArrow={true} 
                mode={inputName == "assetInfo" ? "multiple" : ""}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
                className={menu=="aferRegister" ?"new w-full !appearance-none familyone disabled":"add w-full !appearance-none familyone disabled"}
                placeholder={name=="familyStatus"?"Select Family Status":name=="familyType" ? "Select Family Type":name=="familyValues"?"Select Family Value":name == "assetInfo" ? "Select Multiple" : "Select"}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                open={dropdownOpen} // Control dropdown visibility
                onDropdownVisibleChange={(open) => setDropdownOpen(open)} // Handle dropdown close only on outside click
              />
              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>

    </>
  );
};

const FamilyEditTextView = (props) => {

  const { t:trans } = useTranslation();
  
  useEffect(() =>{
    //console.log(props?.value,"othercaste");
  },[props?.value])

  return (
    <div className= {`grid grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-[2rem] items-center ${props?.title ==  trans('siblings.married') ? "gap-0":"" }`}>
      <div className={`col-span-9 ${props?.title== trans('siblings.married')?"md:col-span-3":"md:col-span-3"} lg:col-span-3`}>
        <p className={` ${props?.title== trans('siblings.married') ? "text-[#1F1F1F]  md:text-[14px] font-bold lg:text-[17px]":"text-[#1F1F1F]  md:text-[12px] lg:text-[17px]"}  flex  font-semibold  `}>
          {props.title}
        </p>
      </div>
      <div className=" md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
        <p className="font-bold">:</p>
      </div>
      <div className={`col-span-10 md:col-span-5 lg:col-span-4 md:pl-[0.5rem] mt-0 md:mt-0`}>
        <p  className={props?.inputID === "parentNumber"?"RegPhone": ""}>
          <input
            type={props.inputType} 
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={ props?.menu=="addfamily" ?"bg-[#E6E6E6] text-[12px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? ''):
              props?.menu == "Occupation" ? " bg-[#E6E6E6] text-[14px] text-[#575556] rounded-[8px] h-[40px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? ''):" bg-[#E6E6E6] text-[14px] text-[#575556] rounded-[3px] h-[46px]  block w-full p-4 lg:p-[1.5rem]  font-bold "+(props.className ?? '')}
            placeholder={props?.placeholder}
            autoComplete="off"
            onChange={props.onChange}   
            disabled={props?.disabled} 
            onKeyDown={props?.onKeyDown}        
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
          {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
            <p className="text-[red] text-[12px]">{props.phoneNumberError}</p>
            )
          )}
        </p>
      </div>
    </div>
  );
};

export {
  BasicCheckBox,
  EducationCheckView,
  PreferenceTextView,
  MultiSelectView,
  MultiSelectViewHeading,
  SelectFamilyInput,
  FamilyBasicEditTextView,
  FamilySelectCurrencyInput,
  SelectRelationInput,
  SelectFamilyNumberInput,
  SelectFamilyViewWithHeading,
  FamilyEditTextView
};
