import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BasicEditTextView, BasicTextView, EditButton, SaveCancelButton, SelectCurrencyInput, SelectInput, SelectNativeInput, SelectViewWithHeading } from '../EditProfileUtills';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAnalytics } from '../../../Hooks/usePageChange';
import { EventKeys, EventName, blockInvalidCharForNumber } from '../../../Constants/constants';
import { validateNumber } from '../../../Utils';
import { LoginContext } from '../../../Contexts/LoginContext';
import { getDeviceInfo } from '../../Kalyanlite/utils';
import { GET, POST } from '../../../Services/api_services';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { MultiSelectView } from '../PartnerPreference/PartnerPreferenceUtils';
import axios from 'axios';
import ActionRestrict from '../../Kalyanlite/Common/ActionRestrict';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBasicData } from '../../../Store/Login/Action';
import constant, {
  nameNumericList,
} from "../../../Constants/constants";
import OverflowToolTip from '../../utils/OverflowTooltip';
import { NavBarContext } from '../../../Contexts/NavBarContext';
let errorNameMessage = ""

export default function AdditionalInformation(props) {

    const { onClickTrack } = useAnalytics();
    const { t: trans } = useTranslation();
    
    const dispatch = useDispatch();
    const NumbersDropdown = [
      { id: '0', name: '0' },
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
      { id: '6', name: '6' },
      { id: '7', name: '7' },
      { id: '8', name: '8' },
      { id: '9', name: '9' },
  ]

  

    const [isShowEdit, setEditView] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [assetDropdown, setAssetDropdown] = useState(false);
    const [occupationDropdown, setOccupationDropdown] = useState(false);
    const [nativeDropdown, setNativeDropdown] = useState(false);
    const [isdCodeDropdown, setIsdCodeDropdown] = useState([]);
    const [isSiblingDisabled, setIsSiblingDisabled] = useState(false)
    const [fatherNameErrorMessage, setFatherNameErrorMessage] = useState("")
    const [motherNameErrorMessage, setMotherNameErrorMessage] = useState("")
    const [fatherNativeErrorMessage, setFatherNativeErrorMessage] = useState("")
    const [motherNativeErrorMessage, setMotherNativeErrorMessage] = useState("")
    const [sistersMarriedOptions, setSistersMarriedOptions] = useState(NumbersDropdown);
    const [brothersMarriedOptions, setBrothersMarriedOptions] = useState(NumbersDropdown);

    const [countryDropDown, setcountryDropDown] = useState([]);
    const [stateDropDown, setstateDropDown] = useState([]);
    const [cityDropDown, setcityDropDown] = useState([]);

    const { loginDetail, logout } = useContext(LoginContext);
    const relationOfParents = props?.data?.relationshipName
    const { microtrackidData } = useContext(NavBarContext);

console.log(cityDropDown,'countryDropDownad');

    console.log(props?.dropDown,'props?.dropDown');
    useEffect(() => {
      setAssetDropdown(props?.dropDown?.assetData ?? []);
      setOccupationDropdown(props?.dropDown?.occupationData ?? []);
      setIsdCodeDropdown(props?.dropDown?.isdcode ?? []);
      setNativeDropdown(props?.dropDown?.LocationData ?? []);
    }, [props?.dropDown])

    const showMother = props?.data?.relationshipId == "5";
    const showFather = props?.data?.relationshipId == "6";
    const Male = props.data && props.data.gender == "M"
    const Female = props.data && props.data.gender == "F"

    const ProfileCountry = props?.data?.country[0]?.name
    const ProfileCountryId = props?.data?.country[0]?.id
    const ProfileState = props?.data?.state[0]?.id
    const ProfileCity = props?.data?.city[0]?.id

    console.log(ProfileCountry,'ProfileCountryProfileCountry');
    

    const formik = useFormik({
        initialValues: {
          
          fathersName: props?.data?.fathersName ? props?.data?.fathersName : "",
          fathersOccupation: props?.data?.fathersOccupation ? props?.data?.fathersOccupation[0].id : "",
          fathersnativelocationtype: props?.data?.fathersnativelocationtype ? nativeDropdown?.find((e) => e.locationName == props?.data?.fathersnativelocationtype)?.locationId : '',
          fatherNative: props?.data?.fathersnativeplace ? props?.data?.fathersnativeplace : '',
          fisdCode: relationOfParents === "Father" ? props?.data?.parentsIsdCode : props?.data?.fathersIsdcode ? props?.data?.fathersIsdcode : "+91",
          fMobileNumber: relationOfParents === "Father" ? props?.data?.parentsMobileNumber : props?.data?.fathersMobilenumber ? props?.data?.fathersMobilenumber : "",
          isFather: relationOfParents ? relationOfParents === "Father" ? true : false : false,

          mothersName: props?.data?.mothersName ? props?.data?.mothersName : "",
          mothersOccupation: props?.data?.mothersOccupation ? props?.data?.mothersOccupation[0].id : "",
          mothersnativelocationtype: props?.data?.mothersnativelocationtype ? nativeDropdown?.find((e) => e.locationName == props?.data?.mothersnativelocationtype)?.locationId: '',
          motherNative: props?.data?.mothersnativeplace ? props?.data?.mothersnativeplace : '',
          misdCode: relationOfParents === "Mother" ? props?.data?.parentsIsdCode : props?.data?.mothersIsdcode ? props?.data?.mothersIsdcode : "+91",
          motherMobileNumber:relationOfParents === "Mother" ? props?.data?.parentsMobileNumber : props?.data?.mothersMobilenumber ? props?.data?.mothersMobilenumber : "",
          isMother: relationOfParents ? relationOfParents === "Mother" ? true : false : false,

          haveSiblings: props?.data?.ishavenosiblings == "Y" ? true : false,
          
          brotherCount:props?.data?.brothers !== undefined ? props?.data?.brothers : "",
          marriedBrotherCount:props?.data?.marriedBrothers !== undefined ? props?.data?.marriedBrothers : "",
          broIsdCode:props?.data?.brothersIsdcode ? props?.data?.brothersIsdcode : "+91",
          broMobileNumber:props?.data?.brothersMobilenumber ? props?.data?.brothersMobilenumber : "",
          
          sisterCount:props?.data?.sisters !== undefined ? props?.data?.sisters : "",
          marriedSisterCount:props?.data?.marriedSisters !== undefined ? props?.data?.marriedSisters : "",
          sisIsdCode:props?.data?.sistersIsdcode ? props?.data?.sistersIsdcode : "+91",
          sisMobileNumber:props?.data?.sistersMobilenumber ? props?.data?.sistersMobilenumber : "",

          assetInfo: props?.data?.asset?.length > 0 
          ? props?.data?.asset.map(item => item.id) 
          : [],
          groomLivesInIndia: props?.data?.livesIn == "India" ? props?.data?.livesIn : "",
          groomLivesInAbroad:props?.data?.livesIn == "Abroad"? props?.data?.livesIn : "",
          isWorkLocationSame: props?.data?.isWorkLocationSame ? props?.data?.isWorkLocationSame :"",
          workCountry: props?.data?.workCountry?.length > 0 ? props?.data?.workCountry[0].id : null,
          workState: props?.data?.workState?.length > 0 ? props?.data?.workState[0]?.id : null,
          workCity: props?.data?.workCity?.length > 0 ? props?.data?.workCity[0]?.id : null,
          
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: Yup.object().shape({
          fathersnativelocationtype: Yup.string().nullable(),
          fatherNative: Yup.string()
            .nullable()
            .test(
              "valid",
              "Please enter a father's native or select a location type",
              function (value) {
                const { fathersnativelocationtype, fatherNative } = this.parent;
                if (fathersnativelocationtype && !value) {
                  return this.createError({
                    message: trans('my_profile.please_enter_fathers_native'),
                    path: "fatherNative",
                  });
                }

                if (!fathersnativelocationtype && value) {
                  return this.createError({
                    message: trans('my_profile.please_select_villagetowndistr'),
                    path: "fatherNative",
                  });
                }

                return true;
              }
            ),
            mothersnativelocationtype: Yup.string().nullable(),
            motherNative: Yup.string()
            .nullable()
            .test(
              "valid",
              "Please enter a mother's native or select a location type",
              function (value) {
                const { mothersnativelocationtype, motherNative } = this.parent;

                if (mothersnativelocationtype && !value) {
                  return this.createError({
                    message: trans('my_profile.please_enter_mothers_native'),
                    path: "motherNative",
                  });
                }

                if (!mothersnativelocationtype && value) {
                  return this.createError({
                    message: trans('my_profile.please_select_villagetowndistr'),
                    path: "motherNative",
                  });
                }

                return true;
              }
            ),
          fMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { fisdCode } = this.parent;
            if (fisdCode == "499" || fisdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "fMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "fMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { motherMobileNumber,broMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == motherMobileNumber || value == broMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "fMobileNumber",
              });
            }
            return true;
          }
        ).when("isFather", (isFather,schema) => {

          if (isFather && isFather[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        motherMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { misdCode } = this.parent;
            if (misdCode == "499" || misdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "motherMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "motherMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,broMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == broMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "motherMobileNumber",
              });
            }
            return true;
          }
        ).when("isMother", (isMother,schema) => {

          if (isMother && isMother[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        broMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { broIsdCode } = this.parent;
            if (broIsdCode == "499" || broIsdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "broMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "broMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,motherMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == motherMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "broMobileNumber",
              });
            }
            return true;
          }
        ),
        sisMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { sisIsdCode } = this.parent;
            if (sisIsdCode == "499" || sisIsdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "sisMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "sisMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,broMobileNumber,motherMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == broMobileNumber || value == motherMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "sisMobileNumber",
              });
            }
            return true;
          }
        ),
      
        }),
        

          onSubmit: (values) => {
          console.log(values,'additionalsubmitvalues');
          if(fatherNameErrorMessage == '' && motherNameErrorMessage == '' && fatherNativeErrorMessage == '' && motherNativeErrorMessage == ''){
            if(values.motherMobileNumber !== '' || values.fMobileNumber !== ''){
              if(values.isFather == true || values.isMother == true){
                setSubmitStatus(true)
                apiCallAdditionalInfosave(values)
              }
            }else{
              setSubmitStatus(true)
              apiCallAdditionalInfosave(values)
            }
          }
        },
      });

      const [hasSelectInputChanged, setHasSelectInputChanged] = React.useState(false);

      const handleSelectInputChange = (fieldName, value) => {
        // Update the field value in Formik
        formik.setFieldValue(fieldName, value);
      
        // Mark that a SelectInput field has been changed
        setHasSelectInputChanged(true);
      };

      console.log(hasSelectInputChanged,'hasSelectInputChanged');
      
      const apiCallAdditionalInfosave = async (value) => {
        let request = {
          email: loginDetail()[0],
          userId: loginDetail()[1],
          "fathersName":value?.fathersName,
          "mothersName":value?.mothersName,
          "mothersOccupation":value?.mothersOccupation,
          "fathersOccupation":value?.fathersOccupation,
          "sisters":value?.sisterCount != "" ? value?.sisterCount : null,
          "brothers":value?.brotherCount != "" ? value?.brotherCount : null,
          "marriedSisters":value?.marriedSisterCount != "" ? value?.marriedSisterCount : null,
          "marriedBrothers":value?.marriedBrotherCount != "" ? value?.marriedBrotherCount : null,
          "fathersIsdcode":value?.fisdCode,
          "fathersMobilenumber":value?.fMobileNumber,
          "mothersIsdcode":value?.misdCode,
          "mothersMobilenumber":value?.motherMobileNumber,
          "brothersMobilenumber":value?.broMobileNumber != "" ? value?.broMobileNumber : null,
          "sistersMobilenumber":value?.sisMobileNumber != "" ? value?.sisMobileNumber : null,
          "sistersIsdcode":value?.sisIsdCode != "" ? value?.sisIsdCode : null,
          "brothersIsdcode":value?.broIsdCode != "" ? value?.broIsdCode : null,
          "editedFrom":"EditMyprofile",
          "loginFrom":getDeviceInfo(),
          "isParentsMobilenumberfilled":props?.data?.isParentsMobilenumberfilled == "Y" ? "Y" : "N",
          "assetIds": value?.assetInfo,
          "isfatherAsParentsnumber": value?.isFather ? "Y" : "N",
          "isMotherAsParentsnumber":value?.isMother ? "Y" : "N",
          "ishavenosiblings": value?.haveSiblings ? "Y" : "N",
         "livesIn":  value?.groomLivesInIndia ? "India" : value?.groomLivesInAbroad ? "Abroad" :"",
          "isWorkLocationSame":value?.isWorkLocationSame? true : false,
          "workCountry": value?.workCountry,
          "workState": value?.workState,
          "workCity": value?.workCity,
          "fathersnativeplace": value?.fatherNative,
          "mothersnativeplace": value?.motherNative,
          "fathersnativelocationtype":  value?.fathersnativelocationtype ? nativeDropdown?.find(e => e.locationId == value?.fathersnativelocationtype)?.locationName : '',
          "mothersnativelocationtype": value?.mothersnativelocationtype ? nativeDropdown?.find(e => e.locationId == value?.mothersnativelocationtype)?.locationName : '',
          "requestfulfilledfrom":getDeviceInfo(),
          "requestfulfilledvia":"viewprofile",
          "recieverprofileid":props?.data?.profileId,
          ...(value?.isFather == "Y" || value?.isMother == "Y" && {"parentnumberrequesttypeid":87}),
          ...(value?.fathersName !== "" && {"fathersnamerequesttypeid":67}),
          ...(value?.fathersOccupation !== "" && {"fathersoccupationrequesttypeid":68}),
          ...(value?.mothersName !== "" && {"mothersnamerequesttypeid":69}),
          ...(value?.mothersOccupation !== "" && {"mothersoccupationrequesttypeid":70}),
          ...((value?.brotherCount != "") && {"brothersrequesttypeid":71}),
          ...((value?.marriedBrotherCount != "") && {"marriedbrothersrequesttypeid":72}),
          ...((value?.sisterCount != "") && {"sistersrequesttypeid":73}),
          ...((value?.marriedSisterCount != "") && {"marriedsistersrequesttypeid":74}),
          ...(value?.assetInfo?.length > 0 && {"assetsrequesttypeid":75}),
          // ...((value?.fatherNative != "") && {"fatherNativePlaceRequestTypeId":93}),
          // ...((value?.motherNative != "") && {"motherNativePlaceRequestTypeId":94}),
          ...((value?.groomLivesInIndia !== '' || value?.groomLivesInAbroad !== '') && {"LivesinRequestTypeId":95}),
          ...((value?.workState != null && value?.workState != '') && {"WorkStateRequestTypeId":96}),
          ...((value?.workCity != null && value?.workCity != '') && {"WorkCityRequestTypeId":97}),
          ...((value?.fatherNative != "" && value?.fathersnativelocationtype !== '') && {"fatherNativePlaceRequestTypeId":93}),
          ...((value?.motherNative != "" &&  value?.mothersnativelocationtype !== '') && {"motherNativePlaceRequestTypeId":94}),
          ...((props?.microisFrom == "microAdditionalDetails" && hasSelectInputChanged) && {microtrackId: microtrackidData})

        };
        console.log(request,'sfjkkjfskdjfks');
    
        let { statusCode, data } = await POST(
          `${config.api.API_URL}${EndPoints.saveAdditionInfo()}`,
          request
        );
    
        if (statusCode === 200) {
          if (data.data.status === "SUCCESS") {
            setSubmitStatus(false)
            props.callBackReload();
            // setEditView(false);
          }
          else{
            if(formik?.values?.isFather){
              formik.setFieldError('fMobileNumber',data?.data?.statusmessage)
              setSubmitStatus(false)
            }
            else{
              formik.setFieldError('motherMobileNumber',data?.data?.statusmessage)
              setSubmitStatus(false)
            }
          }
        } else if (statusCode === 401) {
          logout();
        }
      };
      console.log(props?.data?.workCity,'props?.data?.workCity');
      

      useEffect(()=>{
        if(formik.values?.brotherCount != "" || formik.values?.marriedBrotherCount != "" || formik.values?.broMobileNumber != "" || formik.values?.sisterCount != "" || formik.values?.marriedSisterCount != "" || formik.values?.sisMobileNumber != "" ){
          setIsSiblingDisabled(true)
          formik.setFieldValue('haveSiblings',false)
        }
        else{
          setIsSiblingDisabled(false)
        }
      },[formik.values?.brotherCount, formik.values?.marriedBrotherCount, formik.values?.broIsdCode, formik.values?.broMobileNumber, formik.values?.sisterCount, formik.values?.marriedSisterCount, formik.values?.sisIsdCode, formik.values?.sisMobileNumber, formik.values?.assetInfo])

      // useEffect(() => {
      //   if (formik?.values?.motherMobileNumber !== "" ) {
      //     if (formik?.values?.isMother) {
      //       formik.setFieldValue('isFather', false);
      //     }
      //   }
      //   else{
      //     formik.setFieldValue('isMother', false);
      //   }
      // }, [formik?.values?.motherMobileNumber, formik?.values?.isMother, formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.fMobileNumber !== "") {
      //     formik.setFieldValue('isFather', true);
          
      //     if (formik?.values?.isFather) {
      //       formik.setFieldValue('isMother', false);
      //     }
      //   }
      //   else{
      //     formik.setFieldValue('isFather', false);
      //   }
      // }, [formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.isMother) {
      //     formik.setFieldValue('isFather', false);
      //   }
      //   else if (!(formik?.values?.isMother) && formik?.values?.fMobileNumber != ""){
      //     formik.setFieldValue('isFather', true);
      //   }
        
      // }, [formik?.values?.isMother, formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.isFather) {
      //     formik.setFieldValue('isMother', false);
      //   }
      // }, [formik?.values?.isFather]);


      const selectedAssetValues= useMemo(()=>{
        return assetDropdown?.length > 0 &&  assetDropdown?.filter(option=>option?.id === formik?.values?.assetInfo)?.at(0)
      },[assetDropdown, formik?.values?.assetInfo])
      console.log(selectedAssetValues,'jkfojdofjdfif');


      const selectedFatherOccupationValues= useMemo(()=>{
        return occupationDropdown?.length > 0 &&  occupationDropdown?.filter(option=>option?.id === formik?.values?.fathersOccupation)?.at(0)
      },[occupationDropdown, formik?.values?.fathersOccupation])

      const selectedMotherOccupationValues= useMemo(()=>{
        return occupationDropdown?.length > 0 &&  occupationDropdown?.filter(option=>option?.id === formik?.values?.mothersOccupation)?.at(0)
      },[occupationDropdown, formik?.values?.mothersOccupation])

      const selectedFathersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.fisdCode)[0]
      },[isdCodeDropdown, formik?.values?.fisdCode])

      const selectedMothersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.misdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.misdCode])

      const selectedBrothersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.broIsdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.broIsdCode])

      const selectedSistersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.sisIsdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.sisIsdCode])

      const fatherNameCheckList = async (value,e) => {
        let request = {
          name: value
        };
        let data = await axios.post(
          `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
          request
        );
          if (data.data.status === "Success") {
            errorNameMessage = data?.data?.data?.Messagge
            setFatherNameErrorMessage(data?.data?.data?.Messagge)
            formik.handleChange(e)
          }
    };

    const motherNameCheckList = async (value,e) => {
      let request = {
        name: value
      };
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
        request
      );
        if (data.data.status === "Success") {
          errorNameMessage = data?.data?.data?.Messagge
          setMotherNameErrorMessage(data?.data?.data?.Messagge)
          formik.handleChange(e)
        }
  };

  const fatherNativeCheckList = async (value,e) => {
    let request = {
      name: value
    };
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
      request
    );
      if (data.data.status === "Success") {
        errorNameMessage = data?.data?.data?.Messagge
        setFatherNativeErrorMessage(data?.data?.data?.Messagge)
        formik.handleChange(e)
      }
  };

  const motherNativeCheckList = async (value,e) => {
    let request = {
      name: value
    };
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
      request
    );
      if (data.data.status === "Success") {
        errorNameMessage = data?.data?.data?.Messagge
        setMotherNativeErrorMessage(data?.data?.data?.Messagge)
        formik.handleChange(e)
      }
};


  const updateMarriedBrotherOptions = (count) => {
    const maxBrothers = parseInt(count, 10);
    const newOptions = NumbersDropdown.filter(option => parseInt(option.id, 10) <= maxBrothers);
    setBrothersMarriedOptions(newOptions);
  };

  const handleBrotherCountChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('brotherCount', value);
    updateMarriedBrotherOptions(value);
    handleSelectInputChange(event)

  };

  // Update options whenever brotherCount changes
  useEffect(() => {
    updateMarriedBrotherOptions(formik.values.brotherCount);
    if(formik?.values?.brotherCount){
      if(formik?.values?.brotherCount == "0"){
        formik.setFieldValue('marriedBrotherCount', "");
      }
      if(formik?.values?.marriedBrotherCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedBrotherCount', "")
      }
    }
  }, [formik.values.brotherCount]);

  const updateMarriedSisterOptions = (count) => {
    const maxSisters = parseInt(count, 10);
    const newOptions = NumbersDropdown.filter(option => parseInt(option.id, 10) <= maxSisters);
    setSistersMarriedOptions(newOptions);
  };

  const handleSisterCountChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('sisterCount', value);
    updateMarriedSisterOptions(value);
    handleSelectInputChange(event)

  };

  // Update options whenever sisterCount changes
  useEffect(() => {
    updateMarriedSisterOptions(formik.values.sisterCount);
    if(formik?.values?.sisterCount){
      if(formik?.values?.sisterCount == "0"){
        formik.setFieldValue('marriedSisterCount', '');
      }
      if(formik?.values?.marriedSisterCount > formik?.values?.sisterCount){
        formik.setFieldValue('marriedSisterCount', '')
      }
    } 
  }, [formik.values.sisterCount]);

console.log(formik.values.misdCode,'formik.values.misdCode');


useEffect(() => {
  dispatch(
    getBasicData(localStorage.getItem(constant.loginLocalStorageKeys.loginId))
  );
  // dispatch(registerSubCasteList("122"))
}, []);

const cityvalue = props?.data?.workCity?.map((item)=>({
  value: item.id, // Use the country ID as the value
  label: item.name, // Use the country name as the label
}))

const firstCityValue =cityvalue?.[0] || null; // Extract the first object or return null if city is undefined or empty
console.log(firstCityValue, "firstCityObject");


console.log(cityvalue,"cityvalue");



    const [workCountryDropDown, setworkCountryDropDown] = useState([]);

    const [workStateDropDown, setworkStateDropDown] = useState([]);
    const [workCityDropDown, setworkCityDropDown] = useState([]);


    const selectedCountry = useMemo(() => {
      return workCountryDropDown?.filter(option => option.value === formik.values.workCountry)?.at(0)
    }, [workCountryDropDown, formik.values.workCountry]);
    
    const selectedState = useMemo(()=>{
      return workStateDropDown?.filter(option=>option.value === formik.values.workState)?.at(0)
    },[formik.values.workState, workStateDropDown])
  
    const selectedCity = useMemo(()=>{
      return workCityDropDown?.filter(option=>option.value === formik.values.workCity )?.at(0)
    },[workCityDropDown, formik.values.workCity ])



  const selectCityValue = useMemo(()=>{
    return workCityDropDown?.find(option=>option.value === firstCityValue)?.at(0)
  },[workCityDropDown, firstCityValue])


    console.log({selectedCountry,selectedState ,selectedCity ,selectCityValue});

useEffect(()=>{
  dispatch(
    getBasicData(localStorage.getItem(constant.loginLocalStorageKeys.loginId))
  );
},[])
  const { basicData } = useSelector((state) => ({
    basicData: state.Login?.basicData,
  }));

  console.log({workStateDropDown,workCityDropDown,workCountryDropDown});
  
 

  console.log(basicData,"formik.values.workCity");



  useEffect(() => {
    setworkCountryDropDown(basicData?.data?.isdcode?.map((item)=>({
      value: item.id, // Use the country ID as the value
      label: item.country, // Use the country name as the label
    })));
      
    if(formik.values.groomLivesInAbroad){
      setworkCountryDropDown(basicData?.data?.isdcode?.filter(item=>item.country != "India").map((item)=>({
        value: item.id, // Use the country ID as the value
        label: item.country, // Use the country name as the label
      })));
    }
   
    
  }, [ basicData?.data?.isdcode ,formik.values.groomLivesInAbroad]);



const OnChangeOption = (name, value ,e) => {
  console.log(name, value, "valuename");
  handleSelectInputChange(e)

  if (name === "workCountry") {
      apiCallworkState(value.value); // Pass only the country ID
      formik.setFieldValue("workState", ""); // Reset the state field
      formik.setFieldValue("workCity", "");  // Reset the city field
  } else if (name === "workState") {
      apiCallworkCity(value.value); // Pass only the state ID
      formik.setFieldValue("workCity", "");  // Reset the city field
  }

  // Set the formik field with the selected value object
  formik.setFieldValue(name, value.value); 
};

// const StateId = props?.data?.state[0]?.id
const WorkstateId = props?.data?.workState?.length > 0 ? props?.data?.workState[0]?.id : null;

useEffect(()=>{

 

  if(props?.data?.workCountry){
    apiCallworkState(props?.data?.workCountry[0]?.id)
  }

  if(props?.data?.workState ){
  
   apiCallworkCity(WorkstateId )
  }
 
 
},[props?.data?.workCountry ,props?.data?.workState ,props?.data?.state])

  const apiCallworkState = async (countryID ,from) => {

      try {
        let { statusCode, data } = await GET(
          `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
        );
    
        if (statusCode === 200) {
          setworkStateDropDown(data.data.states?.map((item)=>({
            value: item.id, // Use the country ID as the value
            label: item.name, // Use the country name as the label
          
          })));
         
          if(from == "retain"){
              const temp = [];
              data?.data?.states?.forEach(state => {
                  if (formik?.values?.workState?.includes(state.id)) {
                      temp.push(state.id)
                  }
              });
              formik.setFieldValue("workState", temp);
              apiCallworkCity(temp);
              
          }
         
          
          }
      }
      catch (error) {
          console.log("error on fetch state", error);
      }
  };

  const apiCallworkCity = async (stateID) => {

      try {
        let { statusCode, data } = await GET(
          `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
        );
    
        if (statusCode === 200) {
          if (data.status == "Success") {
            setworkCityDropDown(data.data.cities?.map((item)=>({
              value: item.id, // Use the country ID as the value
              label: item.name, // Use the country name as the label
            })));
            // const temp = [];
            // data?.data?.cities?.forEach(city => {
            //     if (formik.values?.workCity?.includes(city.id)) {
            //         temp.push(city.id)
            //     }
            // });
            // formik.setFieldValue("workCity", temp);
            
        }
          }
      }
      catch (error) {
          console.log("Error on fetch city", error);
      }

  };
  console.log(formik.values,'problem4');

  return (
    <>
    {isShowEdit ? 
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-5 md:p-0">

                <BasicEditTextView
                    title={trans('view_profile.fathers_name')}
                    inputID="fathersName"
                    inputName="fathersName"
                    className="fathersName"             
                    inputType="text" 
                    required={false}
                    error={fatherNameErrorMessage== ''? '': fatherNameErrorMessage}
                    value={formik.values.fathersName}
                    onChange={(e)=>{
                      handleSelectInputChange(e)
                      fatherNameCheckList (e.target.value,e)
                      let text = e.target.value ?? "";
                      text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                      text = text.charAt(0).toUpperCase() + text.slice(1)
                      var splitStr = text.toLowerCase().split(' ');
                      for (var i = 0; i < splitStr.length; i++) {
                          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                      }
                      text =  splitStr.join(' '); 
                      e.target.value = text;
                      if ((e.target.value.split(".").length - 1) <= 1) {
                        formik.handleChange(e)
                      }
                    }}
                    editfromadmin ={props.editfromadmin}
                    
                />

                 
                {/* <SelectInput
                    title="Father's Occupation"
                    name="fathersOccupation"
                    required={false}
                    value={selectedFatherOccupationValues}
                    defaultValue={formik.values.fathersOccupation}
                    error={formik.errors.fathersOccupation}
                    options={[{id:null,name:"Select"},...occupationDropdown]}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selected, ev) => {
                        formik.setFieldValue("fathersOccupation", selected.id)
                    }}
                />  */}
                <SelectViewWithHeading
                  title={trans('view_profile.fathers_occupation')}
                  inputID={"fathersOccupation"}
                  inputName={"fathersOccupation"}
                  value={formik.values.fathersOccupation}
                  onChange={(e)=>{
                    formik.handleChange(e)
                    handleSelectInputChange(e)
                  }}
                  error={formik.errors.fathersOccupation}
                  options={occupationDropdown}
                  required={false}
                  isFrom={"additionalDetails"}
                  defalutValue={formik.values.fathersOccupation}
                  disabled={props.editfromadmin == 'Y'}
                />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                Father's Native
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3 pt-1">
                    <div className=''>
                        <SelectNativeInput
                            name="fathersnativelocationtype"
                            options={(nativeDropdown || [])}
                            onChange={(e)=>{
                              formik.setFieldValue("fathersnativelocationtype", e.target.value)
                              handleSelectInputChange(e)

                            }}
                            placeholder={trans('my_profile.villagetowndistrictcity')}
                            value={formik.values.fathersnativelocationtype}
                            className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(props.editfromadmin == 'Y')? "cursor-not-allowed": ""}`}
                            disabled={props.editfromadmin == 'Y'}
                        />
                    </div>
                    <p className="flex items-center w-[100%] pt-[1px] ">
                      <input
                        id="fatherNative"
                        inputID="fatherNative"
                        inputName="fatherNative"
                        type="text"
                        value={formik.values.fatherNative}
                        onChange={(e)=>{
                          fatherNativeCheckList (e.target.value,e)
                          handleSelectInputChange(e)

                          let text = e.target.value ?? "";
                          text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                          text = text.charAt(0).toUpperCase() + text.slice(1)
                          var splitStr = text.toLowerCase().split(' ');
                          for (var i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                          }
                          text = splitStr.join(' ');
                          e.target.value = text;
                          if ((e.target.value.split(".").length - 1) <= 1) {
                              formik.handleChange(e)
                          }
                        }}
                        disabled={props.editfromadmin == 'Y'}
                        className={` w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${( props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                    
                  </div>
                  {formik.errors.fatherNative && formik.touched.fatherNative &&(
                    <p className="text-[red] text-[12px]">
                      {formik.errors.fatherNative}
                    </p>
                  )}
                  {fatherNativeErrorMessage && (
                    <p className="text-[red] text-[12px]">
                      {trans('my_profile.please_enter_a_valid_location')}
                    </p>
                  )}
                  
                </p>
              </div>
            </div>

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                {trans('tell_us_more_about_your_family.fathers_mobile_number')}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3 pt-1">
                      <SelectCurrencyInput
                        menu=""
                        name="fisdCode"
                        id="fisdCode"
                        title="fisdCode"
                        error={formik.errors.fisdCode}
                        defaultValue={formik.values.fisdCode}
                        value={selectedFathersIsdValues}
                        onChange={(selected, ev ,e) => {
                          formik.setFieldValue("fisdCode", selected.isdcode)
                          handleSelectInputChange(e)

                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(relationOfParents == "Father" || props.editfromadmin == 'Y')? "cursor-not-allowed": ""}`}
                        options={[{id:null,name:"Select"},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={relationOfParents == "Father" || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="fMobileNumber"
                        inputID="fMobileNumber"
                        inputName="fMobileNumber"
                        type="tel"
                        value={formik.values.fMobileNumber}
                        onChange={async(e) => {
                          let inputValue = e.target.value;
                          handleSelectInputChange(e)

                          if (validateNumber(inputValue)) {
                            await formik.setFieldValue("fMobileNumber", inputValue);
                            if(!showMother){
                              if(inputValue.length == 10 && formik?.values?.fisdCode == "+91"){
                                formik.setFieldValue("isFather",true);
                              }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.fisdCode != "+91"){
                                formik.setFieldValue("isFather",true);
                              }
                              else{
                                formik.setFieldValue("isFather",false);
                              }
                            }
                          }
                        }}
                        // onKeyDown={blockInvalidCharForNumber}
                        disabled={relationOfParents == "Father" || props.editfromadmin == 'Y'}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(relationOfParents == "Father" || props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                    
                  </div>
                  {formik.errors.fMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.fMobileNumber}
                      </p>
                    )}
                  
                </p>
              </div>
            </div>
            {showMother ? <></> :
                  <div className="grid grid-cols-9 items-center gap-2 pt-4">
                    <div className="col-start-1 col-span-9 md:col-start-5 md:col-span-5 flex items-center gap-2 pl-0 md:pl-2 lg:pl-4">
                      <input
                          id="isFather"
                          name="isFather"
                          type="checkbox"
                          // checked={formik.values.isMother == true ? false :formik.values.isFather}
                          checked={formik.values.isFather}
                          value={formik.values.isFather}
                          onChange={(e)=>{

                            handleSelectInputChange(e)

                            formik.setFieldValue("isFather",e.target.checked);
                            if(e.target.checked){
                              formik.setFieldValue("isMother",false);
                            }
                          }}
                          className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative  ${(relationOfParents != undefined || props.editfromadmin == 'Y') ? "cursor-not-allowed": "cursor-pointer"}`}
                          autoComplete="off"
                          disabled={showFather || props.editfromadmin == 'Y'}
                      />
                      <div className='lg:block md:hidden hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents != undefined ? "cursor-not-allowed": "cursor-pointer"}`} >
                            {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                        </p>
                      </div>
                      <div className='lg:hidden md:block block'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents != undefined ? "cursor-not-allowed": "cursor-pointer"}`} >
                        {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                        </p>
                      </div>
                    </div>
                </div>
                  }

            <BasicEditTextView
                title={trans('view_profile.mothers_name')}
                inputID="mothersName"
                inputName="mothersName"
                className="mothersName"             
                inputType="text" 
                required={false}
                error={motherNameErrorMessage == '' ? '' : motherNameErrorMessage}
                value={formik.values.mothersName}
                onChange={(e)=>{
                  motherNameCheckList (e.target.value,e)
                  handleSelectInputChange(e)

                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                  }
                  text =  splitStr.join(' '); 
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                  }
                }}
                editfromadmin = {props.editfromadmin}
            />
                 
            {/* <SelectInput
                title="Mother's Occupation"
                name="mothersOccupation"
                required={false}
                value={selectedMotherOccupationValues}
                defaultValue={formik.values.mothersOccupation}
                error={formik.errors.mothersOccupation}
                options={[{id:null,name:"Select"},...occupationDropdown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selected, ev) => {
                    formik.setFieldValue("mothersOccupation", selected.id)
                }}
            />  */}
            <SelectViewWithHeading
                title={trans('view_profile.mothers_occupation')}
                inputID={"mothersOccupation"}
                inputName={"mothersOccupation"}
                value={formik.values.mothersOccupation}
                onChange={(e)=>{
                  handleSelectInputChange(e)

                  formik.handleChange(e)}}
                error={formik.errors.mothersOccupation}
                options={occupationDropdown}
                required={false}
                isFrom={"additionalDetails"}
                disabled={props.editfromadmin == 'Y'}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                Mother's Native
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3 pt-1">
                    <div className=''>
                        <SelectNativeInput
                            name="mothersnativelocationtype"
                            options={(nativeDropdown || [])}
                            onChange={(e)=>{
                              console.log(e,'problem3');
                              formik.setFieldValue("mothersnativelocationtype", e.target.value)
                              handleSelectInputChange(e)

                            }}
                            placeholder={trans('my_profile.villagetowndistrictcity')}
                            value={formik.values.mothersnativelocationtype}
                            className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(props.editfromadmin == 'Y')? "cursor-not-allowed": ""}`}
                            disabled={props.editfromadmin == 'Y'}
                        />
                    </div>
                    <p className="flex items-center w-[100%] pt-[1px] ">
                      <input
                        id="motherNative"
                        inputID="motherNative"
                        inputName="motherNative"
                        type="text"
                        value={formik.values.motherNative}
                        onChange={(e)=>{
                          motherNativeCheckList (e.target.value,e)
                          handleSelectInputChange(e)

                          let text = e.target.value ?? "";
                          text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                          text = text.charAt(0).toUpperCase() + text.slice(1)
                          var splitStr = text.toLowerCase().split(' ');
                          for (var i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                          }
                          text = splitStr.join(' ');
                          e.target.value = text;
                          if ((e.target.value.split(".").length - 1) <= 1) {
                              formik.handleChange(e)
                          }
                        }}
                        disabled={props.editfromadmin == 'Y'}
                        className={` w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                    
                  </div>
                  {formik.errors.motherNative && formik.touched.motherNative && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.motherNative}
                    </p>
                  )}
                  {motherNativeErrorMessage && (
                    <p className="text-[red] text-[12px]">
                      {trans('my_profile.please_enter_a_valid_location')}
                    </p>
                  )}
                  
                </p>
              </div>
            </div>

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                {trans('tell_us_more_about_your_family.mothers_mobile_number')}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="misdCode"
                        id="misdCode"
                        title="misdCode"
                        error={formik.errors.misdCode}
                        defaultValue={formik.values.misdCode}
                        value={selectedMothersIsdValues}
                        onChange={(selected, ev,e) => {
                          formik.setFieldValue("misdCode", selected.isdcode)
                          handleSelectInputChange(e)

                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:null,name:"Select"},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        // isDisabled={formik.values.isMother}
                        isDisabled={relationOfParents == "Mother" || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="motherMobileNumber"
                        inputID="motherMobileNumber"
                        inputName="motherMobileNumber"
                        type="tel"
                        value={formik.values.motherMobileNumber}
                        onChange={async (e) => {
                          let inputValue = e.target.value;
                          handleSelectInputChange(e)

                          if (validateNumber(inputValue)) {
                            await formik.setFieldValue("motherMobileNumber",inputValue)
                            if(!showFather){
                              if(inputValue.length == 10 && formik?.values?.misdCode == "+91"){
                                formik.setFieldValue("isMother",true);
                              }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.misdCode != "+91"){
                                formik.setFieldValue("isMother",true);
                              }
                              else{
                                formik.setFieldValue("isMother",false);
                              }
                            }
                          }
                        }}
                        // disabled={formik.values.isMother}
                        disabled={relationOfParents == "Mother" || props.editfromadmin == 'Y'}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(relationOfParents == "Mother" || props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                  </div>
                  {formik.errors.motherMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.motherMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>
                  {showFather ? <></> :
                  <div className="grid grid-cols-9 items-center gap-2 pt-4">
                    <div className="col-start-1 col-span-9 md:col-start-5 md:col-span-5 flex items-center gap-2 pl-0 md:pl-2 lg:pl-4">
                      <input
                          id="isMother"
                          name="isMother"
                          type="checkbox"
                          // checked={formik.values.isFather == true ? false :formik.values.isMother}
                          checked={formik.values.isFather == true ? '' : formik.values.isMother}
                          value={formik.values.isMother}
                          onChange={(e)=>{
                            formik.setFieldValue("isMother",e.target.checked);
                            handleSelectInputChange(e)

                            if(e.target.checked){
                              formik.setFieldValue("isFather",false);
                            }
                          }}
                          className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative ${(relationOfParents == "Mother" || props.editfromadmin == 'Y') ? "cursor-not-allowed": "cursor-pointer"}`}
                          autoComplete="off"
                          disabled={showMother || props.editfromadmin == 'Y'}
                      />
                      <div className='lg:block md:hidden hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                            {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                        </p>
                      </div>
                      <div className='lg:hidden md:hidden block'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                        {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                        </p>
                      </div>
                      <div className='lg:hidden md:block hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                        {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                        </p>
                      </div>
                    </div>
                  </div>
                  }

            <div className="flex items-center gap-2 w-[18rem] md:whitespace-nowrap pt-4 px-0 md:px-[1rem] lg:px-8">
                <input
                    type="checkbox"
                    name="haveSiblings"
                    disabled={isSiblingDisabled || props.editfromadmin == 'Y'}
                    checked={formik.values.haveSiblings}
                    onChange={(e)=>{
                      handleSelectInputChange(e)

                      formik.handleChange(e)}}
                    className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative ${(isSiblingDisabled || props.editfromadmin == 'Y')? "cursor-not-allowed" :"cursor-pointer"}`}
                />
                <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] words-break lg:text-[16px] font-extrabold pb-0.5 ${(isSiblingDisabled || props.editfromadmin == 'Y') ? "cursor-not-allowed" :"cursor-pointer"}`} >
                {Male ? trans('siblings.no_brothers_or_sisters_to_groo') : Female ? trans('siblings.no_brothers_or_sisters_to_brid') : ''}
                </p>
            </div>

            {/* <BasicEditTextView
                title="Number of Brothers"
                inputID="brotherCount"
                inputName="brotherCount"
                className="brotherCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.brotherCount}
                value={formik.values.brotherCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
            <SelectViewWithHeading
                title={trans('my_profile.number_of_brothers')}
                inputID={"brotherCount"}
                inputName={"brotherCount"}
                value={formik.values.brotherCount}
                onChange={handleBrotherCountChange}
                error={formik.errors.brotherCount}
                options={NumbersDropdown}
                required={false}
                disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                isFrom={"additionalDetails"}
              />

            {/* <BasicEditTextView
                title="Number of Brothers Married"
                inputID="marriedBrotherCount"
                inputName="marriedBrotherCount"
                className="marriedBrotherCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.marriedBrotherCount}
                value={formik.values.marriedBrotherCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
              <SelectViewWithHeading
                title={trans('my_profile.number_of_brothers_married')}
                inputID={"marriedBrotherCount"}
                inputName={"marriedBrotherCount"}
                value={formik.values.marriedBrotherCount}
                onChange={(e)=>{formik.handleChange(e)
                  handleSelectInputChange(e)

                }}
                error={formik.errors.marriedBrotherCount}
                options={brothersMarriedOptions}
                required={false}
                disabled={formik.values.haveSiblings || formik.values.brotherCount == 0 || props.editfromadmin == 'Y' }
                isFrom={"additionalDetails"}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                {trans('my_profile.brothers_mobile_number')}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="broIsdCode"
                        id="broIsdCode"
                        title="broIsdCode"
                        error={formik.errors.broIsdCode}
                        defaultValue={formik.values.broIsdCode}
                        value={selectedBrothersIsdValues}
                        onChange={(selected, ev ,e) => {
                          formik.setFieldValue("broIsdCode", selected.isdcode)
                          handleSelectInputChange(e)

                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:"",name:"Select",isdcode:""},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="broMobileNumber"
                        inputID="broMobileNumber"
                        inputName="broMobileNumber"
                        type="tel"
                        value={formik.values.broMobileNumber}
                        onChange={(e)=>{
                        if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                            handleSelectInputChange(e)

                        }
                        }}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(formik.values.haveSiblings || props.editfromadmin == 'Y') ? "cursor-not-allowed" :""}`}
                        disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                    </p>
                  </div>
                  {formik.errors.broMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.broMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>

            {/* <BasicEditTextView
                title="Number of Sisters"
                inputID="sisterCount"
                inputName="sisterCount"
                className="sisterCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.sisterCount}
                value={formik.values.sisterCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
              <SelectViewWithHeading
                title={trans('my_profile.number_of_sisters')}
                inputID={"sisterCount"}
                inputName={"sisterCount"}
                value={formik.values.sisterCount}
                onChange={handleSisterCountChange}
                error={formik.errors.sisterCount}
                options={NumbersDropdown}
                required={false}
                disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                isFrom={"additionalDetails"}
              />

            {/* <BasicEditTextView
                title="Number of Sisters Married"
                inputID="marriedSisterCount"
                inputName="marriedSisterCount"
                className="marriedSisterCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.marriedSisterCount}
                value={formik.values.marriedSisterCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}

              <SelectViewWithHeading
                title={trans('my_profile.number_of_sisters_married')}
                inputID={"marriedSisterCount"}
                inputName={"marriedSisterCount"}
                value={formik.values.marriedSisterCount}
                onChange={(e)=>{formik.handleChange(e)
                  handleSelectInputChange(e)

                }
                }
                error={formik.errors.marriedSisterCount}
                options={sistersMarriedOptions}
                required={false}
                disabled={formik.values.haveSiblings || formik.values.sisterCount == 0 || props.editfromadmin == 'Y' }
                isFrom={"additionalDetails"}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                {trans('my_profile.sisters_mobile_number')}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="sisIsdCode"
                        id="sisIsdCode"
                        title="sisIsdCode"
                        error={formik.errors.sisIsdCode}
                        defaultValue={formik.values.sisIsdCode}
                        value={selectedSistersIsdValues}
                        onChange={(selected, ev ,e) => {
                          formik.setFieldValue("sisIsdCode", selected.isdcode)
                          handleSelectInputChange(e)

                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:null,name:"Select",isdcode:""},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                        />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="sisMobileNumber"
                        inputID="sisMobileNumber"
                        inputName="sisMobileNumber"
                        type="tel"
                        value={formik.values.sisMobileNumber}
                        onChange={(e)=>{
                        if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                            handleSelectInputChange(e)

                        }

                        }}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(formik.values.haveSiblings || props.editfromadmin == 'Y') ? "cursor-not-allowed" :""}`}
                        disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                    </p>               
                  </div>
                  {formik.errors.sisMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.sisMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>

            {/* <SelectInput
                title="Asset Information"
                name="assetInfo"
                required={false}
                value={selectedAssetValues}
                defaultValue={formik.values.assetInfo}
                error={formik.errors.assetInfo}
                options={[{id:null,name:"Select"},...assetDropdown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selected, ev) => {
                  console.log(selected,'sdfmkldfldkfl');
                    formik.setFieldValue("assetInfo", selected.id)
                }}
            />  */}

              <MultiSelectView
                title={trans('my_profile.assets_owned_by_family')}
                inputID={"assetInfo"}
                inputName={"assetInfo"}
                value={formik?.values?.assetInfo}
                defalutValue={[formik?.values?.assetInfo]}
                onChange={(e) => {
                  formik.setFieldValue("assetInfo", Array.from(e));
                  handleSelectInputChange(e)

                }}
                page={"additionInfo"}
                error={formik.errors.assetInfo}
                options={assetDropdown}
                // isDisabled={formik.values.haveSiblings}
                placeholder={trans('assets_owned_by_family.select_multiple')}
                isFrom='MyProfile'
                editfromadmin = {props.editfromadmin}
              />


              <div className="grid  grid-cols-8 md:grid-cols-10 lg:grid-cols-7 gap-5 space-x-2 lg:gap-0 lg:space-x-0 2xl:grid-cols-6 xl:grid-cols-7  mt-[2rem]">
                <p className={`text-[#7A7A7A] text-[15px] md:text-[12px] lg:text-[16px] font-extrabold pb-1 md:pl-[1rem] lg:pl-[2rem] flex whitespace-nowrap col-span-3 md:col-span-5  lg:col-span-2 xl:col-span-2 2xl:col-span-1`} >
                <OverflowToolTip  as="p" className="truncate ">
                   {props?.data?.gender ==="F" ? trans('my_profile.bride_lives_in'): trans('my_profile.groom_lives_in')} :
                   </OverflowToolTip>
                </p>
                <div className=" md:col-span-3 col-span-3 flex items-center gap-4">
                  <div className="flex gap-2 items-center">
                   
                     <input
                  type="checkbox"
                  className={`form-radio h-4 w-4 accent-gray-500 border border-[#F18181] relative`}
                  name="groomLivesInIndia"
                  value={formik.values.groomLivesInIndia}
                  checked={formik.values.groomLivesInIndia}
                  onChange={(e) => {
                                     
                      formik.setFieldValue("groomLivesInIndia",e.target.checked);
                      handleSelectInputChange(e)

                      if(e.target.checked){    

                          formik.setFieldValue("workCountry","499");
                          formik.setFieldValue("workState", "");   
                          formik.setFieldValue("workCity", ""); 
                          formik.setFieldValue("groomLivesInAbroad",false);
                          apiCallworkState(499);
                      }
                      else {
                        formik.setFieldValue("workCountry","");
                        formik.setFieldValue("workState", "");   
                          formik.setFieldValue("workCity", ""); 
                          formik.setFieldValue("isWorkLocationSame", false);
                      }
                      if(ProfileCountry !="India" || formik.values.groomLivesInIndia){
                        formik.setFieldValue("isWorkLocationSame", false);
                      }
                      
                      
                  }}
                     />
                    <p className={`text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[16px]`} >
                      {trans('my_profile.india')}
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                   
                    <input
                    type="checkbox"
                    className={`form-radio h-4 w-4accent-gray-500 border border-[#F18181] relative`}
                    name="groomLivesInAbroad"
                    value={formik.values.groomLivesInAbroad}
                    checked={formik.values.groomLivesInAbroad}
                    onChange={async (e) => {
                      handleSelectInputChange(e)

                      if (e.target.checked) {
                        formik.setFieldValue("groomLivesInIndia", false);  // Uncheck "India"
                        formik.setFieldValue("groomLivesInAbroad", true);  // Check "Abroad"
                        formik.setFieldValue("isWorkLocationSame", false);
                        formik.setFieldValue("workCountry", "");  // Clear workCountry
                        formik.setFieldValue("workState", "");    // Clear workState
                        formik.setFieldValue("workCity", "");     // Clear workCity
                        setworkCountryDropDown((country) => 
                           country?.filter(prev => prev.value != "499"))
                        
                        if(ProfileCountry == "India"){
                            formik.setFieldValue("isWorkLocationSame", false);
                        }
                      } else {
                        formik.setFieldValue("groomLivesInIndia", false);  // Optionally reset the "India" checkbox
                        formik.setFieldValue("groomLivesInAbroad", false); // Uncheck "Abroad"
                        formik.setFieldValue("isWorkLocationSame", false);
                        formik.setFieldValue("workCountry", "");  // Clear workCountry
                        formik.setFieldValue("workState", "");    // Clear workState
                        formik.setFieldValue("workCity", "");     // Clear workCity
                        setworkCountryDropDown([]);  // Optionally reset the workCountry dropdown
                      }
                    }}
                  />
                    <p className={`text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[16px]`} >
                    <OverflowToolTip  as="p" className="truncate ">
                    {trans('my_profile.abroad')} </OverflowToolTip>
                    </p>
                  </div>
                </div>
                {formik.touched.groomLivesIn && formik.errors.groomLivesIn && (
                   <p className='text-[red] pb-1 text-[12px]'>{formik.errors.groomLivesIn}</p>
                   )}
              </div>

            <div className="md:grid-cols-6 grid xl:grid-cols-7 2xl:grid-cols-6 lg:grid-cols-7 items-center mt-[2rem]">
                <p className={`text-[#7A7A7A] md:text-[#7A7A7A] text-[15px] md:text-[12px]  items-center lg:text-[16px] font-extrabold pb-1 md:pl-[1rem] lg:pl-[2rem] flex whitespace-nowrap col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-1`} >
                <OverflowToolTip  as="p" className="truncate ">
                {trans('my_profile.place_of_work')} :
                </OverflowToolTip>
               
                </p>
                <div className="col-span-4 flex items-center">
                  <div className='flex gap-2 items-center md:pl-[4.5rem] lg:pl-0 '>
                  <input
                    type="checkbox"
                    name="isWorkLocationSame"
                    className={`accent-gray-500  ${(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || 
                      (ProfileCountry== "India" && formik.values.groomLivesInAbroad) ||(ProfileCountry != "India" && formik.values.groomLivesInIndia)  ?"cursor-not-allowed":"cursor-default"} border border-[#F18181]`}
                    checked={formik.values.isWorkLocationSame}
                    value={formik.values.isWorkLocationSame}
                    error={formik.errors.isWorkLocationSame}
                    disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || 
                      (ProfileCountry== "India" && formik.values.groomLivesInAbroad) ||(ProfileCountry != "India" && formik.values.groomLivesInIndia)  }
                      onChange={(e) => {
                        handleSelectInputChange(e)

                        const isChecked = e.target.checked;
                        formik.setFieldValue("isWorkLocationSame", isChecked);
                      
                        if (isChecked) {
                          if(ProfileCountry== "India"){
                            formik.setFieldValue("workCountry",formik.values.workCountry);
                            formik.setFieldValue("workState", ProfileState);
                            formik.setFieldValue("workCity", ProfileCity );

                            apiCallworkState(formik.values.workCountry);// Call API after setting values
                          apiCallworkCity(ProfileState);
                          }
                          else{
                          formik.setFieldValue("workCountry",ProfileCountryId);
                          formik.setFieldValue("workState", ProfileState);
                          formik.setFieldValue("workCity", ProfileCity );
                      
                          apiCallworkState(ProfileCountryId);// Call API after setting values
                          apiCallworkCity(ProfileState);
                          }
                        } else if(ProfileCountry== "India"){
                          formik.setFieldValue("workCountry", formik.values.workCountry);
                          formik.setFieldValue("workState", "");
                          formik.setFieldValue("workCity", "");
                      
                        }
                        else {
                          formik.setFieldValue("workCountry", "");
                          formik.setFieldValue("workState", "");
                          formik.setFieldValue("workCity", "");
                        }
                        
                      }} />
                   
                      <p className={`text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[16px]`} >
                      {trans('my_profile.same_location_as_previously_me')} 
                      </p>
                  </div>
                  {formik.touched.isWorkLocationSame && formik.errors.isWorkLocationSame && (
                                <p className='text-[red] pb-1 text-[12px]'>{formik.errors.isWorkLocationSame}</p>
                            )}
                </div>
            </div>
            <div>
            <SelectInput
             title={trans('my_profile.country')} 
             name="workCountry"
             options={workCountryDropDown}
             // onChange={OnChangeOption}  // Pass both name and value
             onChange={(value, name) => OnChangeOption('workCountry', value, name)}             // error={formik.errors.country}
             value={selectedCountry}
             defaultValue={formik.values.workCountry}
             disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.groomLivesInIndia)  || (formik.values.isWorkLocationSame) }
            error={formik.errors.workCountry}
            />
            {formik.touched.workCountry && formik.errors.workCountry && (
               <p className='text-[red] pb-1 text-[12px]'>{formik.errors.workCountry}</p>
                   )}
            </div>
           
            <div>
            <SelectInput
             name="workState"
             title={trans('my_profile.state')}
             options={workStateDropDown}
             onChange={(value, name) => OnChangeOption('workState', value, name)}             // error={formik.errors.state}
             value={selectedState}
             defalutValue={formik.values.workState}
             error={formik.errors.workState}
             disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.isWorkLocationSame) }
            
                />
            {formik.touched.workState && formik.errors.workState && (
                 <p className='text-[red] pb-1 text-[12px]'>{formik.errors.workState}</p>
                )}
            </div>
          
            <div>
            <SelectInput
            name="workCity"
            title={trans('my_profile.city')}
             options={workCityDropDown}
             onChange={(value, name) => OnChangeOption('workCity', value, name)}              // error={formik.errors.city}
             value={selectedCity}
             defaultValue={formik.values.workCity}
             error={formik.errors.workCity}
             disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.isWorkLocationSame) }
          

             />
              {formik.touched.workCity && formik.errors.workCity && (
                 <p className='text-[red] pb-1 text-[12px]'>{formik.errors.workCity}</p>
                )}
             </div>
                        

                <SaveCancelButton
                    saveCallBack={(e) => {
                        e.preventDefault();
                    }}
                    cancelCallback={() => {
                        // setEditView(false);
                        formik.resetForm()
                    }}
                    submitStatus={submitStatus}
                    editfromadmin = {props.editfromadmin}
                    isFrom="MyProfile"
                />
                </div>
            </form>
        </> 
        :
        <>
            <ActionRestrict>
                <EditButton
                    editCallBack={() => {
                        setEditView(true)
                    }}
                />
            </ActionRestrict>

            <div className="md:mb-8 p-5">
                <BasicTextView
                    title={trans('my_profile.fathers_name')}
                    value={props?.data?.fathersName ? props?.data?.fathersName : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.fathers_occupation')}
                    value={props?.data?.fathersOccupation ? props?.data?.fathersOccupation[0].name : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title= {trans('my_profile.fathers_mobile_number')}
                    value={showFather ? props?.data?.parentsIsdCode+" - "+props?.data?.parentsMobileNumber : props?.data?.fathersMobilenumber ? props?.data?.fathersIsdcode+" - "+props?.data?.fathersMobilenumber : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.mothers_name')}
                    value={props?.data?.mothersName ? props?.data?.mothersName : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.mothers_occupation')}
                    value={props?.data?.mothersOccupation ? props?.data?.mothersOccupation[0].name : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title= {trans('my_profile.mothers_mobile_number')}
                    value={showMother ? props?.data?.parentsIsdCode+" - "+props?.data?.parentsMobileNumber : props?.data?.mothersMobilenumber ? props?.data?.mothersIsdcode+" - "+props?.data?.mothersMobilenumber : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.number_of_brothers')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.brothers !== undefined  ? props?.data?.brothers : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.number_of_brothers_married')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.marriedBrothers !== undefined ? props?.data?.marriedBrothers : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.brothers_mobile_number')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.brothersMobilenumber ? props?.data?.brothersIsdcode+" - "+props?.data?.brothersMobilenumber : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.number_of_sisters')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.sisters !== undefined ? props?.data?.sisters : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.number_of_sisters_married')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.marriedSisters !== undefined ? props?.data?.marriedSisters : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.sisters_mobile_number')}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.sistersMobilenumber ? props?.data?.sistersIsdcode+" - "+props?.data?.sistersMobilenumber : trans('my_profile.not_specified')}
                />
                <BasicTextView
                    title={trans('my_profile.assets_owned')}
                    value={props?.data?.asset?.length > 0
                      ? props?.data?.asset.map(item => item.name).join(', ')
                      : trans('my_profile.not_specified')}               
                />
            </div>
        </>
        }
    </>
  )
}
