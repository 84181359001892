import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import Loader from "../../Components/Loader";
//images
import KMLogo from "../../Images/KM logo@2x.png"
import CloseIcon from "../../Images/Group 761@2x.png";

import { advisorimage, cancelIcon, chatimage, horoimage, matchesimage, phonenumber, premierImage, tickicon, validityimage, whatsappicon, whatsappimage, GreyTick } from "../../Components/Kalyanlite/assets/images";
import axios from "axios";
import config from "../../config";
import EndPoints from "../../Constants/EndPoints";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants from "../../Constants/constants";
import { convertToCurrency } from "../../Utils";
import { Coupen_Code } from "../../Helpers/api_helpers";
import { CommonPopup, CommonValidPopup } from "../../Components/CommonPopup";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";


const PaymentPage = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState("21");
    const [selectedAddOnPack, setSelectedAddOnPack] = useState(["23"])
    const [paymentData, setPaymentData] = useState(null);
    const [coupenData, setCoupenData] = useState(null)
    const { loginDetail, logout } = useContext(LoginContext);
    const [isPaynowLoading, setIsPaynowLoading] = useState(false)
    const [isCoupenLoading, setIsCoupenLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const location = useLocation();
    const paymentStatus = searchParams.get("paymentstatus");
    // Reference for the first div

    // State to store the width of the first div

    const [showUploadPhotoPopup, setShowUploadPhotoPopup] = useState(location.state?.isFrom == "previous")


    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
    const whatsaAppPack = paymentData ? paymentData?.AddonPackages?.find(addonPack => addonPack.membershipId == "23") : null;
    const AstroMatchAddonPack = paymentData ? paymentData?.AddonPackages?.find(addonPack => addonPack.membershipId == "8") : null;
    const selectedPack = paymentData && selectedPlanId ? paymentData.subscriptionPackages?.find(pack => pack.membershipId == selectedPlanId) : null;
    const discountCode = paymentData?.discountCode ? paymentData?.discountCode[paymentData?.discountCode?.length - 1] : null;





    const getCoupen = async () => {
        setIsCoupenLoading(true)
        Coupen_Code({
            email: loginDetail()[0],
            userId: loginDetail()[1],
            pack: [selectedPlanId, ...selectedAddOnPack],
            discountCode: discountCode?.amount
        }).then(response => {
            setIsCoupenLoading(false)
            if (response.status == 200) {
                setCoupenData(response.data);
                return response.data;
            }
        })
    }

    const handlePayNowClick = (pack) => {
        onPackSelect(true, pack)
        setIsPopupVisible(true);
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    const onPackSelect = (checked, pack) => {
        if (checked) {
            setSelectedPlanId(pack.membershipId);
            if (pack.whatsaAppPack != "Included") {
                setSelectedAddOnPack([whatsaAppPack.membershipId])
            } else {
                setSelectedAddOnPack([]);
            }
        } else {
            setSelectedPlanId(null);
            setSelectedAddOnPack([]);
        }
    }

    const onAddonPackSelect = (e, pack, addOnPack) => {
        if (e.target.checked) {
            setSelectedAddOnPack(prev => [...new Set([...prev, addOnPack.membershipId])]);
            if (pack) {
                setSelectedPlanId(pack.membershipId);
            }
        } else {
            setSelectedAddOnPack(prev => prev.filter(id => id != addOnPack.membershipId));
        }
    }

    useEffect(() => {
        if (selectedAddOnPack || selectedPlanId) {
            getCoupen()
        }
    }, [selectedAddOnPack, selectedPlanId])


    useEffect(() => {
        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
        }
        axios.post(`${config.api.API_URL}${EndPoints.membershipdetailsafterregistration()}`, request)
            .then(response => {
                if (response.data?.statusCode == 200) {
                    setPaymentData(response.data.data);
                } else if (response.data?.statusCode === 401) {
                    logout();
                }
            })
    }, [])
    console.log({ paymentData, selectedAddOnPack, selectedPlanId });


    const RupeeSymbol = () => <span className="rupeefont">₹</span>;

    const TableData = ({ children, pack, className = "" }) => {
        return <td className={` border items-center border-[#D5D5D5] text-center text-[12px] py-3 ${className}`} >
            {children}
        </td>
    }

    const TableDataCell = ({ flag, pack, column = "", label }) => {
        if (flag === "Included") {
            return <TableData pack={pack}> <div className="flex items-center justify-center">--</div></TableData>;
        }

        if (column == "price") {
            return <TableData pack={pack} className="align-baseline">
                <div className="flex flex-col md:items-center md:justify-center text-[#D71920] font-semibold text-[17px] font-segeo whitespace-nowrap">
                    <div className="flex flex-col md:flex-row items-center justify-center md:space-x-2">
                        <input
                            value={pack.membershipId}
                            checked={selectedPlanId == pack.membershipId}
                            onChange={(e) => onPackSelect(true, pack)}
                            className="accent-[#D10A11]"
                            id="pack"
                            type="radio" />
                        <div className="w-min flex flex-col items-center">

                            {pack?.topprice && <span className="line-through text-[#545454]">
                                ₹ {convertToCurrency(pack?.topprice)}{" "}
                            </span>}
                            <div className="flex items-center space-x-2">
                                <span className={`${pack?.strikePrice ? "line-through text-[#545454]" : ""}`}>
                                    ₹ {convertToCurrency(pack?.price)}
                                </span>
                            </div>
                            {pack?.strikePrice && <div className="flex items-center space-x-2">
                                <span>
                                    ₹ {convertToCurrency(pack?.strikePrice)}{" "}
                                </span>
                            </div>}
                        </div>
                    </div>
                </div>
            </TableData>;
        }

        if (flag === "Notincluded" && column == "whatsapp") {
            return <TableData pack={pack}>
                <div className="flex items-center justify-center text-[#D71920] font-semibold text-[17px] font-segeo whitespace-nowrap">
                    <div className="flex flex-col md:flex-row items-center space-x-2">
                        <input
                            value={whatsaAppPack.membershipId}
                            checked={selectedAddOnPack.includes(whatsaAppPack.membershipId) && selectedPlanId == pack.membershipId}
                            onChange={(e) => onAddonPackSelect(e, pack, whatsaAppPack)}
                            id="addonpack"
                            className="paymentCheckbox hidden md:block"
                            type="checkbox" />
                        <span className={` mr-2 block whitespace-nowrap}`}>
                            ₹ {convertToCurrency(whatsaAppPack?.price)}
                        </span>
                    </div>
                </div>
            </TableData>;
        }


        return <TableData pack={pack} className={label ? "align-baseline" : ""}>
            <div className="flex flex-col items-center justify-center space-y-1">
                {flag == "NA"
                    ? <img alt="" className="w-5 h-5" src={cancelIcon} />
                    : flag == "Limited"
                        ? <img alt="" className="w-5 h-5" src={GreyTick} />
                        : <img alt="" className="w-5 h-5" src={tickicon} />}
                {!label
                    ? null
                    : <p dangerouslySetInnerHTML={{
                        __html: label.replace(
                            "Unlimited",
                            `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                        ),
                    }} className="text-[#7E7E7E] font-Poppins-Regular text-[11px] sm:text-[12px] md:text-[14px] text-center"></p>}
            </div>
        </TableData>

    }

    const TableRowHeaderCell = ({ label = "", icon, textClassName = "text-[#7D7D7D]" }) => {
        return <td className="text-[12px]  py-3 z-10 bg-white">
            <div className="flex flex-col justify-center items-center h-full space-y-1">
                <img alt="" className="w-5 h-5 md:w-[2rem] md:h-[2rem]" src={icon} />
                <p className={`text-center font-semibold md:text-[14px] ${textClassName}`}>{label}</p>
            </div>
        </td>
    }

    const getTotalAmount = () => {
        const addOnPackAmount = selectedAddOnPack?.map(addonPackId => paymentData?.AddonPackages.find(pack => pack.membershipId == addonPackId))
            .reduce((accumulator, pack) => {
                return accumulator + pack.price;
            }, 0)
        return parseInt(selectedPack.price) + parseInt(addOnPackAmount)
    }

    const onPayNow = () => {
        let request = {
            "pack": [
                selectedPlanId,
                ...selectedAddOnPack
            ],
            "discount": discountCode?.amount
        }
        setIsPaynowLoading(true);
        axios.post(`${config.api.API_URL}${EndPoints.getpaymentrequestwebafterregistration()}`, request)
            .then(response => {
                setIsPaynowLoading(false);
                if (response.status == 200) {
                    window.location.href = response.data.data?.paymentToken;
                } else if (response.status === 401) {
                    logout();
                }
            })
    }

    const navigateToFamilyPage = () => {
        if (paymentStatus === "failure") {
            searchParams.delete("paymentstatus")
            return setSearchParams(searchParams, { replace: true })
        }
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.FAMILY_PAGE)
        sessionStorage.removeItem(Constants.loginLocalStorageKeys.paymentPage)
        navigate(RouteSegments.FAMILY_PAGE, {
            replace: true,
        });
    }

    const PaymentSummary = () => {
        return <div className={"fixed bottom-0 left-0 right-0 md:max-h-[20rem] md:h-fit md:w-[35rem] xl:w-[40rem] md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2  bg-[#ffffff] z-50  p-4   rounded-tr-[35px] rounded-tl-[35px] md:rounded-lg shadow-lg"}
            style={{ boxShadow: '0 -11px 12px -3px #00000029' }}>
            <button
                className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-0 md:top-[-6px] md:right-[-6px]"
                onClick={closePopup}
            >
                <img className="w-6 h-6 md:w-5 md:h-5" src={CloseIcon} alt="" />
            </button>
            {isCoupenLoading
                ? <div className="h-[10rem] flex items-center justify-center">
                    <Loader />
                </div>
                : <>
                    <div className="grid grid-cols-2 gap-2">
                        <div className="flex p-2 shadow-lg items-center justify-between">
                            <input
                                value={whatsaAppPack.membershipId}
                                checked={selectedAddOnPack.includes(whatsaAppPack.membershipId)}
                                onChange={(e) => onAddonPackSelect(e, null, whatsaAppPack)}
                                id="addonpack"
                                className="paymentCheckbox"
                                type="checkbox" />
                            <img alt="" className="w-5 h-5 md:w-[2rem] md:h-[2rem] ml-1.5 md:ml-4" src={whatsappicon} />
                            <p className=" text-[9px] md:text-[14px] xl:text-[16px] text-center">
                                Get  Matching Profile Via Whatapp
                                <span className={`font-bold mr-2 block whitespace-nowrap text-[#D10A11]`}>
                                    Just At  ₹ {convertToCurrency(whatsaAppPack?.price)}
                                </span>
                            </p>
                        </div>
                        <div className="flex p-2 shadow-lg items-center justify-between">
                            <input
                                value={AstroMatchAddonPack.membershipId}
                                checked={selectedAddOnPack.includes(AstroMatchAddonPack.membershipId)}
                                onChange={(e) => onAddonPackSelect(e, null, AstroMatchAddonPack)}
                                id="addonpack"
                                className="paymentCheckbox"
                                type="checkbox" />
                            <img alt="" className="w-5 h-5 md:w-[2rem] md:h-[2rem] ml-1.5 md:ml-4" src={horoimage} />
                            <p className="text-center text-[9px] md:text-[14px] xl:text-[16px]">{AstroMatchAddonPack?.message}
                                <span className={`font-bold mr-2 block whitespace-nowrap text-[#D10A11]`}>
                                    Just At  ₹ {convertToCurrency(AstroMatchAddonPack?.price)}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="space-y-3 ">
                        <div className="justify-start items-start grid grid-cols-12  mt-[1rem] ">
                            <div className=" col-span-5 flex justify-start text-[#575556] text-[13px] xl:text-[16px] font-semibold whitespace-nowrap">
                                <p className="whitespace-normal overflow-hidden text-ellipsis"> Selected Pack </p>
                            </div>
                            <div className="col-span-7 flex justify-start items-center">
                                <p className="font-bold  text-[#000000] text-[14px] xl:text-[17px]">
                                    {selectedPack?.membershiptype} - ₹{convertToCurrency(selectedPack?.price)}
                                </p>
                            </div>
                        </div>
                        <div className=" grid grid-cols-12 mt-[2rem]">
                            <div className=" col-span-5 flex justify-start text-[#575556] text-[13px] xl:text-[16px] font-semibold whitespace-nowrap">
                                <p className="whitespace-normal overflow-hidden text-ellipsis"> Selected add-on</p>
                            </div>

                            <div className=" col-span-7">
                                {selectedAddOnPack?.map(addonPackId => {
                                    const addonPack = paymentData?.AddonPackages.find(pack => pack.membershipId == addonPackId)
                                    return <p className="font-bold  text-[#000000] text-[14px] xl:text-[17px]">
                                        {addonPack?.membershiptype} - ₹{convertToCurrency(addonPack?.price)}
                                    </p>
                                })}
                            </div>
                        </div>

                        <div className=" grid grid-cols-12 mt-1 ">
                            <div className=" col-span-5 flex justify-start items-center text-[#575556] font-semibold text-[13px] xl:text-[16px] whitespace-nowrap">
                                <p className="whitespace-normal overflow-hidden text-ellipsis">  Total amount   </p>
                            </div>
                            <div className=" col-span-7 flex justify-start items-center font-bold text-[14px] xl:text-[17px]">
                                {selectedPack?.strikePrice && <span className="text-[#000000] text-[15px] xl:text-[16px] font-bold line-through text-[#545454] mr-2 ">
                                    <span className="rupeefont">₹ </span>{convertToCurrency(getTotalAmount())}
                                </span>}
                                <span className="text-[#000000] text-[15px] xl:text-[17px] font-bold mr-2 ">
                                    <span className="rupeefont">₹ </span>{convertToCurrency(coupenData?.data?.status == "empty"
                                        ? getTotalAmount()
                                        : coupenData?.data?.amount)}
                                </span>
                                {/* <span className="gap-2">{coupenCodeResponse?.amount && (

                            <span className=" font-bold  text-[#000000] text-[15px]  line-through  ">
                                <span className="rupeefont">₹ </span>{convertToCurrency(selectedPackageAmount)}
                            </span>

                        )}
                            <span className="font-bold  text-[#000000] text-[15px] ">
                                {" "}
                                <span className="rupeefont">₹ </span>{" "}
                                {convertToCurrency(
                                    coupenCodeResponse?.amount
                                        ? coupenCodeResponse.amount
                                        : selectedPackageAmount
                                )}{" "}

                            </span>
                        </span> */}


                            </div>

                        </div>
                    </div>

                    <div className=" flex justify-center pt-1 mb-[0.6rem] ">
                        {/* {isDefaultSelectedId ==
                    premierPack[0]?.membershipId || isAddOnSelect || isOfflineAddOnSelect ? (
                    <div className="flex justify-end items-end h-[3rem]">
                        <OverflowToolTip as="button"
                            onClick={() => {
                                paynowClick();
                                onClickTrack(EventName.SelectPaynowButton);
                            }}
                            className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                        >
                            {trans('payment.pay_now')}
                        </OverflowToolTip>
                    </div>
                )
                    : (
                        <div className="flex justify-end items-end h-[3rem]">
                            <OverflowToolTip as="button"
                                // onClick={payNowPopupClicked}
                                onClick={() => {
                                    payNowPopupClicked();
                                    onClickTrack(EventName.SelectPaynowButton);
                                }}
                                className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                            >
                                {trans('payment.pay_now')}
                            </OverflowToolTip>
                        </div>
                    )
                } */}

                    </div>
                    <div className="flex justify-center">
                        <button
                            onClick={onPayNow}
                            className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                        >
                            Pay Now
                        </button>
                    </div>
                </>}
        </div>
    }

    return (
        <>
            {showUploadPhotoPopup && <CommonValidPopup
                close={() => setShowUploadPhotoPopup(false)}
                title={"Alert!"}
                content={`You cannot go to the previous page.  Choose a pack or click "Skip" to continue`} />
            }
            {paymentStatus && <CommonPopup title={paymentStatus === "success" ? "Transaction Success" : "Transaction Failed"} close={navigateToFamilyPage}>
                <div className="space-y-2">
                    <p className='w-[100%] text-center'>
                        {paymentStatus === "success" ? "Payment Success" : "Please try again later"}
                    </p>
                    {paymentStatus === "success" && <div className="flex justify-center mt-[20px]">
                        <button
                            onClick={navigateToFamilyPage}
                            className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                        >
                            Continue
                        </button>
                    </div>}
                </div>
            </CommonPopup>}
            <div className="bg-white sticky top-0 z-50">
                <div className="flex justify-between items-center pr-4 md:px-[5.75rem] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
                    <img src={KMLogo} className="h-12 md:h-[4rem] lg:h-[3.5rem]" alt="" />
                    {/* <button
                        onClick={navigateToFamilyPage}
                        type="button" className="cursor-pointer  text-[#7F7F7F] text-[14px] underline">Skip</button> */}
                </div>
            </div>
            {paymentData
                ? <div className="flex-grow md:mx-[5.75rem] md:mt-[3rem] md:mb-[3rem] md:shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
                    <div className="font-Poppins bg-[#FFFFFF]">
                        <div className="p-2 md:p-4">
                            <p className="text-[#D10A11] text-[12px] md:text-[15px] font-bold">
                            Buy a Calling Pack to connect with profiles!
                            </p>
                            <p className="text-[#565656] text-[12px] md:text-[14px]">With calling pack, you can also view horoscopes and chat with matches.</p>
                        </div>
                            <table className='w-full rounded-b border-collapse border border-[#D5D5D5]'>

                                <thead className="bg-white border border-[#D5D5D5">
                                    <tr className="">
                                        <th className="text-[#D10A11] px-1 md:px-2 text-[12px] md:text-[16px] border border-[#D5D5D5] text-center py-2 md:py-4  bg-white">
                                            Packs & Benefits
                                        </th>
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <th
                                                key={pack.membershipId}
                                                className={`text-[#000000]  text-[11px] md:text-[16px] border border-[#D5D5D5] text-center py-2 md:py-4 ${pack.membershipId == "1" ? "" : ""}`}>
                                                <div className="flex flex-col items-center justify-center">
                                                    {pack.membershipId == "22" && <img alt="premier pack" src={premierImage} className="w-5 h-5" />}
                                                    {pack.membershiptype}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="View Matches"
                                            icon={matchesimage}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                flag={pack.viewProfileFlag}
                                                pack={pack} />
                                        ))}
                                    </tr>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="Matrimony Advisor"
                                            icon={advisorimage}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                label={pack.advisorsupport}
                                                key={pack.membershipId}
                                                flag={pack.advisorFlag}
                                                pack={pack} />
                                        ))}
                                    </tr>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="Validity"
                                            icon={validityimage}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                column="matrimonyAdvisor"
                                                flag={pack.validityFlag}
                                                label={pack.validity}
                                                pack={pack} />
                                        ))}
                                    </tr>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="Phone Numbers"
                                            icon={phonenumber}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                pack={pack}
                                                key={pack.membershipId}
                                                label={pack.viewPhoneNumber}
                                                flag={pack.viewPhoneNumberFlag} />
                                        ))}

                                    </tr>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="Chats"
                                            icon={chatimage}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                label={pack.sendMessage}
                                                pack={pack}
                                                flag={pack.chatFlag} />
                                        ))}
                                    </tr>
                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="Horoscopes"
                                            icon={horoimage}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                pack={pack}
                                                label={pack.sendHoroscope}
                                                flag={pack.sendHoroscopeFlag} />
                                        ))}
                                    </tr>

                                    <tr className="border border-[#D5D5D5] ">
                                        <TableRowHeaderCell
                                            label="WhatsApp Pack"
                                            icon={whatsappicon}
                                        />
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                column="whatsapp"
                                                flag={pack.whatsaAppPack}
                                                pack={pack} />
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={`border border-[#D5D5D5] py-2 px-2 text-center text-[12px] font-semibold md:text-[14px]  bg-white md:static `}> Price</td>
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            <TableDataCell
                                                key={pack.membershipId}
                                                column="price"
                                                flag={pack.price}
                                                pack={pack} />
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="border border-[#D5D5D5] py-2 px-2   text-[12px] bg-white"> </td>
                                        {paymentData?.subscriptionPackages?.map(pack => (
                                            pack.price == "Included"
                                                ? <td className={` border items-center border-[#D5D5D5] text-center  text-[12px] py-3`}>
                                                    <button onClick={navigateToFamilyPage} className="bg-[#6A6A6A] text-center text-[9px] text-white rounded-[5px] font-bold whitespace-nowrap px-2 py-1.5">Continue</button>
                                                </td>
                                                : <td  className={` border items-center border-[#D5D5D5] text-center  text-[12px] py-3 `}>
                                                    <button
                                                        onClick={() => handlePayNowClick(pack)}
                                                        className={`bg-[#D10A11] text-[9px] text-center text-white rounded-[5px] font-bold  whitespace-nowrap px-2 py-1.5`}>Pay Now</button>
                                                </td>

                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        <div className="flex justify-end mt-5 mx-[1rem] md:mx-[4rem] lg:mx-[4rem] pb-6">
                            <button
                                onClick={navigateToFamilyPage}
                                type="button" className="cursor-pointer pt-2 text-[#7F7F7F] text-[14px] underline">Skip</button>
                        </div>
                        {isPopupVisible && <div className="h-[300px] md:hidden" />}
                        {isPopupVisible && (
                            <>
                                <div className="hidden md:block fixed inset-0 z-50 items-end justify-center overflow-x-hidden overflow-y-auto outline-none md:bg-opacity-50 md:bg-black md:items-center focus:outline-none">
                                    <div className="relative  md:my-6 md:mx-6 rewardpopup" style={{ height: '-webkit-fill-available' }}>
                                        <PaymentSummary />
                                    </div>
                                </div>
                                <div className="block md:hidden">
                                    <PaymentSummary />
                                </div>
                            </>
                        )}
                        {/* } */}
                    </div>
                </div >
                : <Loader />}
            {
                isPaynowLoading ? (
                    <Loader
                        className="loaderTransperancy"
                        loaderClassName="loaderTransperancySpin"
                    />
                ) : (
                    ""
                )
            }
        </>




    );
};
export default PaymentPage;
