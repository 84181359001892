import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActivityList } from '../../Store/KalyanRewards/Action'
import Loader from '../Loader'
import * as Images from "../Kalyanlite/assets/images"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RouteSegments from '../../Routes/RouteSegment'
import { useAnalytics } from '../../Hooks/usePageChange'
import { EventName } from '../../Constants/constants'

const BulletPoints = ({ text, className }) => {
    return <div className={`flex gap-4 ${className}`}>
        <p className="bg-[#4C5B71] w-4 h-4 md:w-4 md:h-4 rounded-[5rem] mt-[.2rem] shrink-0"></p>
        <p className='text-[#707070] font-semibold italic'>{text}</p>
    </div>
}

export default function KalyanRewards() {
    const dispatch = useDispatch()
    const activityList = useSelector(state => state?.KalyanRewards?.activityList)
    const isActivityListLoading = useSelector(state => state?.KalyanRewards?.isActivityListLoading)
    const totalPonits = useSelector(state => state?.KalyanRewards?.totalPonits)

    const { t:trans } = useTranslation();
    const navigate = useNavigate();
    const { onClickTrack } = useAnalytics();



    useEffect(() => {
        dispatch(getActivityList())
    }, [])

    const RupeeSymbol = () => <span className="rupeefont"> ₹</span>;


    console.log({ activityList });
    return isActivityListLoading
        ? <Loader />
        : (
            <div>
                <div className='flex items-center my-4'>
                    <img src={Images.KalyanCoin} className='w-[5rem] h-[5rem] md:w-[8rem] md:h-[8rem] mr-4' alt='' />
                    <div className=''>
                        <p className='text-[#000000] font-segeo font-bold text-[16px] lg:text-[20px]'>{trans('my_profile.your_kalyan_reward_points')}</p>
                        <div className="flex items-center gap-4">
                            <p className='text-[#D10A11] font-segeo font-bold text-[24px] lg:text-[28px]'>{totalPonits}</p>
                            <div className='flex justify-center items-center'>
                                <button 
                                    disabled={totalPonits < 1000}
                                    onClick={(e) =>{
                                        onClickTrack(EventName.My_Profile_Kalyan_Rewards_Redeem_Points)
                                        navigate(RouteSegments.MEMBERSHIP_REGISTER_LITE,{ state :{ isFrom : "Redeem"}})
                                    }} 
                                    className={`flex flex-col items-center justify-center bg-[#D10A11] rounded-lg py-2 px-[2rem] ${totalPonits < 1000 ? "opacity-30" : ""}`}>
                                    <p className='text-[12px] text-white font-extrabold'>{trans('my_profile.redeem_pointscoming_soon')}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className='py-1 flex  bg-[#D10A11] justify-center items-center mt-4'>
                    <p className='text-white text-[16px] md:text-[20px] lg:text-[18px] font-bold'>{trans('my_profile.complete_each_activity_and_get')}</p>
                </div>

                <div className="py-2">
                    <div className={`  flex items-center justify-between px-4 py-2 bg-[#DADADAE0]`}>
                        <div className={"text-[#000000] text-[14px] md:text-[18px] lg:text-[20px] font-bold"}>{trans('my_profile.activity')}</div>
                        <div className={"text-[#000000] text-[14px] md:text-[18px] lg:text-[20px] font-bold"}>{trans('my_profile.points')} <span className="text-[#D10A11] font-bold"> *</span></div>
                    </div>
                    {activityList.map((value, index) => {
                        return (
                            <div className={`flex items-center justify-between px-4 py-2 ${(index + 1) % 2 == 0 ? "bg-[#DADADAE0]" : "bg-[#E9E9E9]"}`}>
                                <div className={`${value?.name?.toLowerCase() == "free member" ? "text-black" : "text-[#575556]"}  text-[12px] md:text-[16px] lg:text-[16px] font-bold`}>{value.name}</div>
                                {/* {value?.id == 9 || value?.id == 10 || value?.id == 11
                                    ? <div className='text-[#575556] text-[12px] md:text-[16px] lg:text-[16px]'>Coming soon</div>
                                    :  */}
                                    <div className={`${value?.name?.toLowerCase() == "free member" ? "text-black" : "text-[#575556]"}  text-[12px] md:text-[16px] lg:text-[16px] font-bold`}>{value.points}{value.title == "Activity" ? <span className="text-[#D10A11] font-bold"> *</span> : " Points"}</div>
                                {/* } */}
                            </div>
                        )
                    })}
                </div>

                {/* <BulletPoints className="mt-4" text="Each Kalyan Reward Point is worth ₹ 2 that can be claimed against any purchase in Kalyan Matrimony" />
                <BulletPoints className="mb-4" text="Note: Two offers cannot be combined together" /> */}


                {/* <div className="flex items-center py-6">
                    <div className="flex gap-4">
                        <p className="bg-[#4C5B71] w-8 h-4 md:w-4 md:h-4 rounded-[5rem] mt-[.2rem]"></p>
                        <div className="flex flex-col text-[#707070] font-semibold italic">
                            <div className="hidden md:block lg:block">
                                <p>Note: The points needs to be redeemed on or before</p>
                                <p>(end of this months +30days)</p>
                            </div>
                            <div className="block md:hidden lg:hidden">
                                <p>Note: The points needs to be redeemed on or before (end of this months +30days)</p>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className='grid md:flex justify-between items-center py-4 w-full gap-4 md:gap-0 lg:gap-0'>
                    <div className='pt-[2rem]'>
                        <p className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>Your Kalyan Rewards Points</p>
                        <p className='text-[#D10A11] font-bold text-[2rem] md:text-[2rem] lg:text-[3rem]'>{totalPonits}</p>
                    </div>
                    <div>
                        <div className="grid grid-cols-3 gap-2">
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>Redeemed till now</div>
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>:</div>
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>25<span className="text-[#D10A11] font-bold text-[12px] md:text-[16px] lg:text-[20px]"> *</span></div>
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>Balance</div>
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>:</div>
                            <div className='text-[#000000] font-bold text-[12px] md:text-[16px] lg:text-[20px]'>25 Points</div>
                        </div>
                    </div>

                </div> */}

                <div className="flex justify-center py-4 px-4 md:px-4 lg:px-0 gap-4 md:gap-4 lg:gap-8">
                    <div className="bg-[#CD7F32] w-[12rem] rounded-[.5rem] flex flex-col gap-4 pt-4 md:pt-4 lg:pt-8">
                        <p className="text-white text-center font-bold text-[10px] md:text-[12px] lg:text-[16px] px-2 md:px-2 lg:px-4">
                        {trans('my_profile.earn_10003000_points_and_get_f')}
                        <RupeeSymbol />
                        {trans('my_profile.250_discount_on_all_packs')} 
                        </p>
                        <img src={Images.CoinShadow} className='w-auto h-auto' alt='' />
                    </div>
                    <div className="bg-[#C0C0C0] w-[12rem] rounded-[.5rem] flex flex-col gap-4 pt-4 md:pt-4 lg:pt-8">
                        <p className="text-black text-center font-bold text-[10px] md:text-[12px] lg:text-[16px] px-2 md:px-2 lg:px-4">
                        {trans('my_profile.earn_30005000_points_and_get_f')}  
                        <RupeeSymbol />
                        {trans('my_profile.500_discount_on_all_packs')}
                        </p>
                        <img src={Images.CoinShadow} className='w-auto h-auto' alt='' />
                    </div>
                    <div className="bg-[#FDC300] w-[12rem] rounded-[.5rem] flex flex-col gap-4 pt-4 md:pt-4 lg:pt-8">
                        <p className="text-black text-center font-bold text-[10px] md:text-[12px] lg:text-[16px] px-2 md:px-2 lg:px-4">
                        {trans('my_profile.earn_5000__points_and_get_flat')}
                        <RupeeSymbol />
                        {trans('my_profile.1000_discount_on_all_packs')}
                        </p>
                        <img src={Images.CoinShadow} className='w-auto h-auto' alt='' />
                    </div>
                </div>

                <div className='flex justify-center items-center mt-8'>
                    <button 
                        disabled={totalPonits < 1000}
                        onClick={(e) =>{
                            onClickTrack(EventName.My_Profile_Kalyan_Rewards_Redeem_Points)
                            navigate(RouteSegments.MEMBERSHIP_REGISTER_LITE,{ state :{ isFrom : "Redeem"}})
                        }} 
                        className={`flex flex-col items-center justify-center bg-[#D10A11] rounded-lg py-2 px-[4rem] ${totalPonits < 1000 ? "opacity-30" : ""}`}>
                        <p className='text-[12px] text-white font-extrabold'>{trans('my_profile.redeem_pointscoming_soon')}</p>
                    </button>
                </div>

            </div>
        )
}
