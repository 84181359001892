import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { LoginContext } from '../Contexts/LoginContext'
import Constants from '../Constants/constants';
import RouteSegments from './RouteSegment';


function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(LoginContext);
  const location = useLocation();
  if (!isLoggedIn()) {
    return <Navigate to="/" state={{ from: location.pathname }} />
  }else if(sessionStorage.getItem("photoPage")){
    return <Navigate replace={true} to={"/uploadPhotoPage"} state={ { isFrom : "previous" } }/>
  } else if(sessionStorage.getItem(Constants.loginLocalStorageKeys.paymentPage) && location.pathname != RouteSegments.PAYMENT_PAGE){
    return <Navigate replace={true} to={RouteSegments.PAYMENT_PAGE} state={ { isFrom : "previous" } }/>
  }
  return children;
}
export default PrivateRoute;