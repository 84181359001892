import React, { useState, useContext, useEffect, memo, useRef } from "react";

import { useTranslation } from 'react-i18next';

import { POST ,GET} from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import Constants, {
  EditProfileMenu,
  emailid,
  EventName,
  EventKeys,
  InboxFilters,
  MenuFunction,

} from "../Constants/constants";
// import { useAnalytics } from "../Hooks/usePageChange";
import MatchesIcon from "../Images/Group 534.svg";
import MatchesIconRed from "../Images/Group 534 red.svg";
import SearchIcon from "../Images/Group 535.svg";
import SearchIconRed from "../Images/Group 535 red.svg";
import InboxIcon from "../Images/Group 536.svg";
import InboxIconRed from "../Images/Group 536 red.svg";
import EditProfileIcon from "../Images/Group 407.svg";
import EditProfileIconRed from "../Images/Group 407 red.svg";
import NotificationIcon from "../Images/Group 653.svg";
import NotificationIconRed from "../Images/Group 653 red.svg";
import PaymentIcon from "../Images/Group 6.svg";
import PaymentIconRed from "../Images/Group 6 red.svg";
import PaymentIconWhite from "../Images/Group 6 white.svg";
import KMLogo from "../Images/kalyanlogo123.png";
import KalyanLogo from "../Images/KM logo.png";
import HelpIcon from "../Images/Group 537.svg";
import ProfileIcon from "../Images/profile.svg";
import DrawerIcon from "../Images/Group 217.svg";
import NotificationMobIcon from "../Images/Group 219.svg";
import ChatIcon from "../Images/Group 221.svg";
import SearchIconGrey from "../Images/Group 535.svg";
import MultiUserIcon from "../Images/Group 534.svg";
import $ from "jquery";
import AppStoreIcon from "../Images/appstorebadge.svg";
import GPlayIcon from "../Images/googleplaystorebadge.svg";

import MatchIconRed from "../Components/Kalyanlite/assets/Images/Group 1930.svg";
import MatchIconGrey from "../Components/Kalyanlite/assets/Images/matchesgreyicon.svg";

import FilterIconGrey from "../Components/Kalyanlite/assets/Images/Group 1931.svg";
import FilterIconRed from "../Components/Kalyanlite/assets/Images/filtericonred.svg";

import ChatIconGrey from "../Components/Kalyanlite/assets/Images/Group 1932.svg";
import ChatIconRed from "../Components/Kalyanlite/assets/Images/chaticonred.svg";

import CallIconGrey from "../Components/Kalyanlite/assets/Images/Group 1934.svg";
import CallIconRed from "../Components/Kalyanlite/assets/Images/calliconred.svg";

import HandIcon from "../Components/Kalyanlite/assets/Images/Group 2169.svg";
import ProfileImageIcon from "../Components/Kalyanlite/assets/Images/Rectangle 911.png";
import ParentCallcon from "../Components/Kalyanlite/assets/Images/Group 2034.svg";
import SelectedIcon from "../Components/Kalyanlite/assets/Images/Group 383.svg";
import HelpChatIcon from "../Components/Kalyanlite/assets/Images/Group 2028.svg";
import SettingsIcon from "../Components/Kalyanlite/assets/Images/Group 2029.svg";
import LogOutIcon from "../Components/Kalyanlite/assets/Images/Group 106.svg";

import ThreeDotIcon from "../Components/Kalyanlite/assets/Images/threedot.svg";
import CamerIcon from "../Components/Kalyanlite/assets/Images/camera.svg";
import MatchIconred from "../Components/Kalyanlite/assets/Images/Group 1930.svg";
import ThreeLineIcon from "../Components/Kalyanlite/assets/Images/Group 3746.svg";
import CircleRupeeIcon from "../Components/Kalyanlite/assets/Images/Group 222.svg";
import MessageIcon from "../Components/Kalyanlite/assets/Images/Group 221.svg";
import BellIcon from "../Components/Kalyanlite/assets/Images/Group 219.svg";
import MenuIcon from "../Components/Kalyanlite/assets/Images/Group 217.svg";
import FamilyGroupicon from '../Components/Kalyanlite/assets/Images/FamilyGroupIcon.svg'
import groupiconred from '../Components/Kalyanlite/assets/Images/groupiconred.svg'
import callingPacks from '../Components/Kalyanlite/assets/Images/callingPack.svg'
import myProfile from '../Components/Kalyanlite/assets/Images/myprofile.svg'
//context
import { LoginContext } from "../Contexts/LoginContext";
import { NavBarContext } from "../Contexts/NavBarContext";
import { NavListContext } from "../Contexts/navContext";
import { useNavigate, useLocation, matchPath } from "react-router";
import RouteSegments, { RouteHelper, RoutePaths } from "../Routes/RouteSegment";
import { BsArrowLeft } from "react-icons/bs";
import { useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import {
  AstroPopup,
  BulkRequestPopup,
  CommonCastPopup,
  CommonConfrimPopup,
  CommonQualityPopup,
  CommonRewardPopup,
  CommonSuccessPopup,
  ImageSuccessPopup,
  LogoutPopup,
  ReligionMigrationPopup,
  UploadPhotoPopup,
  UploadPhotoPopuplite,
} from "../Components/CommonPopup";
import { Badge, Dropdown, Popover, Select } from "antd";
import FilterPanel from "./FilterPanel";
import EndPoints from "../Constants/EndPoints";
import axios from "axios";
import config from "../config";
import useModule from "../Hooks/useModule";
import MobileNavigation from "./Kalyanlite/MobileNavigation";
import MobileSearchIcon from "../Components/Kalyanlite/assets/Images/Group 3.svg";
import kalyanJewellery from "../Images/kalyan_logo.svg"
import { useDispatch, useSelector } from "react-redux";
import SidePopup from "./Kalyanlite/Popup/SidePopup";
import ParentsPhonePopup from "./DashboardComponents/ParentsPhonePopup";
import * as ChatSelector from "../Store/Chats/selectors"
import { CommonParentPopup } from "./CommonPopup";
import * as Images from "../Components/Kalyanlite/assets/images"
import * as MatchSelector from "../Store/KalyanLite/selectors"
import { closeCurrentMatch, getRecommendedFail } from "../Store/KalyanLite/Action";
import usePageChange, { useAnalytics } from "../Hooks/usePageChange";
import SidebarLite from "./SidebarLite";
import ParentsPhonePopupLite from "./DashboardComponents/ParentsPhonePopupLite";
import { getActivityTotalPoints } from "../Store/KalyanRewards/Action";
import MobileSiteLoginPopup from "./Kalyanlite/Popup/MobileSiteLoginPopup";
import { Update_Notification_Status } from "../Helpers/api_helpers";
import { IoLanguage } from "react-icons/io5";
import { languageOptions } from "../i18n";
import i18next from "i18next";
import Tick from "../Components/Kalyanlite/assets/Images/Group 2581.svg";
import LanguageSwitcher from "./Kalyanlite/Popup/LanguageSwitcher";
import OutsideClickListener from "./utils/OutsideClickListener";



const NotificationPopoverContent = ({ setNotificationCount, notificationVisible, setNotificationVisible, close }) => {
  const isV3User = localStorage.getItem("isV3User")
  const navigate = useNavigate();
  const [isNotificationLoading, setIsNotificationLoading] = useState(true);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notificationList, setNotificationList] = useState(null);
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const { isEditProfileMatch, isInbox } = useModule()
  const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })
  const location = useLocation()
  const [showRassipopup, setShowRaasipopup] = useState(false)
  const [showtrailpopup, setShowtrailpopup] = useState(false)

  const { t: trans } = useTranslation();

  console.log(notificationList?.length > 0 ? notificationList?.notificationId : "No notifications", 'notificationId');


  const callBackRedirection = (notificationFor, senderID, subjectId) => {
    console.log("notificationFor", notificationFor);
    if (notificationFor === "Trust") {
      // Trust Document page
      setMenuSelect("4")
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), {
        replace: isEditProfileMatch,
      });
    } else if (notificationFor === "Horoscope") {
      setMenuSelect("5")
      // Horoscope page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), {
        replace: isEditProfileMatch,
      });
    } else if (notificationFor === "Photo") {
      // Gallery page
      setMenuSelect("4")
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
        replace: isEditProfileMatch,
      });
    } else if (notificationFor === "PartnerProfile") {
      // Match View Profile page
      navigate(RouteHelper.getProfileUrl, {
        state: { id: senderID },
        // replace: true,
      });
    } else if (notificationFor === "Admin") {
      // Edit my profile page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), {
        state: { tab: "1" },
        replace: isEditProfileMatch,
      });
    } else if (notificationFor === "Message") {
      // Inbox page All message
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), {
        state: { profileId: senderID, threadId: subjectId, type: "Sent" },
        replace: isInbox,
      });
    } else if (notificationFor === "bulktrigger") {
      bulrequestPopup()
    }

  };

  const [shownotificationID, setShowNotificationID] = useState()

  console.log(showtrailpopup, 'shownotificationID');

  const callBackRedirectionlite = (notificationFor, senderID, subjectId, notificationContent, notification) => {
    const notificationsId = notification?.notificationId
    setShowNotificationID(notificationsId)
    console.log("notificationFor", notificationsId);
    // alert('noti')

    const clcOptions = [
      "AddPhoto",
      "PartnerPreference",
      "Matches",
      "StartMessage",
      "NewMatches",
      "AddParentsNumber",
      "ReadAndReply",
      "UnreadMessage",
      "MsgSeenNotReply",
      "PrivacyOption",
      "Horoscope",
      "StarRaasi"
    ]
    const queryString = new URLSearchParams({
      "path": notificationFor,
      "notificationId": notification?.notificationId,
    }).toString();

    if (notificationFor == "DownloadApp") {
      return window.open(RouteSegments.staticLinkForAppDownload.android)
    } else if (clcOptions.includes(notificationFor)) {

      // if(notificationFor == "PrivacyOption" || notificationFor == "Horoscope"){
      //   const request = {
      //     "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      //     "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      //     "isClicked": true,
      //     "notificationId": notificationsId
      //   }
      //   Update_Notification_Status(request);
      // }

      return navigate(`/new/matches?${queryString}`, {
        replace: true, state: {
          data: {
            notificationId: notificationsId,
          }
        }
      })
    }

    else if (notificationFor == "StarRaasi") {
      // const request = {
      //   "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      //   "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      //   "isClicked": true,
      //   "notificationId": notificationsId
      // }
      // Update_Notification_Status(request);
      sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, notificationsId)

      setShowRaasipopup(true)
      return navigate(`/new/matches?${queryString}`, {
        replace: true, state: {
          data: {
            notificationId: notificationsId,
          }
        }
      })

    }
    if (notificationFor === "Admin") {

      const isTrustDocument = notificationContent.includes("Trust Document added");
      const isHoroscope = notificationContent.includes("Horoscope added by you");
      const isPhoto = notificationContent.includes("Photo");

      if (isTrustDocument) {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), {
          state: {
            tab: "4",
          },

        });

      } else if (isHoroscope) {

        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), {
          state: {
            tab: "5",
            isfrom: "notification"
          },
        });
      } else if (isPhoto) {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
          state: {
            tab: "3",
            isfrom: "notification"
          },

        });
      }
    }

    else if (notificationFor === "Trust") {
      // navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { isFrom: "notification", id: "KM"+senderID ,isFromChat: true } })

      // if (isTrust) {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      },)
      // }

    }
    else if (notificationFor === "Horoscope") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    } else if (notificationFor === "Photo") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    }
    else if (notificationFor === "PartnerProfile") {
      const isParentsnumber = notificationContent.includes("Parents Number Request from");
      if (isParentsnumber) {
        navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
          replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
          state: {
            isFrom: "notification",
            id: "KM" + senderID,
            isFromChat: true,
            from: "newConversations"
          }
        })
      } else {
        navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
          replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
          state: {
            isFrom: "notification",
            id: "KM" + senderID,
            isFromChat: false,
            from: "search"
          }
        })
      }
      setMenuSelect("3")
    } else if (notificationFor === "Message") {

      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    } else if (notificationFor === "bulktrigger") {
      bulrequestPopup()
    }
    else if (notificationFor === "Notspecifiedrequest") {

      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    } 
    else if (notificationFor === "TrialPack") {
      // alert('hi')
      // setShowtrailpopup(true)
      // navigate(RouteSegments.MATCH_LITE, {
      //   replace: RouteSegments.MATCH_LITE == location.pathname,

      // })


      sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, notificationsId)

      setShowtrailpopup(true)
      return navigate(`/new/matches?${queryString}`, {
        replace: true, state: {
          data: {
            notificationId: notificationsId,
          }
        }
      })
    
    }

    else if (notificationFor === "WhoViewedMyprofile") {

      const request = {
        "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
        "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        "isClicked": true,
        "notificationId": notificationsId
      }
      Update_Notification_Status(request);

      navigate(`/new/match-family?WhoViewedMyprofile=Y`, {
        replace: true, state: {
          data: {
            notificationContent: notificationContent,
            notificationId: notification?.notificationId,
            isFrom: "listnotification",
          }
        }
      })
    }

    close()
  };


  useEffect(() => {
    //console.log(menuSelect,"menuSelectsss");
  }, [menuSelect])


  const getNotificationCount = async () => {

    let response = await POST(
      `${config.api.API_URL}${endPoints.notificationCountUrl()}`,
      {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
      }
    );

    if (response.statusCode === 200) {
      setNotificationCount(response?.data?.data?.notificationCount)
    }
  }

  const apiCallNotification = async () => {
    setIsNotificationLoading(true);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
    };
    POST(
      `${config.api.API_URL}${endPoints.notificationUrl()}`,
      request
    ).then(({ data, statusCode }) => {

      if (statusCode === 200) {
        if (data?.data?.notificationData?.length > 0) {
          setNotificationList(data?.data?.notificationData);
        }
      } else if (statusCode === 401) {
        logout();
      }
      setIsNotificationLoading(false);
    })
  };

  useEffect(() => {
    if (notificationVisible) {
      // getNotificationCount()
      apiCallNotification()
    }
    return () => {
      setNotificationVisible(false)
    }
  }, [notificationVisible])

  useEffect(() => {
    setIsNotificationLoading(true);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
    };
    POST(
      `${config.api.API_URL}${endPoints.notificationUrl()}`,
      request
    ).then(({ data, statusCode }) => {

      if (statusCode === 200) {
        if (data?.data?.notificationData?.length > 0) {
          setNotificationList(data?.data?.notificationData);
        }
      } else if (statusCode === 401) {
        logout();
      }
      setIsNotificationLoading(false);
    })
  }, [loginDetail, logout])
  //console.log('notificationList-12',notificationList);

  const bulrequestPopup = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "bulktrigger": true
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequestpopup()}`,
      request
    );

    if (statusCode === 200) {
      if (data?.data?.recomm_count > 0) {
        setShowBulkRequestPopupData({
          show: true,
          title: "Send Request",
          content: "",
          message: data?.data?.bulkrequestcontent,
        })
      }
    } else if (statusCode === 401) {
      logout();
    }
  }

  const closeBulkRequestPopup = () => {
    setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setShowBulkRequestPopupData({
        show: true,
        title: "Send Request",
        content: "Request Sent Successfully",
      })
    }

  }


  const onBulkRequestSend = (status) => {
    if (status) {
      apiCallBulkRequest()
    } else {
      closeBulkRequestPopup()
    }
  }
  const { onClickTrack } = useAnalytics();


  return (
    <div className="">
      {
        showBulkRequestPopupData.show && (
          <BulkRequestPopup
            close={closeBulkRequestPopup}
            onSend={onBulkRequestSend}
            content={showBulkRequestPopupData.content}
            message={showBulkRequestPopupData?.message}
            imageSrc={showBulkRequestPopupData.imageSrc}
            title={showBulkRequestPopupData.title} />
        )
      }
      {
        showRassipopup &&
        <AstroPopup OnClose={() => setShowRaasipopup(false)} notificationListId={shownotificationID} />
      }

       {showtrailpopup &&
        <CommonQualityPopup OnClose={() => setShowtrailpopup(false)} />}

      {
        isNotificationLoading
          ? <div className="w-52">
            <Loader />
          </div>
          : <ul className="text-sm text-gray-700 dark:text-gray-200 divide-y divide-[#F18181]">
            {notificationList?.map(
              (e, i) =>
                i < 9 && (
                  <li className={`hover:bg-gray-100 px-2 dark:hover:bg-gray-600 dark:hover:text-white ${e.isViewed === false ? "bg-[#ffffff]" : "bg-[#fff4f4]"}`}>
                    <div onClick={() => {
                      if (isV3User) {
                        callBackRedirectionlite(
                          e.notificationFor,
                          e.notificationSenderId,
                          e.subjectId,
                          e.notificationContent,
                          e
                        );
                      }
                      else {
                        callBackRedirection(
                          e.notificationFlag,
                          e.notificationSenderId,
                          e.subjectId,
                        );

                      }
                    }} className="block py-1">
                      {e?.notificationContent}
                    </div>
                  </li>
                )
            )}
          </ul>
      }
      <div className="flex justify-end py-2 mx-2">
        <NavLink onClick={() => onClickTrack(EventName.Notifications_Notifications_See_More_Button)} to={{ pathname: isV3User ? RouteSegments.NOTIFICATION_LITE : RouteSegments.NOTIFICATION }} replace className="text-[14px] text-[#D10A11] hover:text-[#D10A11] underline cursor-pointer" >{trans('notifications.see_more')}</NavLink>
      </div>
    </div>
  );
};


const NavBar = ({ ProfileData, data, menu, props }) => {
  const isV3User = localStorage.getItem("isV3User")
  const { loginDetail, logout } = useContext(LoginContext);
  const { menuSelect, setMenuSelect, isPusherReceived, setIsPusherReceived } = useContext(NavBarContext);
  const { navList, setNavList } = useContext(NavListContext);
  const [showLoader, setShowLoader] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  usePageChange()
  const { isInbox, isMatch, isEditProfileMatch, isSearch, isNotifications, isMemberShip } = useModule();
  const [notificationVisible, setNotificationVisible] = useState(false)

  const dispatch = useDispatch()
  const { t: trans } = useTranslation();


  const [showReligiousMigrationPopup, setShowReligiousMigrationPopup] = useState(false)

  // const [showcastepopup,setShowCastePopup] = useState(false)


  // const [menuAction, setMenuAction] = useState({
  //   matches: true,
  //   search: false,
  //   inbox: false,
  //   editProfile: false,
  //   notification: false,
  //   paynow: false,
  // });
  const [profileDropdown, setProfileDropdown] = useState(false);
  // const [notificationDropdown, setNotificationDropdown] = useState(false);
  // const { menuAction, setMenuAction } = MenuFunction();
  // const [notificationList, setNotificationList] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0)

  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [navbarhelp, setnavbarhelp] = useState(false)
  const { openDrawer, isOpenFilter } = useContext(NavBarContext)
  const [successImagePopup, setSuccessImagePopup] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const { onClickTrack } = useAnalytics();

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  useEffect(() => {
    localStorage.removeItem(Constants.loginLocalStorageKeys.allowAfterRegPage)
    localStorage.removeItem(Constants.loginLocalStorageKeys.loginCurrentPage)
  }, [])


  const [navbarClear, setnavbarClear] = useState("dash")
  const getNotificationCount = async () => {

    let response = await POST(
      `${config.api.API_URL}${endPoints.notificationCountUrl()}`,
      {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
      }
    );

    if (response.statusCode === 200) {
      setNotificationCount(response?.data?.data?.notificationCount)
    }
  }

  useEffect(() => {
    // apiCallNotification();
    getNotificationCount()
    // return ()=>{
    //   setIsPusherReceived(false)
    // }
  }, []);
  // useEffect(() => {}, [notificationList]);
  const apiLogin = async () => {
    let loginRequest = {
      email: emailid,
      password: "123123123",
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      ioskey: "",
      androidkey: "",
    };

    let response = await POST(
      `${config.api.API_URL}${endPoints.loginUrl()}`,
      loginRequest
    );
    // //console.log("cookiee", response);
  };

  //signout function
  const logoutUsignAPI = async () => {
    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        familyloginid: localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId) ?? undefined
      },
    });

    // let chatResponse = await axios.post(
    //   `${config.api.chat_api}${EndPoints.logout()}`,
    //   {
    //     id: localStorage.getItem(Constants.loginLocalStorageKeys.chatLoginId),
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem(
    //         Constants.loginLocalStorageKeys.chatLogin,
    //       )}`,
    //     },
    //   },
    // );

    //signout chat session

    console.log(data, "datadatadatadata");
    if (data.data.status === "Success") {
      setShowLoader(false);
      logout();
    }
  };

  const signout = () => {
    if (ProfileData?.myphoto === "N") {
      setShowUploadImagePopup(true)
    } else {
      setShowLoader(true)
      logoutUsignAPI();
    }
  };
  // const apiCallNotification = async () => {
  //   setIsNotificationLoading(true);
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //   };

  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.notificationUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     if (data?.data?.notificationData?.length > 0) {
  //       setNotificationList(data?.data?.notificationData);
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  //   setIsNotificationLoading(false);
  // };

  const ProfileDropdown = () => {
    const isV3User = localStorage.getItem("isV3User")
    const onSwitchClick = () => {
      if (isV3User) {
        localStorage.removeItem("isV3User")
        navigate(RouteSegments.DASHBOARD)
      } else {
        localStorage.setItem("isV3User", "true")
        navigate(RouteSegments.MATCH_LITE)
      }
    }
    return (
      <>
        {showLoader ?
          // <Loader className="h-[33rem]"/>
          <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
          :
          <div
            id="dropdown"
            className={`${profileDropdown ? "" : "hidden"
              } z-10 absolute  right-4 top-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <a
                  onClick={() => {
                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), { state: { tab: "1" } });

                    //console.log(RouteSegments.EDITPROFILE, { state: { tab: "1" } },"editp")
                  }}
                  // to={RouteSegments.EDITPROFILE}
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                >
                  {trans('matches.my_profile')}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath), { state: { tab: "6" } });
                  }}
                  // to={navigate(RouteSegments.EDITPROFILE, { state: { tab: "4", isfrom: "inbox" } })}
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer "
                >
                  Settings
                </a>
              </li>
              {/* <li>
                <div
                  onClick={onSwitchClick}
                  // to={navigate(RouteSegments.EDITPROFILE, { state: { tab: "4", isfrom: "inbox" } })}
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer "
                >
                  {isV3User ? "Switch to v2" : "Switch to v3"}
                </div>
              </li> */}
              <li>
                <a
                  onClick={signout}
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                >
                  Sign Out
                </a>
              </li>
            </ul>
            {showSuccessPopup && (
              <CommonSuccessPopup
                close={() => setshowSuccessPopup(false)}
                title={trans('photos.success')}
                content={successMessage}
              />
            )}
          </div >
        }
      </>
    );
  };

  // const NotificationDropdown = () => {
  //   const callBackRedirection = (notificationFor, senderID) => {
  //     if (notificationFor === "Trust") {
  //       // Trust Document page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "4" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Horoscope") {
  //       // Horoscope page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "5" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Photo") {
  //       // Gallery page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "3" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "PartnerProfile") {
  //       // Match View Profile page
  //       navigate(RouteSegments.MATCHES, {
  //         state: { id: senderID },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Admin") {
  //       // Edit my profile page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "1" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Message") {
  //       // Inbox page All message
  //       navigate(RouteSegments.INBOX, {
  //         state: { menu: "MESSAGE", subMenu: 0 },
  //         replace: true,
  //       });
  //     }

  //   };
  //   return (
  //     <div
  //       id="dropdown"
  //       className={`${notificationDropdown ? "" : "hidden"
  //         } z-10 absolute right-4 top-12 bg-white rounded-lg border border-[#FEBABA] shadow w-[20rem] dark:bg-gray-700 min-w-[200px] max-w-[250px]`}
  //     >
  //       {
  //         isNotificationLoading
  //           ? <div className="w-52">
  //             <Loader />
  //           </div>
  //           : <ul className="p-2 text-sm text-gray-700 dark:text-gray-200 divide-y divide-[#F18181]">
  //             {notificationList?.map(
  //               (e, i) =>
  //                 i < 9 && (
  //                   <li className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
  //                     <div onClick={() => {
  //                       callBackRedirection(
  //                         e.notificationFor,
  //                         e.notificationSenderId
  //                       );
  //                     }} className="block py-1">
  //                       {e?.notificationContent}
  //                     </div>
  //                   </li>
  //                 )
  //             )}
  //           </ul>
  //       }
  //       <div className="flex justify-end my-2 mx-2">
  //         <NavLink to={{ pathname: RouteSegments.NOTIFICATION }} replace className="text-[14px] text-[#D10A11] underline cursor-pointer" >{trans('notifications.see_more')}</NavLink>
  //       </div>
  //     </div>
  //   );
  // };

  useEffect(() => {
    if (ProfileData) {
      setShowReligiousMigrationPopup(ProfileData?.showcastemigration === "Y")
      // setShowCastePopup(ProfileData?.cleanClearExecution == true)
    }
  }, [ProfileData])




  const onBack = () => {
    if (location.key !== 'default') {
      navigate(-1)
    }
  }

  const showBackButton = () => {
    const isMatch = matchPath(`${RouteSegments.DASHBOARD}`, location.pathname)
    if (isMatch != null) {
      return false
    }

    return location.key !== 'default'
  }

  //console.log("showBackButton", showBackButton());

  const onInboxClick = () => {
    navigate(RouteSegments.INBOX, {
      state: { menu: "MESSAGE", subMenu: 0 },
    });
    // navigate("/inbox");
    setMenuSelect("3");
    setNavList(['Inbox', 'All Messages'])
  }

  const onNotificationClick = () => {
    onClickTrack(EventName.Notifications_Notifications_Notifications_Button)
    setMenuSelect("5");
    setNavList(['Notification'])
    // navigate("/notification");
  }

  const onMobileNotificationClick = () => {
    onNotificationClick();
    if (isV3User) {
      navigate(RouteSegments.NOTIFICATION_LITE)
    } else {
      navigate(RouteSegments.NOTIFICATION)
    }
  }

  const onPayNowClick = () => {
    if (window.location.pathname.includes(RouteSegments.MEMBERSHIP_REGISTER())) {
      window.location.reload()
    } else {
      navigate(RouteSegments.MEMBERSHIP_REGISTER())
    }
    setMenuSelect("6")
    setNavList(['Pay Now'])
  }

  const onProfileClick = () => {
    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath));
    setMenuSelect("4");
    setNavList(['Edit Profile', 'My Profile'])
  }

  const onStoreClick = () => {
    window.open(
      RouteSegments.JEWELLERY_MICROSITE,
      '_blank' // <- This is what makes it open in a new window.
    );
    // navigate(RouteSegments.JEWELLERY_MICROSITE, true);
    setMenuSelect("7");
  }

  useEffect(() => {
    //console.log(menuSelect,"setMenuSelect");

    // if(menuSelect==="1"){
    //   setMenuSelect("1")

    // }else if(window.location.href.indexOf('search') >-1){
    //   setMenuSelect("2")
    // }else if(window.location.href.indexOf('inbox')>-1){
    //   setMenuSelect("3")
    // }else if(window.location.href.indexOf('manage?tab=1')>-1 
    // || window.location.href.indexOf('manage?tab=2')>-1
    //  || window.location.href.indexOf('manage?tab=3')>-1){
    //   setMenuSelect("4")

    // }else if(menuSelect==="5"){
    //   setMenuSelect("5")

    // }else if(window.location.href.indexOf('matches') >-1){
    //   setMenuSelect("")
    // }
    // else if (window.location.href.indexOf('membershipregister')>-1){
    //   setMenuSelect("6")
    // }

    if (isMatch) {
      setMenuSelect("1")
    } else if (isSearch) {
      setMenuSelect("2")
    } else if (isInbox) {
      setMenuSelect("3")
    } else if (isNotifications) {
      setMenuSelect("5")
    } else if (isEditProfileMatch) {
      setMenuSelect("4")
    } else if (isMemberShip) {
      setMenuSelect("6")
    }



  }, [isEditProfileMatch, isInbox, isMatch, isMemberShip, isNotifications, isSearch, menuSelect])

  // useState(()=>{
  //   if(window.location.href.indexOf('contact-us-for-help')){
  //     window.location.reload()

  //   }else{
  //     return false
  //   }

  // })
  const scriptToInject = `
  `


  // $('head').append(scriptToInject)    

  useEffect(() => {

    if (isCommunitySite) {
      //no script for this community site
    } else {
      if (location.pathname !== `/${RoutePaths.MEMBERSHIP_REGISTER}`) {
        if ($('.pixelScript').length > 0) {
          $('.pixelScript').remove()
          $('head').append(scriptToInject)
        } else {
          $('head').append(scriptToInject)
        }
      }
    }

    return () => {
      $('.pixelScript').remove()
    }
  }, [])

  if (isV3User) return <LiteNavBar ProfileData={ProfileData} />


  return (
    <>
      {/* <InjectScript script={scriptToInject} /> */}
      {localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite) ?
        <>
          <div className='mobview'>
            <div className="mob-top">
              <div id="top1" className='h-[3rem]'>
                <p className="pt-2">{localStorage.getItem(Constants.loginLocalStorageKeys.tagContent)}
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className='webview'>
            <div className="mob-top">
              <div id="top1" className='h-[3rem]'>
                <p className="pt-2">{localStorage.getItem(Constants.loginLocalStorageKeys.tagContent)}
                </p>
              </div>
            </div>
          </div>
        </>

        : ""}
      {/* <header className=" sticky top-0 z-10  w-full bg-white md:border-b-[5px] border-[#D10A11]"> */}
      <header id="mobile-header" className=" sticky top-0 z-10  w-full bg-white md:border-b-[5px] border-[#D10A11]">
        <Sidebar profileData={ProfileData} onLogout={signout} />
        {
          isOpenFilter ? <FilterPanel /> : <></>
        }
        {successImagePopup && (
          <ImageSuccessPopup
            close={() => setSuccessImagePopup(false)}
            title={trans('photos.success')}
            content={successMessage}
            image={croppedImage}
            goto={() => {
              setMenuSelect("4")
              navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
                replace: isEditProfileMatch,
              });
            }}
          />
        )}
        {showUploadImagePopup ? (
          isV3User ?
            <UploadPhotoPopuplite
              close={() => setShowUploadImagePopup(false)}
              title={trans('my_profile.upload_photo')}
              callBack={() => {
                setsuccessMessage(trans('photos.image_uploaded_successfully'))
                setshowSuccessPopup(true)
              }}
              callBackImage={(value) => {
                var objectURL = URL.createObjectURL(value);
                setCroppedImage(objectURL);
                setsuccessMessage("Image uploaded succesfully!")
                setSuccessImagePopup(true)
              }}
              isFrom={"sign out"}
            />
            :
            <UploadPhotoPopup
              close={() => setShowUploadImagePopup(false)}
              title={trans('my_profile.upload_photo')}
              callBack={() => {

                setsuccessMessage(trans('photos.image_uploaded_successfully'))
                setshowSuccessPopup(true)

              }}
              callBackImage={(value) => {
                var objectURL = URL.createObjectURL(value);
                console.log(value, "iii", objectURL)
                setCroppedImage(objectURL);
                setsuccessMessage("Image uploaded succesfully!")
                setSuccessImagePopup(true)

              }}
              isFrom={"sign out"}
            />
        ) : (
          <></>
        )}
        {showReligiousMigrationPopup && <ReligionMigrationPopup profileDate={ProfileData} close={() => setShowReligiousMigrationPopup(false)} />}

        {/* {
   
    showcastepopup &&
    <CommonCastPopup profileDate={ProfileData}  close={()=>setShowCastePopup(false)}/>

    } */}
        <div className="">
          {/* <div className=" flex  mx-0 p-2 md:p-0 md:mx-5 lg:mx-9 md:h-[5rem] lg:h-[6rem] md:pt-[1rem] justify-between items-center "> */}
          <div className="mobile-header flex  mx-0 p-2 md:p-0 md:mx-3 lg:mx-9 md:h-[5rem] lg:h-[6rem] md:pt-[1rem] justify-between items-center ">
            <div className="flex items-center justify-center">
              <div
                onClick={onBack}
                className={`mx-2 ${showBackButton() ? "block" : "hidden"}  md:hidden cursor-pointer`}>
                <BsArrowLeft size={18} color="#D10A11" />
              </div>
              <Link to={RouteSegments.DASHBOARD} className="flex items-center">
                <img
                  src={KMLogo}
                  className="h-12 md:h-[3.5rem] lg:h-[4.5rem]"
                  alt=""
                />
              </Link>
            </div>
            <section>
              <div
                className="hidden md:flex flex-col items-stretch w-full"
                x-data="{tab: 1}"
              >
                <div className="flex cursor-pointer flex-row md:space-x-5 lg:space-x-3 ">
                  <NavItem
                    title={"Matches"}
                    image={
                      menuSelect === "1"
                        ? MatchesIconRed
                        : MatchesIcon
                    }
                    isSelected={menuSelect === "1"}
                    isShowCount={false}
                    callBack={() => {
                      navigate("/dashboard");
                      setMenuSelect("1");
                      setNavList(['Matches'])
                    }}
                    hoverImage={MatchesIconRed}
                  />
                  <NavItem
                    title={"Search"}
                    image={
                      menuSelect === "2"
                        ? SearchIconRed
                        : SearchIcon
                    }
                    isSelected={menuSelect === "2"}
                    isShowCount={false}
                    callBack={() => {
                      navigate(RouteSegments.searchTab(1));
                      setMenuSelect("2");
                      setNavList(['Search'])
                    }}
                    hoverImage={SearchIconRed}
                  />
                  <div className="relative">
                    <NavItem
                      title={"Inbox"}
                      image={
                        menuSelect === "3"
                          ? InboxIconRed
                          : InboxIcon
                      }

                      isSelected={menuSelect === "3"}
                      isShowCount={parseInt(data?.profileunreadmessagecount) > 0}
                      count={parseInt(data?.profileunreadmessagecount)}
                      callBack={onInboxClick}
                      hoverImage={InboxIconRed}
                    />
                  </div>
                  <NavItem
                    title={"My Profile"}
                    image={
                      menuSelect === "4"
                        ? EditProfileIconRed
                        : EditProfileIcon
                    }
                    isSelected={menuSelect === "4"}
                    isShowCount={false}
                    callBack={onProfileClick}
                    hoverImage={EditProfileIconRed}
                  />
                  <Popover
                    overlayInnerStyle={{
                      border: "1px solid #FEBABA",
                      width: "20rem",
                      maxWidth: "200px",
                      minWidth: "250px",
                      padding: "0px"
                    }}
                    onVisibleChange={(visible) => setNotificationVisible(visible)}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    trigger="click"
                    placement="bottom"
                    content={<NotificationPopoverContent notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} setNotificationCount={setNotificationCount} />}>
                    <div>
                      <NavItem
                        title={"Notification"}
                        image={
                          menuSelect === "5"
                            ? NotificationIconRed
                            : NotificationIcon
                        }
                        isSelected={menuSelect === "5"}
                        isShowCount={notificationCount > 0}
                        count={notificationCount}
                        callBack={onNotificationClick}
                        hoverImage={NotificationIconRed}
                      />
                    </div>
                  </Popover>
                  <NavItem
                    title={trans('matches.pay_now')}
                    image={
                      menuSelect === "6"
                        ? PaymentIconRed
                        : PaymentIcon
                    }
                    isSelected={menuSelect === "6"}
                    isShowCount={false}
                    callBack={onPayNowClick}
                    hoverImage={PaymentIconRed}
                  />
                  <NavItem
                    title={trans('matches.store')}
                    image={
                      menuSelect === "7"
                        ? kalyanJewellery
                        : kalyanJewellery
                    }
                    isSelected={menuSelect === "7"}
                    isShowCount={false}
                    callBack={onStoreClick}
                    hoverImage={kalyanJewellery}
                  />
                  {/* <a
                  className=" font-semibold py-5 text-[12px] text-[#7A7A7A] flex flex-col  items-center     opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 2, ' transform ': tab !== 2}"
                  href="#"
                  //  @click.prevent="tab= 2"
                  //  @click.prevent="tab= 2"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]  items-center"
                    src={MultiUserIcon}
                  />
                  Matches
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col items-center font-semibold font-segeo opacity-[.8] items-center"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 3, 'transform ': tab !== 3}"
                  href="#"
                  //  @click.prevent="tab= 3"
                  //  @click.prevent="tab= 3"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]   items-center"
                    src={SearchIconGrey}
                  />
                  Search
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1]   transform  ': tab
                  //  === 4, 'transform ': tab !== 4}"
                  //  href="#" @click.prevent="tab= 4"
                  //  @click.prevent="tab= 4"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]   items-center"
                    src="/Assets/Images/Group 536.svg"
                  />
                  {props.data.profilemessage_count === "0" ? (
                    <></>
                  ) : (
                    <button type="button" className=" ">
                      <div
                        className="absolute inline-flex items-center
                          justify-center w-6 h-6                         
                           top-5
                           md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px]
                          
                          font-bold text-white bg-[#FDC300] rounded-full"
                        //   "absolute inline-flex
                        //           top-2 left-8 md:left-5 lg:left-8
                        //          justify-center items-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px]
                        //          font-bold  text-white bg-[#FDC300] rounded-full"
                      >
                        {props.data.profilemessage_count}
                      </div>
                    </button>
                  )}
                  <p className="font-semibold">Inbox</p>
                </a>

                <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] transform ': tab
                  //  === 5, 'transform ': tab !== 5}"
                  //  href="#" @click.prevent="tab= 5"
                  //  @click.prevent="tab= 5"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px] "
                    src="/Assets/Images/Group 407.svg"
                  />
                  <span>Edit Profile</span>
                </a>
                <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1]   transform  ': tab
                  //  === 6, 'transform ': tab !== 6}"
                  //  href="#" @click.prevent="tab= 6"
                  //  @click.prevent="tab= 6"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]  flex flex-col items-center"
                    src="/Assets/Images/Group 653.svg"
                  />
                  <p>Notification</p>
                  {props.data.profilenotification_count === "0" ? (
                    <></>
                  ) : (
                    <button type="button" className=" ">
                      <div
                        className="absolute inline-flex items-center
                                 justify-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold
                                 text-white 
                                 top-5
                                 bg-[#FDC300] rounded-full"
                        // "absolute inline-flex top-2 left-8 md:left-8 lg:left-9 justify-center items-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#FDC300] rounded-full"
                      >
                        {props.data.profilenotification_count}
                      </div>
                    </button>
                  )}
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 7, 'transform ': tab !== 7}"
                  href="#"
                  //  @click.prevent="tab= 7"
                  //  @click.prevent="tab= 7"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px] "
                    src="/Assets/Images/Group 6.svg"
                  />
                  <p>Pay Now</p>
                </a> */}
                </div>
              </div>
            </section>
            <div className="hidden md:flex cursor-pointer space-x-4 md:mb-[0.6rem] ">

              <div className="mb-2">
                <p className="text-[8px] md:text-[11px] lg:text-[14px]  flex justify-center text-[#575556] font-semibold">{trans('matches.download_our_app')}</p>
                <div className="flex space-x-2 md:space-x-3 md:mt-[0.3rem] lg:mt-0">
                  <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank">

                    <img className='w-[11rem] md:w-[5rem]  lg:w-[8rem] xl:w-[8rem]' src={GPlayIcon} alt="" />
                  </a>
                  <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">

                    <img className='w-[11rem] md:w-[5rem] lg:w-[8rem] xl:w-[8rem]' src={AppStoreIcon} alt="" />
                  </a>
                </div>

              </div>

              <div className={menu === "footermenu" ? "flex cursor-pointer mt-5" : location?.state?.isfrom === "report" ? "flex cursor-pointer mt-5" : "flex cursor-pointer mt-5"}>
                <a
                  //  href={RouteSegments.HELP_AND_FAQ()}

                  onClick={() => {
                    navigate(RouteSegments.HELP_AND_FAQ())
                    if (RouteSegments.HELP_AND_FAQ()) {
                      // window.location.reload()
                    }

                  }
                    // navigate(RouteSegments.HELP_AND_FAQ())

                  }
                  // window.location.reload(),



                  className=" font-segeo font-normal text-[13px] text-[#7A7A7A]">
                  <img
                    className=" md:h-[20px] md:w-[20px] lg:h-[32px] lg:w-[40px]  items-center"
                    src={HelpIcon}
                    alt=""
                  />
                </a>
              </div>
              <div
                className={menu === "footermenu" ? "flex cursor-pointer mt-5 " : location?.state?.isfrom === "report" ? "flex cursor-pointer mt-5" : "flex cursor-pointer mt-5"}
                onClick={() => {
                  setProfileDropdown(!profileDropdown);
                }}
              >
                <img
                  className=" md:h-[20px] md:w-[20px] lg:h-[32px] lg:w-[40px]  items-center"
                  src={ProfileIcon}
                  alt=""
                />
              </div>
            </div>
            <ProfileDropdown />

            <div className=" md:hidden cursor-pointer flex mx-2 space-x-4">
              <div onClick={onPayNowClick}>
                <img alt="Pay now" className="w-6 h-6 cursor-pointer " src={PaymentIconRed} />
              </div>
              <div onClick={onInboxClick}>
                <span className="relative inline-block">
                  <img alt="" className="w-6 h-6 " src={ChatIcon} />
                  {data?.profilemessage_count != "0" && data?.profilemessage_count
                    ? <span className="absolute top-[3px] left-[0.6rem] inline-flex items-center justify-center px-[5px] py-[2px] text-[13px] font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full ">
                      {data?.profilemessage_count}
                    </span>
                    : null}
                </span>
              </div>

              <div onClick={onMobileNotificationClick}>
                <span className="relative inline-block">
                  <img alt="" className="w-6 h-6" src={NotificationMobIcon} />
                  {notificationCount > 0
                    ? <span className="absolute top-[3px] left-[0.6rem] inline-flex items-center justify-center  px-[5px] py-[2px] text-[13px] font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full ">
                      {notificationCount}
                    </span>
                    : null}
                </span>
              </div>
              <div onClick={onProfileClick}>
                <img alt="Profile" className="w-6 h-6" src={ProfileIcon} />
              </div>

              <div onClick={openDrawer}>
                <img alt="Menu" className="w-6 h-6" src={DrawerIcon} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export const NavItem = (props) => {

  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const handleMouseEnter = () => {
    if (!props.isSelected) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <a
      className={`${props.isSelected
        ? "text-[#D10A11] opacity-[1]"
        : "text-[#7A7A7A] opacity-[.8]  hover:text-[#D10A11]  "
        } font-semibold relative font-segeo justify-center  text-[12px] md:text-[10px] lg:text-[12px]  flex flex-col  items-center lg:w-20`}
      //  :className="{'text-[#D10A11] opacity-[1] ': tab
      //  === 2, ' transform ': tab !== 2}"
      // href="/inbox"
      onClick={() => {
        props.callBack();
        setIsHovered(false);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]  items-center"
        src={isHovered ? props.hoverImage : props.image}
        alt="Icon"
      />
      {props.isShowCount ?
        props.count > 0 ?
          <button type="button" className=" ">
            <div
              className={`${props?.title === "Notification" ? "absolute inline-flex items-center justify-center w-6 h-6 md:top-[-0.5rem] md:right-[0.8rem] lg:top-[-0.8rem] lg:right-[0.8rem] md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#FDC300] rounded-full"
                : "absolute inline-flex items-center justify-center w-6 h-6 md:top-[-0.5rem] md:right-[-0.2rem] lg:top-[-0.8rem] lg:right-[0.5rem] md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#FDC300] rounded-full "
                }  `}
            >
              {props.count}
            </div>
          </button>
          : <></>
        : (
          <></>
        )}
      {props.title}
    </a>
  );
};

export const LiteNavItem = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const handleMouseEnter = () => {
    if (!props.isSelected) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={`space-y-2 ${props.wrapperClassName}`}>
      <a
        className={`${props.isSelected
          ? "text-[#D10A11] opacity-[1]"
          : "text-[#707070] opacity-[.8]  hover:text-[#D10A11] text-center  "
          } font-semibold relative font-Poppins-Regular justify-center text-center  text-[12px] md:text-[9px] lg:text-[10px] xl:text-[11px] 2xl:text-[11px]  flex flex-col  items-center lg:w-[4rem] xl:w-[4.5rem]`}
        //  :className="{'text-[#D10A11] opacity-[1] ': tab
        //  === 2, ' transform ': tab !== 2}"
        // href="/inbox"
        onClick={() => {
          props.callBack();
          setIsHovered(false);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>

        {props?.isRewardBadge == true
          ? <Badge overflowCount={999} count={props?.totalPonits} color="#FDC300" className="reward-badge">
            <img
              className='  h-[20px] w-[20px] mb-1 md:h-[18px] md:w-[18px] lg:h-[18px] lg:w-[18px] xl:h-[24px] xl:w-[24px]  items-center'
              src={isHovered ? props.hoverImage : props.image}
              alt='Icon'
            />
          </Badge>
          : <img
            className='  h-[20px] w-[20px] mb-1 md:h-[18px] md:w-[18px] lg:h-[18px] lg:w-[18px] xl:h-[24px] xl:w-[24px]  items-center'
            src={isHovered ? props.hoverImage : props.image}
            alt='Icon'
          />}
        {props.isShowCount ? (
          props.count > 0 ? (
            <button type='button' className=' '>
              <div
                className={`absolute inline-flex items-center justify-center w-[1rem] h-[1rem] md:w-3 md:h-3 text-[8px]  font-bold text-black bg-[#FDC300] rounded-full ${props.className}`}>
                {props.count}
              </div>
            </button>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {props.title}
      </a>
    </div>
  );
};

export const LiteNavBar = ({ ProfileData, data, menu, props, isFrom }) => {
  const { loginDetail, logout, isLoggedIn } = useContext(LoginContext);
  const { menuSelect, setMenuSelect, showLogoutPopup, setShowLogoutPopup, showClearFilterPopup, setShowClearFilterPopup, showParentPopup, setShowParentPopup } = useContext(NavBarContext);
  const { navList, setNavList } = useContext(NavListContext);
  const [showLoader, setShowLoader] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(() => {
    const isLoginFromSms = sessionStorage.getItem(Constants.loginLocalStorageKeys.isLoginFromSms)
    const isMobile = localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice) === "Mobile Site";
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.isLoginFromSms)
    return isLoginFromSms && isMobile ? true : false
  })


  const navigate = useNavigate();
  const dispath = useDispatch()
  usePageChange()
  const location = useLocation();
  const {
    isInbox,
    isMatch,
    isEditProfileMatch,
    isSearch,
    isNotifications,
    isMemberShip,
  } = useModule();

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  console.log(ProfileData, "userDatauserData");
  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);
  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
  const [showGiftPopup, setShowGiftPopup] = useState(true)
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const isFromMicrosite = localStorage.getItem("isFromMicrosite")

  const { t: trans, i18n } = useTranslation();


  const isTamil = i18n.language === 'ta'
  useEffect(()=>{

  i18n.on('languageChanged', (lan) => {
    setShowLoader(false);
  });
  
  },[])


  console.log(userData?.popup[0]?.showpopup, "userData?.popup?.showpopup");


  const toggleSidePopup = () => {
    setIsSidePopupOpen(!isSidePopupOpen);
  };

  // console.log(ProfileData,'Sidebar');
  // const [menuAction, setMenuAction] = useState({
  //   matches: true,
  //   search: false,
  //   inbox: false,
  //   editProfile: false,
  //   notification: false,
  //   paynow: false,
  // });
  const [profileDropdown, setProfileDropdown] = useState(false);
  // const [notificationDropdown, setNotificationDropdown] = useState(false);
  // const { menuAction, setMenuAction } = MenuFunction();
  // const [notificationList, setNotificationList] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [navbarhelp, setnavbarhelp] = useState(false);
  const { openDrawer, isOpenFilter, setSearchFilter, isPusherReceived, setIsPusherReceived } = useContext(NavBarContext);
  const [successImagePopup, setSuccessImagePopup] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [setPath, setSetPath] = useState("");
  const [showNotificationPopup, setShowNotificationPopup] = useState(false)
  console.log({ showNotificationPopup });
  let totalUnreadCount = useSelector(ChatSelector.getTotalUnreadCount);
  const totalPonits = useSelector(state => state?.KalyanRewards?.totalPonits)
  // console.log({ totalUnreadCount });

  // const familyChatList = useSelector(state => state?.Chats?.groupChatList);
  // const familyChatListCount = familyChatList[0]?.unread_message_count;
  // console.log('familyChatListCount', familyChatListCount);

  // if (familyChatListCount > 0) {
  //   totalUnreadCount += 1;
  // }


  // const [showReligiousMigrationPopup, setShowReligiousMigrationPopup] =
  //   useState(true);

  const dispatch = useDispatch()
  // const [showcastepopup,setShowCastePopup] = useState(true)

  console.log({ totalUnreadCount });

  useEffect(() => {
    localStorage.removeItem(Constants.loginLocalStorageKeys.allowAfterRegPage);
    localStorage.removeItem(Constants.loginLocalStorageKeys.loginCurrentPage);
  }, []);

  const [navbarClear, setnavbarClear] = useState("dash");
  const getNotificationCount = async () => {
    let response = await POST(
      `${config.api.API_URL}${endPoints.notificationCountUrl()}`,
      {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        loginFrom: localStorage.getItem(
          Constants.loginLocalStorageKeys.loginDevice,
        ),
      },
    );

    if (response.statusCode === 200) {
      setNotificationCount(response?.data?.data?.notificationCount);
    }
  };

  useEffect(() => {
    // apiCallNotification();
    getNotificationCount();
    return () => {
      setIsPusherReceived(false)
    }
  }, [isPusherReceived]);

  useEffect(() => {
    if (userData?.profileId) {
      dispatch(getActivityTotalPoints({profile_id: userData?.profileId}))
    }
  }, [userData?.profileId])


  // useEffect(() => {}, [notificationList]);
  const apiLogin = async () => {
    let loginRequest = {
      email: emailid,
      password: "123123123",
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice,
      ),
      ioskey: "",
      androidkey: "",
    };

    let response = await POST(
      `${config.api.API_URL}${endPoints.loginUrl()}`,
      loginRequest,
    );
    // //console.log("cookiee", response);
  };

  //signout function
  const logoutUsignAPI = async () => {

    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
          familyloginid: localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId) ?? undefined
        },
      });

    let chatResponse = await axios.post(
      `${config.api.chat_api}${EndPoints.logout()}`,
      {
        id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Constants.loginLocalStorageKeys.chatLogin,
          )}`,
        },
      },
    ).catch(e => { });

    //signout chat session

    console.log(data, "datadatadatadata");
    if (data.data.status === "Success") {
      setShowLoader(false);
      if (isCommunitySite) {
        dispatch(getRecommendedFail({ isFrom: "logout" }))
      }
      logout();
      setShowClearFilterPopup(false)
    }
  };
  const signout = () => {
    onClickTrack(EventName.Three_Dots_Logout_Logout_Button)
    if (userData?.myphoto === "N") {
      setShowUploadImagePopup(true);
    } else {
      // setShowConfirmPopup(true)
      // setShowLoader(true);
      // logoutUsignAPI();
      setShowLogoutPopup(true);
    }
  };
  // const apiCallNotification = async () => {
  //   setIsNotificationLoading(true);
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //   };

  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.notificationUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     if (data?.data?.notificationData?.length > 0) {
  //       setNotificationList(data?.data?.notificationData);
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  //   setIsNotificationLoading(false);
  // };


  // const openNewWindow = () => {
  //   const newWindow = window.open(RouteSegments.JEWELLERY_MICROSITE, '_blank');

  //   // Update the title after a delay to ensure the new window is fully loaded
  //   setTimeout(() => {
  //     if (newWindow) {
  //       newWindow.document.title = "Kalyan Jewellers Muhurat Wedding Jewellery – Wedding Gold, Wedding Diamonds & Wedding Necklace";
  //     }
  //   }, 1000); // Adjust the delay as needed
  // };

  // const onStoreClick = () => {
  //   try {
  //     openNewWindow();
  //     setMenuSelect("7");
  //   } catch (error) {
  //     console.error("Error in onStoreClick:", error);
  //   }
  // };
  const onStoreClick = () => {
    window.open(
      RouteSegments.JEWELLERY_MICROSITE,
      '_blank' // <- This is what makes it open in a new window.
    );
    // navigate(RouteSegments.JEWELLERY_MICROSITE, true);
    setMenuSelect("7");
  }

  const onParentsNumberClick = () => {
    setShowParentNumPopup(true)
  }

  const onKalyanRewardsClick = () => {
    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath))
  }

  const selectedProfiles = useSelector(state => state?.DashBoardAPI?.ProfileData?.data?.selectedProfiles);

  const hasSelectedProfiles = selectedProfiles && selectedProfiles.length > 0;

  const isV3User = localStorage.getItem("isV3User")
  const onSwitchClick = () => {
    if (isV3User) {
      localStorage.removeItem("isV3User")
      navigate(RouteSegments.DASHBOARD)
    } else {
      localStorage.setItem("isV3User", "true")
      navigate(RouteSegments.MATCH_LITE)
    }
  }

  const [open, setOpen] = useState(false);

  const [languages, setLanguages] = useState([]);
  const [isLangResourceLoading, setIsLangResourceLoading] =useState(false);

useEffect(() => {
  console.log(languages, 'languages'); // This will log whenever languages changes
}, [languages]);

  useEffect(() => {
    axios
      .get(`${config.cms_api}/translation-languages`)
      .then((response) => {
        // Check for successful response
        if (response.status === 200 ) {
          setLanguages(response.data.languages ?? []);
        } 
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);


  useEffect(() => {
    
    i18next.on('loaded', () => {
      // setIsLangResourceLoading(false)
    });

    i18next.on('languageChanged', () => {
      // setIsLangResourceLoading(true)
    });
  
    
  }, [])

  const saveLanguagePref = (option)=>{
    const request = {
      "vernacularId":option.id,
      "vernacularCode":option.code
    }
    
    axios.post(`${config.api.API_URL}${EndPoints.vernacular()}`,request)
  }
  


  const changeLanguage = (code) => {
    let option = languages.find(lang=>lang.code == code)
    setShowLoader(true);

    saveLanguagePref(option)
    i18n.changeLanguage(code);
    localStorage.setItem(Constants.loginLocalStorageKeys.vernacularCode, code);
  };

  const ProfileDropdown = () => {
    const profilePic = userData?.profile_imageurl?.includes("femaleavatar") ? Images?.WomanIcon : userData?.profile_imageurl?.includes("maleavatar") ? Images?.ManIcon : userData?.profile_imageurl

    const isAvatar = userData?.profile_imageurl?.includes("femaleavatar") || userData?.profile_imageurl?.includes("maleavatar") ? true :  false


    const { selectedMatchesData } = useSelector(MatchSelector.getSelectedMatches)
    const totalPonits = useSelector(state => state?.KalyanRewards?.totalPonits)

    const { t: trans } = useTranslation();

    const onLangClick = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      setShowLanguageOptions(true)
    }

    if (showLanguageOptions) {
      return <LanguageDropdown />
    }

    return (


      <div
        // id="dropdown"
        className={`bg-white divide-y divide-gray-400 rounded-lg shadow lite-scrollbar max-h-[85vh] overflow-y-auto w-[18rem] lg:w-[19rem] dark:bg-gray-700`}>
        <ul
          className='py-4 text-sm text-gray-700 dark:text-gray-200'
          aria-labelledby='dropdownDefaultButton'>
          <li>
            <div className='flex px-[0.6rem] mb-2'>
              <img
                src={profilePic}
                alt=''
                onClick={() => {
                  setMenuSelect("2")
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath))
                }}
                className={`rounded-full mr-2 ml-1 h-[2.5rem] w-[2.5rem] text-gray-400 group-hover:text-gray-500 cursor-pointer ${isAvatar ? "border border-[#8292A8]" : ""}`}
              />
              <div>
                <p className='text-[#1A1A1A] text-[14px] font-segoe font-bold cursor-pointer'
                  onClick={() => {
                    setMenuSelect("2")
                    onClickTrack(EventName.Three_Dots_Profile_Name_Profile_Name_Button)
                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath))
                  }}
                >
                  {trans('my_profile_icon.hi')} {userData?.username}
                </p>
                <p className='text-[#1A1A1A] text-[13px] font-segoe font-semibold'>{trans('my_profile_icon.welcome_back')}</p>
                <div className='flex justify-between items-center gap-2 mb-2 text-[11px] ps-1 font-segoe font-semibold'>
                  <span className='text-[#7A7A7A] '>{trans('my_profile_icon.to_contact_matches')}</span>
                  <button onClick={() => {
                    if (userData?.isPaid === "Y") {
                      onClickTrack(EventName.Three_Dots_My_Plan_My_Plan_Button)
                      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[6].urlPath))
                    } else {
                      onClickTrack(EventName.Three_Dots_Buy_a_Pack_Buy_a_Pack_Button)
                      navigate(RouteSegments.MEMBERSHIP_REGISTER())
                    }
                  }} className='bg-[#FDC300] whitespace-nowrap text-[11px] text-white px-2.5 font-bold py-0.5 rounded w-full'>
                    {userData?.isPaid === "Y" ? trans('my_profile.my_plan') : trans('my_profile_icon.buy_a_pack')}
                  </button>
                </div>
              </div>
            </div>
            <hr className='mx-4' />
          </li>
          {/* <hr className='mx-4' />	 */}

          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={myProfile}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={() => {
                  // onClickTrack(EventName.Three_Dots_Set_My_Expectations_Set_My_Expectations_Button);
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath));
                }}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('matches.my_profile')}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li>
          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={HandIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={() => {
                  onClickTrack(EventName.Three_Dots_Set_My_Expectations_Set_My_Expectations_Button);
                  setMenuSelect("2")
                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath))
                }}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile_icon.set_partner_expectations')}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li>

          {userData?.parentsNumberAvailable === "Yes" ?
            "" :
            <li>
              <div className="flex items-center px-4 py-2 ">
                <img
                  src={ParentCallcon}
                  alt=''
                  className='mr-3 h-5 w-5 '
                />
                <a
                  onClick={onParentsNumberClick}
                  className=''
                  role='menuitem'
                  tabIndex='-1'
                >
                  <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile.add_parents_phone_number')}</p>
                </a>
              </div>
              <hr className='mx-4' />
            </li>
          }
          <li>
            <div
              onClick={selectedMatchesData?.length > 0 ? () => { onClickTrack(EventName.Three_Dots_Selected_Profiles_Selected_Profiles_Button); navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { from: "search", onlySelected: true } }) } : null}
              className={`flex items-center px-4 py-2 ${selectedMatchesData?.length > 0 ? "cursor-pointer " : "cursor-not-allowed"}`}>
              <img
                src={SelectedIcon}
                alt=''
                className='mr-3 h-5 w-5 '
                loading="lazy"
              />
              <span

                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className={`text-[#7A7A7A] text-[13px] ${selectedMatchesData?.length > 0 ? " hover:text-blue-300 " : ""} font-Poppins-Regular`}>{trans('my_profile_icon.selected_profiles')}</p>
              </span>
            </div>
            <hr className='mx-4' />

          </li>

          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={callingPacks}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={() => {
                  const isFromRedeem = isFrom === "Redeem"; // Evaluate the condition

                  setMenuSelect("6");
                  setNavList(["Pay Now"]);
                  if (window.location.pathname.includes(RouteSegments.MEMBERSHIP_REGISTER() ,isFromRedeem)) {
                    window.location.reload();
                  } else {
                    navigate(RouteSegments.MEMBERSHIP_REGISTER(), { state: { isFrom: "Redeem" } }); // Pass state here
                  }
                }}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile_icon.calling_packs')}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li>

                    <li onClick={onLangClick}>
            <div className="flex items-center px-4 py-2 cursor-pointer">
              <img src={Images.Language} className="mr-3 h-5 w-5 shrink-0" />
              <span className="text-[#7A7A7A] text-[13px] font-Poppins-Regular flex gap-4 shrink-0 mr-2">{trans('my_profile_icon.choose_language')}</span>
              {/* <select className="pl-2" onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>

                {languages.map(lang => (<option value={lang.code} key={lang.code}>{lang.name}</option>))}
              </select> */}
            </div>
            <hr className='mx-4' />
          </li>

          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={HelpChatIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={() => {
                  onClickTrack(EventName.Three_Dots_Hep_Help_Button);
                  navigate(
                    RouteSegments.HELP_AND_FAQ()
                  );
                }}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile_icon.help')}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li>

          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={SettingsIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={() => {
                  onClickTrack(EventName.Three_Dots_Settings_Settings_Button);
                  navigate(
                    RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath),
                    { state: { tab: "6" } },
                  );
                }}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile_icon.settings')}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li>

          {/* <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={SettingsIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={onSwitchClick}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular"> {isV3User ? "Switch to v2" : "Switch to v3"}</p>
              </a>
            </div>
            <hr className='mx-4' />
          </li> */}
          <li>
            <div className="flex items-center px-4 py-2 ">
              <img
                src={Images.TrophyIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath)}>
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular flex gap-4">{trans('my_profile_icon.your_kalyan_score_is')} <span className="bg-[#FDC300] text-[#D10A11] font-bold flex items-center justify-center px-2 rounded-[2rem]">{totalPonits}<span className="text-[#D10A11] font-bold"> *</span></span></p>
              </Link>
            </div>
            <hr className='mx-4' />
          </li>




          <li>
            <div className="flex items-center justify-center px-4 py-2 mt-2.5 ">
              <img
                src={LogOutIcon}
                alt=''
                className='mr-3 h-5 w-5 '
              />
              <a
                onClick={signout}
                target="_blank"
                className=''
                role='menuitem'
                tabIndex='-1'
              >
                <p className="text-[#7A7A7A] text-[13px] hover:text-blue-300 font-Poppins-Regular">{trans('my_profile_icon.logout')}</p>
              </a>
            </div>
          </li>


          {/* <li>
      <a
        onClick={() => {
          navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), { state: { tab: "1" } });

        }}
        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
      >
        My Profile
      </a>
    </li> */}
          {/* <li>

      <a
        onClick={() => {
          navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath), { state: { tab: "6" } });
        }}
        // to={navigate(RouteSegments.EDITPROFILE, { state: { tab: "4", isfrom: "inbox" } })}
        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer "
      >
        Settings
      </a>
    </li> */}
          {/* <li>
      <a
        onClick={signout}
        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
      >
        <img src={LogOutIcon} alt="" className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        Sign Out
      </a>
    </li> */}
        </ul>
        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => setshowSuccessPopup(false)}
            title={trans('photos.success')}
            content={successMessage}
          />
        )}
      </div>
    );
  };

  const LanguageDropdown = () => {
    return <div
      className={` rounded-lg shadow w-[12rem] dark:bg-gray-700 bg-white`}>
      <ul
        className='py-4 px-4 text-sm text-gray-700 dark:text-gray-200 divide-y divide-gray-400'
        aria-labelledby='dropdownDefaultButton'>
        <li>
          <div className="flex items-center px-4 py-2 ">
          <img src={Images.Language} className="mr-3 h-5 w-5 shrink-0" />
            <span className="text-[#7A7A7A] text-[13px] font-Poppins-Regular  mr-2">{trans('my_profile_icon.choose_language')}</span>
          </div>
        </li>
        {languages.map(lang => (<li key={lang.code}>
          <div onClick={()=>changeLanguage(lang.code)} className="flex items-center justify-between px-4 py-1.5 cursor-pointer">
            <p className="text-[#7A7A7A] text-[13px] font-Poppins-Regular flex gap-4 shrink-0 mr-2">{lang.name}</p>
            {i18n.language == lang.code && <img
              src={Tick}
              alt='tick'
              className='shrink-0 w-3 h-3 md:w-4 md:h-4 lg:w-3 lg:h-3'
            />}
          </div>
        </li>))}
      </ul>
    </div>
  }

  

  // const NotificationDropdown = () => {
  //   const callBackRedirection = (notificationFor, senderID) => {
  //     if (notificationFor === "Trust") {
  //       // Trust Document page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "4" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Horoscope") {
  //       // Horoscope page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "5" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Photo") {
  //       // Gallery page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "3" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "PartnerProfile") {
  //       // Match View Profile page
  //       navigate(RouteSegments.MATCHES, {
  //         state: { id: senderID },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Admin") {
  //       // Edit my profile page
  //       navigate(RouteSegments.EDITPROFILE, {
  //         state: { tab: "1" },
  //         replace: true,
  //       });
  //     } else if (notificationFor === "Message") {
  //       // Inbox page All message
  //       navigate(RouteSegments.INBOX, {
  //         state: { menu: "MESSAGE", subMenu: 0 },
  //         replace: true,
  //       });
  //     }

  //   };
  //   return (
  //     <div
  //       id="dropdown"
  //       className={`${notificationDropdown ? "" : "hidden"
  //         } z-10 absolute right-4 top-12 bg-white rounded-lg border border-[#FEBABA] shadow w-[20rem] dark:bg-gray-700 min-w-[200px] max-w-[250px]`}
  //     >
  //       {
  //         isNotificationLoading
  //           ? <div className="w-52">
  //             <Loader />
  //           </div>
  //           : <ul className="p-2 text-sm text-gray-700 dark:text-gray-200 divide-y divide-[#F18181]">
  //             {notificationList?.map(
  //               (e, i) =>
  //                 i < 9 && (
  //                   <li className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
  //                     <div onClick={() => {
  //                       callBackRedirection(
  //                         e.notificationFor,
  //                         e.notificationSenderId
  //                       );
  //                     }} className="block py-1">
  //                       {e?.notificationContent}
  //                     </div>
  //                   </li>
  //                 )
  //             )}
  //           </ul>
  //       }
  //       <div className="flex justify-end my-2 mx-2">
  //         <NavLink to={{ pathname: RouteSegments.NOTIFICATION }} replace className="text-[14px] text-[#D10A11] underline cursor-pointer" >{trans('notifications.see_more')}</NavLink>
  //       </div>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   if (ProfileData) {
  //     // setShowReligiousMigrationPopup(ProfileData?.showcastemigration === "Y");
  //     setShowCastePopup(ProfileData?.cleanClearExecution === false)
  //   }
  // }, [ProfileData]);

  const onBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    }
  };

  const showBackButton = () => {
    const isMatch = matchPath(`${RouteSegments.DASHBOARD}`, location.pathname);
    if (isMatch != null) {
      return false;
    }

    return location.key !== "default";
  };

  //console.log("showBackButton", showBackButton());

  const onInboxClick = () => {
    if (showClearFilterPopup) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.CHAT_LITE)
    } else {
      setMenuSelect("3");
      navigate(RouteSegments.CHAT_LITE, {
        state: { menu: "MESSAGE", subMenu: 0 },
      });
      // navigate("/inbox");
      setNavList(["Inbox", "All Messages"]);
    }
  };

  const onNotificationClick = () => {
    onClickTrack(EventName.Notifications_Notifications_Notifications_Button)
    setMenuSelect("5");
    setNavList(["Notification"]);
    // navigate("/notification");
  };

  const onMobileNotificationClick = () => {
    onNotificationClick();
    if (isV3User) {
      navigate(RouteSegments.NOTIFICATION_LITE)
    } else {
      navigate(RouteSegments.NOTIFICATION)
    }
  };

  const onPayNowClick = () => {
    if (showClearFilterPopup) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.MEMBERSHIP_REGISTER())
    } else {
      setMenuSelect("6");
      setNavList(["Pay Now"]);
      if (window.location.pathname.includes(RouteSegments.MEMBERSHIP_REGISTER())) {
        window.location.reload();
      } else {
        navigate(RouteSegments.MEMBERSHIP_REGISTER(),{ state: { path: 'paynow' } });
      }
    }
  };

  const onProfileClick = () => {
    if (showClearFilterPopup) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.editProfileTab(0));
    } else {
      setMenuSelect("2");
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath));
      setNavList(["Edit Profile", "My Profile"]);
    }
  };



  const onGroupClick = () => {

    if (showClearFilterPopup) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.MATCH_GROUP)
    } else {
      setMenuSelect("9");
      navigate(RouteSegments.MATCH_GROUP, { state: { path: location.pathname } });

    }


  }

  const onMatchClick = () => {
    const filterStateStr = sessionStorage.getItem(Constants.loginLocalStorageKeys.filterState);
    const filterState = JSON.parse(filterStateStr)
    if (showClearFilterPopup && (filterStateStr != null && (location.pathname == RouteSegments.MATCH_LITE))) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.MATCH_LITE)
    } else {
      setMenuSelect("1");
      dispath(closeCurrentMatch())
      navigate(RouteSegments.MATCH_LITE, { state: filterState });
      setNavList(["Matches"]);
    }
  }

  const onFilterClick = () => {
    const filterStateStr = sessionStorage.getItem(Constants.loginLocalStorageKeys.filterState);
    const filterState = JSON.parse(filterStateStr)
    console.log({ filterState, filterStateStr });


    if (location.pathname == RouteSegments.FILTER_LITE) return;

    if (filterState) {
      const isFromFilter = filterState?.isFrom === "filter" && filterState?.selectedFilter?.length > 0;
      const isBasicSearch = filterState?.isBasicSearch

      setSearchFilter(isFromFilter)
      if (isFromFilter) {
        navigate(RouteSegments.FILTER_LITE, { state: { isFrom: "editfilter" } })
      } else {
        navigate(RouteSegments.FILTER_LITE, {
          state: {
            isFrom: "editSearch",
            tab: isBasicSearch ? 1 : 2,
            isBasicSearch: isBasicSearch,
            isAdvanceSearch: !isBasicSearch,
            formData: filterState?.formData,
            searchState: filterState
          }
        })
      }
      dispath(closeCurrentMatch())
      return;
    } else {
      setSearchFilter(true)
    }


    if (showClearFilterPopup) {
      setShowParentPopup(true);
      setSetPath(RouteSegments.FILTER_LITE)
    } else {
      setMenuSelect("4");
      navigate(RouteSegments.FILTER_LITE);
    }
    // setNavList(['Edit Profile', 'My Profile'])
  };

  const logoutFunctionality = () => {
    logoutUsignAPI();
    setShowLoader(true)
    setShowLogoutPopup(false)
    localStorage.setItem("isLoggedout",true)
  }

  const handleFilter = () => {
    // setSearchFilter(isFromFilter)
    setMenuSelect("4")
    navigate(RouteSegments.FILTER_LITE, { state: { isFrom: "editfilter", tab: location.state.tab } })
  }

  const callBackPath = () => {
    navigate(setPath)
  }

  useEffect(() => {
    //console.log(menuSelect,"setMenuSelect");

    // if(menuSelect==="1"){
    //   setMenuSelect("1")

    // }else if(window.location.href.indexOf('search') >-1){
    //   setMenuSelect("2")
    // }else if(window.location.href.indexOf('inbox')>-1){
    //   setMenuSelect("3")
    // }else if(window.location.href.indexOf('manage?tab=1')>-1
    // || window.location.href.indexOf('manage?tab=2')>-1
    //  || window.location.href.indexOf('manage?tab=3')>-1){
    //   setMenuSelect("4")

    // }else if(menuSelect==="5"){
    //   setMenuSelect("5")

    // }else if(window.location.href.indexOf('matches') >-1){
    //   setMenuSelect("")
    // }
    // else if (window.location.href.indexOf('membershipregister')>-1){
    //   setMenuSelect("6")
    // }

    if (isMatch) {
      setMenuSelect("1");
    } else if (isSearch) {
      setMenuSelect("2");
    } else if (isInbox) {
      setMenuSelect("3");
    } else if (isNotifications) {
      setMenuSelect("5");
    } else if (isEditProfileMatch) {
      setMenuSelect("2");
    } else if (isMemberShip) {
      setMenuSelect("6");
    }
  }, [
    isEditProfileMatch,
    isInbox,
    isMatch,
    isMemberShip,
    isNotifications,
    isSearch,
    menuSelect,
  ]);

  // useState(()=>{
  //   if(window.location.href.indexOf('contact-us-for-help')){
  //     window.location.reload()

  //   }else{
  //     return false
  //   }

  // })
  const scriptToInject = `
  `;

  // $('head').append(scriptToInject)

  useEffect(() => {
    if (isCommunitySite) {
      //no script for this community site
    } else {
      if (location.pathname !== `/${RoutePaths.MEMBERSHIP_REGISTER}`) {
        if ($(".pixelScript").length > 0) {
          $(".pixelScript").remove();
          $("head").append(scriptToInject);
        } else {
          $("head").append(scriptToInject);
        }
      }

    }

    return () => {
      $(".pixelScript").remove();
    };
  }, []);
  const { onClickTrack } = useAnalytics()
  const [notificationVisible, setNotificationVisible] = useState(false)


  //   const { casteList, domainList, subcasteList } = useSelector((state) => ({
  //     casteList: state.Home?.cList?.data?.data?.castes ?? [] ,
  //     subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
  //     domainList: state?.Home?.denominationList?.data?.domain ?? [],
  //   }));

  // useEffect(()=>{
  //   console.log(casteList,"casteList12");

  // },[casteList])


  const [finalLoginDevice, setFinalLoginDevice] = useState("Unknown");

  useEffect(() => {
    const loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop";
    console.log(loginDevice, "loginDevice123");

    setFinalLoginDevice(loginDevice);  // Set finalLoginDevice state
  }, []);

  const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      ),
    },
  ];

  return (
    <>

      {showParentNumPopup ?
        isV3User ?
          <ParentsPhonePopupLite close={() => setShowParentNumPopup(false)}
          title={trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
            callBackApply={() => { }}
            parentsIsdCode={userData?.parentsIsdCode}
            parentsMobileNumber={userData?.parentsMobileNumber}
          />
          :
          <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
            title={trans('my_profile.add_parents_phone_number')}
            callBackApply={() => { }}
            parentsIsdCode={userData?.parentsIsdCode}
            parentsMobileNumber={userData?.parentsMobileNumber}
          />
        : ""
      }
      {showParentPopup && <CommonParentPopup navPath={setPath} close={() => setShowParentPopup(false)} setShowClearFilterPopup={setShowClearFilterPopup} handleFilter={handleFilter} callBackPath={() => callBackPath()} setSearchFilter={setSearchFilter} />}

      {showLogoutPopup && (
        <LogoutPopup close={() => setShowLogoutPopup(false)} logout={logoutFunctionality} />
      )}
      {showUploadImagePopup ? (
        isV3User ?
          <UploadPhotoPopuplite
            signout={logoutFunctionality}
            close={() => setShowUploadImagePopup(false)}
            title={trans('my_profile.upload_photo')}
            callBack={() => {
              setsuccessMessage(trans('photos.image_uploaded_successfully'));
              setshowSuccessPopup(true);
            }}
            callBackImage={(value) => {
              var objectURL = URL.createObjectURL(value);
              console.log(value, "iii", objectURL);
              setCroppedImage(objectURL);
              setsuccessMessage("Image uploaded succesfully!");
              setSuccessImagePopup(true);
            }}
            isFrom={"sign out"}
          />
          :
          <UploadPhotoPopup
            close={() => setShowUploadImagePopup(false)}
            title={trans('my_profile.upload_photo')}
            callBack={() => {
              setsuccessMessage(trans('photos.image_uploaded_successfully'));
              setshowSuccessPopup(true);
            }}
            callBackImage={(value) => {
              var objectURL = URL.createObjectURL(value);
              console.log(value, "iii", objectURL);
              setCroppedImage(objectURL);
              setsuccessMessage("Image uploaded succesfully!");
              setSuccessImagePopup(true);
            }}
            isFrom={"sign out"}
          />
      ) : (
        <></>
      )}
      {showDownloadPopup && finalLoginDevice !== "Desktop" && isFromMicrosite !== "Yes" && <MobileSiteLoginPopup onClose={() => setShowDownloadPopup(false)} />}
      {(showLoader || isLangResourceLoading) && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
      {/* 
      {showReligiousMigrationPopup && (
          <ReligionMigrationLitePopup
            profileDate={userData}
            
            close={() => setShowReligiousMigrationPopup(false)}
          />
        )} */}

      {/* { showcastepopup &&        
          <CommonCastPopup 
          profileDate={userData}  
         
          close={()=>setShowCastePopup(false)}/>
      } */}

      {/* <InjectScript script={scriptToInject} /> */}
      {localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite) ? (
        <>
          <div className='mobview bg-white'>
            <div className='mob-top md:!left-[68%] md:right-[7%]'>
              <div id='top1' className='md:h-[3rem]'>
                <p className='py-1 md:pt-2'>
                  {localStorage.getItem(
                    Constants.loginLocalStorageKeys.tagContent,
                  )}
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className='webview'>
            <div className='mob-top !left-[68%] right-[7%]'>
              <div id='top1' className='h-[3rem] !z-[11]'>
                <p className='pt-2'>
                  {localStorage.getItem(
                    Constants.loginLocalStorageKeys.tagContent,
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* <header className=" sticky top-0 z-10  w-full bg-white md:border-b-[5px] border-[#D10A11]"> */}
      <header
        id='mobile-header'
        className={`sticky top-0 z-10 w-full bg-white lg:bg-white border-b-[3px] border-[#D10A11] md:mt-0 ${isFrom == "conversation" && ""
          }`}>
                {
        (showLanguageOptions && !profileDropdown) && <LanguageSwitcher 
        changeLanguage={changeLanguage}
        languages={languages}
        onClose={()=>setShowLanguageOptions(false)}
        selectedLanguage={i18n.language}
        />
      }
        {isV3User ?
          <SidebarLite setShowLanguageOptions={setShowLanguageOptions} setShowParentNumPopup={setShowParentNumPopup} profileData={userData} onLogout={signout} />
          :
          <Sidebar setShowParentNumPopup={setShowParentNumPopup} profileData={userData} onLogout={signout} />
        }
        {isOpenFilter ? <FilterPanel /> : <></>}
        {successImagePopup && (
          <ImageSuccessPopup
            close={() => setSuccessImagePopup(false)}
            title={trans('photos.success')}
            content={successMessage}
            image={croppedImage}
            goto={() => {
              setMenuSelect("4");
              navigate(
                RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath),
                {
                  replace: isEditProfileMatch,
                },
              );
            }}
          />
        )}

        {showConfirmPopup && (
          <CommonConfrimPopup
            close={() => setShowConfirmPopup(false)}
          />

        )}
        {/* {showReligiousMigrationPopup && (
          <ReligionMigrationPopup
            profileDate={userData}
            close={() => setShowReligiousMigrationPopup(false)}
          />
        )} */}

        {/* { showcastepopup &&        
          <CommonCastPopup 
          profileDate={userData}  
          close={()=>setShowCastePopup(false)}/>
      } */}

        <div className=''>
          <div className='md:ml-0 md:flex lg:pt-0 md:justify-between items-center'>
            {/* <div className={`mobile-header flex justify-between items-center ${isFrom=="conversation" ? "md:h-[4rem] lg:h-[5rem] md:mr-2 lg:mr-3":"md:h-[5rem] lg:h-[6rem] md:pt-[1rem] mx-0 p-2 md:p-0 md:mx-3 lg:mx-9 "}`}> */}

            {/* <div
              onClick={onBack}
              className={`mx-2 ${showBackButton() ? "block absolute bottom-[18px] left-0" : "hidden"
                }  hidden cursor-pointer`}>
              <BsArrowLeft size={18} color='#D10A11' />
            </div> */}
            <div className='flex justify-between pt-3 pb-3 md:py-1 shrink-0'>
              <div>
                <Link
                  to={RouteSegments.MATCH_LITE}
                  className='flex lg:items-center pl-2'>
                  <img
                    src={KMLogo}
                    className='w-[7rem] h-[3rem] md:w-[10] md:h-[3.5rem] lg:w-[10rem] lg:h-[3.8rem] xl:w-[11rem] xl:h-[4.8rem] 2xl:w-[14rem] 2xl:h-[5.5rem]'
                    alt=''
                  />
                </Link>
              </div>

              {isLoggedIn() && <div className='flex  space-x-4 md:space-x-5  mt-[1rem] md:hidden lg:hidden mr-4'>


                <div>

                  <LiteNavItem

                    image={menuSelect === "9" ? groupiconred : groupiconred}
                    isSelected={menuSelect === "9"}
                    isShowCount={false}
                    callBack={() => {
                      onGroupClick()
                    }}

                  />
                </div>
                <div>

                  <LiteNavItem

                    image={menuSelect === "1" ? MatchIconRed : MatchIconRed}
                    isSelected={menuSelect === "1"}
                    isShowCount={false}
                    callBack={() => {
                      onMatchClick()
                    }}

                  />
                </div>
                <div >
                  {isFrom == "conversation" ? (
                    <LiteNavItem

                      image={
                        menuSelect === "4" ? FilterIconRed : FilterIconRed
                      }
                      isSelected={menuSelect === "4"}
                      isShowCount={false}
                      callBack={onFilterClick}
                      hoverImage={FilterIconRed}
                    />
                  ) : (
                    <LiteNavItem

                      image={
                        menuSelect === "4" ? FilterIconRed : FilterIconRed
                      }
                      isSelected={menuSelect === "4"}
                      isShowCount={false}
                      callBack={onFilterClick}
                      hoverImage={FilterIconRed}
                    />
                  )}
                </div>

                <div>
                  <LiteNavItem

                    image={menuSelect === "6" ? PaymentIconRed : PaymentIconRed}
                    isSelected={menuSelect === "6"}
                    isShowCount={false}
                    callBack={onPayNowClick}
                    hoverImage={PaymentIconRed}
                  />
                </div>

                <div className='relative'>
                  <LiteNavItem

                    image={menuSelect === "3" ? MessageIcon : MessageIcon}
                    isSelected={menuSelect === "3"}
                    isShowCount={totalUnreadCount > 0}
                    count={totalUnreadCount}
                    callBack={onInboxClick}
                    hoverImage={MessageIcon}
                    className="-top-[4px]  -right-[4px] "
                  />
                </div>

                <div className="hidden md:block">
                  <Popover
                    // onOpenChange={setShowNotificationPopup}
                    // open={showNotificationPopup}
                    overlayInnerStyle={{
                      border: "1px solid #FEBABA",
                      width: "20rem",
                      maxWidth: "200px",
                      minWidth: "250px",
                      padding: "0px",
                      left: 60,
                      top: 31,
                      position: "relative",
                      '@media (max-width: 768px)': {
                        width: "100%", // Adjust as needed
                        left: 30,
                        position: "absolute"
                      },
                    }}
                    onVisibleChange={(visible) => setNotificationVisible(visible)}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    trigger='click'
                    placement='bottom'
                    content={<NotificationPopoverContent notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} setNotificationCount={setNotificationCount} close={() => setShowNotificationPopup(false)} />}>
                    <div>
                      <LiteNavItem

                        image={
                          menuSelect === "5"
                            ? BellIcon
                            : BellIcon
                        }
                        isSelected={menuSelect === "5"}
                        // isShowCount={totalUnreadCount > 0}
                        // count={totalUnreadCount}
                        isShowCount={notificationCount > 0}
                        count={notificationCount}
                        callBack={onNotificationClick}
                        hoverImage={BellIcon}
                        className="-top-[9px] md:-top-[6px]  -right-[4px] "
                      />
                    </div>
                  </Popover>
                </div>
                <div className="block md:hidden">
                  <LiteNavItem
                    image={
                      menuSelect === "5"
                        ? BellIcon
                        : BellIcon
                    }
                    isSelected={menuSelect === "5"}
                    // isShowCount={totalUnreadCount > 0}
                    // count={totalUnreadCount}
                    isShowCount={notificationCount > 0}
                    count={notificationCount}
                    callBack={onMobileNotificationClick}
                    hoverImage={BellIcon}
                    className="-top-[9px] md:-top-[6px]  -right-[4px] "
                  />
                </div>
                <Badge overflowCount={999} count={totalPonits} size="small" color="#FDC300" className="reward-badge">
                  <img
                    className=' h-[20px] w-[20px]'
                    onClick={openDrawer}
                    src={ProfileIcon}
                    alt=''
                  />
                </Badge>
                {/* <div>
                  <div onClick={openDrawer}>
                    <img src={MenuIcon} className='w-[2rem] h-5' alt='Logo' />
                  </div>

                </div> */}

              </div>}

            </div>
            {isLoggedIn() && <section className="hidden md:block xl:h-[40px] md:h-[31px] h-[25px] lg:h-[30px]">
              <div
                className='hidden md:flex flex-col items-stretch w-full'
                x-data='{tab: 1}'>
                <div className={`flex cursor-pointer ${isTamil?"lg:space-x-2":"lg:space-x-0"} flex-row md:space-x-2   `}>

                  <LiteNavItem
                    title={trans('matches.family')}
                    image={menuSelect === "9" ? groupiconred : FamilyGroupicon}
                    isSelected={menuSelect === "9"}
                    isShowCount={false}
                    callBack={() => {
                      onGroupClick()
                    }}
                    hoverImage={groupiconred}
                  />
                  <LiteNavItem
                    title={trans('matches.matches')}
                    image={menuSelect === "1" ? MatchIconRed : MatchIconGrey}
                    isSelected={menuSelect === "1"}
                    isShowCount={false}
                    callBack={() => {
                      onMatchClick();
                      onClickTrack(EventName.Matches_Matches_Matches_Button)
                    }}
                    hoverImage={MatchIconRed}
                  />

                  {/* <LiteNavItem
                    title={"Group"}
                    image={
                      menuSelect === "2"
                        ? MatchesIconRed
                        : MatchesIcon
                    }
                    isSelected={menuSelect === "2"}
                    isShowCount={false}
                    callBack={() => {
                      navigate(RouteSegments.searchTab(1));
                      setMenuSelect("2");
                      setNavList(['Search'])
                    }}
                    hoverImage={MatchesIconRed}
                  /> */}

                  {
                    // isFrom == "conversation" ? (

                    <LiteNavItem
                      title={trans('matches.filter')}
                      image={
                        menuSelect === "4" ? FilterIconRed : FilterIconGrey
                      }
                      isSelected={menuSelect === "4"}
                      isShowCount={false}
                      callBack={() => {
                        onFilterClick();
                        onClickTrack(EventName.filterButon);
                      }}
                      hoverImage={FilterIconRed}
                    />

                    // ) 
                    // :
                    // (
                    //   <LiteNavItem
                    //     title={"Filter-lite"}
                    //     image={
                    //       menuSelect === "4" ? FilterIconRed : FilterIconGrey
                    //     }
                    //     isSelected={menuSelect === "4"}
                    //     isShowCount={false}
                    //     callBack={onFilterClick}
                    //     hoverImage={FilterIconRed}
                    //   />
                    // )
                  }
                  <div className='relative'>
                    <LiteNavItem
                      title={trans('matches.chat')}
                      image={menuSelect === "3" ? ChatIconRed : ChatIconGrey}
                      isSelected={menuSelect === "3"}
                      isShowCount={totalUnreadCount > 0}
                      count={totalUnreadCount}
                      callBack={() => {
                        onInboxClick();
                        onClickTrack(EventName.Chat_Chat_Chat_Button)
                      }}
                      hoverImage={ChatIconRed}
                      className="-top-[8px] right-[14px] md:right-[0px] lg:right-[20px] "
                    />
                  </div>
                  <LiteNavItem
                    title={trans('matches.my_profile')}
                    image={
                      menuSelect === "2" ? EditProfileIconRed : EditProfileIcon
                    }
                    isSelected={menuSelect === "2"}
                    isShowCount={false}
                    callBack={() => {
                      onProfileClick();
                      onClickTrack(EventName.profileMainButton);
                    }
                    }
                    hoverImage={EditProfileIconRed}
                  />
                  {/* <NavItem
                    title={"Calls"}
                    image={
                      menuSelect === "6"
                        ? CallIconRed
                        : CallIconGrey
                    }
                    isSelected={menuSelect === "6"}
                    isShowCount={false}
                    callBack={onPayNowClick}
                    hoverImage={CallIconRed}
                  /> */}

                  <Popover
                    onOpenChange={setShowNotificationPopup}
                    open={showNotificationPopup}
                    overlayInnerStyle={{
                      border: "1px solid #FEBABA",
                      width: "20rem",
                      maxWidth: "200px",
                      minWidth: "250px",
                      padding: "0px",
                      position: "relative",

                      top: 31,

                    }}
                    onVisibleChange={(visible) => setNotificationVisible(visible)}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    trigger='click'
                    placement='bottom'
                    content={<NotificationPopoverContent notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} setNotificationCount={setNotificationCount} close={() => setShowNotificationPopup(false)} />}>
                    <div>
                      <LiteNavItem
                        title={trans('matches.notification')}
                        image={
                          menuSelect === "5"
                            ? NotificationIconRed
                            : NotificationIcon
                        }
                        isSelected={menuSelect === "5"}
                        isShowCount={notificationCount > 0}
                        count={notificationCount}
                        callBack={onNotificationClick}
                        hoverImage={NotificationIconRed}
                        wrapperClassName="px-2"
                        className="-top-[8px] md:right-[20px] xl:right-[28px]"
                      />
                    </div>
                  </Popover>
                  <LiteNavItem
                    title={trans('matches.pay_now')}
                    image={menuSelect === "6" ? PaymentIconRed : PaymentIcon}
                    isSelected={menuSelect === "6"}
                    isShowCount={false}
                    callBack={onPayNowClick}
                    hoverImage={PaymentIconRed}
                  />
                  <LiteNavItem
                    title={trans('matches.store')}
                    image={
                      menuSelect === "7"
                        ? kalyanJewellery
                        : kalyanJewellery
                    }
                    isSelected={menuSelect === "7"}
                    isShowCount={false}
                    callBack={onStoreClick}
                    hoverImage={kalyanJewellery}
                  />
                  <div className="hidden lg:block">
                    <LiteNavItem
                      isRewardBadge={true}
                      totalPonits={totalPonits}
                      title={<span className="text-center">{trans('matches.kalyanrewards')}</span>}
                      image={Images.TrophyIcon}
                      isSelected={menuSelect === "8"}
                      isShowCount={false}
                      callBack={onKalyanRewardsClick}
                      hoverImage={Images.TrophyIcon}
                    />
                  </div>
                  {/* <a
                  className=" font-semibold py-5 text-[12px] text-[#7A7A7A] flex flex-col  items-center     opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 2, ' transform ': tab !== 2}"
                  href="#"
                  //  @click.prevent="tab= 2"
                  //  @click.prevent="tab= 2"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]  items-center"
                    src={MultiUserIcon}
                  />
                  Matches
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col items-center font-semibold font-segeo opacity-[.8] items-center"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 3, 'transform ': tab !== 3}"
                  href="#"
                  //  @click.prevent="tab= 3"
                  //  @click.prevent="tab= 3"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]   items-center"
                    src={SearchIconGrey}
                  />
                  Search
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1]   transform  ': tab
                  //  === 4, 'transform ': tab !== 4}"
                  //  href="#" @click.prevent="tab= 4"
                  //  @click.prevent="tab= 4"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]   items-center"
                    src="/Assets/Images/Group 536.svg"
                  />
                  {props.data.profilemessage_count === "0" ? (
                    <></>
                  ) : (
                    <button type="button" className=" ">
                      <div
                        className="absolute inline-flex items-center
                          justify-center w-6 h-6                         
                           top-5
                           md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px]
                          
                          font-bold text-white bg-[#FDC300] rounded-full"
                        //   "absolute inline-flex
                        //           top-2 left-8 md:left-5 lg:left-8
                        //          justify-center items-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px]
                        //          font-bold  text-white bg-[#FDC300] rounded-full"
                      >
                        {props.data.profilemessage_count}
                      </div>
                    </button>
                  )}
                  <p className="font-semibold">Inbox</p>
                </a>

                <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] transform ': tab
                  //  === 5, 'transform ': tab !== 5}"
                  //  href="#" @click.prevent="tab= 5"
                  //  @click.prevent="tab= 5"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px] "
                    src="/Assets/Images/Group 407.svg"
                  />
                  <span>Edit Profile</span>
                </a>
                <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1]   transform  ': tab
                  //  === 6, 'transform ': tab !== 6}"
                  //  href="#" @click.prevent="tab= 6"
                  //  @click.prevent="tab= 6"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px]  flex flex-col items-center"
                    src="/Assets/Images/Group 653.svg"
                  />
                  <p>Notification</p>
                  {props.data.profilenotification_count === "0" ? (
                    <></>
                  ) : (
                    <button type="button" className=" ">
                      <div
                        className="absolute inline-flex items-center
                                 justify-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold
                                 text-white 
                                 top-5
                                 bg-[#FDC300] rounded-full"
                        // "absolute inline-flex top-2 left-8 md:left-8 lg:left-9 justify-center items-center w-6 h-6 md:w-4 md:h-4 lg:w-6 lg:h-6 text-[12px] md:text-[8px] lg:text-[12px] font-bold text-white bg-[#FDC300] rounded-full"
                      >
                        {props.data.profilenotification_count}
                      </div>
                    </button>
                  )}
                </a> */}
                  {/* <a
                  className=" py-5 text-[12px] text-[#7A7A7A] flex flex-col
                           items-center font-semibold font-segeo
                           opacity-[.8]"
                  //  :className="{'text-[#D10A11] opacity-[1] ': tab
                  //  === 7, 'transform ': tab !== 7}"
                  href="#"
                  //  @click.prevent="tab= 7"
                  //  @click.prevent="tab= 7"
                >
                  <img
                    className="md:h-[20px] md:w-[20px] lg:h-[27px] lg:w-[40px] "
                    src="/Assets/Images/Group 6.svg"
                  />
                  <p>Pay Now</p>
                </a> */}
                </div>
              </div>
            </section>}
            <div className='hidden md:py-0 lg:py-0 mr-3  md:flex cursor-pointer md:space-x-2 lg:space-x-2 relative '>
              {/* <p onClick={onSwitchClick} className="absolute text-[12px] top-[1px] right-[1px] text-[#1976D2] underline">👈Back to older version</p> */}
              <div className={`md:block lg:block ${isCommunitySite ? "invisible" : ""}`}>
                <p className='text-[8px] md:text-[10px] md:pt-1 lg:pt-0 lg:text-[12px] my-0.5 flex justify-center text-[#575556] font-semibold'>

                  {trans('matches.download_our_app')}
                </p>
                <div className='flex justify-center space-x-2 lg:space-x-3 lg:mt-0'>
                  <a
                    href={RouteSegments.staticLinkForAppDownload.android}
                    target='_blank'>
                    <img
                      className='w-[11rem] md:w-[3.5rem]  lg:w-[7rem] xl:w-[7.5rem]'
                      src={GPlayIcon}
                      alt=''
                    />
                  </a>
                  <a
                    href={RouteSegments.staticLinkForAppDownload.ios}
                    target='_blank'>
                    <img
                      className='w-[11rem] md:w-[3.5rem] lg:w-[7rem] xl:w-[7.5rem]'
                      src={AppStoreIcon}
                      alt=''
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="flex gap-5 justify-center">
                <p className="font-seoge-semibold font-semibold text-[8px] md:text-[9px] lg:text-[10px] md:pt-2 md:py-1.5 flex justify-center text-[#D10A11] pr-[2rem] md:pr-0 "><a className="text-center" href="/gold-saving-scheme-online" target="_blank">{trans('matches.buy_gold_saving_scheme_online')}</a></p>

              {/* <span className=" whitespace-nowrap font-seoge-semibold font-semibold text-[8px] md:text-[9px] lg:text-[10px] md:pt-2 
              md:py-1.5 flex justify-center text-[#D10A11] pr-[2rem] md:pr-0 " 
              >Choose language</span> */}
            
               {/* <Select
                dropdownAlign={{ offset: [0, 8] }} // Ensure dropdown opens below the text
                style={{ minWidth: 150 }}
                value={i18n.language}
                className="!text-[12px]"
                onChange={changeLanguage}
                options={languages.map((lang) => ({ value: lang.value, label: lang.label }))}
              /> */}
              {/* <select
              className="px-4 !text-[12px]"
              onChange={(e) => changeLanguage(e.target.value)}
              value={i18n.language}
              placeholder="choose"
            >
              {languages.map((lang) => (
                <option value={lang.value} key={lang.value}>
                  {lang.label}
                </option>
              ))}
           </select> */}
      {/* <Popover
        content={dropdownContent}
        trigger="click"
        visible={open}
        onVisibleChange={(visible) => setOpen(visible)}
        placement="bottom"
        overlayClassName="multilanguage"
        overlayInnerStyle={{
          padding: 0,
          left: 14,
          top: 32,
          position: "relative",

        }}

      >
        <span
          className="whitespace-nowrap  font-seoge-semibold font-semibold absolute right-[1rem] text-[8px] md:text-[9px] lg:text-[10px] md:pt-2 md:py-1.5 flex justify-center text-[#D10A11] pr-[2rem] md:pr-0  cursor-pointer"
        >
          Choose language
        </span>
      </Popover> */}


           
      
           
            </div>
              </div>
              <div className="flex-col shrink-0">
              <div className="flex space-x-1.5">
              <div
                className={
                  menu === "footermenu"
                    ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5"
                    : location?.state?.isfrom === "report"
                      ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5"
                      : "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5"
                }>
                <a
                  //  href={RouteSegments.HELP_AND_FAQ()}

                  onClick={
                    () => {
                      if (showClearFilterPopup) {
                        setShowParentPopup(true);
                        setSetPath(RouteSegments.HELP_AND_FAQ())
                      } else {
                        navigate(RouteSegments.HELP_AND_FAQ());
                        if (RouteSegments.HELP_AND_FAQ()) {
                          // window.location.reload()
                        }
                      }
                    }
                    // navigate(RouteSegments.HELP_AND_FAQ())
                  }
                  // window.location.reload(),

                  className=' font-segeo font-normal text-[13px] text-[#7A7A7A]'>
                  <img
                    className=' md:h-[20px] md:w-[20px] lg:h-[32px] xl:w-[40px] lg:w-[28px]  items-center'
                    src={HelpIcon}
                    alt=''
                  />
                </a>
              </div>
                 
              {isLoggedIn() && <div className="md:block relative">
                <div
                  className={
                    menu === "footermenu"
                      ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                      : location?.state?.isfrom === "report"
                        ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                        : "flex cursor-pointer  md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                  }
                  onClick={() => {
                    onClickTrack(EventName.Three_Dots_Profile_Photo_Profile_Photo_Button)
                    setProfileDropdown(!profileDropdown);
                  }}>
                  <img
                    className=' md:h-[20px] md:w-[20px] lg:h-[32px] xl:w-[40px] lg:w-[28px]   items-center'
                    src={ProfileIcon}
                    alt=''
                  />
                </div>
                {profileDropdown && <OutsideClickListener onOutsideClick={()=>{
                  setProfileDropdown(false)
                  setShowLanguageOptions(false)
                }} 
                className="top-[60px] right-0 absolute z-50">
                  <ProfileDropdown />
                  </OutsideClickListener>}
              </div>}


              {isLoggedIn() && <div className=" md:hidden"><Popover

                overlayInnerStyle={{
                  padding: 0,
                  left: -22,
                  top: 30,
                  position: "relative",

                }}


                overlayClassName="logoutpopup"

                // getPopupContainer={triggerNode => triggerNode.parentNode}
                trigger='click'
                placement='bottom'
                content={ <ProfileDropdown />}>
                <div
                  className={
                    menu === "footermenu"
                      ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                      : location?.state?.isfrom === "report"
                        ? "flex cursor-pointer md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                        : "flex cursor-pointer  md:mt-5 md:pt-1 mt-4 xl:mt-5 2xl:mt-5 lg:mt-4 pt-2.5 pe-3"
                  }
                  onClick={() => {
                    onClickTrack(EventName.Three_Dots_Profile_Photo_Profile_Photo_Button)
                    setProfileDropdown(!profileDropdown);
                  }}>

                  <Badge overflowCount={999} count={totalPonits} size="small" color="#FDC300" className="reward-badge">
                    <img
                      className=' md:h-[20px] md:w-[20px] lg:h-[32px] xl:w-[40px] lg:w-[28px]   items-center'
                      src={ProfileIcon}
                      alt=''
                    />
                  </Badge>
                </div>
              </Popover>  </div>}
            </div>
           
            {/* <Dropdown
        menu={{
          onClick:changeLanguage,
          items :languages.map(lang=>({key:lang.code, label:lang.name})),
          selectable:true,
          
          defaultSelectedKeys:i18n.language
        }}
        placement="bottomRight"
      >
        <span
          className="whitespace-nowrap  font-seoge-semibold font-semibold absolute right-[-6px] text-[8px] md:text-[9px] lg:text-[12px] md:pt-2 md:py-1.5 flex justify-center text-[#D10A11] pr-[2rem] md:pr-0  cursor-pointer"
        >
          <IoLanguage className="mr-1 h-4 w-4 shrink-0" />
          {languages.find(lang=>lang.code == i18n.language)?.name}
        </span>
      </Dropdown> */}
            
            {/* <p  onClick={() => setShowDropdown(!showDropdown)} className="font-seoge-semibold font-semibold text-[8px] md:text-[9px] lg:text-[10px] md:pt-2 md:py-1.5 flex justify-center text-[#D10A11] pr-[2rem] md:pr-0 ">
            Choose language</p> */}
       
     
 

          
            </div>
            </div>

            {/* <ProfileDropdown /> */}

            {/* <div className=" md:hidden cursor-pointer flex mx-2 ml-[6rem] space-x-4"> */}

            {/* <div onClick={onInboxClick}>
            <img
                      src={CamerIcon}
                      className="h-4"
                      alt="Logo"
                    />
              {/* <img alt="Pay now" className="w-6 h-6 cursor-pointer " src={PaymentIconRed} /> */}
            {/* </div>
            <div onClick={onInboxClick}>
            <img
                      src={SearchIcon}
                      className="h-4"
                      alt="Logo"
                    /> */}
            {/* <span className="relative inline-block">
                <img alt="" className="w-6 h-6 " src={ChatIcon} />
                {data?.profilemessage_count != "0" && data?.profilemessage_count
                  ? <span className="absolute top-[3px] left-[0.6rem] inline-flex items-center justify-center px-[5px] py-[2px] text-[13px] font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full ">
                    {data?.profilemessage_count}
                  </span>
                  : null}
              </span> */}
            {/* </div>   

            <div onClick={onMobileNotificationClick}>
            <img
                      src={ThreeDotIcon}
                      className="h-4"
                      alt="Logo"
                    /> */}
            {/* <span className="relative inline-block">
                <img alt="" className="w-6 h-6" src={NotificationMobIcon} />
                {notificationCount > 0
                  ? <span className="absolute top-[3px] left-[0.6rem] inline-flex items-center justify-center  px-[5px] py-[2px] text-[13px] font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#FDC300] rounded-full ">
                    {notificationCount}
                  </span>
                  : null}
              </span> */}
            {/* </div> */}
            {/* <div onClick={onProfileClick}>
              <img alt="Profile" className="w-6 h-6" src={ProfileIcon} />
            </div> */}

            {/* <div onClick={openDrawer}>
              <img alt="Menu" className="w-6 h-6" src={DrawerIcon} />
            </div> */}
            {/* <div className="flex space-x-4 md:hidden lg:hidden">
            <MobileNavigation />
          
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};



export default NavBar;
