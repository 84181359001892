import React, { useEffect, useState, useContext, useMemo } from "react";
import Constants, { loginFrom , EventName, EventKeys } from "../../Constants/constants";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST } from "../../Services/api_services";
import { ProfileAccordianComponent } from "../ViewMessageComponents/AccordianComponents";

import { LoginContext } from "../../Contexts/LoginContext";
import {
  ProfileSettingsPhoneField,
  ProfileSettingsSelectField,
  ProfileSettingsTextField,
  SettingsRadioButton,
} from "./SettingsComponents/SettingsUtills";
import * as Yup from "yup";
import { useFormik } from "formik";
import OtpPopup from "./SettingsComponents/OtpPopup";
import WarningPopup from "./SettingsComponents/WarningPopup";
import PasswordPopup from "./SettingsComponents/PasswordPopup";
import { NotificationPopup } from "../CommonPopup";
import Loader from "../Loader"
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import RouteSegments, { RoutePaths } from "../../Routes/RouteSegment";
import config from "../../config";
import { NavListContext } from "../../Contexts/navContext";
import PlusIcon from "../../Images/Path 3979.svg"
import AccrodianSelectImage from '../../Images/Group 930@2x.png'

import { useAnalytics } from "../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";
import { getDeviceInfo } from "../Kalyanlite/utils";


const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const validEmailMsg = ('my_profile.please_enter_valid_email')
// const validPasswordMsg = "Please enter valid password"


const ProfileSettingsComponents = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [emailAlertsData, setEmailAlerts] = useState([]);
  const [profileStatusData, setProfileStatus] = useState([]);
  const [phonePrivacyData, setPhonePrivacy] = useState([]);
  const [horoscopePrivacyData, setHoroscopePrivacy] = useState([]);
  const [photoePrivacyData, setPhotoPrivacy] = useState([]);
  const [showOtpPopup, setShowOTPpopup] = useState(false);
  const [showWarningPopup, setShowWarningpopup] = useState(false);
  const [showPasswordPopup, setShowPasswordpopup] = useState(false);
  const [photoPassword, setPhotoPassword] = useState("");
  const [emailSave, setEmailSave] = useState(false)
  const [settingSave,setSettingSave] = useState(false)

  const [isOTPSending, setIsOTPSending] = useState(false)
  const [isProfileStatusUpdating, setIsProfileStatusUpdating] = useState(false)
  const [isEmailUpdating, setIsEmailUpdating] = useState(false)
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false)
  const [notification, setNotification] = useState({ show: false, title: "", message: "" })
  const navigate = useNavigate();
  const { navList, setNavList } = useContext(NavListContext);

  const isV3User = localStorage.getItem("isV3User")
  const { onClickTrack } = useAnalytics()
  const { t: trans } = useTranslation();
  



  const formik = useFormik({
    initialValues: {
      phoneNum: props?.data?.mobileNumber ? props?.data?.mobileNumber : "",
      profileStatus: props?.data?.profileStatus
        ? props?.data?.profileStatus?.filter((e) => e.selected === "Y")[0]?.value
        : "",
      eMail: props?.data?.email ? props?.data?.email : "",
      isdCode: props?.data?.country
        ? props?.data?.country?.filter((e) => e.selected === "Y")[0]?.id
        : "",
      password: props?.data?.password ? props?.data?.password : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // phoneNum: Yup.string()
      //   .min(10, "Length should be 10")
      //   .required("Please enter phone number"),
      phoneNum: Yup.string()
      .when('isdCode', (isdCode, schema) => {
        if (isdCode == '+91') {
          return schema
            .min(10, trans('family.please_enter_a_valid_mobile_nu'))
            .max(10, trans('family.please_enter_a_valid_mobile_nu'))
            .required(trans('family.please_enter_mobile_number'));
        } else if(isdCode !== '+91'){
          return schema
            .min(7, trans('family.please_enter_a_valid_mobile_nu'))
            .max(15, trans('family.please_enter_a_valid_mobile_nu'))
            .required(trans('family.please_enter_mobile_number'));
        }
      }),
      profileStatus: Yup.string(),
      eMail: Yup.string().required(trans('my_profile.please_enter_email')).email(trans(validEmailMsg)).matches(emailRegex, trans(validEmailMsg)),
      password: Yup.string().required(trans('my_profile.please_enter_password'))
        .min(6, trans('my_profile.password_must_contain_at_least', {number: 6}))
        .max(15, "Password must less than 16 characters")

    }),

    // onSubmit: (values) => {
    //   // SubmitCall(values);
    // },
  });
  useEffect(() => {
    //console.log("settings", props.data);
    setEmailAlerts(props?.data?.emailAlerts);
    setProfileStatus(props?.data?.profileStatus);
    setPhonePrivacy(props?.data?.phonePrivacy);
    setHoroscopePrivacy(props?.data?.horoPrivacy);
    setPhotoPrivacy(props?.data?.photoPrivacy);
  }, [props.data]);
  useEffect(() => {
    //console.log(
    //   "emailAlertsData",
    //   phonePrivacyData,
    //   horoscopePrivacyData,
    //   photoePrivacyData,
    //   emailAlertsData,
    //   profileStatusData
    // );
  }, [
    emailAlertsData,
    profileStatusData,
    phonePrivacyData,
    horoscopePrivacyData,
    photoePrivacyData,
  ]);

  const apiCallEmailAlert = async () => {
    setEmailSave(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      matchingProfileAlertName: emailAlertsData.filter(
        (e) => e.selected === "Y"
      )[0].id,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.settingsEmailAlertUrl()}`,
      request
    );
    if (statusCode === 200) {
      //console.log("data.", data.data);
      if (data.status == "Success") {
        // alert("Email Alert Saved");
        // setEmailSave(false)
        
        setTimeout(function () {
          setEmailSave(false)
          onClickTrack(EventName.profileSettingsSaveButton, {
            [EventKeys.profileSettingsSave]: request
          });
        }, 1000);
        
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const onSubmitPhoneNum = () => {
    //console.log("phonesubmit");
  };
  const onSubmitEmail = () => {
    if (isEmailUpdating) return;
    //console.log("emailsubmit");
    setIsEmailUpdating(true)

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],

      userName: formik.values.eMail,
    };

    const callBackOnSucess = () => {
      localStorage.setItem(Constants.loginLocalStorageKeys.loginName, formik.values.eMail)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginEmail,formik.values.eMail)
    }
    apiCallSaveSettings(request, callBackOnSucess);
    onClickTrack(EventName.profileEmailIdSaveButton, {[EventKeys.profileEmailIdSave]:request})
  };
  const onSubmitProfileStatus = () => {
    if (isProfileStatusUpdating) return;
    //console.log("onSubmitProfileStatus");
    setIsProfileStatusUpdating(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      profileVisibility: profileStatusData.filter((e) => e.selected === "Y")[0]
        .value,
    };
    apiCallSaveSettings(request);
    onClickTrack(EventName.profileProfileStatusSaveButton, {[EventKeys.profileStatusSave]:request})
  };

  
  const onSubmitPassword = () => {
    
    
    if (isPasswordUpdating) return;

    if (formik.values.password === ""){
      return
    }
    setIsPasswordUpdating(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],

      password: formik.values.password,
    };
    apiCallSaveSettings(request);
    //console.log("onSubmitPassword");
    onClickTrack(EventName. profilePasswordSaveButton , {[EventKeys.profilePaswordSave]:request})
  };
  
  const apiCallSaveSettings = async (request, callBack) => {
    console.log(request,'Save a EmailId');
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.saveSettings()}`,
      request
    );
    if (statusCode === 200) {
      //console.log("data.", data.data);
      if (data.status == "Success") {
        const responseData = data?.data;

        if (responseData?.status === "SUCCESS") {
          if (callBack) {
            callBack();
          }
          props.refresh()
          showSucessNotification({ message: "Profile settings Saved" })
        } else if (responseData?.status === "FAILURE") {
          if(responseData?.message === "Phone number cannot be set as password"){
            formik.setFieldError("password",responseData?.message)
          } else if(responseData?.statusMessage === "email already exist"){
            formik.setFieldError("eMail",responseData?.statusMessage)
          }else if (responseData?.statusMessage === "Mobile Number Already Exist") {
            formik.setFieldError("phoneNum", responseData?.statusMessage); 
          }
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsProfileStatusUpdating(false)
    setIsEmailUpdating(false)
    setIsPasswordUpdating(false)
  };
  const apiCallPrivacySettiting = async () => {
    setSettingSave(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      photoPrivacyname: photoePrivacyData.filter((e) => e.selected === "Y")[0]
        .id,
      photoPassword: photoPassword,
      horoscopePrivacyname: horoscopePrivacyData.filter(
        (e) => e.selected === "Y"
      )[0].id,
      phonePrivacyname: phonePrivacyData.filter((e) => e.selected === "Y")[0]
        .id,
        ...(props.isFromInitialPopup === true ? { notificationId: props.notificationId } : ''),
      // messageTypeId:"2",
      // kmcareId: "1"

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.savePrivacySettings()}`,
      request
    );
    if (statusCode === 200) {
      //console.log("data.", data.data);
      if (data.status == "Success") {
        // alert("Profile settings Saved");
        setTimeout (function () {
          setSettingSave(false)
          onClickTrack(EventName.profilePrivacySettingsSaveButton, { [EventKeys.profilePrivacySettingsSave]: request });
        },1000)
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apicallSendOtp = async () => {
    if (isOTPSending) return;
    // 9678456432
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      isdcode: formik.values.isdCode,
      mobileNumber: formik.values.phoneNum,
      fromPage:"myprofile",
      loginFrom: getDeviceInfo()

    };
    setIsOTPSending(true);
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.sendOtpUrl()}`,
      request
    );
    if (statusCode === 200) {
      //console.log("data.", data.data);
      if (data.data.status == "SUCCESS") {
        setShowOTPpopup(true);
        onClickTrack(EventName.profilePhoneNumberSaveButton , {[EventKeys.profilePhoneNumberSave]:request})
      } else {
        setShowOTPpopup(false)
        formik.setFieldError("phoneNum", data.data.statusMessage);
        //console.log(data.data.statusMessage);
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsOTPSending(false);
  };

  const onVerified = () => {
    localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, formik.values.phoneNum)
    props.refresh()
    setShowOTPpopup(false)
  }

  const closeNotification = () => {
    setNotification(prev => ({ ...prev, show: false }))
  }

  const showSucessNotification = ({ message, title = trans('photos.success') }) => {
    setNotification(prev => ({ ...prev, show: false, message, title }))
  }

  const onMobileNumChange = (e) => {
    let text = e.target.value ?? "";

    text = text.replace(/[^0-9]/g, "")
    if (text.length > 16) {
      formik.setFieldValue('phoneNum', formik.values.phoneNum)
    } else {
      formik.setFieldValue('phoneNum', text)
    }
  }
  const disableSave = useMemo(() => {
    if (props.error) {
      return true;
    } else if (props?.value === props?.data?.email) {
      return true;
    } else if (props.isLoading) {
      return true;
    }

    return false
  }, [props.error, props?.data?.email, props.isLoading, props.value])

  // const onEditClick = () => {
  //   setEditView(!isShowEdit);
  // }


  //console.log('isOTPSending', isOTPSending);
  // Only paid members can select this option. Upgrade Now and enjoy our services.
  return (
    <div x-show="tab === 7">
      {showOtpPopup ? (
        <OtpPopup
          callBackOTP={apicallSendOtp}
          close={() => setShowOTPpopup(false)}
          mobileNum={formik.values.phoneNum}
          isdCode={formik.values.isdCode}
          onVerified={onVerified}
        />
      ) : (
        <></>
      )}
      {showWarningPopup ? (
        <WarningPopup
          close={() => setShowWarningpopup(false)}
          title={"Suggestions"}
          content={""}
        >
          <p className="text-[14px] font-semibold">
            Only paid members can select this option.{" "}
            <NavLink
              to={RouteSegments.MEMBERSHIP_REGISTER()}
              className="underline cursor-pointer text-[#0000ff]"
            >
              Upgrade Now and enjoy our services.
            </NavLink>
          </p>
        </WarningPopup>
      ) : (
        <></>
      )}
      {showPasswordPopup ? (
        <PasswordPopup
          data={formik}
          callBackApply={(val) => {
            setPhotoPassword(val);
            setShowPasswordpopup(false);
          }}
          close={() => setShowPasswordpopup(false)}
        />
      ) : (
        <></>
      )}
      {
        notification.show
          ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
          : null
      }
      <div className="  px-[1rem] md:px-0 mx-auto mt-8">
        <div className="">
          <div className={`${isV3User ? "bg-white" : "rounded-[9px] md:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={trans('my_profile.emails_alerts')}
              titleClassName={`flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] ${isV3User ? "bg-[#DADADAE0]" : "bg-[#E9E9E9] md:py-[0.5rem]"} lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
              defaultView={false}
              titleIcon1={
                <img src={AccrodianSelectImage} className="" alt="" />
              }
              titleIcon2={
                <img src={PlusIcon} className="" alt="" />
              }
              Callback={() => { onClickTrack(EventName.profileSettingsEmailAlertButton) }}
            >
              <div className="p-[1rem]">
                {/* <div className=" flex justify-end pr-[1rem] mt-2 ">
                  <div className="flex justify-between items-center ">
                    <img className="w-[1.3rem]" src={EditIcon} />
                  </div>
                  <p className=" md:text-[12px] lg:text-[14px] flex items-center text-[#D10A11] font-semibold pl-2 ">
                    Edit
                  </p>
                </div> */}
               
                     <div className="   pl-[1rem]  justify-between  ">
                      {emailSave ? 
                      <Loader/> :
                        <>
                          {emailAlertsData?.map((e, i) => (
                            <SettingsRadioButton
                              id={e.id}
                              key={i}
                              name={e.email}
                              selected={e.selected} 
                              radiotext={e.email}
                              onChange={(item) => {
                                //console.log("radio", item.target.value);
                                var newVal = emailAlertsData.map((ele, i) => {
                                  if (item.target.value === ele.email) {
                                    return { ...ele, selected: "Y" };
                                  } else {
                                    return { ...ele, selected: "N" };
                                  }
                                });
                                setEmailAlerts(newVal);
                              }}
                              isFrom={"email"}
                            />
                          ))}

                          <button
                            // onClick={() => {
                            //   apiCallEmailAlert();
                            // }}
                            disabled={disableSave}
                            onClick={() => {
                              apiCallEmailAlert();
                            }}
                            className={`  mt-2 mb-[1rem] flex items-center space-x-2 justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] ${disableSave ? "opacity-50" : ""}`}>
                            
                            <span className="truncate">
                              {emailSave ? trans('my_profile.saving') : trans('my_profile.save')}
                            </span>
                          </button>
                      </>
                        }
                    </div>
                
              </div>
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className="  px-[1rem] md:px-0 mx-auto mt-8">
        <div className="">
          <div className={`${isV3User ? "bg-white" : "rounded-[9px] md:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={trans('my_profile.profile_settings')}
              titleClassName={`flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] ${isV3User ? "bg-[#DADADAE0]" : "bg-[#E9E9E9] md:py-[0.5rem]"} lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
              // defaultView={true}
              defaultView={props.isFromInitialPopup == true ? false : true}
              titleIcon1={
                <img src={AccrodianSelectImage} className="" alt="" />
              }
              titleIcon2={
                <img src={PlusIcon} className="" alt="" />
              }
              Callback={() => { }}
            >
              {
                props.isLoading
                  ? <Loader />
                  : <div className="mb-8 pl-3">
                    <ProfileSettingsPhoneField
                      key={props?.data?.mobileNumber}
                      title={trans('view_profile.phone_number')}
                      value={props?.data?.mobileNumber}
                      inputType={"text"}
                      inputID={"phoneNum"}
                      inputName={"phoneNum"}
                      inputvalue={formik.values.phoneNum}
                      onChange={onMobileNumChange}
                      error={formik.errors.phoneNum}
                      onSave={apicallSendOtp}
                      selectType={"select"}
                      selectID={"isdCode"}
                      selcectName={"isdCode"}
                      selcetValue={formik.values.isdCode}
                      onChangeSelect={formik.handleChange}
                      options={props?.data?.country}
                      setDefault={() => {
                        formik.setFieldValue('phoneNum', props?.data?.mobileNumber)
                        formik.setFieldValue('isdCode', props?.data?.country
                          ? props?.data?.country?.filter((e) => e.selected === "Y")[0]?.id
                          : "")
                      }}
                    />
                    <ProfileSettingsSelectField
                      title={trans('my_profile.profile_status')}
                      value={
                        props?.data?.profileStatus?.filter(
                          (e) => e.selected === "Y"
                        )[0]?.name
                      }
                      inputType={"select"}
                      inputID={"profileStatus"}
                      inputName={"profileStatus"}
                      inputvalue={formik.values.profileStatus}
                      onChange={(e) => {
                        var newVal = profileStatusData.map((ele, i) => {
                          if (e.target.value === ele.value) {
                            if (e.target.value === "delete") {
                              setNavList(prev=>([...prev,"Delete Profile"]))
                              navigate(RoutePaths.REMOVE_PROFILE)
                            }
                            return { ...ele, selected: "Y" };
                          } else {
                            return { ...ele, selected: "N" };
                          }
                        });
                        setProfileStatus(newVal);
                        formik.handleChange(e);
                      }}
                      error={formik.errors.profileStatus}
                      onSave={onSubmitProfileStatus}
                      options={profileStatusData}
                      isLoading={isProfileStatusUpdating}
                      status={props?.editprofilestatus}
                      setDefault={() => formik.setFieldValue('profileStatus', props?.data?.profileStatus?.filter((e) => e.selected === "Y")[0]?.id)}
                    />
                    <ProfileSettingsTextField
                      key={props?.data?.email}
                      title={trans('my_profile.email_id')}
                      value={props?.data?.email}
                      inputType={"email"}
                      inputID={"eMail"}
                      inputName={"eMail"}
                      inputvalue={formik.values.eMail}
                      onChange={formik.handleChange}
                      error={formik.errors.eMail}
                      onSave={onSubmitEmail}
                      isLoading={isEmailUpdating}
                      setDefault={() => formik.setFieldValue('eMail', props?.data?.email)}
                    />
                    <ProfileSettingsTextField
                      key={props?.data?.password}
                      title={trans('my_profile.password')}
                      value={"*********"}
                      inputType={"password"}
                      inputID={"password"}
                      inputName={"password"}
                      inputvalue={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.errors.password}
                      onSave={onSubmitPassword}
                      isLoading={isPasswordUpdating}
                      setDefault={() => formik.setFieldValue('password', props?.data?.password)}
                    />

                    {/* {//console.log(formik.errors.password, "formik.errors.password")} */}
                  </div>
              }
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className="  px-[1rem] md:px-0 mx-auto mt-8 mb-[7rem] ">
        <div className="">
          <div className={`${isV3User ? "bg-white" : "rounded-[9px] md:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={trans('my_profile.privacy_settings')}
              titleClassName={`flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] ${isV3User ? "bg-[#DADADAE0]" : "bg-[#E9E9E9] md:py-[0.5rem]"} lg:py-[1rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
              // defaultView={false}
              defaultView={props.isFromInitialPopup == true ? true : false }
              titleIcon1={
                <img src={AccrodianSelectImage} className="" alt="" />
              }
              titleIcon2={
                <img src={PlusIcon} className="" alt=""/>
              }
              Callback={() => { onClickTrack(EventName.profilePrivacySettingPlusButton) }} 
            >
              {settingSave ?  
                <Loader/> :
                <div className="p-[1.6rem]">
                  <div className="text-black font-semibold text-[11px] md:text-[11px] lg:text-[13px] pl-[1rem]  ">
                  {trans('view_profile.phone_number')}
                  </div>
                  <div className="  mt-4  pl-[2rem]  justify-between  ">
                    {phonePrivacyData?.map((e, i) => (
                      <SettingsRadioButton
                        id={e.id}
                        key={i}
                        name={e.phonePrivacy}
                        selected={e.selected}
                        radiotext={e.phonePrivacy}
                        onChange={(item) => {
                          //console.log("radio", item.target.value, i);
                          // if (i === 1 && props.isPaid == "N") {
                          //   setShowWarningpopup(true);
                          //   return;
                          // }
                          var newVal = phonePrivacyData.map((ele, i) => {
                            if (item.target.value === ele.phonePrivacy) {
                              return { ...ele, selected: "Y" };
                            } else {
                              return { ...ele, selected: "N" };
                            }
                          });
                          setPhonePrivacy(newVal);
                        }}
                        isFrom={"phone"}
                      />
                    ))}
                  </div>
                  <div className="text-black mt-[1rem] font-semibold text-[11px] md:text-[10px] lg:text-[13px] pl-[1rem]  ">
                  {trans('view_profile.horoscope')}
                  </div>
                  <div className="  mt-4  pl-[2rem]  justify-between  ">
                    {horoscopePrivacyData?.map((e, i) => (
                      <SettingsRadioButton
                        id={e.id}
                        key={i}
                        name={e.id}
                        selected={e.selected}
                        radiotext={e.horoPrivacy}
                        onChange={(item) => {
                          //console.log("radio", item.target.value);
                          // if (i === 1 && props.isPaid == "N") {
                          //   setShowWarningpopup(true);
                          //   return;
                          // }
                          var newVal = horoscopePrivacyData.map((ele, i) => {
                            if (item.target.value === ele.id) {
                              return { ...ele, selected: "Y" };
                            } else {
                              return { ...ele, selected: "N" };
                            }
                          });
                          setHoroscopePrivacy(newVal);
                        }}
                        isFrom={"horo"}
                      />
                    ))}
                  </div>
                  <div className="text-black mt-[1rem] font-semibold text-[11px] md:text-[10px] lg:text-[13px] pl-[1rem]  ">
                  {trans('my_profile.photo')}
                  </div>
                  <div className="  mt-4  pl-[2rem]  justify-between  ">
                    {photoePrivacyData?.map((e, i) => (
                      <SettingsRadioButton
                        id={e.id}
                        key={i}
                        name={e.photoPrivacy}
                        selected={e.selected}
                        radiotext={e.photoPrivacy}
                        onChange={(item) => {
                          //console.log("radio", item.target.value);
                          // if ((i === 1 || i === 2) && props.isPaid == "N") {
                          //   setShowWarningpopup(true);
                          //   return;
                          // }
                          var newVal = photoePrivacyData.map((ele, i) => {
                            if (item.target.value === ele.photoPrivacy) {
                              return { ...ele, selected: "Y" };
                            } else {
                              return { ...ele, selected: "N" };
                            }
                          });
                          setPhotoPrivacy(newVal);

                          if (i === 2) {
                            setShowPasswordpopup(true);
                          } else {
                            setPhotoPassword("")
                          }
                        }}
                        isFrom={"photo"}
                      />
                    ))}

                  

                    <button
                          
                          // disabled={disableSave}
                          onClick={() => {
                            apiCallPrivacySettiting();
                          }}
                          className={`  mt-[1rem] mb-[1rem] flex items-center space-x-2 justify-center  font-bold text-white bg-[#D10A11] px-[2rem] rounded-[4px] py-2 text-[12px] lg:text-[13px]`}>
                          
                          <span className="truncate">
                            {settingSave ? trans('my_profile.saving') : trans('my_profile.save')}
                          </span>
                        </button>
                  </div>
                </div>
              }
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettingsComponents;
