import React, { useState, useEffect, useContext, useRef } from "react";
import { ProfileAccordianComponent } from "../ViewMessageComponents/AccordianComponents";
import BasicPeference from "./PartnerPreference/BasicPeference";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST, GET } from "../../Services/api_services";
import { EditButton, SaveCancelButton } from "./EditProfileUtills";
import PhysicalAttributes from "./PartnerPreference/PhysicalAttributes";
import EducationPreference from "./PartnerPreference/EducationPreference";
import AstrologicalPreference from "./PartnerPreference/AstrologicalPreference";
import HabitsPreference from "./PartnerPreference/HabitsPreference";
import config from "../../config";
import LoaderGif from "../../Gif/loader.gif"
import PlusIcon from "../../Images/Path 3979.svg"
import AccrodianSelectImage from '../../Images/Group 930@2x.png'
import { useLocation, useNavigate } from "react-router-dom";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";
// import Constants from "../../Constants/constants";
// import { useDispatch, useSelector } from "react-redux";
// import { clearDynamicPixel, dynamicPixel } from "../../Store/Payment/Action";
// import Constants from "../../Constants/constants";
// import $ from 'jquery'
import Constants, {
  EventKeys,
  EventName,
} from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OverflowToolTip from "../utils/OverflowTooltip";
import { NavBarContext } from "../../Contexts/NavBarContext";
import * as MatchSelector from "../../Store/KalyanLite/selectors";


const ProfilePartnerPreferences = (props) => {
  // const dispatch = useDispatch()
  const { loginDetail, logout } = useContext(LoginContext);

  const { menuSelect, setMenuSelect,completeNow,setCompleteNow,packStatusData, setPackStatusData,microtrackidData, setMicrotrackidData } = useContext(NavBarContext);

  console.log(microtrackidData,'microtrackidData');
  
    const {initialMatchDataPopup} = useSelector(MatchSelector.getRecommendedMatches)


  const navigate = useNavigate()
  const location = useLocation()
  console.log("ProfilePartnerPreferences-location", completeNow);
  const [managePreferenceData, setPreferenceData] = useState();
  const submitBtnRef = useRef(null);
  const [isLoading,setIsLoading] =useState(false)
  useEffect(() => {
    // apiCallManagePreference();
    setPreferenceData(props.managePreferenceData);
  }, [props?.managePreferenceData]);
  const isV3User = localStorage.getItem("isV3User")

  const { t:trans } = useTranslation();

  console.log(props?.microisFrom,'kmjnhn');
  
  const  liteNotification = initialMatchDataPopup?.currentMatch?.matchdata?.popup[0]?.notificationId;


  // const apiCallManagePreference = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //   };

  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${EndPoints.managepreferencesdataUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setPreferenceData(data?.data);
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  useEffect(() => {
    console.log(props?.dashboard1Data?.editfromadmin,"props?.contentCard");
  },[props?.dashboard1Data])

  const partnerPrefSearchApi =async ()=>{
    let request = {
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.partnerpreferencesearch()}`,
      request
    );

    if (statusCode === 200) {
      if (isV3User) {
        const locState = {
          isFrom: "basicAdvanceSearch",
          menu: "savepreferences",
          searchTerms: data?.data,
          ppedited: false,
          isBasicSearch: false,
          loading: true
        }
        sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify(locState))
        navigate(RouteSegments.MATCH_LITE, {
          state: locState,
        });
      } else {
        searchPageNewApi(data?.data)
      }

    } else if (statusCode === 401) {
      logout();
    }
  }

  const searchPageNewApi =async (request)=>{

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.searchPageNew()}`,
      request
    );

    if (statusCode === 200) {
      navigate(RouteHelper.getSavePartnerPreferenceUrl, {
        state: {contentData:data?.data?.contentCard, dataSet:data?.data, data: data?.data?.filtermatches, isfrom:"partner_profile_search",banner:data?.data?.bannerWebright, topBanner: data?.data?.bannerWebTop,  menu: "savepreferences",request : request },
      });
    } else if (statusCode === 401) {
      logout();
    }
  }




  const formik = useFormik({
    initialValues: {
      maritalStatus: managePreferenceData?.maritalStatus
        ? managePreferenceData?.maritalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      agefrom: managePreferenceData?.minage ? managePreferenceData?.minage : "",
      ageTo: managePreferenceData?.maxage ? managePreferenceData?.maxage : "",
      country: managePreferenceData?.country
        ? managePreferenceData?.country
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      state: managePreferenceData?.state
        ? managePreferenceData?.state
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      city: managePreferenceData?.city
        ? managePreferenceData?.city
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      motherTongue: managePreferenceData?.motherTongue
        ? managePreferenceData?.motherTongue
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      religion: managePreferenceData?.religion
        ? managePreferenceData?.religion
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        denomination: managePreferenceData?.domain
        ? managePreferenceData?.domain
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      cast: managePreferenceData?.caste
        ? managePreferenceData?.caste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        subcast: managePreferenceData?.subCaste
        ? managePreferenceData?.subCaste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      minheight: managePreferenceData?.minheightId
        ? managePreferenceData?.minheightId
            .filter((e) => e.selected === "Y")?.at(0)?.id ?? null
        : null,
      maxheight: managePreferenceData?.maxheightId
        ? managePreferenceData?.maxheightId
            .filter((e) => e.selected === "Y")?.at(0)?.id ?? null
        : null,

      physicalStatus: managePreferenceData?.physicalStatus
        ? managePreferenceData?.physicalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      education: managePreferenceData?.education
        ? managePreferenceData?.education

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      occupation: managePreferenceData?.occupation
        ? managePreferenceData?.occupation

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      employment: managePreferenceData?.employedIn
        ? managePreferenceData?.employedIn
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      star: managePreferenceData?.star
        ? managePreferenceData?.star
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      raasi: managePreferenceData?.raasi
        ? managePreferenceData?.raasi
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      dosam: managePreferenceData?.dosham
        ? managePreferenceData?.dosham
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      foodhabits: managePreferenceData?.food
        ? managePreferenceData?.food
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      smoking: managePreferenceData?.smoking
        ? managePreferenceData?.smoking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      drinking: managePreferenceData?.drinking
        ? managePreferenceData?.drinking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      income_from: managePreferenceData?.minsalaryId
      ? managePreferenceData?.minsalaryId
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)[0] || ""
      : "",
      income_to: managePreferenceData?.maxsalaryId
      ? managePreferenceData?.maxsalaryId
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)[0] || ""
      : ""
      
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),

    onSubmit: (values) => {
      if(props?.dashboard1Data?.editfromadmin=="N"){
        setIsLoading(true)
        apiCallSubmitPreference(values);
      }
     
    },
  });

  useEffect(() => {


    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])

  console.log({formik},'lll');
  useEffect(() => {}, [managePreferenceData]);
  useEffect(() => {}, [formik]);

  // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? 
  // sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId


  const NotificationId =
  localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
      ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
        props?.showdatas ||
        props?.message ||
        props?.ShowpopupDataNotification
      : userData?.popup[0]?.notificationId ||
      props?.message ||
      props?.ShowpopupDataNotification;


      const [hasSelectInputChanged, setHasSelectInputChanged] = React.useState(false);

      const handleSelectInputChange = (fieldName, value) => {
        // Update the field value in Formik
        formik.setFieldValue(fieldName, value);
      
        // Mark that a SelectInput field has been changed
        setHasSelectInputChanged(true);
      };


  const apiCallSubmitPreference = async (value) => {
    const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl)
    let notificationId = null;

    if (loginUrl?.includes("editpreferences")|| props?.isFromInitialPopup || props.isFromTrailPopup) {
      if(liteNotification){
        notificationId = liteNotification;
      }else if(props?.contentCard?.message){
        notificationId = props?.contentCard?.message;
      }else if(userData?.popup[0]?.notificationId){
        notificationId = userData?.popup[0]?.notificationId;
      }else{
        notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
      }
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }

  console.log(props.isFromTrailPopup,'props.isFromTrailPopup',NotificationId,notificationId);
    
let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      age_from: value.agefrom,
      age_to: value.ageTo,
      height_from: value.minheight,
      height_to: value.maxheight,
      maritalStatusId: value.maritalStatus,
      motherTongueId: value.motherTongue,
      religionId: value.religion,
      domainId:value.religion.includes(Constants.christialId)?value.denomination:[],
      casteId: value.cast,
      subcasteId: value.subcast,
      countryId: value.country?.map(id=>parseInt(id)),
      stateId:value.state?.map(id=>parseInt(id)),
      cityId: value.city?.map(id=>parseInt(id)),
      physicalStatusId: value.physicalStatus,
      educationId: value.education,
      employedInId: value.employment,
      occupationId: value.occupation,
      starId: value.star,
      raasiId: value.raasi,
      doshamId: value.dosam,
      foodId: value.foodhabits,
      drinkingId: value.drinking,
      smokingId: value.smoking,
      isContent: props?.contentCard?.name,
      contentId: props?.contentCard?.contentId,
      notificationId: props.KMNotificationId ? props.KMNotificationId : (notificationId ?? undefined),
      // microtrackId:microtrackidData,
      income_from: managePreferenceData?.minsalaryId
      ? managePreferenceData?.minsalaryId
          .filter((e) => e.id == value.income_from)
          .map((e, i) => e.name).toString()
      : "" ,
      income_to: managePreferenceData?.maxsalaryId
      ? managePreferenceData?.maxsalaryId
          .filter((e) => e.id == value.income_to)
          .map((e, i) => e.name).toString()
      : "" ,
      ...(props.isFromTrailPopup === 'Y' &&  { editviatrialpack: props.isFromTrailPopup }),
      ...(props.isFromTrailPopup == 'Y' && {notificationId:NotificationId}),
      ...(props.messageId && {messageTypeId: props.messageId}),
      ...(props.kmcareId && {kmcareId: props.kmcareId}),
    };

    if (props?.microisFrom && hasSelectInputChanged) {
      request = {
          ...request,
          microtrackId: microtrackidData, // Ensures microtrackId is always included
      };
  }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencessaveUrl()}`,
      request
    );   

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        if(submitBtnRef.current === "Save"){
          setIsLoading(false)
          props.callBackReload();
          onClickTrack(EventName.profilePartnerPrefernceSaveButton , {[EventKeys.profilePartnerPrefernceSave]:value})
        }else if(props.isFromTrailPopup == 'Y'){
          props.postData()
          setIsLoading(false)
          setCompleteNow(true);
          navigate(RouteSegments.MATCH_LITE,{ replace: true })
        }else{
          if(props.isFromTrailPopup === 'Y'){
            return '';
          }else{
            partnerPrefSearchApi()
          }
        }
        
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

//   useEffect(() => {
//     if(localStorage.getItem(Constants.loginLocalStorageKeys.isFirstLogin)){
//        //only enable in LIVE if client asked
//        if(localStorage.getItem(Constants.loginLocalStorageKeys.verificationLoginFrom) !== "partial"){
//           dispatch(dynamicPixel("data"))
//        }
//        localStorage.removeItem(Constants.loginLocalStorageKeys.verificationLoginFrom)
//        localStorage.removeItem(Constants.loginLocalStorageKeys.isFirstLogin)
//     }
//  },[])


  // const setConversionScript = (dynmaicPixel)=>{
  //   $('head').append(
  //       `
  //       <div class="pixelScript">
  //       ${dynmaicPixel}

  //       <!-- Google tag (gtag.js) -->
  //       <script class="pixelScript" async src="https://www.googletagmanager.com/gtag/js?id=AW-875555676"></script>
  //   <script class="pixelScript">
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
    
  //     gtag('config', 'AW-875555676');
  //   </script>
    
  //   <!-- Event snippet for Sign-up Jan 17th 2022 - Kalyan Matrimony Full Profiles conversion page -->
  //   <script class="pixelScript">
  //     gtag('event', 'conversion', {'send_to': 'AW-875555676/t6U4CLeYo5EDENzWv6ED'});
  //   </script>
    
    
  //   <!-- Meta Pixel Code-->
  //     <script class="pixelScript">
  //     !function(f,b,e,v,n,t,s)
  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //     n.queue=[];t=b.createElement(e);t.async=!0;
  //     t.src=v;s=b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t,s)}(window,document,'script',
  //     'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', '276119551481259');
  //     fbq('track', 'Complete Registration');
  //     </script>
  //     <noscript class="pixelScript">
  //     <img height="1" width="1"
  //     src="https://www.facebook.com/tr?id=276119551481259&ev=PageView
  //     &noscript=1"/>
  //     </noscript>
  //     <!-- End Meta Pixel Code -->

  //     <!-- Meta Pixel Code -->

  //     <script>

  //     !function(f,b,e,v,n,t,s)

  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?

  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};

  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

  //     n.queue=[];t=b.createElement(e);t.async=!0;

  //     t.src=v;s=b.getElementsByTagName(e)[0];

  //     s.parentNode.insertBefore(t,s)}(window, document,'script',

  //     'https://connect.facebook.net/en_US/fbevents.js');

  //     fbq('init', '282692746417295');

  //     fbq('track', 'CompleteRegistration');

  //     </script>

  //     <noscript><img height="1" width="1" style="display:none"

  //     src="https://www.facebook.com/tr?id=282692746417295&ev=CompleteRegistration&noscript=1"

  //     /></noscript>

  //     <!-- End Meta Pixel Code -->

  //     <!-- Meta Pixel Code -->
  //     <script>
  //     !function(f,b,e,v,n,t,s)
  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //     n.queue=[];t=b.createElement(e);t.async=!0;
  //     t.src=v;s=b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t,s)}(window, document,'script',
  //     'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', '691644631999638');
  //     fbq('track', 'CompleteRegistration');
  //     </script>
  //     <noscript><img height="1" width="1" style="display:none"
  //     src="https://www.facebook.com/tr?id=691644631999638&ev=PageView&noscript=1"
  //     /></noscript>
  //     <!-- End Meta Pixel Code -->


  //     <!-- Meta Pixel Code -->
  //     <script>
  //     !function(f,b,e,v,n,t,s)
  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //     n.queue=[];t=b.createElement(e);t.async=!0;
  //     t.src=v;s=b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t,s)}(window, document,'script',
  //     'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', '276119551481259');
  //     fbq('track', 'CompleteRegistration');
  //     </script>
  //     <noscript><img height="1" width="1" style="display:none"
  //     src="https://www.facebook.com/tr?id=276119551481259&ev=PageView&noscript=1"
  //     /></noscript>
  //     <!-- End Meta Pixel Code -->

  //     <!-- Meta Pixel Code -->

  //     <script>

  //     !function(f,b,e,v,n,t,s)

  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?

  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};

  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

  //     n.queue=[];t=b.createElement(e);t.async=!0;

  //     t.src=v;s=b.getElementsByTagName(e)[0];

  //     s.parentNode.insertBefore(t,s)}(window, document,'script',

  //     'https://connect.facebook.net/en_US/fbevents.js');

  //     fbq('init', '125380406059651');

  //     fbq('track', 'CompleteRegistration');

  //     </script>

  //     <noscript><img height="1" width="1" style="display:none"

  //     src=https://www.facebook.com/tr?id=125380406059651&ev=PageView&noscript=1

  //     /></noscript>
  //     <!-- End Meta Pixel Code -->

  //   </div>
  //   `
  //   )
  // }

  // let { dynmaicPixelData } = useSelector(state => ({
  //   dynmaicPixelData: state.Payment?.dynmaicPixelData?.data
  // }));  

  // useEffect(() => {
  //   if(dynmaicPixelData?.pixels){
  //       setConversionScript(dynmaicPixelData?.pixels)
  //   }
  // }, [dynmaicPixelData?.pixels])


  //  useEffect(() => {
   
  //    return () => {
  //     dispatch(clearDynamicPixel())
  //     $('.pixelScript').remove()
  //    }
  //  }, [dispatch])

  const { onClickTrack } = useAnalytics()

  const countFilledFields = () => {
    const values = formik.values;
    let count = 0;

    for (const key in values) {
        if (Array.isArray(values[key])) {
            count += values[key].length; // Count the number of selected items in arrays
        }
    }

    return count;
};


const onClickCancel = () =>{
  // alert('u')
  setCompleteNow(true);
  props?.setCompleteNow(true);
  navigate(RouteSegments.MATCH_LITE,{ replace: true });
}
  return (
    <div>
       {isLoading ?
         <center><img src={LoaderGif} alt="loading..." className='fixed top-[30%] md:left-[40%] lg:left-[50%] left-[30%] z-[99]' /></center>
         : ''}
         
      <form onSubmit={formik.handleSubmit}>       
        <div className=" md:block p-4 md:p-0">
          <div className={`mx-auto mt-8`}>
            <div className={`${isV3User ? "" : "md:px-6 py-1"} mx-auto bg-white`}>
              <div className={`${isV3User ? "border-[#EAE6DF] " : "rounded-[9px]  md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={trans('my_profile.basic_expectations')}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]" } flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] tracking-wider lg:text-[16px]`}
                  defaultView={true}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => {}}
                >
                  <div className="mb-8">
                    <div className="">
                      {/* <!-- edit part  --> */}
                      <BasicPeference
                        data={formik}
                        editbasicinfofreshData={managePreferenceData}
                        tab="profilePartner"
                        setOnchangeData={props.setOnchangeData}
                        isTrailPack={props.isFromTrailPopup}
                        handleSelectInputChange={handleSelectInputChange}
                        // updateFromData={(e)=>{
                        //   if(e.target.value > formik.values?.ageTo){
                        //     setPreferenceData({ ...managePreferenceData, "minage": e.target.value ,"maxage": e.target.value });
                        //   }else{
                        //     setPreferenceData({ ...managePreferenceData, "minage": e.target.value });
                        //   }
                        // }}
                        // updateToData={(e)=>{
                        //   setPreferenceData({ ...managePreferenceData,"minage": formik.values.agefrom, "maxage": e.target.value });
                        // }}
                      />
                    </div>
                  </div>
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>
          <div className={`mx-auto mt-8`}>
            <div className={`${isV3User ? "" : "md:px-6 py-1"} mx-auto bg-white`}>
              <div className={`${isV3User ? "border-[#EAE6DF] " : "rounded-[9px]  md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={trans('my_profile.physical_attributes')}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : " bg-[#E9E9E9] lg:py-[1rem]" } flex items-center justify-between tracking-wider w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                  defaultView={true}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => {}}
                >
                  <div className="lg:mb-8">
                    <PhysicalAttributes
                       handleSelectInputChange={handleSelectInputChange}

                      data={formik}
                      editbasicinfofreshData={managePreferenceData}
                      updateData={(e)=>{
                        setPreferenceData({ ...managePreferenceData, selected: e.selected === "Y" ? "N" : "Y" });
                      }}
                      setOnchangeData={props.setOnchangeData}
                      isTrailPack={props.isFromTrailPopup}
                    />
                  </div>
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>
          <div className={`mx-auto mt-8`}>
            <div className={`${isV3User ? "" : "md:px-6 py-1"} mx-auto bg-white`}>
              <div className={`${isV3User ? "border-[#EAE6DF] " : "rounded-[9px]  md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={trans('my_profile.professional_details')}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : " bg-[#E9E9E9] lg:py-[1rem]" } flex items-center tracking-wider justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                  defaultView={true}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => {}}
                >
                  <div className="md:mb-8 p-5 md:p-0">
                    {/* <p className="text-black mt-[1rem] md:pl[1rem] lg:pl-[2rem] font-semibold text-[9px] md:text-[15px] lg:text-[18px] ">
                      Education
                    </p> */}
                    <EducationPreference
                    handleSelectInputChange={handleSelectInputChange}
                      data={formik}
                      educationinfofreshData={managePreferenceData}
                      setOnchangeData={props.setOnchangeData}
                      isTrailPack={props.isFromTrailPopup}
                    />
                  </div>
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>
          <div className={`mx-auto mt-8`}>
            <div className={`${isV3User ? "" : "md:px-6 py-1"} mx-auto bg-white`}>
              <div className={`${isV3User ? "border-[#EAE6DF] " : "rounded-[9px]  md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={trans('my_profile.astrological_information')}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : " bg-[#E9E9E9] lg:py-[1rem]" } flex items-center justify-between tracking-wider w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                  defaultView={true}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => {}}
                >
                  <div className="md:mb-8 p-4 md:pl-4">
                    <AstrologicalPreference
                    handleSelectInputChange={handleSelectInputChange}
                      data={formik}
                      edithoroinfofreshData={managePreferenceData}
                      setOnchangeData={props.setOnchangeData}
                      isTrailPack={props.isFromTrailPopup}
                    />
                  </div>
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>
          <div className={`mx-auto mt-8`}>
            <div className={`${isV3User ? "" : "md:px-6 py-1"} mx-auto bg-white`}>
              <div className={`${isV3User ? "border-[#EAE6DF] " : "rounded-[9px]  md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={trans('my_profile.habits')}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : " bg-[#E9E9E9] lg:py-[1rem]" } flex items-center tracking-wider justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px]`}
                  defaultView={true}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => {}}
                >
                  <div className="md:mb-8 p-5 md:pl-4">
                    <HabitsPreference
                    handleSelectInputChange={handleSelectInputChange}
                      data={formik}
                      managePreferenceData={managePreferenceData}
                      setOnchangeData={props.setOnchangeData}
                      isTrailPack={props.isFromTrailPopup}
                    />
                  </div>
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>

          <div className="w-full flex space-x-2 lg:w-3/4 md:mx-auto lg:space-x-3  justify-center items-center mt-[1rem] mb-[6rem] ">
          { props.isFromTrailPopup === 'Y' ? 
            <>
              <button
              onClick={onClickCancel}
                
              
              className={`flex w-full items-center justify-center  h-[1rem]   border border-[#707070]
              px-[1rem]  whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] cursor-pointer`}
              >
                <OverflowToolTip as="p" className="truncate">
                  {trans('my_profile.cancel')}
                </OverflowToolTip>
              </button>
              <button
                type="submit"
                onClick={()=>{
                  if(props?.dashboard1Data?.editfromadmin=="Y"){
                    submitBtnRef.current = "Save"
                  }
                  if(props.isFromTrailPopup == 'Y'){
                    onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Submit_Button)
                    setCompleteNow(true);
                  }
                }}
                className={`w-full flex items-center justify-center  h-[1rem]  text-white  ${props.onchangedata ? " bg-[#8E8E8E] cursor-not-allowed":"cursor-pointer border border-[#D10A11] bg-[#D10A11]"}  px-[1rem]  whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] truncate`}
              >
                <OverflowToolTip as="p" className="truncate">
                  {trans('my_profile.save')}
                </OverflowToolTip>
              </button> 
              </>
            :  
            <>
            <button
              type="submit"
              onClick={()=>{
                if(props?.dashboard1Data?.editfromadmin=="Y"){
                  submitBtnRef.current = "Save"
                }
                if(props.isFromTrailPopup == 'Y'){
                  onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Submit_Button)
                }
              }}
              className={`w-full flex items-center justify-center  h-[1rem]  text-white  border ${props?.dashboard1Data?.editfromadmin=="Y"?"cursor-not-allowed bg-[#d68789]":"cursor-pointer bg-[#D10A11]"} border-[#D10A11] px-[1rem] rounded-[5px] font-bold   py-4 text-[12px] truncate `}
            >
              <OverflowToolTip as="p" className="truncate">{trans('my_profile.save_changes')}</OverflowToolTip>
            </button>
            <button
              type="reset"
              onClick={() => {
                if(props?.dashboard1Data?.editfromadmin=="N"){
                  props.callBackReload();
                }
                //console.log("calles discard");
                window.scrollTo(0, 0)
               
                onClickTrack(EventName.profilePartnerPrefernceCancelButton)
              }}
              className={`w-full flex items-center justify-center  h-[1rem] font-bold text-[#7A7A7A] bg-white border border-[#9F9F9F] ${props?.dashboard1Data?.editfromadmin=="Y"?"cursor-not-allowed":"cursor-pointer "} px-[1rem] rounded-[5px]  py-4 text-[12px]  truncate`}
            >
              <OverflowToolTip as="p" className="truncate">{trans('my_profile.discard_changes')}</OverflowToolTip>
            </button>
            <button
              type="submit"
              onClick={()=>{
                submitBtnRef.current = "SaveSearch" 
              ; onClickTrack(EventName.profilePartnerPrefernceSaveSearchButton)
              if(props.isFromTrailPopup == 'Y'){
                onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Submit_Button)
              }
            }}
              className={`w-full hidden md:flex items-center justify-center  h-[1rem]  text-white  border border-[#D10A11] px-[1rem]  ${props?.dashboard1Data?.editfromadmin=="Y"?"bg-[#d68789] cursor-not-allowed":"cursor-pointer bg-[#D10A11]"} whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] truncate`}
            >
             <OverflowToolTip as="p" className="truncate">{trans('my_profile.save_changes__search')}</OverflowToolTip>
            </button>
            <button
              type="submit"
              onClick={()=>{
                submitBtnRef.current = "SaveSearch" ; 
                onClickTrack(EventName.profilePartnerPrefernceSaveSearchButton)
                if(props.isFromTrailPopup == 'Y'){
                  onClickTrack(EventName.Trial_Pack_popup_Set_Your_Expectations_Submit_Button)
                }
              }}
              className={`flex md:hidden w-full items-center justify-center  h-[1rem]  text-white  border border-[#D10A11] px-[1rem]  ${props?.dashboard1Data?.editfromadmin=="Y"?"cursor-not-allowed bg-[#d68789]":"cursor-pointer bg-[#D10A11]"} whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] truncate`}
            >
              <OverflowToolTip as="p" className="truncate">{trans('my_profile.save__search')}</OverflowToolTip>
            </button>
            </>}
          </div>
        </div>      
      </form>
    </div>
  );
};

export default ProfilePartnerPreferences;
